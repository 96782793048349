 <template>
    <div class="hbox">
      <slot/>
    </div>
</template>

<script>
    export default {
        name: "Hbox"
    }
</script>

<style lang="scss" scoped>
  @import "../../assets/kit.scss";

</style>
