<template>
  <div  :class="couleur" :style="SphereStyle">
  </div>
</template>

<script>
export default {
  name: "Sphere",
  props: {
    couleur : {
    type:String,
      default:"accent",
    },
    taille:{
    type:String,
      default:"50",
    },
    top:{
      type:String,
      default:null,
    },
    left: {
      type:String,
      default:null,
    },
    bottom: {
      type:String,
      default:null,
    },
    right:{
      type:String,
      default:null,
    },
    position:{
      type:String,
      default:"absolute",
    },

},
  computed: {
    SphereStyle(){
      return {

        width: this.taille+'px',
        height: this.taille+'px',
        top: this.top+'px',
        left:this.left+'px',
        right:this.right+'px',
        bottom:this.bottom+'px',
        position:this.position
      }
    },
  }
}
</script>

<style lang="scss" scoped>

div {
  z-index: 0;
  background: var(--gip-accent-color);
  border-radius: 180px;
  position: absolute;
}

.accent{
  background: var(--gip-accent-color);
}

.action{
  background: var(--gip-action-color);
}

.grey{
  background: var(--gip-sphere-grey);
}

.pink{
  background: #E0C5D8 ;
}

.green{
  background: #2E756E ;
}
.yellow{
  background: var(--Buttercup-color-f4b322);
}

.sphere-legend{
  position: unset;
}





</style>