
import CClass from '../CClass.js'
import CField from '../CField.js'
import CObjectSet from '../CObjectSet.js'
import CObject from '../CObject.js'

/**
 * CRelationField
 * @class com.omniapp.kit.model.field.CRelationField
 * @extends com.omniapp.kit.model.CField
 */
function CRelationField(cclass, cfield) {
    CField.call(this, cclass, cfield, 'CRelationField');

    var _targetClass = null;
    var _relationType = null;
    var _compositionType = null;
    var _multiple = null;

    /** relation type, one of ASSOCIATION, COMPOSITION */
    this.relationType = function() {
        if (_relationType == null)
            _relationType = this.node.attr('relationType');
        return _relationType;
    };

    /** retrieve target class */
    this.targetClass = function() {
        if (_targetClass == null) {
            var tc = this.node.attr('targetClass');
            _targetClass = this._model.cclass(tc);
        }
        return _targetClass;
    };

    /** retrieve multiple state */
    this.multiple = function() {
        if (_multiple == null) {
            var m = this.node.attr('multiple');
            _multiple = m.toLowerCase() === 'true';
        }
        return _multiple;
    };

    /** CRelationField -> display String */
    this.stringValue = function(val) {
        // check for undefined or null
        if (val == null)
            return null;
        else if (typeof val === 'string')
            return val;
        else if (val instanceof CObject)
            return val.id;
        throw new Error("stringValue is not implemented by CRelationField, val=" + val + " field=" + this.id());
    };

    /** convert for relation */
    this.convert = function(val) {
        if ((val === undefined) || (val === null))
            return null;
        else if (val instanceof CObjectSet)
            return val;
        else if (val instanceof CObject)
            return val;
        else if (typeof val === 'string')
            return val;
        else if (Object.getPrototypeOf(val) === Object.prototype) {
            return new CObject(this.targetClass(), null, val)
        }
        else
            throw new Error("convert is not implemented by CRelationField, val=" + val + " field=" + this.id());
    };

    this.displayValue = function(val) {
        if (val === null)
            return "";
        else if (val instanceof CObject)
            return val.name();
        else {
            throw new Error("displayValue is not implemented by relation for set relation=" + this.id());
        }
    };

} // end class CRelationField

export default CRelationField
