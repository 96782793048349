<template>
  <div v-if="data.image != null" v-on:click="navigate" class="gip-card vbox">
    <img v-lazy="'/images/actualites/'+ data.image ">
    <p class="titre">{{ data.titre }}</p>
    <p class="text"><span>{{ data.categorie }}</span> - {{ formatDate(data.dateCreation, 'DD MMMM YYYY') }}</p>
    <p class="text">
      <PlusLogo class="logo"/>
      En savoir plus
    </p>
    <div class="flex-grow-1"></div>
    <div class="line"></div>
    </div>
</template>

<script>

import PlusLogo from "../../assets/svg/plus_rounded_action_logo.svg";
import GipMixin from "./GipMixin";

export default {
  name: "CardActualite",
  mixins: [GipMixin],
  components: {PlusLogo},
  props: {data:{
      default: () => ({
        image: null
      })
    }},
  methods: {
    navigate() {
      window.scrollTo(0, 0)
      this.$router.push({name: "ActualiteId", params: {id: this.data.__id }})
    }

  }
}
</script>

<style  lang="scss" scoped>

.logo{
  margin-right: 13px  ;
}

img{
  width: 315px;
  height: 196px;
  display: block;
  border-radius: 5px;
  object-fit: contain;
  object-position: top;
}
.titre{
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  margin-top:23px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text{
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 53px;
  margin-top:17px;

  span{
    color: var(--gip-accent-color);
    font-weight: 700;
    line-height: 22px;
  }
}
.line{
  border: 3px;
  width: 100%;
  height: 3px;
  margin-top:24px;
  background: var(--gip-tint-color);
  opacity: 0.08;

}
.gip-card{
  padding: 20px 19px 20px 19px;
  vertical-align: top;
  background: white;
  border-radius: 5px;
  display: flex;
  max-width: 300px;
  box-shadow: 0px 3px 23px #0443700F;

  text-align: left;
  &:hover{
    box-shadow: 0px 12px 23px #0000001A;
    cursor: pointer;
    .line{

      background: var(--gip-action-color);
      opacity: 1;

    }
  }


}

</style>