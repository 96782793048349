<template>
    <div v-show="nom == currentPanel">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'WizardPanel',
  props: ['nom', 'title', 'description', 'nextButton', 'isValid', 'size'],
  inject: ['currentPanel', 'addPanel'],
  mounted() {
    this.addPanel(this)
  }

}
</script>