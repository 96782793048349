
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CNumberField
 * Javascript type is Number
 * @class com.omniapp.kit.model.field.CNumberField
 * @extends com.omniapp.kit.model.CField
 */
function CNumberField(cclass, cfield) {

    CField.call(this, cclass, cfield, 'CNumberField');

    var _type = null;
    var _decimals = null;

    /** return type, one of INTEGER, DOUBLE, FLOAT */
    this.type = function() {
        if (this._type == null)
            this._type = this.node.attr('type');
        return this._type;
    };

    /** retrieve num of decimals */
    this.decimals = function() {
        if (this._decimals == null) {
            this._decimals = parseInt(this.node.attr('numDecimal'));
            if (this._decimals === undefined)
                this._decimals = 2;
        }
        return this._decimals;
    };

    /** String -> Number */
    this.convert = function(val) {

        // null / empty value
        if ((val === undefined) || (val === null) || (val === ''))
            return null;

        // base type
        if (typeof val === 'number')
            return val;

        // INTEGER
        if (this.type() === 'INTEGER') {
            var converted = parseInt(val);
            if (isNaN(converted)) {
                // throw new Error("cannot convert " + val + " to int");
                console.error("cannot convert " + val + " to int");
                return null;
            }
            return converted;
        }

        // FLOAT/DOUBLE
        else {
            // allow , or . as decimal separator, take care of thousand sep + decimal sep
            if (val.contains(",")) {
                if (val.contains("."))
                    val = val.replace(".", "");
                val = val.replace(",", ".");
            }
            var c = parseFloat(val);
            if (isNaN(c))
                throw new Error('cannot convert ' + val + 'to decimal');
            return c;
        }
    };

    /** Number -> String */
    this.stringValue = function(val) {
        if (val === null)
            return null;
        return val.toString();
    };

    /** Number -> display String */
    this.displayValue = function(val) {
        if (this.formatter() !== null) {
            var f = this.formatter();
            return f.format(this, val);
        }
        if (val === null)
            return '';
        else if (this.type() === 'INTEGER')
            return this.stringValue(val);
        else {
            return val.format(',', '.', this.decimals());
        }
    };
} // end class CNumberField

export default CNumberField
