<template>
  <router-view v-if="initDone" id="app"/>
<!--  <Footer/>-->
</template>

<script>
  import kit from './kit/kit.js'
  import GipMixin from "./components/ui/GipMixin";
  export default {
  name: 'SiteGip',
  mixins: [GipMixin],
  // components: {Header, Footer}

    data() {
    return {
      initDone : false,

    }
    },
    // computed: {
    //   initDone: function() {
    //     return kit.initialized
    //   }
    // },
    async created() {

     // let f = async() => {
       await kit.init("/services", "/resources/model.xml",
           // post framework load callback
           () => {
          kit.log.level('warning')
             console.log('kit init done')
              // kit.services.service("auth?action=info", null, (data) => {
             //   console.log("data")
             //   console.log(data)
             //
             //   if (!data || data.length == 0) {
             //     this.disconnect()
             //   }
             //   else {
             //     // alert("on a bien un user " + data.__cclass)
             //     if (data.__cclass == "Employeur"){
             //       this.connectEmployeur(data)
             //     }else{
             //       this.connectFinanceur(data)
             //     }
             //   }
             //   // this.state.user = !data || data.length == 0 ? null : data
             //   // this.state.isEmployeur = true
             //   // this.state.is
             //   // alert("info, user=" + self.user)
             // }, (error) => {
             //   console.log("big pppppp")
             // })
             // // charge les données du site
             // //    kit.services.query({cclass: 'SiteGip', json: true, depth:2},
             // //        (data) => {
             // //              this.site = data[0]
             // //           // Vue.prototype.$options = data[0]
             // //          console.log("BIM LE SITE " + this.site.montantMinimumBrut)
             // //       },
             // //        (error) => {
             // //      alert("erreur chargement donnés du site")
             // //        }
             // //       )
             //
             // // self.$emit('model-loaded')
           });

      // n'appelle le service que si besoin, une fois qu'il est dans le contexte c'est ok
         await kit.call(async () => {
            await kit.services.query({cclass: 'SiteGip', json: true, depth: 3},
                async (data) => {
                   // PENDING le wait de merde pour palier au async :D
                  // await new Promise(r => setTimeout(r, 700));
                  // END PENDING
                  this.postLoad(() => {
                    this.state.site = data[0]

                    this.site = this.state.site
                    //console.log("XXX " + this.site.montantMinimumBrut)
                    this.initDone = true
                  })

                  // alert("montantMinimumBrut=" + this.site.montantMinimumBrut)
                  // Vue.prototype.$options = data[0]
                  // console.log("BIM LE SITE " + this.site.montantMinimumBrut)

                },
                (error) => {
                  alert("erreur chargement données du site")
                }
            )
          })



      // console.log("appelle auth apres init")
      //  kit.services.service2("auth?action=info", {
      //   onSuccess: (data) => {
      //     if (!data || data.length == 0 || (data.__cclass == null)) {
      //       // alert("disconnect")
      //       this.disconnect()
      //     }
      //     else {
      //       console.log("on a bien un user " + data.__cclass)
      //       if (data.__cclass == "Employeur") {
      //         this.connectEmployeur(data)
      //       }
      //       else {
      //         this.connectFinanceur(data)
      //       }
      //     }
      //   },
      //   onError: (error) => {
      //     //   self.user = null
      //   }})


       // await kit.services.query({cclass: 'SiteGip', json: true, depth: 2},
       //     (data) => {
       //       this.site = data[0]
       //       // Vue.prototype.$options = data[0]
       //       console.log("BIM LE SITE " + this.site.montantMinimumBrut)
       //     },
       //     (error) => {
       //       alert("erreur chargement donnés du site")
       //     }
       // )
     // }
     // f()
    },
    async mounted() {

        // kit.services.query({cclass: 'SiteGip', json: true, depth: 2},
        //     (data) => {
        //       this.site = data[0]
        //       // Vue.prototype.$options = data[0]
        //       console.log("BIM LE SITE " + this.site.montantMinimumBrut)
        //     },
        //     (error) => {
        //       alert("erreur chargement donnés du site")
        //     }
        // )

      console.log("appelle auth")

      // auth pour login la haut
      // kit.call(() => {

        console.log("appelle auth apres init")
        await kit.services.service2("auth?action=info", {
          onSuccess: (data) => {
            if (!data || data.length == 0 || (data.__cclass == null)) {
               // alert("disconnect")
            this.disconnect()
            }
            else {
              console.log("on a bien un user " + data.__cclass)
              if (data.__cclass == "Employeur") {
                this.connectEmployeur(data)
              }
              else {
                this.connectFinanceur(data)
            }
          }
        },
            onError: (error) => {
          //   self.user = null
        }})

      // })




    }
}
</script>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import '../node_modules/buefy/dist/buefy.css';

@import "assets/kit.scss";
@import "assets/gip.scss";

$primary: red;


</style>
