<script src="../../router/index.ts"></script>
<template>
  <div  class="gip-btn" @click='doLien($event)'>
    <a v-bind:class="type + (this.disabled === true ? ' btnDisabled' : '')" href='#'>{{ titre }}
     <span v-if="withLogo" class="arrow-icon">
      <FlecheLogo v-if="type == 'action'" color="#C22292" class="icon" />
       <FlecheLogo v-else color="#FFFFFF" class="icon" />
      </span>
    </a>
  </div></template>

<script>
// TODO : voire pourquoi disabled toujours en off
import FlecheLogo from "@/components/Icons/FlecheLogo";
export default {
  name: "btnAction",
  props:
      {
        disabled: {
          type: Boolean,
          required: false,
          default: false
        },
        titre:{
        type: String,
          required: true
        },
        type: {
          type: String,
          default: "default",
        },
        withLogo: {
          type: Boolean,
          default: true,
        },
        lien:{
          type:String,
          default:null
        },
        goTo:{
          type: String,
          default:null
        },
        href:{
          type: String,
          default:null
        },
        to:{
          type: String,
          default:null
        },
        click:{
          default:null
        },
        once:{
          type: Boolean,
          default:false,
        }
      },
  components: {FlecheLogo},
  data(){
    return {
      triggered:false
    }
  },
  methods:{
    doLien(e){
      e.preventDefault()
    if((!this.triggered && this.once ) || !this.once) {
      this.triggered = true;

      if (this.href != null && !this.disabled) {
        window.open(this.href, '_blank');
      }
      if (this.goTo != null && !this.disabled) {
        this.goToElem(this.goTo)
      }
      if (this.click != null && !this.disabled) {
        this.click();
      }
      if (this.to != null && !this.disabled) {
        window.scrollTo(0, 0)
        this.$router.push({name: this.to})
      }
      //this.closest("form").submit(); return false;
    }
    },
    goToElem(elem){
      let el = document.getElementById(elem);
      console.log(this.goTo)

      el.scrollIntoView({behavior:"smooth"})
    },
    reset(){
      this.triggered = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.gip-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
  border-radius: 5px;


  a {
    background: var(--gip-action-color);
    border-radius: 5px;
    text-decoration: none;
    color: white;
    display: inline-block;
    padding: 0.8em 2em;
    border: solid 2px var(--gip-action-color); 
    cursor: pointer;
    display: block;
    white-space: nowrap;
    text-align: center;
  }

  a.action {
    border: none;
  }
  a.outline {
    color: var(--gip-action-color); 
    background-color: white;
  }

  .arrow-icon {
    transition: margin-left 0.5s ease-in-out;
  }

  a:hover {
    background: var(--gip-action-rollover-color);
    border-color: var(--gip-action-rollover-color);
    .arrow-icon {
      margin-left: 15px;
      transition: margin-left 0.5s ease-in-out;

    }
  }

  a.outline:hover {
    color: var(--gip-action-rollover-color);
    background-color: #eee;
    border-color: var(--gip-action-rollover-color);
  }

  .action {
    background: none;
    color: var(--gip-action-color);

    &:hover {
      background: none;
      color: var(--gip-action-rollover-color);
    }
  }

  .white {
    background: none;
    color: white;

    &:hover {
      background: none;
      color: var(--gip-white-color);
    }
  }
}

.icon {
  margin-left: 10px;
  height: 10px;
  width: 12px;
}

.btnDisabled {
  opacity: 0.5;
}

a.btnDisabled {
  cursor: not-allowed;
}


</style>