
<template>
  <!-- test avec field direct -->
  <!--      <k-field field="Employeur.nom"/>-->


  <form v-if="this.employeur != null" autocomplete="off">
    <!-- nom etablissement et nom entreprise -->
    <hbox class="wrap-small">
      <k-field ref="nom" label="Nom de l’établissement*">
        <input v-model="employeur.nom" placeholder="Le nom de votre établissement" type="text">
      </k-field>
      <k-field ref="raisonSociale" class="margin-wrap" label="Nom de l'entreprise">
        <input v-model="employeur.raisonSociale" placeholder="Ex. : Café du commerce,…" type="text">
      </k-field>
    </hbox>

    <!-- forme juridique et siret -->
    <hbox class="wrap-small">
      <k-field ref="formeJuridiqueId" class="gip-select" label="Forme juridique*">
        <select ref="formeJuridiqueChooser" v-model="formeJuridiqueId" >
          <option disabled value="null">Faites votre choix</option>
          <option v-for="(option) in formesJuridiques" :key="option.formeJuridiqueId"
                  v-bind:value="option.formeJuridiqueId">
            <!--                 :selected="isFormeJuridiqueSelected(option)">-->
            {{ option.nom }}
          </option>
        </select>
      </k-field>
      <k-field ref="siret" class="margin-wrap" label="Nº SIRET*">
        <input v-model="employeur.siret" placeholder="Votre numéro SIRET" type="text">
      </k-field>
    </hbox>

    <!-- adresse et ville -->
    <hbox class="wrap-small">
      <k-field ref="adresse1" label="Adresse*">
        <input v-model="employeur.adresse1" placeholder="Votre adresse" type="text">
      </k-field>
      <k-field ref="communeId" class="margin-wrap gip-select" label="Ville*">
        <!-- il ne faut pas renseigner v-model="employeur.communeId", ça part en vrille sinon -->
        <b-autocomplete ref="communeChooser"
                        v-model="communeId"
                        :custom-formatter="formatCommune"
                        :data="communes"
                        placeholder="code postal, ville..."
                        @select="selectCommune"
                        @typing="getCommunes">
          <template slot-scope="props">
            {{ props.option.nom }} ({{ props.option.codePostal }})
          </template>
          <template #empty>Aucun résultat</template>
        </b-autocomplete>
        <!--        <input v-model="employeur.communeId" type="text" placeholder="Votre ville">-->
      </k-field>
    </hbox>

    <!-- nom et prénom dirigeant -->
    <hbox class="wrap-small">
      <k-field ref="nomGerant" label="Nom du dirigeant*">
        <input v-model="employeur.nomGerant" placeholder="Nom du dirigeant*" type="text">
      </k-field>
      <k-field ref="prenomGerant" class="margin-wrap" label="Prénom dirigeant*">
        <input v-model="employeur.prenomGerant" placeholder="Prénom du dirigeant*" type="text">
      </k-field>
    </hbox>

    <!-- mail et tel -->
    <hbox class="wrap-small">
      <k-field ref="mail" label="Mail*">
        <input v-model="employeur.mail" placeholder="Votre adresse mail" type="text">
      </k-field>
      <k-field ref="tel" class="margin-wrap" label="Téléphone*">
        <input v-model="employeur.tel" placeholder="Votre téléphone" type="text">
      </k-field>
    </hbox>

    <!-- guso et naf -->
    <hbox class="wrap-small">
      <k-field ref="guso" label="Nº GUSO*">
        <input v-model="employeur.guso" placeholder="Votre numéro GUSO" type="text">
      </k-field>
      <k-field ref="naf" class="margin-wrap gip-select" label="NAF*">
        <b-autocomplete ref="nafChooser"
                        v-model="naf"
                        :custom-formatter="formatNaf"
                        :data="nafs"
                        placeholder="code naf"
                        @select="selectNaf"
                        @typing="getNafs">
          <template v-slot="props">
            {{ props.option.nafId }} ({{ props.option.nom }})
          </template>
          <template #empty>Aucun résultat</template>
        </b-autocomplete>
      </k-field>
    </hbox>

    <!-- iban et bic -->
    <hbox class="wrap-small">
      <k-field ref="iban" class="fill" label="IBAN*">
        <input v-model="employeur.iban" placeholder="Xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxx" type="text">
      </k-field>
      <k-field ref="bic" class="margin-wrap match" label="BIC*">
        <input v-model="employeur.bic" placeholder="Votre code BIC" type="text">
      </k-field>
    </hbox>

    <!-- mot de passe et confirmation, optionnel (uniquement en création de compte) -->
    <hbox v-show="withPassword" class="wrap-small">
      <k-field ref="password" label="Mot de passe*">
        <input v-model="employeur.password" placeholder="Choisissez votre mot de passe" type="password">
      </k-field>
      <k-field ref="password2" class="margin-wrap" label="Confirmer votre mot de passe*">
        <input v-model="employeur.password2" placeholder="Confirmez votre mot de passe" type="password">
      </k-field>
    </hbox>

    <vbox>
      <p>* Champs obligatoires</p>
      <hbox class="align-flex-start">
        <k-field ref="licence" nolabel>
          <c-enum-field-view v-model="employeur.licence" checkbox
                             field="Employeur.licence" label="J’ai une licence d’entrepreneur de spectacles ?"/>
        </k-field>
      </hbox>
      <k-field v-show="employeur.licence === 'O' || employeur.licence.value === 'O'" ref="numeroLicence"
               class="margin-wrap match"
               label="Votre numéro de licence*">
        <input v-model="employeur.numeroLicence" placeholder="votre numéro de licence" type="text">
      </k-field>
      <div v-show="isAideNormale">
        <k-field ref="etablissementEnCentreCommercial" nolabel>
          <c-enum-field-view v-model="employeur.etablissementEnCentreCommercial"
                             checkbox
                             field="Employeur.etablissementEnCentreCommercial"
                             label="Votre établissement est installé dans un centre commercial ?"/>
        </k-field>

        <!-- categorie et type d'erp -->
        <hbox class="wrap-small">
          <k-field ref="categorieErp" class="fill" label="Catégorie d'ERP">
            <select v-model="employeur.categorieErp">
              <option disabled hidden selected value="">Faites votre choix</option>
              <option v-for="(option,index) in optionCategorieErp" :key="index" v-bind:value="option">
                {{ option }}
              </option>
            </select>
          </k-field>
          <k-field ref="typeErp" class="fill margin-wrap" label="Type d'ERP">
            <select v-model="employeur.typeErp">
              <option disabled hidden selected value="">Faites votre choix</option>
              <option v-for="(option,index) in optionTypeErp" :key="index" v-bind:value="option">
                {{ option }}
              </option>
            </select>
          </k-field>
        </hbox>

        <!-- licence restauration n'est affichée que si categorie/type erp est 5M -->
        <k-field v-show="is5M" ref="licenceRestauration" nolabel>
          <c-enum-field-view v-model="employeur.licenceRestauration"
                             checkbox
                             field="Employeur.licenceRestauration"
                             label="Avez vous une license 4 ou une license de petite restauration ?"/>
        </k-field>

      </div> <!-- end div aideNormale -->

      <!-- demandeur tiers -->
      <k-field ref="autoriserDemandeurTiers" nolabel>
        <c-enum-field-view v-model="autoriserDemandeurTiers"
                           checkbox
                           field="Employeur.autoriserDemandeurTiers"
                           label="J’autorise un tiers à saisir des demandes pour mon compte"/>
      </k-field>

      <!-- commission sécurité -->
      <k-field ref="avisCommissionSecurite" nolabel>
        <c-enum-field-view v-model="avisCommissionSecurite"
                           checkbox
                           field="Employeur.avisCommissionSecurite"
                           label="je tiens à la disposition du Gip Cafés Cultures l’avis de la commission de sécurité*"/>
      </k-field>

      <hbox v-if="!state.newFeature" class="btn-container justify-center">
        <BtnAction ref="btn" :click="this.verifForm" :once="true" :titre="btnAction"
                   :withLogo="false" class="btn-creer-mon-compte"></BtnAction>
      </hbox>
    </vbox>

  </form>
</template>

<script>
import kit from "@/kit/kit";
import CObject from "@/kit/data/CObject";
import {default as CFilter, CFilterCondition, CFilterType} from '../../kit/data/CFilter.js'
// import {CFilter, CFilterType, CFilterCondition} from "../../kit/data/CFilter";
import debounce from 'lodash/debounce'
import GipMixin from "../ui/GipMixin";
import CEnumFieldView from "../../kit/vue/CEnumFieldView";

export default {
  name: "CreationCompte",
  mixins: [GipMixin],
  components: {CEnumFieldView},
  props: {
    /** on affiche ou non le mot de passe */
    withPassword: {
      type: Boolean,
      default: true
    },
    btnAction: {
      type: String,
      default: null
    },
    update: {
      type: Function,
      default: null
    },
      propEmployeur:{
        type:Object,
        default: null
      }


  },
  data() {
    return {

      // la liste des champs
      // fields: ["nom", "raisonSociale", "siret", "adresse1", "communeId"],

      // motDePasse:"",
      // ConfirmationMdp:"",
      // licenseGip: false,
      // asLicense:false,

      // categorieErp:"",
      // typeErp:"",

      // inCentreCommercial:false,

      // asLicense4: false,
      // tierGip: false,
      // dispoGip: false,

      optionTypeErp: ['J', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'PA', 'SG', 'PS', 'GA', 'OA', 'REF', 'EF', 'CTS'],
      optionCategorieErp: ['1', '2', '3', '4', '5'],
      // optionFormeJuridique:  [ 'SARL', 'SAS', 'AUTRE', 'EURL', 'SASU' ], // attention c'est une relation avec index+1
      aideNormaleNafList: ["8411Z", "5630Z", "5610A", "5610C", "5520Z", "1105Z", "4725Z", "5510Z"],

      formesJuridiques: [
        {
          "__id": "1",
          "formeJuridiqueId": "1",
          "nom": "SARL"
        },
        {
          "__id": "2",
          "formeJuridiqueId": "2",
          "nom": "SAS"
        },
        {
          "__id": "3",
          "formeJuridiqueId": "3",
          "nom": "Autre"
        },
        {
          "__id": "4",
          "formeJuridiqueId": "4",
          "nom": "EURL"
        },
        {
          "__id": "5",
          "formeJuridiqueId": "5",
          "nom": "SASU"
        }
      ],

      // le modèle pour les codes naf
      nafs: [],

      communeId: null,
      naf: null,
      formeJuridiqueId:null,

      // le modèle pour les communes
      communes: [],
      commune : '',
      // la liste des k-field
      kfields: null,

      errors: null
    }
  },

  mounted() {
    // http://localhost:8081/services/query?cclass=NAF&json
    //   var field = kit.model.cclass("Employeur").field("typeErp")
    //   console.log("field=" + field)
    //   var values = field.values()
    //   field.values().map((v) => {
    //     console.log('v=' + v)
    //   })
    let filter = new CFilter(CFilterType.AND)
    filter.add("NAF.eligibilite", CFilterCondition.EQUALS, "NORMALE")
    kit.services.query({
          cclass: "NAF",
          filter: filter,

        },
        (data) => {
          this.aideNormaleNafList = []
          data.forEach((item) => this.aideNormaleNafList.push(item.nafId))
          console.log(this.aideNormaleNafList)
        },
        (error) => {
          this.aideNormaleNafList = []
          alert("error")
        })

    // on filtre toutes les refs qui sont des k-field pour recuperer les ids
    this.kfields = []
    for (var r in this.$refs) {
      if (this.$refs[r].$options?.name === "KField")
        this.kfields.push(r)
    }
    // supprime les champs password si nécessaire
    if (!this.withPassword) {
      this.kfields.splice(this.kfields.indexOf("password"), 1)
      this.kfields.splice(this.kfields.indexOf("password2"), 1)
    }
  },
  methods: {
    login() {
      return true
    },
    verifForm() {

      // on crée un object qui n'a que les valeurs des champs affichés dans la vue
      let object = new CObject("Employeur", this.employeur.__id)

      // for (var ff of kfields)
      //   console.log("--->" + ff.id)
      for (var kfield of this.kfields) {
        // console.log("----> vue=" + kfield.__vue__)
        let val = this.employeur[kfield]
        if (val === undefined)
          val = null
        object.newValue(kfield, kit.model.cclass("Employeur").field(kfield).convert(val))
        console.log('set field ' + kfield + " value " + val)
      }
      // appelle le service de check. Si ok on appelle la methode en prop 'update', et sinon
      // on met à jour les champs en erreur
      kit.services.check(object, () => {
            this.errors = null
            console.log(object)
            this.update(object, this.onResponse);
          },
          (error) => {

            if (!this.state.newFeature) this.$refs.btn.reset()
            this.errors = error
            this.$emit('validationError')
            for (var kfield of this.kfields) {
              this.$refs[kfield].setError(null)
              if (error.errorFields().includes(kfield)) {
                console.log("on a une erreur")
                this.$refs[kfield].setError(error.error(kfield))
              }
            }
          })
    },
    errorMessage: function (field) {
      return this.errors === null ? null : this.errors.error(field)
    },
    onResponse: function () {
      console.log("calledback")
      if(!this.state.newFeature)this.$refs.btn.reset()
    },
    errorType: function (field) {
      return this.errorMessage(field) === null ? null : 'kit-error'
    },

    // debounce permet de faire du lazy load avec timeout (ne pas refaire la requete et attendre un chouille)
    getCommunes: debounce(function (name) {
      if (!name.length) {
        this.communes = []
        return
      }
      let filter = new CFilter(CFilterType.OR)
      filter.add("Commune.nom", CFilterCondition.CONTAINS, name)
      filter.add("Commune.codePostal", CFilterCondition.CONTAINS, name)
      kit.services.query({
            cclass: "Commune",
            filter: filter,
            limit: 100
          },
          (data) => {
            this.communes = []
            data.forEach((item) => this.communes.push(item))
          },
          (error) => {
            this.communes = []
            alert("error")
          })

    }, 500),

    // debounce permet de faire du lazy load avec timeout (ne pas refaire la requete et attendre un chouille)
    getNafs: debounce(function (name) {
      if (!name.length) {
        this.nafs = []
        return
      }
      let filter = new CFilter(CFilterType.OR)
      filter.add("NAF.nafId", CFilterCondition.CONTAINS, name)
      filter.add("NAF.nom", CFilterCondition.CONTAINS, name)
      kit.services.query({
            cclass: "NAF",
            filter: filter,
            limit: 100
          },
          (data) => {
            console.log(data)
            this.nafs = []
            data.forEach((item) => this.nafs.push(item))
            console.log("nafs size=" + this.nafs.length)
          },
          (error) => {
            this.nafs = []
            alert("error")
          })

    }, 500),

    selectCommune(option) {
      // alert('select ' + option.communeId)
      this.employeur.communeId = option
    },
    formatCommune(d) {
      return d.nom + " (" + d.codePostal + ")"
    },
    selectNaf(option) {
      this.employeur.naf = option
    },

    formatNaf(d) {
      return d.nafId + " - " + d.nom
    },

    emp_call(employeur){
      this.communes = []
      console.log(this.formeJuridiqueId)
      console.log(employeur)

      if (this.communeId == null && employeur.communeId != null){
        this.communeId = this.formatCommune(employeur.communeId)
      }
      if (employeur.naf != null && this.naf == null){
        this.naf = this.formatNaf(employeur.naf)
      }


      if(employeur.formeJuridiqueId == null && this.formeJuridiqueId != null){
        employeur.formeJuridiqueId = this.formeJuridiqueId;
      }
      // fix forme juridique le bordel !
      if (this.formeJuridiqueId == null && employeur.formeJuridiqueId != null){
      this.formeJuridiqueId = employeur.formeJuridiqueId.formeJuridiqueId
      }
      //if (employeur.communeId)
        return employeur
    }
  },
  computed: {
    employeur(){
      let employeur = this.propEmployeur
     //  console.log(employeur)
      if(employeur){
      return this.emp_call(employeur)

      }
      return null
    },
    avisCommissionSecurite: {
      get(){

        return this.employeur.avisCommissionSecurite === "OUI"
      },
      set(elm){
        this.employeur.avisCommissionSecurite = elm;
      }
    },
    autoriserDemandeurTiers: {
      get(){
        return this.employeur.autoriserDemandeurTiers === "OUI"
      },
      set(elm){
        this.employeur.autoriserDemandeurTiers = elm;
      }
    },
    /**
     *  est ce qu'on est en aide normale, ie NAF dans la liste des naf normaux. PENDING on doit utiliser eligibilite
     * dans NAF qui est à EXCEPTIONNELLE NORMALE ou REFUS
     */
    isAideNormale: function () {
      // console.log("naf=" + this.employeur.naf.nafId)
      return this.aideNormaleNafList.includes(this.employeur.naf?.nafId)
    },

    /** est ce qu'on est en 5M en categorie/type d'erp ? */
    is5M: function () {
      return (this.employeur.categorieErp === "5" && this.employeur.typeErp === "M")
    },
    // showNumeroLicence() {
    //   // alert('licence=' + this.employeur.licence)
    //   return this.employeur.licence === 'O' || this.employeur.licence.value === 'O'
    // }

  }
}

</script>


<style lang="scss" scoped>
@import "../../assets/kit.scss";

.btn-creer-mon-compte a {
  padding: 15px;
}

.gip-container {
  margin: 0 auto;

  position: relative;
}

.btn-container {
  text-align: center;

}

.gip-overlay {
  padding: 1em;
  background: white;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 3px 23px #04437010;
}

.btn-creer-mon-compte {
  flex-grow: 1;
  max-width: 320px;
  text-align: center;
}

label {
  font-size: 13px;
  font-weight: 600;
}

.margin-wrap {
  margin: 0 0 0 50px;
}

@media (max-width: $size-small) {
  .margin-wrap {
    margin: 0 0 0 0;
  }
}

.gip-container {
  h1 {
    color: var(--gip-accent-color);
    font-size: 1.5em;
    font-weight: 600;
  }

  p {
    font-weight: 500;
    font-size: 13px;
  }
}

.gip-background {
  padding-top: 76px;
  padding-bottom: 76px;
}

</style>