<template>
    <div class="pw-container">
        <input v-model="password" :type="show ? 'text' : 'password'" :placeholder="placeholder">
        <span @click="show = !show">
            <EyeIcon class="eye" :slash="show"/>
        </span>
       
    </div>
</template>

<script>
import EyeIcon from '@/assets/icons/EyeIcon.vue';

export default {
    components: { EyeIcon },
    props: ['placeholder', 'value'],
    data() {
        return {
            show: false
        }
    },
    computed: {
        password: {
        get() {
            return this.value;
        },
        set(val) {
            this.$emit('input', val);
        }
        }
    }
}
</script>

<style scoped>
.pw-container {
  position: relative
}

.pw-container input {
  padding-right: 2.7em;
  width: 100%;
}
.eye {
  position: absolute;
  right: 1em;
  top: 50%;
  translate: 0 -50%;
}

.eye:hover {
    cursor: pointer;
}
</style>