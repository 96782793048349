"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var Financeur_vue_1 = require("../components/financeur/Financeur.vue");
var Employeur_vue_1 = require("../components/employeur/Employeur.vue");
var Main_vue_1 = require("../components/site/Main.vue");
var GipMixin_js_1 = require("../components/ui/GipMixin.js");
vue_1.default.use(vue_router_1.default);
var router = new vue_router_1.default({
    routes: [
        // {
        //  path:"/*",
        //  name: 'Maintenance',
        //  component: () => import('../components/site/Maintenance.vue')
        //},
        {
            path: '/financeur',
            name: 'Financeur',
            redirect: '/financeur/dashboard',
            component: Financeur_vue_1.default,
            meta: { requiresFinanceur: true },
            children: [
                {
                    path: '',
                    name: 'DashboardFinanceur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/financeur/DashboardFinanceur.vue'); }); }
                },
                {
                    path: 'documents',
                    name: 'DocumentsFinanceur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/financeur/DocumentsFinanceur.vue'); }); }
                },
                {
                    path: 'profil',
                    name: 'ProfilFinanceur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/financeur/ProfilFinanceur.vue'); }); }
                },
            ]
        },
        {
            path: '/employeur',
            name: 'Employeur',
            component: Employeur_vue_1.default,
            meta: { requiresEmployeur: true },
            children: [
                {
                    path: '',
                    name: 'DashboardEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/DashboardEmployeur.vue'); }); }
                },
                {
                    path: 'profil',
                    name: 'ProfilEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/ProfilEmployeur.vue'); }); }
                },
                {
                    path: 'demandes',
                    name: 'DemandesEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/Demandes.vue'); }); }
                },
                {
                    path: 'creationDemande',
                    name: 'CreationDemandeEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/CreationDemande.vue'); }); }
                },
            ]
        },
        {
            path: '/',
            name: 'Main',
            redirect: '/home',
            component: Main_vue_1.default,
            children: [
                {
                    path: '',
                    name: 'Home',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: function () { return Promise.resolve().then(function () { return require(/* webpackChunkName: "about" */ '../components/site/Home.vue'); }); }
                },
                {
                    path: 'mentionsLegales',
                    name: 'MentionsLegales',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: function () { return Promise.resolve().then(function () { return require(/* webpackChunkName: "about" */ '../components/site/MentionsLegales.vue'); }); }
                },
                {
                    path: 'presentation',
                    name: 'Presentation',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/Presentation.vue'); }); }
                },
                {
                    path: 'fondsAide',
                    name: 'FondsAide',
                    props: { aideNormale: true },
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/FondsAide.vue'); }); }
                },
                {
                    path: 'fondsAideSpecifique',
                    name: 'FondsAideSpe',
                    props: { aideNormale: true },
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/FondsAide.vue'); }); }
                },
                {
                    path: 'membres',
                    name: 'Membres',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/Membres.vue'); }); }
                },
                {
                    path: 'membres/:id',
                    name: 'MembreId',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/Membre.vue'); }); }
                },
                {
                    path: 'actualites',
                    name: 'Actualites',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/Actualites.vue'); }); }
                },
                {
                    path: 'actualites/:id',
                    name: 'ActualiteId',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/Actualite.vue'); }); }
                },
                {
                    path: 'login',
                    name: 'LoginFinanceur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/financeur/LoginFinanceur.vue'); }); }
                },
                {
                    path: 'employeur/register',
                    name: 'CreationEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/CreationCompte.vue'); }); }
                },
                {
                    path: 'employeur/confirmation',
                    name: 'ConfirmationEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/Confirmation.vue'); }); }
                },
                {
                    path: 'employeur/login',
                    name: 'LoginEmployeur',
                    component: function () { return Promise.resolve().then(function () { return require('../components/employeur/LoginEmployeur.vue'); }); }
                },
                {
                    path: 'resetPassword',
                    name: 'resetPassword',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/ResetPassword.vue'); }); },
                    props: true
                },
                {
                    path: 'updatePassword',
                    name: 'updatePassword',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/UpdatePassword.vue'); }); }
                },
                {
                    path: '*',
                    name: '404',
                    component: function () { return Promise.resolve().then(function () { return require('../components/site/404.vue'); }); }
                },
            ]
        },
    ],
    mode: 'history'
});
router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    //  alert("state.isEmployeur=" + getCookie("employeur") == '')
    // ici il faudrait se bloquer jusquà ce que auth?info soit terminé
    // https://stackoverflow.com/questions/51495461/how-to-prevent-any-routing-before-some-async-data-in-vuex-store-has-loaded
    if (to.matched.some(function (record) { return record.meta.requiresEmployeur; })) {
        // if (!state.isEmployeur)
        if (GipMixin_js_1.getCookie("employeur") == '')
            next({ name: "LoginEmployeur" });
    }
    if (to.matched.some(function (record) { return record.meta.requiresFinanceur; })) {
        if (GipMixin_js_1.getCookie("financeur") == '')
            // if (!state.isFinanceur)
            next({ name: "LoginFinanceur" });
    }
    next();
});
exports.default = router;
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
//
// export default router
