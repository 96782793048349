

import kit from '../kit.js'
import CModel from './CModel.js'
import CClass from './CClass.js'
// import CClass2 from './CClass2.js'
import $ from 'jquery'

/**
 * a CQuery encapsulate a CFilter and add more informations to it. It can be used
 * instead of a CFilter when calling services.query().
 * params are :
 * - limit : max size of the request
 * - limitOffset : offset for the query
 * - sortField : sort the query
 * - reverse : order of the sort if orderField is set
 * - depth : depth for the query, -1 for no depth limit
 *
 * @class com.omniapp.kit.model.CQuery
 * @param cclass the CClass we are querying. either a String or a CClass
 * @param filter the filter this query is encapsulating
 * @constructor
 */
var CQuery = function(values) {
    this.cclass = null;
    this.filter = null;
    this.limit = -1;
    this.limitOffset = 0;
    this.orderField = null;
    this.reverse = false;
    this.match = null;
    this.matchFields = null;
    this.havingClause = null;
    this.depth = 0;
    this.json = true;

    $.extend(this, values);

    // var test = new CClass2()
    var test2 = new CClass("bim")

    // console.log("this=" + this + " this.cclass=" + this.cclass + " CClass=" + CClass + " instanceof " + !(this.cclass instanceof CClass))
    // if (this === null)
    //     console.log("this est null ??")
    if (typeof(this.cclass) === 'string' || this.cclass instanceof String) {
        this.cclass = kit.model.cclass(this.cclass);
    }
  // if (!(this.cclass instanceof CClass))
  //   this.cclass = kit.model.cclass(this.cclass);

  if ((this.orderField !== null) && (typeof this.orderField === 'string'))
      this.orderField = this.cclass.field(this.orderField);

  kit.log.trace('after:' + this);
}

CQuery.prototype._buildMatch = function() {
    if ((this.match === null) || (this.matchFields === null))
        return null;
    var str = '';
    var fields = this.matchFields.split(/(?:,| )+/);
    var words = this.match.split(/(?: )+/);
    if ((fields.length === 0) || (words.length === 0))
        return null;
    // field1,field2, xx, yy -> (field1 like '%xx%' or field2 like '%xx%') and (field1 like '%yy%' or field2 like '%yy%')
    for (var j = 0 ; j < words.length ; j++) {
        var w = words[j];
        var orc = '';
        for (var i = 0 ; i < fields.length ; i++) {
            var f = fields[i];
            orc += orc.length > 0 ? ' or ' : '';
            orc += f + " like '%" + w + "%'"
        }
        str += str.length > 0 ? ' and ' : '';
        str += orc.length === 0 ? '' : '(' + orc + ')';
    }
    return str;
}

CQuery.prototype.toSql = function() {
    var req = 'select * from ' + this.cclass;
    if (this.filter)
        req += ' where ' + this.filter;
    var m = this._buildMatch();
    if (m !== null) {
        if (this.filter)
            req += ' and (' + m + ')';
        else
            req += ' where ' + m;
    }
    if (this.havingClause)
        req += ' having ' + this.havingClause;
    if (this.orderField) {
        req += ' order by ' + this.orderField + (this.reverse ? ' desc' : '');
    }
    if (this.limit > 0) {
        if (this.limitOffset > 0)
            req += ' limit ' + this.limitOffset + ',' + this.limit;
        else
            req += ' limit ' + this.limit;
    }
    return req;
}

export default CQuery
// module.exports = CQuery;
