
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CDataField
 * @class com.omniapp.kit.model.field.CDataField
 * @extends com.omniapp.kit.model.CField
 */
function CDataField(cclass, cfield) {
    CField.call(this, cclass, cfield, 'CDataField');

    this.convert = function(val) {
        // always SKIPPED ?
        return val;
    };
    this.stringValue = function(val) {
        console.log('data field=' + this.id());
        if (val === null)
            return null;
        else if (typeof val === 'string' || val instanceof String)
            return val;
        throw new Error("CDataField, ????");
    };
}

export default CDataField
