<template>
    <b-input></b-input>
</template>

<script>
    export default {
        name: "CRelationFieldView"
    }
</script>

<style scoped>

</style>
