<template>
  <div>
    <b-input v-if="rows === 1" @input="test($event)"  ref="input" v-model="model"></b-input>
    <textarea  v-if="rows > 1" ref="textarea"  v-model="model" class="form-control" rows="5" type="textarea"></textarea>
  </div>
</template>
<script>
import kit from '../kit'

export default {
  name: "CTextFieldView",
  data: function () {
    return { model: null }
  },
  props: {
    field: {
      type: String,
      default: function () {
        return null;
      }
    }
  },
  computed: {
    name: function () {
      return kit.model.field(this.field).name()
    },
    rows: function () {
      return kit.model.field(this.field).rows()
    },
    type: function () {
      return kit.model.field(this.field).type()
    },
    value: {
      get: function () {
        return this.model
      },
      set: function (newValue) {
        this.model = newValue
      }
    }
  },
  mounted() {
    console.log("set placeholder")
    if (this.field)
      var placeholder = kit.model.field(this.field).placeholder()
    this.$refs.input.$refs.input.placeholder = placeholder ? placeholder : ""
  },
  methods: {
    // value: function() {
    //     return this.model
    // }
    test (e){
      this.$emit('input',e)
    }
  }
}
</script>

<style scoped>

</style>
