<template>
  <vbox class="gip-background">

    <vbox class="gip-page max-100">
      <vbox class="gip-container max-100">
        <div class="login-container">
          <Sphere bottom="60" right="-60" taille="213"/>
          <Sphere top="40" left="-50" couleur="grey" taille="213"/>
          <div class="sphere-container">
            <h1>
              Réinitialisation du mot de passe
            </h1>
            <p>Vous avez demandé à réinitialiser votre mot de passe, saisissez sa nouvelle valeur</p>
             <password-updater ref="passwordUpdater" notitle :onUpdatePassword="updatePassword"/>
          </div>
        </div>
    </vbox>
  </vbox>
  </vbox>
</template>

<script>
import PasswordUpdater from '../ui/back/PasswordUpdater'
import GipMixin from "../ui/GipMixin";
import kit from "../../kit/kit";
import CObject from "../../kit/data/CObject";
import CServicesProvider from "../../kit/data/CServicesProvider";

export default {
  name: "UpdatePassword",
  mixins: [GipMixin],
  components: {PasswordUpdater},
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    console.log("UpdatePassword.create(), token=" + urlParams.get('token') + " type=" + urlParams.get('type'));
  },

  methods: {

    updatePassword(password, password2) {
      let urlParams = new URLSearchParams(window.location.search);
      var token = urlParams.get('token')
      var type = urlParams.get('type')
      console.log("token=" + token + " type=" + type)

      let object = new CObject(type)
      object.newValue("password", password)
      object.newValue("password2", password2)

      // CServicesProvider.service("check", xml, onSuccess, onFail);
      var xml = CServicesProvider.write(object);
      kit.services.service("password?token=" + token + "&type=" + type, xml, () => {
            kit.dialogs.info("Votre mot de passe a été mis à jour")
            this.$router.push({name:"Home"})
          },
          (error) => {
            this.$refs.passwordUpdater.setError(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  margin-top: 3em;
  margin-bottom: 5em;
  //padding: 5em;
  //background: var(--gip-white-color);
  max-width: 100%;
  width: 600px;
  align-self: center;
  //overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.login-container h1 {
  color: var(--gip-accent-color);
  font-size: 1.5em;
}

.max-100 {
  max-width: 100%;
}

.sphere-container {
  z-index: 1;
  position: relative;
  padding: 2em;
  background: var(--gip-white-color);
}

</style>