<template>
  <!--<div>membre {{ $route.params.id }}</div> -->
  <div class="gip-bg-default">
    <div class="membre">
      <div>
        <div class="image-container">
          <div>


            <img  class="image" :src="'/images/membres/' + this.data.image" alt="fond presentation"/>

            <div class="gip-max-width">
              <vbox class="infos">
                <div class="overlay">
                  <div class="gip-breadcrumb">
                    <HomeLogo/>
                    > <router-link :to="{ name:'Membres' }"><span>Membres</span></router-link> > {{ data.nom }}
                  </div>
                  <vbox class="space-between">
                  <hbox class="flex-wrap  space-around">
                  <vbox class="info-container ">
                    <div class="image-logo">
                      <img v-lazy="'/images/membres/'+ data.logo">
                    </div>
                    <btn-action type="action" :href="data.lien" titre="VOIR LE SITE"></btn-action>
                  </vbox>
                  <vbox class="info-right">
                    <h1 class="titre"> {{ data.nom }}</h1>
                    <p class="date">Membre depuis le : <span>{{ formatDate(data.adhesion, 'DD MMMM YYYY') }}</span></p>
                    <p class="text" v-html="data.textePersonnalite">{{ data.textePersonnalite }}</p>
                  </vbox>
                  </hbox>
                  <hbox v-if="data.accrochePersonnalite" class="quote flex-wrap">
                    <vbox class="text fill">
                      <p class="text-quote fill" v-html="data.accrochePersonnalite">
                        <QuoteIcon/>
                        {{ data.accrochePersonnalite }}
                      </p>
                      <div class="quoteName">

                        <p class="name">{{ data.nomPersonnalite }}</p>
                        <br/>
                        <p class="role">{{ data.titrePersonnalite }}</p>
                      </div>
                    </vbox>
                    <vbox class="quote-img hidden-small ">
                      <img v-lazy="'/images/membres/personnalites/'+ data.photoPersonnalite">
                    </vbox>
                  </hbox>
                  </vbox>
                </div>
                <Sphere left="-70" bottom="120" couleur="grey" taille="213"></Sphere>
                <Sphere top="240" right="-70" taille="213"></Sphere>
              </vbox>


            </div>
          </div>
        </div>


      </div>
      <div class="gip-max-width">

        <DernierMembre/>
      </div>
    </div>
  </div>
</template>

<script>
import DernierMembre from "../ui/DernierMembre";
import Sphere from "../ui/Sphere";
import BtnAction from "../ui/BtnAction";
import HomeLogo from "../../assets/svg/icons8-accueil.svg"
import kit from "../../kit/kit";
import GipMixin from "../ui/GipMixin";

export default {
  name: "Membre",
  mixins: [GipMixin],

  components: {
    BtnAction, Sphere, DernierMembre, HomeLogo
  },
  data() {
    return {
      data: {
        nom: "Région Bourgogne-Franche- Comté",
        text: "Les cafés-cultures participent à l’émergence artistique, à la diversité culturelle mais aussi  à l’éveil culturel de nos concitoyens et notamment des plus jeunes d’entre nous. Le fonds d’aide à l’emploi développé via le Gip Cafés Cultures nous permet d’être à leurs côtés. Ce dispositif répond à leurs préoccupations et à celles des artistes. \n \n Mener de front son activité commerciale classique et celle d’organisateur d’événements n’est pas chose facile, tout comme il n’est pas facile de se lancer dans une carrière artistique pérenne. Je remercie donc le Gip d’avoir imaginé, en lien avec les premiers concernés, ce fonds de dotation d’aide à l’emploi artistique direct, solution concrète et simple à même de répondre aux besoins des entrepreneurs et des artistes émergents. Ce mode de financement croisé entre collectivités, État et personnes morales et privées est inventif. Il permet la réactivité et l’implication de tous les acteurs constituant ainsi une véritable voie d’avenir.",
        date: "20 juillet 2021",
        url: "",
        id: "",
        quote: "Les cafés cultures accomplissent un travail de terrain et de passion pour faire vivre une culture de proximité et animer la vie dans nos territoires.",
        quoteName: "Marie-Guite Dufay",
        quoteRole: " Présidente de la région Bourgogne-Franche-Comté"
      }
    }
  },
  methods: {

    getMembre() {
      kit.call(() => {
        var query = {cclass: 'Membre', id: this.$route.params.id, json: true};
        kit.services.query(query,
            (set, req) => {
              console.log(set)
              this.data = set

            },
            () => {
              kit.log.error("membre " + this.$route.params.id + " inconnu")
              this.$router.push({name: "404"})
            }
        )
      });
    }
  },
  mounted() {
    this.getMembre()
  },
  computed: {
    backgroundStyle() {
      return {
        "background-image": "url(/images/membres/" + encodeURI(this.data.image) + ")",
        // background: "red"
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.gip-breadcrumb {
  padding-bottom: 25px;

}


.gip-btn {
}



.info-container {
  vertical-align: top;
}

.image-container {

  width: 100%;

  .image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin: 0 auto;
    max-width: 1280px;

  }

}

.overlay {
  position: relative;
  z-index: 2;
  padding: 76px;
  background: white;
  border-radius: 5px;


}

.infos {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: -120px;

  p{margin: 0}
  .info-right {

    max-width: 644px;
    text-align: left;
    .text{
      padding-bottom: 20px ;
    }

  }

  .titre {
    font-weight: 500;
    line-height: 48px;
    font-size: 40px;
  }

  .text {
    line-height: 22px;
    white-space: pre-line;
  }

  .date {
    font-weight: 400;
    font-size: 12px;

    span {
      font-weight: 700;
    }
  }

  .text-quote {

    text-align: left;
  }

  .image-logo {
    border-radius: 5px;
    margin: 0 auto;
    background: var(--gip-menu-background);
    padding: 20px;
    width: 213px;
    height: 219px;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .image-logo {
    margin: 0 auto;

  }


}

.quote {
  background: var(--gip-tint-color);
  color: white;
  font-size: 21px;
  font-weight: 500;
  height: 346px;
  display: flex;
  justify-content: space-between;

  .quoteName {
    text-align: right;

    font-size: 12px;
  }

  .text {
    padding: 65px 73px 65px 73px;
    max-width: 70%;
    vertical-align: top;

  }

  .name {
    font-weight: 700;

  }

  .quote-img {
    vertical-align: top;
    text-align: right;

    width: auto;

    img {
      max-height: 346px;
    }
  }

  .role {
    font-weight: 400;

  }


}

@media  (max-width: 960px){
  .infos{
    padding: 0px;
  }
  .overlay{
    padding:1.5em;
  }
  .quote{
    .text{
      padding:  26px 35px 14px 26px;
    }
  }
}
</style>