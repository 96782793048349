import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CComputedField
 * @class com.omniapp.kit.model.field.CComputedField
 * @extends com.omniapp.kit.model.CField
 */
function CComputedField(cclass, cfield) {
    CField.call(this, cclass, cfield, 'CComputedField');

    this.convert = function (val) {
        // always SKIPPED ?
        return val;
    };
    this.stringValue = function (val) {
        console.log('computed field=' + this.id());
        if (val === null)
            return null;
        return null
        // throw new Error("CComputedField, ???? " + val);
    };
}

export default CComputedField
