/**
 * @class com.omniapp.kit
 *
 * The main entry to operate with the OmniKit framework.
 * Once initialized, access to main stuff is done this way :
 *
 * ## {@link com.omniapp.kit.model.CModel kit.model}
 *
 *  - kit.model.cclass('id')
 *  - kit.model.field('xx.yy')
 *  - kit.model.cclass('id').field('fid')
 *
 * ## {@link com.omniapp.util.Log kit.log}
 *
 * - kit.log.error('msg')
 * - kit.log.log('msg')
 * - kit.log.trace('msg')
 * - kit.log.warning('msg')
 * - kit.level(level)
 *
 * ##{@link com.omniapp.util.MessageManager kit.message}
 *
 * - kit.message.listen(topic, callback)
 * - kit.message.unlisten(topic, callback)
 * - kit.message.send(topic, args)
 *
 * ## {@link com.omniapp.kit.model.CServicesProvider kit.services}
 *
 * - kit.services.query()
 * - kit.services.check()
 * - kit.services.get()
 * - kit.services.create()
 * - kit.services.delete()
 * - kit.services.update()
 * - kit.services.service()
 *
 * ## {@link com.omniapp.ui.Dialogs kit.dialogs}
 *
 * - kit.dialogs.error('msg')
 * - kit.dialogs.message('msg')
 * - kit.dialogs.custom(title, content, buttons, callback)
 *
 *
 * What we want in the future :
 *
 * - kit.controller
 * - kit.controller.listController()
 * - kit.controller.editController()
 * - kit.controller.listDetailController()
 * - kit.controller.navigationController()
 *
 */
// import Loader from './misc/Loader.js'
import MessageManager from './misc/MessageManager.js'
import Log from './misc/Log.js'
import DateExt from './misc/DateExt.js'
import Dialogs from './ui/Dialogs.js'
import CTemplate from './template/CTemplate.js'
import CModel from './data/CModel.js'
import CEntity from './data/CEntity.js'
import CClass from './data/CClass.js'
import CObject from './data/CObject.js'
import CObjectSet from './data/CObjectSet.js'
import VueDialogs from './vue/VueDialogs.js'
// import CFilter from './data/CFilter.js'
import { default as CFilter, CFilterCondition } from './data/CFilter.js'
import XMLWriter from './misc/XMLWriter'
import CServicesProvider from './data/CServicesProvider.js'

import './misc/String'
import './misc/Utilities'

import $ from 'jquery'

var kit = {

    /** is kit initialized ? It means model is loaded */
    initialized: false,

    /** version */
    version: "0.1",

    /** services base url */
    servicesUrl: "/services",

    /** model */
    model: null,

  /** callbacks */
  callbacks : [],

  /** add a init callback */
  call: async function(callback) {
    if (kit.initialized)
      callback();
    else
      kit.callbacks.push(callback);
  },

  log: Log,

    /**
     * Install all script files needed by the framework.
     * @param servicesUrl base url for services
     * @param model file containing model. Can either be local (./xyz-model.xml) or
     *        call service (/services/model)
     * @param callback function to be called once all the framework is initialized.
     */
    init: async function (servicesUrl, model, callback) {

      kit.message = new MessageManager();
      kit.dialogs = new VueDialogs()
      kit.services = CServicesProvider;
      kit.template = CTemplate;
      // kit.log = Log;

      kit.servicesUrl = servicesUrl;

      if (model != null) {

        let modelXml = await($.get(model))
        // console.log(result)
        //   $.get(model, function (modelXml) {
          kit.model = new CModel(modelXml);

            kit.initialized = true;

            // call pending
          while (kit.callbacks.length > 0) {
              console.log("appelle post load callback");
              await (kit.callbacks.shift())();
          }

          if (callback)
            await callback();
        // });
      }
      // else if (callback)
      //   callback();

      // kit.initialized = true;

      return;

      // //Log.log("init kit, services=" + servicesUrl + ", model=" + model);
      //   kit.servicesUrl = servicesUrl;
      //
      //   var e = document.createElement('script');
      //   e.type = "text/javascript";
      //   e.async = false;
      //   e.src = '/kit/misc/Loader.js';
      //   document.getElementsByTagName('head')[0].appendChild(e);
      //   e.onload = function () {
      //       kit._init(model, callback);
      //   };

        // load loader that will load all subscripts
        // $.getScript("/cframework/misc/Loader.js", function() {
        // kit._init(model, callback);
        // });

        // kit._init(model, callback);
    },

    /** loader callback, load all dependancies */
    _init: function (model, callback) {



        // function called after all scripts are loaded. Init model if defined,
        // does common stuff, and call callback if defined
        var postLoad = function () {

            // install wrappers on kit
            kit.dialogs = Dialogs;
            kit.services = CServicesProvider;
            kit.template = CTemplate;
            kit.log = Log;
            kit.message = new MessageManager();

            // Hasher.init('');
            // Engine.init();
            if (model != null) {
                $.get(model, function (modelXml) {
                    kit.model = new CModel(modelXml);
                    if (callback)
                        callback();
                });
            }
            else if (callback)
                callback();


        };

        // if we are minified load is done
        if (kit.minified) {
            postLoad();
            return;
        }
        // load all scripts of OmniKit
        // Loader
        //     .loadScript("m4", "/kit/misc/Log.js")
        //     .loadScript("d1", "/kit/data/CDataProvider.js")
        //     .loadScript("d2", "/kit/data/CServicesProvider.js")
        //     .loadScript("d3", "/kit/data/CObjectBuilder.js")
        //     .loadScript("d4", "/kit/data/CError.js")
        //     .loadScript("d5", "/kit/data/CObject.js")
        //     .loadScript("d6", "/kit/data/CObjectSet.js")
        //     .loadScript("d7", "/kit/data/CModel.js")
        //     .loadScript("d8", "/kit/data/CActionContext.js")
        //     .loadScript("d9", "/kit/data/CAction.js")
        //     .loadScript("d10", "/kit/data/CFilter.js")
        //     .loadScript("d11", "/kit/data/CFieldFormatter.js")
        //     .loadScript("m1", "/kit/misc/DateExt.js")
        //     .loadScript("m2", "/kit/misc/Utilities.js")
        //     .loadScript("m3", "/kit/misc/String.js")
        //     .loadScript("m5", "/kit/misc/XMLWriter.js")
        //     .loadScript("m7", "/kit/misc/Hasher.js")
        //     .loadScript("m8", "/kit/misc/MessageManager.js")
        //     .loadScript("a1", "/kit/action/CCreateAction.js")
        //     .loadScript("a2", "/kit/action/CDeleteAction.js")
        //     .loadScript("t1", "/kit/template/CTemplate.js")
        //     .loadScript("t2", "/kit/template/CTemplateFactory.js")
        //     .loadScript("u1", "/kit/ui/Balloon.js")
        //     .loadScript("u2", "/kit/ui/Dialogs.js")
        //     .loadScript("u3", "/kit/ui/ViewFactory.js")
        //     .loadScript("u4", "/kit/ui/JLayout.js")
        //     .loadScript("u5", "/kit/ui/Pager.js")
        //     .loadScript("u6", "/kit/ui/Table.js")
        //     .loadScript("u7", "/kit/ui/UIUtilities.js")
        //     .loadScript("v1", "/kit/view/CController.js")
        //     .loadScript("v2", "/kit/view/CEditController.js")
        //     .loadScript("v3", "/kit/view/CListController.js")
        //     .loadScript("v4", "/kit/view/CTableController.js")
        //     .loadScript("v5", "/kit/view/CListDetailController.js")
        //     .loadScript("v6", "/kit/view/CSearchController.js")
        //     .loadScript("v7", "/kit/view/CNavigationController.js")
        //     .loadScript("v8", "/kit/view/CFieldEditor.js")
        //     .loadScript("v9", "/kit/view/field/CNumberFieldEditor.js")
        //     .loadScript("v10", "/kit/view/field/CDateFieldEditor.js")
        //     .loadScript("v11", "/kit/view/field/CRelationFieldEditor.js")
        //     .loadScript("v12", "/kit/view/field/CEnumFieldEditor.js")
        //     .loadScript("v13", "/kit/view/CSplitNavigationController.js")
        //     .loadScript("v14", "/kit/view/CListDetailController2.js")
        //     .loadScript("v15", "/kit/view/field/CTextFieldEditor.js")
        //     .loadScript("c1", "/contrib/media/js/jquery.dataTables.js")
        //     .loadScript("c2", "/contrib/jquery.ba-bbq.min.js")
        //     .loadScript("p1", "/kit/app/AppView.js")
        //     .attach("d1 d2 d3 d4 d5 d6 d7 d8 d9 d10 d11 m1 m2 m3 m4 m5 m7 m8 a1 a2 t1 t2 u1 u2 u3 u4 u5 u6 u7 v1 v2 v3 v4 v5 v6 v7 v8 v9 v10 v11 v12 v13 v14 v15 c1 c2 p1",
        //     postLoad);
    },

    /**
     * add a css to the head of current document
     */
    // addCss: function (css) {
    //     Loader.loadStyle(css);
    // },

    /** set model */
    setModel: function (modelFile) {
        $.get(modelFile, function (xml) {
            kit.model = new CModel(xml);
        });
    },

    // /**
    //  * Parse application.xml file, install groups
    //  * @param app xml application file
    //  * @param group-view view containing entries
    //  * @param content-view view displaying selected view
    //  */
    // installApp: function (model, app, groupView, contentView, callback) {
    //
    //     var views = {};
    //
    //     $.get(app, function (xml) {
    //         $(contentView).addClass('pager');
    //         // logo
    //         $(xml).find('application').each(function () {
    //             var logo = $(this).attr('logo');
    //             if (logo) {
    //                 var lsplit = logo.split('.');
    //                 var header = $('<img src="resources/' + lsplit[0] + '@2x.' + lsplit[1]
    //                 + '" alt="logo" class="app-logo fill" style="max-height:70px"/>');
    //                 $(groupView).append(header);
    //             }
    //         });
    //         // views
    //         $(xml).find('view').each(function () {
    //             var id = $(this).attr('id');
    //             var cclass = $(this).attr('cclass');
    //             var type = $(this).attr('type');
    //             var appView = new AppView(id, cclass, type);
    //             views[id] = appView;
    //         });
    //         // tabs
    //         $(xml).find('tabs').children().each(function () {
    //             // var tindex = $(this).index();
    //             var div = $("<div class='group-cell-main'>" + $(this).attr("name") + "<div>");
    //             // div.addClass("group-cell");
    //             // div.addClass("group-cell-main");
    //             $(this).find('tab').each(function () {
    //                 var target = $(this).attr('target');
    //                 if ((typeof target === "undefined") || (target.contains('web'))) {
    //                     var cdiv = $("<div>" + $(this).attr("name") + "<div>");
    //                     cdiv.addClass("group-cell");
    //                     if ($(this).attr('view'))
    //                         cdiv.data('view', $(this).attr('view'));
    //                     else
    //                         cdiv.data("view", $(this).attr("class"));
    //                     cdiv.click(function () {
    //                         kit.loadView(contentView, $(this).data("view"), views);
    //                         $(".group-cell.selected").removeClass("selected");
    //                         $(this).addClass("selected");
    //                     });
    //                     var icon = $(this).attr("icon");
    //                     icon = icon.replace('.png', '@2x.png'); // retina
    //                     // icon = icon.replace('img/', 'img-alt/'); // PENDING !!
    //                     cdiv.css("background-image", "url(resources/" + icon + ")");
    //                     div.append(cdiv);
    //                 }
    //             });
    //             $(groupView).append(div);
    //         });
    //         if (callback)
    //             callback();
    //     });
    // },

    // /**
    //  * Load a page, execute js and install css
    //  * @param container container div
    //  * @param page html page to load
    //  * @param a viewId/AppView mapping for known views
    //  */
    // loadView: function (container, page, views) {
    //
    //
    //     // hide current view
    //     $(container).find(".app-view").hide();
    //
    //     var existant = $(container).find("." + page);
    //     if (existant.length === 1) {
    //         console.log('reuse ' + page);
    //         existant.show();
    //         return;
    //     }
    //
    //     Log.log("load view, page=" + page);
    //
    //     var appView = views ? views[page] : null;
    //
    //     // either we create an AppView
    //     if (appView) {
    //         var content = appView.create();
    //         content.addClass("app-view");
    //         content.addClass(page);
    //         $(container).append(content);
    //     }
    //     // or we load view html/css/js
    //     else {
    //         view = "view/" + page + ".html";
    //         js = "view/" + page + ".js";
    //         view_css = "view/" + page + ".css";
    //
    //         $.get(view_css, {}, function (data) {
    //             kit.addCss(view_css);
    //
    //             $.get(view, {}, function (data) {
    //                 var content = $(data);
    //                 content.addClass("app-view");
    //                 content.addClass(page);
    //                 $(container).append(content);
    //
    //                 // apply js
    //                 $.getScript(js);
    //             });
    //         });
    //     }
    // },
    //
    // /**
    //  * Define different view types
    //  */
    // ViewTypeEnum: {
    //     ViewTypeListDetail: "ViewTypeListDetail",
    //     ViewTypeList: "ViewTypeList",
    //     ViewTypeDetail: "ViewTypeDetail"
    // },


    /**
     * install a CListDetail view on a container. we expect to find
     * a list-view and a detail-view in the subtree.
     * @param container
     * @param cclass
     * @param template
     */
//     installListDetail: function (container, cclass, listTemplate, editTemplate) {
//
//         // define context
//         var context = new kit.CActionContext(container);
//         context.template = editTemplate;
//         context.cclass = cclass;
//         context.view = container;
//
//         // install actions
//         container.find(".action").each(function () {
//             var actionId = $(this).attr("id");
//             var action = kit.getAction(actionId);
//             action.cclass = cclass;
//             $(this).data("action", action);
//             $(this).attr("title", action.name);
//             $(this).click(function () {
//                 action.doAction(context);
//             });
//         });
//
//         // load list
//         url = "/content?cclass=" + cclass + "&template=" + listTemplate + "&size=100&offset=0&editable=false";
//         $.get(kit.servicesUrl + url, function (data) {
//             //alert("content=" + $("#FournisseursView").html());
//             container.find(".list-view").html(data);
// //			alert("table=" + $(".vbox table"));
//             $(".vbox table").dataTable({
//                 "sScrollY": "200px",
//                 "bPaginate": false,
//                 //"bAutoWidth" : false,
//                 // "bFilter": false,
//                 "bInfo": false
//             });
//             $(".dataTables_scrollHead").css("width", "");
//             $(".dataTables_scrollBody").css("width", "");
//             $('.list-view div[class="vbox"]').removeClass("vbox");
//
//
//             var searchField = container.find(".dataTables_filter input");
//             searchField.css({'left': '30px'});
//             container.find(".dataTables_filter").hide();
//             container.find(".actions").append(searchField);
//             searchField.attr("display", "inline-block");
//             searchField.addClass("search-field");
//
//             // install selection
//             container.find(".list-view tr").click(function (event) {
//                 if (!event.altKey)
//                     container.find(".list-view tr").removeClass("selected");
//                 if ($(this).hasClass("selected"))
//                     $(this).removeClass("selected");
//                 else
//                     $(this).addClass("selected");
//                 updateContext(container.find(".list-view .selected"));
//             });
//
//             // select first if relevant
//             var first = container.find("tbody tr").first();
//             first.addClass("selected");
//             updateContext(first);
//
//         });
//
//         /**
//          * Update context. Called when selection change.
//          * Update actions state and update detail depending
//          * on selection.
//          * @param ctx selected elements (with class 'selected')
//          */
//         function updateContext(ctx) {
//             size = ctx.size();
//             Log.log("update context size=" + size);
//
//             // update actions
//             container.find(".action").each(function () {
//                 action = $(this).data("action");
//                 Log.log("update context, action=" + action.toString());
//                 if (action.isAvailable(ctx)) {
//                     $(this).removeClass("disabled");
//                 }
//                 else {
//                     $(this).addClass("disabled");
//                 }
//             });
//
//             // update detail
//             if (ctx.size() == 1) {
//                 cid = ctx.first().attr("id");
//                 CEditController.load(context, cid);
//             }
//
//             // update context
//             context.context = ctx;
//
//         }
//
//
//     },

    /** define default actions */
    // actions: null,
    //
    // _initActions: function () {
    //     if (kit.actions == null) {
    //         Log.log("init actions");
    //         kit.actions = {};
    //         kit.actions["CCreateAction"] = CCreateAction;
    //         kit.actions["CDeleteAction"] = CDeleteAction;
    //     }
    // },

    // /** add an action */
    // registerAction: function (id, action) {
    //     kit._initActions();
    //     kit.actions[id] = action;
    //     Log.log("register action " + id);
    // },
    //
    // /**
    //  * retrieve an action given its id
    //  * @param actionId action id
    //  */
    // getAction: function (actionId) {
    //     kit._initActions();
    //     Log.log("actions=" + kit.actions);
    //     if (!kit.actions[actionId]) {
    //         // if action is not registered try to eval it using its name
    //         //var evalString = 'new ' + actionId + '()';
    //         // alert('action ' + actionId + ' not registered, try to new it with ' + evalString);
    //         var a = Utilities.eval(actionId);
    //         return a;
    //         // kit.registerAction(actionId, a);
    //     }
    //     return new kit.actions[actionId];
    // }
};

export default kit



