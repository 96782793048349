 <template>
  <div class="gip-bg-default">
<div class="gip-container">
  <div class="gip-overlay">
  <h1 >Créer mon compte</h1>
  <p>Bienvenue dans l’espace employeur, vous pouvez créer un compte pour votre établissement en remplissant les champs indiqués ci-dessous.</p>
    <EmployeurForm  :prop-employeur="employeur" :btn-is-disabled="btnIsDisabled" ref="employeurForm" :update="createAccount"  btnAction="Créer mon compte"/>

  </div>
  <Sphere top="120"  left="-90" taille="213"/>
  <Sphere bottom="120"   couleur="grey" right="-90" taille="213"/>
</div>


  </div>
</template>

<script>
import EmployeurForm from "./EmployeurForm";
import Sphere from "../ui/Sphere";
import kit from "../../kit/kit";

export default {
name: "CreationCompte",
  components: {Sphere, EmployeurForm},

  data () {
    return {
      btnIsDisabled:false,
      employeur: {
        "nom": null,
        "formeJuridiqueId": null,
        "raisonSociale": null,
        "nomGerant": null,
        "prenomGerant": null,
        "tel": null,
        "tel2": null,
        "mail": null,
        "guso": null,
        "password": null,
        "password2": null,
        "siret": null,
        "naf": null,
        "etablissementBancaire": null,
        "iban": null,
        "bic": null,
        "licence": 'N',
        "numeroLicence": null,
        "etablissementEnCentreCommercial": 'N',
        "categorieErp": null,
        "typeErp": null,
        "licenceRestauration": "N",
        "adresse1": null,
        "communeId": null,
        "notifMail": "O",
        "autoriserDemandeurTiers": 'N',
        "avisCommissionSecurite": "N",
      },
    }
  },
  mounted() {
      // this.$refs.employeurForm.setEmployeur(this.employeur)
  },
    methods:{
      createAccount(object,callback) {

        console.log("verif")
        this.btnIsDisabled = true
        kit.services.submit(object, () => {
              this.$router.push({name: "ConfirmationEmployeur"})
              this.btnIsDisabled = false
              callback();
              console.log(object)
            },
            (error) => {
              alert("pb update")
              this.btnIsDisabled = false
              callback();
              console.log(error)
            })
      }
    }

}

</script>


<style lang="scss">

.btn-creer-mon-compte a{

  padding: 15px ;
}

</style>
<style lang="scss" scoped>

.gip-container{
  margin: 0 auto;

  position: relative;
}

.btn-container{
  text-align: center;

}

.gip-overlay {
  background: white;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 3px 23px #04437010;
  padding: 5em;
}


.gip-container  {
  h1{
  color: var(--gip-accent-color);
  font-size: 1.5em;
  font-weight: 600;
  }
  p{
    font-weight: 500;
    font-size: 13px;
  }
}

.gip-background{
  padding-top: 76px;
  padding-bottom: 76px;
}


</style>