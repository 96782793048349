<template>
  <div class="carte   ">
    <div class="gip-max-width ">

      <Diviseur couleur="white" titre="UNE COUVERTURE NATIONALE"/>
      <div class="mapcontainer">
        <div class="areaLegend">
          <span>Alternative content for the legend</span>
        </div>
        <div class="plotLegend">
          <span>Alternative content for the legend</span>
        </div>
      <div id="carte-container" class="">
        <h2 class="alternance hidden-big">Découvrez la présence<br/> du Gip <span>sur le territoire</span></h2>

        <div class="info hidden-small">
          <Sphere top="-45" left="-60" taille="213"/>
          <div class="overlay">
            <div class="elem">
            <h2 class="alternance ">Découvrez la présence<br/> du Gip <span>sur le territoire</span></h2>

            <div class="hidden-small">

            <div class="date">
              <span class="annee">Année :</span>
              <Range :value="rangeCarte" :max="nbMaxAnnee" @update="updateRangeCarte"/>
              <span class="anneeNum">{{ annee }}</span>
            </div>
            <div class="radiolLabel">Type de carte :</div>
            <div class="labelContainer">
              <div>
                <input v-model="typeCarteComputed" id="CarteCommune" name="carte" type="radio" value="population" checked>
                <label for="CarteCommune">Population par commune</label>
              </div>
              <div>
                <input v-model="typeCarteComputed" id="CarteTotal" name="carte" type="radio" value="financement">
                <label for="CarteTotal">Financement par commune</label>
              </div>
            </div>
            </div>
            <vbox v-if="typeCarte == 'population'" class="legende">
              <h3 class="titre">Légende</h3>
              <div class="mapLegend"></div>
              <hbox class="legend" @click.native="filtreCarte('3')" id="leg1">
                <div class="sphere-legend-container">
                  <Sphere position="unset" couleur="pink" taille="22"/>
                </div>
                <div class="legend-label" :class="{active: active[3]}">Au-delà de 20 000 habitants</div>
              </hbox>
              <hbox class="legend" @click.native="filtreCarte('2')" id="leg2">
                <div class="sphere-legend-container">
                  <Sphere position="unset" couleur="action" taille="19"/>
                </div>
                <div class="legend-label" :class="{active: active[2]}">De 5 000 à 20 000 habitants</div>
              </hbox>
              <hbox class="legend" @click.native="filtreCarte('1')" id="leg3">
                <div class="sphere-legend-container">
                  <Sphere position="unset" couleur="green" taille="15"/>
                </div>
                <div class="legend-label" :class="{active: active[1]}">De 1 000 à 5 000 habitants</div>
              </hbox>
              <hbox class="legend" @click.native="filtreCarte('0')" id="leg4">
                <div class="sphere-legend-container">
                  <Sphere  position="unset" taille="11"/>
                </div>
                <div class="legend-label" :class="{active: active[0]}">Moins de 1 000 habitants</div>
              </hbox>
            </vbox>
              <vbox v-else class="legende">
                <h3 class="titre">Légende</h3>
                <div class="mapLegend"></div>
                <hbox class="legend" @click.native="filtreCarte('3')" id="leg1">
                  <div class="sphere-legend-container">
                    <Sphere position="unset" couleur="pink" taille="22"/>
                  </div>
                  <div class="legend-label" :class="{active: active[3]}">Au delà de 5000 €</div>
                </hbox>
                <hbox class="legend" @click.native="filtreCarte('2')" id="leg2">
                  <div class="sphere-legend-container">
                    <Sphere position="unset" couleur="action" taille="19"/>
                  </div>
                  <div class="legend-label" :class="{active: active[2]}">De 1000 à 5000 €</div>
                </hbox>
                <hbox class="legend" @click.native="filtreCarte('1')" id="leg3">
                  <div class="sphere-legend-container">
                    <Sphere position="unset" couleur="green" taille="15"/>
                  </div>
                  <div class="legend-label" :class="{active: active[1]}">De 500 à 1000 €</div>
                </hbox>
                <hbox class="legend" @click.native="filtreCarte('0')" id="leg4">
                  <div class="sphere-legend-container">
                    <Sphere  position="unset" taille="11"/>
                  </div>
                  <div class="legend-label" :class="{active: active[0]}">Moins de 500 €</div>
                </hbox>
              </vbox>
          </div>

          </div>
        </div>

        <div class="map_cc-container">

        <!-- <div class="mapPlace fill"><div class="map"></div></div> -->

            <div class="map_cc-row">
          <div class="map_cc-france"  id="div1">

          <div class="map">
              <span>Alternative content for the map</span>
            </div>
          </div>
            </div>

          </div>
        <vbox v-if="typeCarte == 'population'" class="legende hidden-big">
          <h3 class="">Légende</h3>
          <div class="mapLegend"></div>
          <hbox class="legend" @click.native="filtreCarte('3')" id="leg1">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="pink" taille="22"/>
            </div>
            <div class="legend-label" :class="{active: active[3]}">Au-delà de 20 000 habitants</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('2')" id="leg2">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="action" taille="19"/>
            </div>
            <div class="legend-label" :class="{active: active[2]}">De 5 000 à 20 000 habitants</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('1')" id="leg3">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="green" taille="15"/>
            </div>
            <div class="legend-label" :class="{active: active[1]}">De 1 000 à 5 000 habitants</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('0')" id="leg4">
            <div class="sphere-legend-container">
              <Sphere  position="unset" taille="11"/>
            </div>
            <div class="legend-label" :class="{active: active[0]}">Moins de 1 000 habitants</div>
          </hbox>
        </vbox>
        <vbox v-else class="legende hidden-big">
          <h3 class="">Légende</h3>
          <div class="mapLegend"></div>
          <hbox class="legend" @click.native="filtreCarte('3')" id="leg1">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="pink" taille="22"/>
            </div>
            <div class="legend-label" :class="{active: active[3]}">Au delà de 5000 €</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('2')" id="leg2">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="action" taille="19"/>
            </div>
            <div class="legend-label" :class="{active: active[2]}">De 1000 à 5000 €</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('1')" id="leg3">
            <div class="sphere-legend-container">
              <Sphere position="unset" couleur="green" taille="15"/>
            </div>
            <div class="legend-label" :class="{active: active[1]}">De 500 à 1000 €</div>
          </hbox>
          <hbox class="legend" @click.native="filtreCarte('0')" id="leg4">
            <div class="sphere-legend-container">
              <Sphere  position="unset" taille="11"/>
            </div>
            <div class="legend-label" :class="{active: active[0]}">Moins de 500 €</div>
          </hbox>
        </vbox>
            <!-- <use xlink:href="#potofgold"/> -->
      </div>
    </div>

  </div>
  </div>

</template>

<script>
import Diviseur from "./Diviseur";
import Sphere from "./Sphere"
import Range from "./Range"
import GipMixin from "./GipMixin";

import 'jquery-mapael'
import 'jquery-mapael/js/maps/france_departments';
import 'jquery-mapael/js/maps/france_regions_domtom.js';

import $ from  "jquery";
import kit from "../../kit/kit";

// import '../../assets/js/maps/france_regions_domtom'
// const toto = () => import ('../../assets/js/maps/france_regions_domtom.js');

export default {
  name: "CarteGip",
  mixins: [GipMixin],
  components: {Diviseur,Sphere,Range},

  data() {
    return {
      premiereFois: true,
      nbMaxAnnee: 4,
      currentDataPlot:null,
      typeCarte: "population",
      annee:"2021",
      rangeCarte: 6,
      fondZone: "#EDF0F1",
      fondRolloverZone: "#EDF0F1",
      bordureZone: "#044370",

      // légendes/plots actives ou non
      active: {"0" : true, "1": true, "2": true, "3": true},

      // les couleurs des points
      color1: "#43AD9E",
      borderColor1: "#fff",

      color2: "#2E756E",
      borderColor2: "#fff",

      color3: "#C22292",
      borderColor3: "#fff",
      oldpam: null,

      color4: "#E0C5D8",
      borderColor4: "#fff",

      strokeWidth: 0, // contour des cercles

      plots : {},

      mmlegend:{
      plot: {
        cssClass: 'mapLegend',
            labelAttrs: {
          fill: "#4a4a4a"
        },
        titleAttrs: {
          fill: "#4a4a4a"
        },
        marginBottom: 20,
            animDuration: 0,
            marginLeft: 30,
            hideElemsOnClick: {
          opacity: 0
        },
        title: "Population par commune",
            slices: [{
          size: 6,
          type: "circle",
          max: 1000,
          attrs: {
            fill: "#43AD9E",
            stroke: "#fff", // couleur des traits
            "stroke-width": 0,
          },
          label: "Moins de 1000 habitants"
        }, {
          size: 10,
          type: "circle",
          min: 1000,
          max: 5000,
          attrs: {
            fill: "#2E756E",
            stroke: "#fff", // couleur des traits
            "stroke-width": 0,
          },
          label: "De 1000 à 5000 habitants"
        }, {
          size: 15,
          type: "circle",
          min: 5000,
          max: 20000,
          attrs: {
            fill: "#C22292",
            stroke: "#fff", // couleur des traits
            "stroke-width": 0,

          },
          label: "De 5000 à 20 000 habitants"
        }, {
          size: 25,
          type: "circle",
          min: 20000,
          attrs: {
            fill: "#E0C5D8",
            stroke: "#fff", // couleur des traits
            "stroke-width": 0,

          },
          label: "Au delà 20 000 habitants"
        }]
      }
    },
      xxlegend:{
        plot: {
          cssClass: 'mapLegend',
          labelAttrs: {
            fill: "#4a4a4a"
          },
          titleAttrs: {
            fill: "#4a4a4a"
          },
          marginBottom: 20,
          marginLeft: 30,
          hideElemsOnClick: {
            opacity: 0
          },
          title: "Financé",
          slices: [{
            size: 6,
            type: "circle",
            max: 500,
            attrs: {
              fill: "#43AD9E",
              stroke: "#fff", // couleur des traits
              "stroke-width": 0,
            },
            label: "Moins de 500 €"
          }, {
            size: 10,
            type: "circle",
            min: 500,
            max: 1000,
            attrs: {
              fill: "#2E756E",
              stroke: "#fff", // couleur des traits
              "stroke-width": 0,
            },
            label: "De 500 à 1000 €"
          }, {
            size: 15,
            type: "circle",
            min: 1000,
            max: 5000,
            attrs: {
              fill: "#C22292",
              stroke: "#fff", // couleur des traits
              "stroke-width": 0,
            },
            label: "De 1000 à 5000 €"
          }, {
            size: 25,
            type: "circle",
            min: 5000,
            attrs: {
              fill: "#E0C5D8",
              stroke: "#fff", // couleur des traits
              "stroke-width": 0,
            },
            label: "Au delà de 5000 €"
          }]
        }
      },
    }
  },

  methods : {
    updateRangeCarte(data){
      console.log("vvvv")
      this.rangeCarte = data;
        let val = Object.keys(this.plots)[data] + "";
     this.annee = val.toString().replace('annee','');
      $(".mapcontainer").trigger('update', [{

        newPlots: this.plots[val],
        deletePlotKeys:  Object.keys(this.currentDataPlot),

      }]);


      this.currentDataPlot = this.plots[val]
    },
    getPlotData() {
      kit.call(() => {
        kit.services.service('/carte?type='+this.typeCarte, { type : "" },
            (set, req) => {
              // alert('carte service ok')
              kit.log.log('data=' + set)
              console.log('data=' , set)

              this.plots = set
              this.nbMaxAnnee = Object.keys(this.plots).length - 1;
              const now = new Date()
              // la première fois on se place sur l'année courante à partir de septembre sinon sur l'année courante - 1
              if (this.premiereFois) {
                this.annee = now.getFullYear() - (now.getMonth() > 7 ? 0 : 1)
                this.rangeCarte = Object.keys(this.plots).indexOf("annee" + this.annee)
                this.premiereFois = false
              }
              let val = Object.keys(this.plots)[this.rangeCarte] + "";
              this.installMapael()
              $(".mapcontainer").trigger('update', [{
                newPlots: this.plots[val],
                deletePlotKeys: Object.keys(this.currentDataPlot),
              }]);
              this.currentDataPlot = this.plots[val]
              Object.keys(this.active).filter(a => this.active[a] = true)
            },
            (error) => {
              alert("erreur service carte")
            }
        )
      });
    },
    installMapael() {
      $(".mapcontainer").mapael({
        map: {
          // name: "france_departments",
          name: "france_regions_domtom",
          afterInit : function(container, paper, areas, plots, options) {
            let res  = container.find(".map > svg")
            // console.log("css=" + res.attr("style"))

            // ca ne marche pas parce que deboite le zoom
            // res.css("overflow", "")
            // res.css("overflow", "visible");

            let viewBox = res.attr("viewBox")
            res.attr("viewBox", viewBox.replace("0 0 ", "0 -20 "))
            // res.attr("viewBox", "0 -20 641.85596 627.08588")
            // console.log("css=" + res.attr("style"))

            // $('.mapcontainer .map').unbind("resizeEnd");
           // paper.setSize(650,650);
            // paper.canvas.setAttribute('preserveAspectRatio', 'none');
          },
          // afterUpdate : function($self, paper, areas, plots, options) {
          //   // $('.mapcontainer .map').unbind("resizeEnd");
          //   paper.setSize(800, 800);
          //   // paper.canvas.setAttribute('preserveAspectRatio', 'none');
          // },
          // name: "france_regions_domtom",
          zoom: {
            enabled: true,
            animDuration : 200,
            maxLevel: 10
          },

          defaultArea: {
            attrs: {
              stroke: this.bordureZone, // couleur des traits
              "stroke-width": 1,
              fill: this.fondZone // remplissage des zones
            },
            attrsHover: {
              animDuration:100,
              "stroke-width": 2,
              fill: this.fondRolloverZone // remplissage rollover
            },
          }
          ,
          tooltip:{
            cssClass: "mapTooltip"
          },

        },
        legend: this.typeCarte == "population" ? this.mmlegend : this.xxlegend,
        plots: this.plots["annee2018"],

      });
      this.currentDataPlot =  this.plots["annee2018"]
    },

    filtreCarte(id){
      this.active[id] = !this.active[id]
      let mapael = $(".mapcontainer").data("mapael")
      mapael.handleClickOnLegendElem(mapael.legends["plot"]["0"].elems[id], id, "0", "plot")
    },


  },
  mounted() {
      this.getPlotData();

  },
  computed: {
    typeCarteComputed: {
      get :  function() {
        this.getPlotData()
        return this.typeCarte
      },
      set: function(newValue) {
        this.typeCarte = newValue;
      }
    }
  }
}
</script>

<style  lang="scss" >
.mapael .mapTooltip {
  position: absolute;
  background-color: #fff;
  opacity: 0.70;
  filter: alpha(opacity=70);
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  color: #343434;
}


.mapael .zoomButton {
  display: none;
}


.mapael .mapLegend {
  width: 100%;
  padding: 10px;
}
</style>

<style  lang="scss" scoped>

.date{
    padding-top: 60px;
  padding-bottom: 30px;
  .annee{
    margin-right: 10px;
  }
  .anneeNum{
    font-weight: 600;
  }
}

#carte-container{

}

.sphere-legend-container{
  width: 22px;
  height: 22px;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.carte {
  background: var(--gip-tint-color);
  position: relative;
  padding-top: var(--gip-padding-header);
  margin: 0 auto;
  overflow: visible;
  //max-height: 800px;
}
.mapPlace {

  display: inline-block;
  vertical-align: top;

}


.overlay{
  text-align: left;
  max-width: 724px;

  vertical-align:top ;
  border-radius: 5px;
  box-shadow: 0 3px 15px #00000029;

  z-index: 5;

  padding: 48px 53px 48px  53px;
  background: var(--Athens-Gray-color-f9fafb);

  position: relative;

}



.radiolLabel {
  display: inline-block;
  vertical-align: top;
  padding-right: 15px;
}

.labelContainer {
  display: inline-block;
  vertical-align: top;
}

.legende {
  background: white;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: 30px;
  margin-top: 40px;
  div{
    cursor:pointer;

  }
  p div{
    display: inline-block;
    vertical-align: middle;
    position: unset;
    width: 15px;

  }
  .titre {
    position: relative;
    top: -41px;
    background: var(--gip-tint-color);
    font-size: 16px;
    padding: 10px 48px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
}


input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #d5dbe1;
  background: white;
  border-radius: 50%;
  background-clip: content-box;
  + {
    label {
      position: relative;
      top: -5px;
      left: 8px;
      font-size: 15px;
      color: var(--gip-tint-color);
      font-weight: 600;
    }
  }
  &:checked {
    border: 5px solid var(--gip-action-color);
  }
  &:focus {
    outline-color: transparent;
    outline-style: none;
  }
}

.map {
  & > *{
    max-width: 100%;
  }
}

.mapLegend{
  display: none;
}

.legend {
  padding-bottom: .5em;
}

.legend-label:not(.active) {
  color: var(--gip-disabled-color);
}


.map_cc-row{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
}

.map_cc-france{
  width:100%;
  height:100%;
  display: inline-block;

}

#carte-container{
  padding-bottom: 2em;
  transform-origin: top left;
  transform: rotate(0deg) ;

}

.info {
  text-align: left;
  display: inline-block;
  width: 50%;
  // on marge bottom = top
  position: relative;
  vertical-align: middle;


}

.map_cc-container {
  //margin: auto;
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  position:relative;


}

.mapael .map {
  position: relative;
}

@media all and (max-width: 960px) {

  #carte-container {
    width: 100%;
    height: 100%;
  }



    .info{
      display: none;


  }
  .info .overlay{
    margin: 0 auto;

  }
  .legende{
    margin-top: 30px;
  }


  #carte-container{
    padding: 35px;
  }
  .map_cc-container {
  width: 100%;

    display: block;
    margin : 0 auto;
    margin-top: 30px;

  }

    .alternance{
      color: white;
    }

  }
</style>

