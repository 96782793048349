<template>
  <div>
    <EspaceHeader/>
    <Header v-slot:default="props">
      <div  id="nav">
        <router-link @click.native="props.clickHandler" to="/employeur">Synthèse</router-link>
        <router-link @click.native="props.clickHandler" to="/employeur/profil">Profil</router-link>
        <router-link @click.native="props.clickHandler"  to="/employeur/demandes">Demandes</router-link>
        <a @click="faireDemande(props.clickHandler)"  class="btn" id="faire-demande">Faire une demande</a>
<!--        <router-link @click.native="props.clickHandler" class="btn" to="/employeur/creationDemande">FAIRE UNE DEMANDE</router-link>-->
      </div>
    </Header>
    <transition name="gip-transition"  mode="out-in">
      <div class="gip-bg-default">

      <router-view/>
      </div>
    </transition>
    <Footer :is-small="true"/>
  </div>
</template>

<script>
import Header from '../../components/site/Header.vue'
import Footer from '../../components/site/Footer.vue'
import EspaceHeader from '../../components/site/EspaceHeader.vue'
import GipMixin from '../../components/ui/GipMixin'
import kit from "../../kit/kit";

export default {
  name: 'Membre',
  mixins: [GipMixin],
  components: {Header, Footer,EspaceHeader},

  methods: {
    /** faire demande doit vérifier que le profil est valide sinon on affiche une information */
    faireDemande(native) {
      console.log("employeur valide=" + this.state.user.valide)
      native();
      if (this.state.user.valide === 'O')
        this.$router.push({name:"CreationDemandeEmployeur"});
      else
        kit.dialogs.info("Vous ne pouvez pas effectuer de demande tant que votre profil n'est pas validé, veuillez nous contacter.")
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/gip.scss";

#faire-demande {
  text-transform: uppercase;
}
#faire-demande:hover {
  color: var(--gip-white-color);
}
</style>


