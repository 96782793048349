<template>
<vbox class="dashboard gip-page">
  <div class="gip-container">
  <vbox class="back-title justify-left">
   <h1 class="first-title">Bonjour,</h1>
    <h1>{{ financeur.nom}}</h1>
    <p>Dernière connexion : {{formatDate(financeur.lastConnection, 'D MMMM YYYY')}}</p>
  </vbox>

  <vbox>
    <back-header2 title="Synthèse" class="justify-between">
      <hbox class="align-center justify-center">
        <h1>Synthèse</h1><b-form-select id="anneeChooser" @change="onUpdateAnneeOrFinancement()" v-model="annee" :options="annees" ></b-form-select>
      </hbox>
      <hbox v-if="isFinanceurMultiple" class="align-center justify-center">
        <h1>Financement</h1><b-form-select id="anneeChooser" @change="onUpdateAnneeOrFinancement()" v-model="financement" :options="financements" ></b-form-select>
      </hbox>
    </back-header2>
  <hbox class="dashboard-row wrap-small">
    <dashboard-cell class="fill left-cell" :compteur="format2(infosFinanceur.nbEmployeursFinances)"
                    libelle="Employeurs financés" icon="HomeIcon"/>
    <dashboard-cell class="fill" :compteur="format2(infosFinanceur.montantAidesAttribuees)"
                    libelle="D'aides attribuées"  icon="EuroIcon" unit="€"/>
    <dashboard-cell class="fill right-cell" :compteur="format2(infosFinanceur.nbSpectaclesFinances)"
                    libelle="Spectacles financés"  icon="HandIcon"/>
  </hbox>
    <hbox class="dashboard-row wrap-small">
      <vbox class="fill dashboard-cell left-cell">
        <dashboard-cell id="salairesAides" class="inner-cell fill left-cell" :compteur="format2(infosFinanceur.nbSalairesAides)"
                        libelle="Salaires aidés" icon="PersonneIcon"/>
        <dashboard-cell id="salariesAides" class="inner-cell fill left-cell" :compteur="format2(infosFinanceur.nbSalariesAides)"
                        libelle="Salariés aidés"/>
      </vbox>
<!--      <hbox class="dashboard-cell chart-cell fill wrap-small right-cell">-->
      <vbox  id="typesSpectacles" class="categories-graphique fill dashboard-cell cell-merge-right">
        <h2 class="dashboard-cell-label gip-label">Types de spectacles financés</h2>
        <hbox><span class="cagegorie-graphique fill">Danse</span>
          <span id="pourcentDanse" class="compteur-small">{{ pourcentDANSE }}%</span>
        </hbox>
        <hbox><span class="cagegorie-graphique fill">Théatre</span>
          <span id="pourcentTheatre" class="compteur-small">{{ pourcentTHEATRE }}%</span></hbox>
        <hbox><span class="cagegorie-graphique fill">Musique</span>
          <span id="pourcentMusique" class="compteur-small">{{ pourcentMUSIQUE }}%</span></hbox>
      </vbox>
      <vbox class="fill dashboard-cell right-cell cell-merge-left">
        <DonutsChart ref="donut" :labels="donutLabels" unit="%" :colors="donutColors"/>
      </vbox>
    </hbox>
<!--    </hbox>-->
  </vbox>

    <b-modal ref="ajoutDocRef" size="lg" static centered ok-title="Envoyer" title="Ajout d'un document"
             cancel-title="Annuler">
    </b-modal>
  </div>
</vbox>
</template>

<script>

import GipMixin from '../ui/GipMixin.js'
import DashboardCell from '../ui/back/DashboardCell'
import BackHeader2 from '../ui/back/BackHeader2'
import DonutsChart from "../ui/DonutsChart";
import { BModal } from 'bootstrap-vue'
import Utilities from "../../kit/misc/Utilities";

// import M from "/public/resources/M"

import kit from '../../kit/kit'

export default {
  name: "DashboardFinanceur",
  mixins: [GipMixin],
  components: {DashboardCell, BackHeader2, DonutsChart, BModal},
  data() {
    return {
      annee: new Date().getFullYear(),
      annees: [
        { value: 'Totale', text: 'Totale' },
        { value: '2015', text: '2015' },
        { value: '2016', text: '2016' },
        { value: '2017', text: '2017' },
        { value: '2018', text: '2018' },
        { value: '2019', text: '2019' },
        { value: '2020', text: '2020' },
        { value: '2021', text: '2021' },
        { value: '2022', text: '2022' },
        { value: '2023', text: '2023' },
      ],
      isFinanceurMultiple : false,
      financement: 'CHR',
      financements : [
        { value: 'CHR', text: 'CHR' },
        { value: 'OCCAS', text: 'Occasionnel'},
      ],
      pourcentTHEATRE: "10",
      pourcentDANSE: "80",
      pourcentMUSIQUE: "10",

      donutLabels: ["Danse", "Théatre" , "Musique"],
      donutColors: [
          'rgb(199, 211, 3)',
          'rgb(67, 173, 158)',
          '#044370'],

      // pour test
      financeur: {
        nom: "Région Pays de la Loire",
        lastConnection: "2021-09-01"
      },
      // pour test
      infosFinanceur: {
        "__cclass": "InfosFinanceur",
        "__id": "TempId|1",
        "nbEmployeursFinances": "120",
        "nbSalairesAides": "1867",
        "nbSalariesAides": "1298",
        "montantAidesAttribuees": "163046",
        "nbSpectaclesFinances": "757",
        "categoriesSpectacles": [
          {
            "__cclass": "InfosCategorieSpectacleFinanceur",
            "__id": "TempId|2",
            "categorie": "MUSIQUE",
            "pourcentage": "98"
          },
          {
            "__cclass": "InfosCategorieSpectacleFinanceur",
            "__id": "TempId|3",
            "categorie": "THEATRE",
            "pourcentage": "2"
          }
        ]
      },
    toto: "bim"
    }
  },
  created() {
    // kit.log.log('rofl')
    // this.$refs.ajoutDocRef.show()
    this.financeur = this.state.user
    const current = new Date().getFullYear()
    let ans = [ { value: "Totale", text: "Totale" }]
    for (let i = 2015; i < current + 1; i ++) {
      ans.push({ value: String(i), text: String(i) })
    }
    this.annees = ans
    this.isFinanceurMultiple = this.financeur.financeurId.montantFinance > 0 && this.financeur.financeurId.montantFinanceOccas > 0
    this.financement = this.financeur.financeurId.montantFinance > 0 || this.isFinanceurMultiple ? "CHR" : "OCCAS"
    this.onUpdateAnneeOrFinancement()
  },
  methods: {

    showDialog() {
      // alert("dialog")
      // kit.dialogs.warning("bim")
      this.$refs.ajoutDocRef.show()
    },
    /**
     * http://localhost:8081/services/query?cclass=InfosFinanceur&id=6|2021&json&depth=3
     * appelé sur selection de l'année : appelle le service 123|2020|CHR ou 123|2020|OCCAS
     */
    onUpdateAnneeOrFinancement() {
      let an = this.annee === "Totale" ? "0" : this.annee
      let id = this.financeur.membreId + "|" + an + "|" + this.financement
      
      kit.services.query({cclass: "InfosFinanceur", id: id, depth: 3, json: true},
          (data) => {
            this.infosFinanceur = data
            // this.$refs.donut.values = [100, 100, 100];
            this.updatePourcents(this.infosFinanceur.categoriesSpectacles)
            this.$refs.donut.updateValues([this.pourcentDANSE, this.pourcentTHEATRE, this.pourcentMUSIQUE])
          },
          (error) => {
          alert("error")
          });
    },
    
    /**
     * Met à jour les pourcentages des categories de spectacles depuis les valeurs retournées par le service
     * @param cats : les categories du service
     */
    updatePourcents(cats) {
      this.pourcentDANSE = 0
      this.pourcentTHEATRE = 0
      this.pourcentMUSIQUE = 0
      cats.map(c => {
        // console.log("cat " + c.categorie + " val=" + c.pourcentage)
        this.$data["pourcent" + c.categorie] = c.pourcentage
      });
    }
  }
  }
</script>

<style scoped lang="scss">


.dashboard {
  /*background-color: #04437010;*/
  /*!*background-color: yellow;*!*/
  /*max-width:1280px;*/
  /*margin: 0 auto;*/
  padding-bottom:10em;
}

.chart-cell {
  /*flex-grow:1;*/
}
/*.one-col {*/
/*  flex-grow:1;*/
/*}*/
/*.two-col {*/
/*  flex-grow:1;*/
/*}*/



.dashboard-cell {

}

.dashboard-cell h1 {
  color: var(--gip-accent-color);
  font-style: normal;
  font-size: 3em;
  margin-bottom: 0.5em;
  font-weight: 500;
}


#salairesAides {
  margin-bottom: 0;
}

#salariesAides {
  margin-top: 0;
}

.back-title p {
  color: var(--gip-disabled-color);
  font-size: 0.9em;
  margin-top: 0.5em;
}

#anneeChooser {
  flex-basis: fit-content;
  width: auto;
  $form-select-bg: red;
  $form-select-color: red;
  color: var(--gip-tint-color);
  font-weight: 600;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat
}


//#pourcentDanse, #pourcentTheatre, #pourcentMusique {
//  font-size: 2.3em;
//  font-weight: 500;
//  //line-height: 1em;
//}
#pourcentDanse {
  color: var(--gip-yellow-color);
}
#pourcentTheatre {
  color: var(--gip-accent-color);
}
#pourcentMusique {
  color: var(--gip-tint-color);
}

.dashboard-row > * {
  flex: 1 1 0px;
  flex-basis: 100%;
  width: 0;
  //background: green;
}

.inner-cell {
  margin: 0;
  padding: 0;
}
</style>