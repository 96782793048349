<script>

export default {

  props: ['value'],
  computed: {
    datas: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<template>
    <div>
      <h5>Vous certifiez sur l'honneur :</h5>
      <br>
      <div class="hbox align-left">
       <input id="autre1an" type="checkbox" v-model="datas.auMoins1an"/>
        <label for="autre1an">Que votre structure a au moins un an d'existance</label>                     
      </div>
      <br>
      <div class="hbox align-left">
       <input id="autreCa" type="checkbox" v-model="datas.ca"/>
        <label for="autreCa">Avoir moins de 2M€ de CA</label>                     
      </div>
      <br>
      <div class="hbox align-left">
       <input id="autreSalaries" type="checkbox" v-model="datas.salaries"/>
        <label for="autreSalaries">Avoir moins de 10 salariés</label>                     
      </div>
    </div>
</template>

<style>

</style>