

<template>
  <div>
    <CommuneField v-model="datas.communeId" :avecEpci="true"/>
    <div v-if="datas.communeId" class="radios">
      <div class="hbox alignt-left">
        <input id="collCommune" type="radio" v-model="datas.type" value="commune"/>
        <div>
          <label for="collCommune">je m'inscris en tant que commune</label>
          <div v-if="datas.type == 'commune'" class="hbox align-left check">
            <input id="moins3500" type="checkbox" v-model="datas.moins3500"/>
            <label for="moins3500">je certifie que ma commune a moins de 3500 habitants</label>
          </div>
        </div>
      </div>
     
      
      <div class="hbox alignt-left" v-if="datas.communeId.codeEpci">
        <input id="collEpci" type="radio" v-model="datas.type" value="epci"/>
        <div>
        <label for="collEpci">j'inscris L'EPCI '{{ datas.communeId.codeEpci.nom }}'</label>
        <div v-if="datas.type == 'epci'" class="hbox align-left check">
            <input id="moins7000" type="checkbox" v-model="datas.moins7000"/>
            <label for="moins7000">je certifie que mon EPCI a moins de 7000 habitants</label>
          </div>  
      </div>
      </div>
    </div>
</div>
</template>

<style scoped>
.check {
  font-size: 80%;
}

input[type=radio] {
  margin-right: 8px;
}
.radios {
  margin-top: 1.5em;
}

</style>

<script>

import CommuneField from '@/components/ui/CommuneField.vue'

export default {
  components: { CommuneField },
  props: ['value'],
  computed: {
    datas: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>