<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.71" height="27.71" viewBox="0 0 27.71 27.71">
    <path id="iconfinder_linkedin_circle_black_107159" d="M25.631,24.384V18.975c0-2.9-1.547-4.247-3.61-4.247A3.112,3.112,0,0,0,19.2,16.287V14.95H16.059c.042.885,0,9.434,0,9.434H19.2V19.115a2.138,2.138,0,0,1,.1-.764A1.715,1.715,0,0,1,20.907,17.2c1.135,0,1.589.865,1.589,2.133v5.047h3.135ZM12.756,13.662a1.635,1.635,0,1,0,.021-3.26,1.635,1.635,0,1,0-.041,3.26Zm5.1,18.047A13.855,13.855,0,1,1,31.71,17.855,13.855,13.855,0,0,1,17.855,31.71Zm-3.531-7.326V14.95H11.188v9.434Z" transform="translate(-4 -4)" fill="#fff"/>
  </svg>



</template>

<script>
export default {
  name: "LinkedinLogo",
}
</script>

