import Log from "../misc/Log";

/**
 * CEntity object
 * @class com.omniapp.kit.model.CEntity
 */
function CEntity(n) {

    this.node = n;
    this._model = null;

    var _id = null;
    var _tags = null;

    /** return id of this CEntity */
    this.id = function() {
        if (_id === null)
            _id = this.node.attr("id");
        return _id;
    };

    /** return name of this CEntity */
    this.name = function() {
        return this.node.attr("name");
    };

    /** return help for this CEntity */
    this.help = function() {
        return this.node.attr("help");
    };

    this.removeTag = function(tag) {
        this._loadTags()
        _tags = _tags.filter(e => e !== tag)
    };
    this.addTag = function(tag) {
        this._loadTags()
        _tags.push(tag)
    };
    /** has a given tag */
    this.hasTag = function(tag) {
        this._loadTags()
        return _tags.indexOf(tag) >= 0
        // var tags = this.node.attr("tags");
        // return (tags !== undefined) && tags.contains(tag);
    };

    /** retrieve all possible values of this enum */
    this._loadTags = function() {
        if (_tags == null) {
            _tags = [];
            var tags = this.node.attr("tags");
            if (tags)
                _tags = tags.split(/,| /)
        }
    };
} // end class CEntity

export default CEntity
