<template>
  <div>
    <EspaceHeader/>
    <Header v-slot:default="props">
      <div id="nav">
        <!--      <router-link to="/">Home</router-link> | le lien est dans le header -->
        <router-link  @click.native="props.clickHandler" to="/presentation">Présentation</router-link>
        <router-link @click.native="props.clickHandler" to="/fondsAide">Fonds d'aide</router-link>
        <router-link @click.native="props.clickHandler" to="/membres">Membres</router-link>
        <router-link @click.native="props.clickHandler" to="/actualites">Actualités</router-link>
<!--        <a @click=" props.clickHandler();goTo('Contact'); ">Contact</a>-->
      </div>
    </Header>
    <transition name="gip-transition" mode="out-in">
      <router-view :key="$route.path" class="background"/>
    </transition>
    <Footer/>
  </div>
</template>

<script>

import Header from '../../components/site/Header.vue'
import Footer from '../../components/site/Footer.vue'
import EspaceHeader from '../../components/site/EspaceHeader'

export default {
  name: "Main",
  components: {Header, Footer,EspaceHeader},
  methods:{
   goTo(elem){
     let el = document.getElementById(elem);
     el.scrollIntoView({behavior:"smooth"})
   }
}
}
</script>

<style lang="scss">
.background{
  background: var(--gip-background);
}

</style>


