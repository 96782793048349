<template>
  <hbox class="document-cell">
    <p class="fill gip-label" >{{ libelle }}</p>
    <div >
    <div class="buttons-container">
      <icon-base  width="20" height="20" v-on:click.native="launchCallback()">
        <component class="action" v-bind:is="icon"/>
      </icon-base>
      <icon-base
          v-if="hasDeleteListener" active title="Supprimer ce document" 
          iconColor="var(--gip-white-color)" 
          background-color="var(--gip-error-color)"         
          class="delete-button"
          :class="{ hidden: false }"
          @click.native="$emit('delete')">
          <trash-small></trash-small>
      </icon-base>
    </div>
    </div>
  </hbox>
</template>

<script>
import kit from "../../../kit/kit";
import GipMixin from '../../ui/GipMixin.js'
import { useListeners } from "vue";

export default {
  name: "DocumentCell",
  mixins: [GipMixin],

  props: {
    libelle: String,
    icon: String,
    context: Object,
    callback: Function
  },
  data() {
    return {}
  },
  methods: {
    launchCallback: function () {
      if (this.callback)
        this.callback(this.context)
    }
  },
  created() {
    kit.log.log("doc cell created")
  },
  computed:{
    hasDeleteListener(){
      const listeners = useListeners();
      return typeof listeners['delete'] !== 'undefined';
    }
  }
}
</script>

<style scoped lang="scss">
.document-cell {
  align-items: center;
  //margin-left: 1em;
  //margin-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  margin-left: 0;
  margin-right: 0;
  padding-top: .5px;
  padding-bottom: .5em;
  padding-left: 1.5em;
  padding-right: 1.5em;

}

.document-cell p {
  display: inline-block;
  text-align: left;
  margin-right: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
.action rect:hover {
  background: red;
}
.document-cell .action {
  cursor: pointer;
}
.buttons-container {
  display: flex;
}

.buttons-container .delete-button {
  margin-left: 0.5em;
}

</style>