
import CClass from './CClass.js'
import CTextField from './field/CTextField.js'
import CNumberField from './field/CNumberField.js'
import CEnumField from './field/CEnumField.js'
import CDateField from './field/CDateField.js'
import CDataField from './field/CDataField.js'
import CRelationField from './field/CRelationField.js'
import CLinkField from './field/CLinkField.js'
import CVirtualField from './field/CVirtualField.js'
import CComputedField from './field/CComputedField.js'

import $ from 'jquery'

/**
 * for now this class combines CField/CClass classes and enable access on
 * model properties using jquery.
 *
 * First we have to load the xml model file, and this should be done only once.
 *
 * What we want once the xml loaded ?
 *
 * accessing model properties is done the lazy way, storing only accessed data, loading
 * them from xml at demand.
 *
 * model.cclass('cclassId').field('fieldId').name()
 * model.cclass('cclassId').name();
 * model.cclass('cclassId').field('fieldId').
 * model.cclass("cclass").field('toto').hasTag('CONSULT');
 *
 * field = kit.model.cclass("cclass").field("toto");
 * field.name(), field.shortName(), field.displayName(), field.convert(), field.displayValue(),
 * field.stringValue()
 *
 * classes/fields are extracted from xml, but should be cached once accessed.
 *
 * There is no way to build a model using thoses classes, a model can only be read from xml
 *
 * @class com.omniapp.kit.model.CModel
 */
function CModel(xml) {

    this.SKIPPED = "__SKIPPED";

    this.model = xml;

    var _classes = {};

    this.fieldMap = {};

    /**
     * list fields ids of a given cclass
     */
    this.fields = function(cclass) {

        // retrieve fields of cclass

        // prepend fields of superclass if any
    };

    /**
     * retrieve all classes ids in this model
     */
    this.classes = function() {
        var names = [];
        $(this.model).find("cclass").each(function() {
            names.push($(this).attr('id'));
        });
        return names;
    };

    /**
     * retrieve a CClass given its id
     * @param id identifier of the class
     * @return the CClass object with this id
     */
    this.cclass = function(id) {
        if (id instanceof CClass)
            return id;
        var cached = _classes[id];
        if (cached) {
            //Log.log("return cached class");
            return cached;
        }
        var c = $(this.model).find("cclass[id='" + id + "']");
        var cc = new CClass(c);
        cc._model = this;
        _classes[id] = cc;
        return cc;
    };

    /**
     * retrieve a field, id has the 'xx.yy' form
     */
    this.field = function(id) {
        var splitted = id.split('.');
        return this.cclass(splitted[0]).field(splitted[1]);
    }





    /**
     * Register a CField kind for a given element name.
     * Allow to customize models with specialized fields
     */
    function registerField(name, fieldConstructor) {
        this.fieldMap[name] = fieldConstructor;
    }

    /** init the field factory */
    this.fieldMap['number'] = CNumberField;
    this.fieldMap['text'] = CTextField;
    this.fieldMap['date'] = CDateField;
    this.fieldMap['data'] = CDataField;
    this.fieldMap['enum'] = CEnumField;
    this.fieldMap['relation'] = CRelationField;
    this.fieldMap['link'] = CLinkField;
    this.fieldMap['virtual'] = CVirtualField;
    this.fieldMap['computed'] = CComputedField;

} // end CModel

export default CModel

