<template>
  <hbox class="dashboard-cell">
    <div class="dashboard-cell-container fill" :class="{vbox: vertical, hbox: !vertical}">
      <hbox class="fill">
        <vbox class="justify-start fill">
          <transition name="gip-transition" mode="out-in">
            <h1 :key="compteur">{{ compteur }}<span>{{ unit }}</span></h1>
          </transition>
          <p class="gip-label">{{ libelle }}</p>
        </vbox>
        <!--  <icon-base><dialog-icon/></icon-base>-->
        <component class="cell-icon" v-bind:is="icon"/>
      </hbox>
      <slot/>
    </div>
  </hbox>
</template>

<script>

import GipMixin from '../GipMixin.js'
// import DialogIcon from '../../../assets/icons/dialog-icon'

export default {
  name: "DashboardCell",
  mixins: [GipMixin],
  // components: {DialogIcon},
  props: {
    compteur: String,
    libelle: String,
    icon: String,
    unit: String,
    vertical: {type: Boolean, default: false}
  }
}
</script>
/** le css n'est pas scoped pour pouvoir l'utiliser dans les dashboards */
<style lang="scss">

.dashboard-cell {
  margin : 0.5em;
  background-color: var(--gip-white-color);
  padding-right: 1em;
  padding-left: 2em;
  padding-bottom: 2.5em;
  padding-top: 2.5em;
  border-radius: 5px;
}

.dashboard-cell h1 {
  color: var(--gip-accent-color);
  font-style: normal;
  font-size: 3em;
  margin-bottom: 0.2em;
  font-weight: 500;
}
.dashboard-cell h1 span {
  font-size: 0.5em;
}

.dashboard-cell .compteur-small {
  font-size: 2.3em;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 1em;
}
.cell-icon {
  align-self: center;
}

.left-cell {
  margin-left: 0;
}
.right-cell {
  margin-right: 0;
}

.categories-graphique h2 {
  //color: var(--gip-tint-color);
  //font-size: 1em;
  //font-weight:600;
  margin-bottom: 3em;
  text-align: left;

}


.categories-graphique .hbox {
  align-items: center;
  //background: red;
}
.cagegorie-graphique {
  color: var(--gip-tint-color);
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  white-space: nowrap;
}

.cell-merge-left {
  margin-left: 0;
  //margin-right:1em;
  //padding-left: -1em;
}
.cell-merge-right {
  margin-right: 0;
  //padding-right: 3em;
  //margin-left: 1em;
  //padding-right: -1em;
}

</style>