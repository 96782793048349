
import CObject from './CObject.js';
import $ from 'jquery'

/**
 * A sort criteria
 * @class com.omniapp.kit.model.SortCriteria
 */

function SortCriteria(aField, isReverse) {

    this.field = aField;
    this.reverse = isReverse ? isReverse : false;
    
    this.compare = function(o1, o2) {
        if (this.reverse)
            return this.field.compare(o2, o1);
        else
            return this.field.compare(o1, o2);
    };
    
    this.toString = function() {
        return '[SortCriteria, field=' + this.field.toString() + ' reverse=' + this.reverse + ']';
    };

} // end class SortCriteria

/**
 * A set of CObject
 * @class com.omniapp.kit.model.CObjectSet
 */
function CObjectSet (aCClass) {

    this.cclass = aCClass;
    this.objects = [];

    /** retrieve CClass of this CObjectSet */
    this.getCClass = function () {
        return this.cclass;
    };

    /** retrieve number of objects in this set */
    this.size = function() {
        return this.objects.length;
    };
    /** retrieve CObject at index */
    this.get = function(index) {
        return this.objects[index];
    };

    /** does an object is contained into this set */
    this.contains = function(object) {
        return this.objects.indexOf(object) >= 0;
    };

    /** add an object to this set */
    this.add = function(object) {
        this.objects.push(object);
    };

    /** remove an object from this set */
    this.remove = function(object) {
        var index = this.objects.indexOf(object);
        if (index !== -1) {
            //alert('CObjectSet remove at index ' + index);
            this.objects.splice(index, 1);
        }
    };
    /** write this CObjectSet as xml */
    // this.toXML = function ( prolog ) {
// 		
        // var xml = '<cobjectset cclass="' + data.cclass + '">';
        // for ( var i = 0; i < data.data.length; i++ )
            // xml += "\n" + data.data[i].toXML();
// 		
        // xml += '</cobjectset>';
// 		
        // if ( prolog )
            // xml = '<?xml version="1.0" encoding="utf-8"?>' + "\n" + xml;
// 		
        // return xml;
// 		
    // }

	// PENDING
    // this.sort = function(criterias) {
	//
    // };
}

/** static method to build a CObjectSet from xml */
CObjectSet.fromXml = function ( xmlNode ) {
    // Log.log('node=' + xmlNode.nodeName);
    if (xmlNode.nodeName === "#document")
        xmlNode = xmlNode.firstChild;
    // alert('cobjectset : ' + $(xmlNode).html());
    var node = $(xmlNode).children();
    // Log.debug('cclass dans CObjectSet=' + node.attr('cclass'));
    var set = new CObjectSet(node.attr('cclass'));
    node.each(function () {
        set.add(CObject.fromXML(this));
    });
    return set;
};

export default CObjectSet
