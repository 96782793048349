<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="82.224" height="34.018" viewBox="0 0 82.224 34.018">
    <path id="Tracé_5427" data-name="Tracé 5427" d="M205.61,616.31a7.506,7.506,0,0,0-1.135-2.1c-.218-.269-.7-.84-1.049-.667a61.572,61.572,0,0,0-7.559.45c-2.546.238-5.093.493-7.635.82q-7.611.98-15.2,2.374a283.049,283.049,0,0,0-30.153,7.286c-2.792.836-5.584,1.719-8.388,2.456-1.36.357-2.724.68-4.092.95-.7.138-1.4.263-2.1.4-.535.1-1.7-.015-2.051.629l.011.022c-.193.208-.142.837-.056,1.217a3.847,3.847,0,0,0,1.159,2.154,2.82,2.82,0,0,0,1.963.25c.645-.026,1.288-.073,1.932-.15,1.277-.152,2.552-.35,3.823-.581,2.547-.463,5.076-1.033,7.607-1.674,5.058-1.281,10.119-2.515,15.2-3.576,10.159-2.123,20.34-3.867,30.569-4.992,2.871-.316,5.743-.618,8.617-.873,2.855-.253,5.726-.322,8.567-.8C206.729,619.721,205.872,617.006,205.61,616.31Z" transform="translate(-3.104 -626.304) rotate(11)" fill="#43ad9e"/>
  </svg>
</template>

<script>
export default {
  name: "DiviseurLogo",
}
</script>

