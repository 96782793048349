/**
* le composant de login, utilisé par LoginEmployeur et LoginMembre
*/
<template>
  <vbox class="gip-background">

    <vbox class="gip-page max-100">
      <vbox class="gip-container max-100">
        <div class="login-container">
          <Sphere bottom="60" right="-60" taille="213"/>
          <Sphere couleur="grey" left="-50" taille="213" top="40"/>
          <div class="sphere-container">
            <h1>
              {{ titre }}
            </h1>
            <p v-html="texte"></p>
            <b-form class="vbox">
              <b-form-group id="login-group" :label="loginLabel" class="gip-label" label-for="login">
                <b-form-input
                    id="login" ref="login"
                    v-model="login"
                    :placeholder="loginPlaceholder"
                    required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="password-group" class="gip-label" label="Mot de passe" label-for="password">
                <BPasswordField v-model="password" id="password" ref="password" placeholder="Entrez votre mot de passe"/>
              </b-form-group>
            
              <p class="error gip-label"> {{ error }} </p>
              <hbox id="top-options" class="justify-center justify-expand-self">
                <!--                <b-form-checkbox v-model="stayConnected" class="gip-label">Se souvenir de moi</b-form-checkbox>-->
                <div>
                  <input id="stayConnected" v-model="stayConnected" type="checkbox">
                  <label class="gip-label" for="stayConnected">Se souvenir de moi</label>
                </div>
                <div class="fill"/>
                <a id="forgot-password" @click="forgotPassword">Mot de passe oublié ?</a>
                <!--                <BtnAction :withLogo="false" to="resetPassword" type="action" class="password-action"-->
                <!--                           titre="Mot de passe oublié ?"></BtnAction>-->
              </hbox>
              <BtnAction :click="connection" :withLogo="false" class="fill connect" titre="SE CONNECTER"></BtnAction>
            </b-form>

            <CreationCompteEmployeur ref="creationWizard"/>

            <hbox v-if="type === 'Employeur'" class="pas-inscrit">
              <span class="gip-label">Pas encore inscrit ?</span>

              <BtnAction :click="creationEmployeur" :withLogo="false" class="pas-inscrit-action"
                         titre="Créer mon compte"
                         type="action"/>
            </hbox>
          </div>
        </div>
      </vbox>
    </vbox>
  </vbox>
</template>

<script>
import GipMixin from '../ui/GipMixin.js'
import Sphere from "./Sphere";
import BtnAction from "./BtnAction.vue";
import {state, connection} from "../ui/GipMixin"
import kit from "../../kit/kit";
import {CFilterCondition, CFilterType} from "@/kit/data/CFilter";
import debounce from 'lodash/debounce'
import {default as CFilter} from '../../kit/data/CFilter.js'
import CreationCompteEmployeur from '../site/CreationCompte/CreationCompteEmployeur.vue';
import BPasswordField from './BPasswordField.vue';

export default {
  name: "Login",
  mixins: [GipMixin],
  components: {Sphere, BtnAction, CreationCompteEmployeur, BPasswordField},

  props: {
    type: {
      type: String,
      default: null
    },
    titre: {
      type: String,
      default: "Le titre"
    },
    texte: {
      type: String,
      default: "le texte"
    },
    loginLabel: {
      type: String,
      default: "le label du login"
    },
    loginPlaceholder: {
      type: String,
      default: "LOGIN PLACEHOLDER"
    },
    targetPage: String,
    connectCallback: Function
  },

  data() {
    return {
      password: null,
      login: null,
      error: null,
      fin: false,
      nafs: null,
      naf: null,
      typeCommune: null,
      typeEmployeur: null,
      stayConnected: false,
      creationWizard: null
    }
  },
  methods: {
    /**
     * Appelle le service d'auth, et si c'est ok emet l'evt connect au composant supérieur
     * @param e
     */
    getNafs: debounce(function (name) {
      // var self = this
      if (!name.length) {
        this.nafs = []
        return
      }
      let filter = new CFilter(CFilterType.OR)
      filter.add("NAF.nafId", CFilterCondition.CONTAINS, name)
      filter.add("NAF.nom", CFilterCondition.CONTAINS, name)
      kit.services.query({
            cclass: "NAF",
            filter: filter,
            limit: 100
          },
          (data) => {
            console.log(data)
            this.nafs = []
            data.forEach((item) => this.nafs.push(item))
            console.log("nafs size=" + this.nafs.length)
          },
          (error) => {
            this.nafs = []
            alert("error")
          })

    }, 500),
    connection(e) {
      // const self = this
      kit.services.service2('auth?action=login', {
        params: {login: this.login, password: this.password, type: this.type, stayConnected: this.stayConnected},
        onSuccess: (data) => {
          this.$refs.login.$el.classList.remove("kit-error")
          this.$refs.password.$el.classList.remove("kit-error")
          this.error = null
          this.$emit("connect", data)
        },
        onError: (err) => {
          this.error = err.toString()
          this.$refs.login.$el.classList.add("kit-error")
          this.$refs.password.$el.classList.add("kit-error")
        }
      });
    },

    /**
     * Mot de passe oublié : ouvre le reset de mot de passe en passant le bon type, Employeur ou Membre
     */
    forgotPassword(e) {
      window.scrollTo(0, 0)
      this.$router.push({name: "resetPassword", params: {type: this.type}})
    },
    selectNaf(option) {
      // alert('select ' + option.communeId)
      this.naf = option
    },
    formatNaf(d) {
      return d.nafId + " - " + d.nom
    },
    creationEmployeur(e) {
      this.$refs.creationWizard.show()
    },
    next() {
      // TODO : vérif si Autre ou Commune et vérif les champs correspondant
      this.fin = true;
    },
    checkNaf(NafObjet) {
      // TODO : ajout appelle a service pour verif c quoi le naf et agir en conséquence
      kit.call(() => {
        var query = {
          cclass: 'NAF',
          json: true,
          filter: new CFilter("NAF.nafId", NafObjet.__id),
          limit: 1,
        }
        kit.services.query(query,
            (set, req) => {
              this.typeEmployeur = set[0].eligibilite
              if (this.typeEmployeur == "NORMALE") {
                this.next()
              }
            },
            () => {
              kit.log.error("erreur récuperation informations du code naf")
            }
        )
      });


    }
  },
  computed: {
    // state
  },
  mounted() {
    console.log(this.site)
    this.creationWizard = this.$refs.creationWizard
  }
}
</script>

<style lang="scss">

.login-container {
  margin-top: 3em;
  margin-bottom: 5em;
  //padding: 5em;
  //background: var(--gip-white-color);
  max-width: 100%;
  width: 600px;
  align-self: center;
  position: relative;
  z-index: 1;
}

.login-container h1 {
  color: var(--gip-accent-color);
  font-size: 1.5em;
}

.max-100 {
  max-width: 100%;
}

.sphere-container {
  z-index: 1;
  position: relative;
  padding: 3.5em 5.9em;
  background: var(--gip-white-color);
}

.password-action a {
  padding-right: 0 !important;
}

.connect {
  text-align: center;
}

.pas-inscrit {
  align-items: center;
  padding-top: 1em;
}

.pas-inscrit-action {
  font-size: inherit !important;
}

.pas-inscrit-action a {
  padding: 0em 0 0 0.5em !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  font-size: 1em !important;
}

.register-modal-btn-holder {
  display: block;
  justify-content: center;

  div {
    display: block;

  }

  button {
    padding: 16px;
    border-radius: 8px;
    margin-top: 45px;
    height: 50px;
    color: white;
    background: var(--gip-text-color);
  }
}

#forgot-password {
  color: var(--gip-action-color);
  font-weight: 700;
  font-size: 1em;
  margin: 0;
  padding: 0;

  &:hover {
    color: var(--gip-action-rollover-color);
  }
}

#top-options {
  padding-bottom: 1em;
}
</style>