<template>
  <hbox @click.native="navigate" class="gip-card">
    <vbox class="img-container">
      <img v-if="data.logo" class="card-img" v-lazy="'/images/membres/'+ data.logo ">
      <img v-else class="card-img" src="/images/gip-logo.png">
    </vbox>
    <vbox class="info-container fill">
      <p class="titre">{{ data.nom }}</p>
      <p class="text">Membre depuis le : <span>{{ formatDate(data.adhesion, 'DD MMMM YYYY') }}</span></p>
      <div class="fill"/>
      <p class="text">
        <PlusLogo class="logo"/>
        En savoir plus
      </p>
<!--      <div class="line"></div>-->
    </vbox>
  </hbox>

</template>

<script>

import PlusLogo from "../../assets/svg/plus_rounded_action_logo.svg"
import GipMixin from "./GipMixin";
export default {
  name: "CardMenbre",
  mixins: [GipMixin],
  components: {PlusLogo},
  props:  {data:{
default: () => ({
    logo:""
  })
}},
  data() {
    return {
      info: {
        titre: "Le bar, du plus loin que je me souvienne…",
        img: null,
        date: "20 juillet 2021",
        id:"vroom"
      },

    }
  },
  methods: {
    navigate() {
      // si page détaillée on ouvre la page
      if (this.data.pageDetaillee === 'O') {
        window.scrollTo(0, 0)
        this.$router.push({name: "MembreId", params: {id: this.data.membreId}})
      }
      // et sinon on ouvre le lien
      else {
        window.open(this.data.lien)
      }
    }
  }

}
</script>



<style lang="scss" scoped>

.logo {
  margin-right: 13px  ;
}

.card-img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;

  padding: 23px;
}

.img-container {
  background: var(--gip-sphere-grey);
  margin-right: 24px;
  //display: inline-block;
   vertical-align: top;
  width: 213px;
  height: 209px;
  border-radius: 5px;
  overflow: hidden;
  justify-content: center;
}

.info-container {
  vertical-align: top;
  max-width: 243px;
  border-bottom: 3px solid var(--gip-sphere-grey);
}

.titre {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 23px;
}

.text {
  font-size: 12px;
  font-weight: 400;
  //line-height: 22px;
  margin-top: 0;
  margin-bottom: 1.5em;
  //max-width: 213px;

  span {
    font-weight: 700;
    line-height: 12px;
  }
}

.gip-card {
  max-width: 100%;
  position: relative;
  padding: 1.5em;
  background: var(--gip-white-color);
  border-radius: var(--gip-border-radius);

  text-align: left;
  width: 534px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 12px 23px #0000001A;

    .info-container {
      border-bottom: 3px solid var(--gip-action-color);
   }
  }
}

@media all and (max-width: 960px) {
  .gip-card{
    flex-direction: column !important;
  align-items: center;
    width: 300px;
  }
  .img-container{
    align-items: center;
    margin: 0;
  }
  .titre{

  }

  .text{


    span{

    }
  }
  .gip-card{

    &:hover{
      box-shadow: 0px 12px 23px #0000001A;
      .line{
      }
    }
  }

}

</style>