<template>
  <vbox class="dashboard gip-page">
    <div class="gip-container">
      <vbox class="back-title justify-left">
        <h1 class="first-title">Bonjour,</h1>
        <h1>{{ employeur.nom}}</h1>
        <p>Dernière connexion : {{formatDate(employeur.lastConnection, 'D MMMM YYYY')}}</p>
      </vbox>

      <vbox>
        <back-header title="Synthèse de mon activité" class="fill">
          <hbox class="align-items-center fill">
            <b-form-select id="anneeChooser" @change="onAnneeSelected()" v-model="annee"
                           :options="annees"></b-form-select>
          </hbox>
        </back-header>
        <hbox class="wrap-medium">
          <dashboard-cell id="demandes-cell" class="fill left-cell align-self-start grow-medium"
                          :compteur="format2(infosEmployeur.nbDemandes)"
                          libelle="Demandes d'aide" icon="HomeIcon" :vertical="true">
            <vbox id="detail-demandes">
              <p>
                  <span class="small-number">{{infosEmployeur.nbDemandesAcceptees}}</span>
                  <span v-if="infosEmployeur.nbDemandesAcceptees > 1"> Demandes acceptées</span>
                <span v-else> Demande acceptée</span>
              </p>
              <p>
                <span class="small-number">{{infosEmployeur.nbDemandesEnAttente}}</span>
                <span v-if="infosEmployeur.nbDemandesEnAttente > 1"> Demandes en attente</span>
                <span v-else> Demande en attente</span>
              </p>
              <p>
                <span class="small-number">{{infosEmployeur.nbDemandesRefusees}}</span>
                <span v-if="infosEmployeur.nbDemandesRefusees > 1"> Demandes refusées</span>
                <span v-else> Demande refusée</span>
              </p>
            </vbox>
          </dashboard-cell>
          <vbox class="fill">
            <hbox class="wrap-small">
              <dashboard-cell class="fill" :compteur="format2(infosEmployeur.montantAides)"
                              libelle="D'aides attribuées" icon="EuroIcon" unit="€"/>
              <dashboard-cell class="fill right-cell" :compteur="format2(infosEmployeur.nbSalaries)"
                              libelle="Salariés" icon="PersonneIcon"/>
            </hbox>
            <hbox class="flex-wrap dashboard-cell">
              <vbox class="categories-graphique fill cell-merge-right">
                <h2 class="dashboard-cell-label gip-label">Répartition de vos financements</h2>
                <hbox v-for="(financement, index) in infosEmployeur.financements" :key="index">
                  <span class="cagegorie-graphique fill">{{ financement.financeur }}</span>
                  <span class="compteur-small" :style="couleur(index)">{{ financement.montant }} €</span>
                </hbox>
              </vbox>
              <vbox class="justify-center right-cell cell-merge-left">
                <DonutsChart   :labels="donutLabels" ref="donut" unit="€" :colors="donutColors"/>
              </vbox>
            </hbox>
          </vbox>
        </hbox>
      </vbox>
    </div>
  </vbox>
</template>

<script>

import GipMixin from '../ui/GipMixin.js'
import DashboardCell from '../ui/back/DashboardCell'
import BackHeader from '../ui/back/BackHeader'
import DonutsChart from "../ui/DonutsChart";
import kit from "../../kit/kit";
import CObject from "../../kit/data/CObject";
import moment from "moment";

export default {
  name: "DashboardEmployeur",
  mixins: [GipMixin],
  components: { DashboardCell, BackHeader, DonutsChart },

  data() {
    return {
      annee: new Date().getFullYear(),
      annees: [
        { value: 'Totale', text: 'Totale' },
        { value: '2015', text: '2015' },
        { value: '2016', text: '2016' },
        { value: '2017', text: '2017' },
        { value: '2018', text: '2018' },
        { value: '2019', text: '2019' },
        { value: '2020', text: '2020' },
        { value: '2021', text: '2021' },
        { value: '2022', text: '2022' },
        { value: '2023', text: '2023' },
      ],
      colors: [
          "red", "blue"
      ],
      // pour test
      employeur: {
        nom: "Christelle Dupont",
        lastConnection: "2021-09-01"
      },
      // pour test
      infosEmployeur: {
        "__cclass": "InfosEmployeur",
        "__id": "TempId|78",
        "nbDemandes": "12",
        "nbDemandesAcceptees": "12",
        "nbDemandesEnAttente": "0",
        "nbDemandesRefusees": "0",
        "nbSalaries": "32",
        "montantAides": "1969",
        "financements": null
      },
      donutColors: [
        'rgb(199, 211, 3)',
        'rgb(67, 173, 158)',
        '#044370','rgb(128, 128, 128)'],
    }
    // }
  },
  created() {
    this.employeur = this.state.user
    const current = new Date().getFullYear()
    let ans = [ { value: "Totale", text: "Totale" }]
    for (let i = 2015; i < current + 1; i ++) {
      ans.push({ value: String(i), text: String(i) })
    }
    this.annees = ans
    this.onAnneeSelected()
  },
  computed: {
    donutLabels : function() {
      if (this.infosEmployeur.financements)
        return Array.from(this.infosEmployeur.financements, f => f.financeur)
    else
      return []
      },
    donutValues: function(){
      if (this.infosEmployeur.financements){
         let v = Array.from(this.infosEmployeur.financements, f => f.montant);


        return v
      }else{
        return [0,0,0,-1]
      }
    },

    },
  methods: {
    couleur: function(index) {
      var index2 = index % this.donutColors.length
      console.log("index =" + index + " index2" + index2)
      return "color:" + this.donutColors[index2] + ";"
    },
    onAnneeSelected() {
      let an = this.annee === "Totale" ? "0" : this.annee
      kit.services.query({cclass: "InfosEmployeur", id: this.employeur.employeurId + "|" + an, depth: 3, json: true},
          (data) => {
            this.infosEmployeur = data

            let v = Array.from(this.infosEmployeur.financements, f => f.montant)
            v = v.length ? v : [0,0,0,-1]

            this.$refs.donut.updateValues(v)
            // this.updatePourcents(this.infosFinanceur.categoriesSpectacles)
            // this.$refs.donut.updateValues([this.pourcentDANSE, this.pourcentTHEATRE, this.pourcentMUSIQUE])
          },
          (error) => {
            alert("error")
          });
    },
    telechargerReleveAnnuel() {
      var config = new CObject("RapportEtablissements")
      config.newValue("financeurId", this.state.user.employeurId)
      config.newValue("dateDebut", moment("01/01/" + this.annee, "DD/MM/YYYY").toDate())
      config.newValue("dateFin", moment("31/12/" + this.annee, "DD/MM/YYYY").toDate())
      config.newValue("dateCalcul", "DATE_SPECTACLE")
      config.newValue("typeFinanceurs", "TOUS");

      var xml = kit.services.write(config);
      kit.services.service2("report?reportId=RapportEmployeur&cclass=Employeur&reportType=pdf&id=" + this.state.user.employeurId,
          {
            data: xml,
            onSuccess: (report) => {
              console.log("report=" + report.file)
              // window.open(window.location.origin + "/" + report.file, "_blank")
              window.open(kit.servicesUrl + "/document?type=report& ``id=" + report.file, "_blank")
            },
            onError: (error) => {
              kit.dialogs.error("Erreur lors de l'édition du rapport : " + error)
            }
          });
      // window.open(kit.servicesUrl + "/report?reportId=" + "RapportEmployeur"
      //     + "&cclass=Employeur&id=" + this.state.user.employeurId + "&reportType=pdf&data=" + encodeURIComponent(xml), "_blank");
    }
  }
}
</script>

<style scoped lang="scss">

#anneeChooser {
  flex-basis: fit-content;
  width: auto;
  font-weight: 600;
  color: var(--gip-tint-color);
}
.small-number {
  color: var(--gip-accent-color);
}
.gip-container {
  padding-bottom: 6em;
}

#releve-annuel {
  color: var(--gip-white-color);
  font-size: 0.8em;
  font-weight: 600;
  margin-right: 1em;
}

#download-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.8em;
}
#releve-annuel path {
  fill: var(--gip-white-color);
}

#detail-demandes {
  border-top: solid 1px var(--gip-menu-background);
  padding-top: 1em;
}

.compteur-small {
    margin-right: 1em;
}
</style>