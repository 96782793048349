<template>
  <div>
    <div class="gip-bg-default">
      <div class="gip-page">
        <div class="back-title">
          <h1>Faire une demande</h1>

        </div>
        <BackHeader title="Ma demande">
        </BackHeader>
        <!-- les infos globales du spectacle -->

        <c-object-view cclass="Spectacle" ref="spectacle">
        <vbox id="spectacle">
          <div class="infos-all">
            <h5>Informations sur la représentation</h5>
            <hr>
            <hbox id="infos-spectacle" class="wrap-small">
              <k-field label="Date du spectacle" ref="dateRepresentation">
                <b-field class="fix-icon">
                  <b-datepicker
                      v-model="spectacle.dateRepresentation"
                      locale="fr-FR"
                      placeholder="jj/mm/aaaa"
                      icon-right="calendar-today"
                      trap-focus
                  >
                  </b-datepicker>
                </b-field>
              </k-field>
              <k-field label="Titre du spectacle*" field="Spectacle.nom">
                <input v-model="spectacle.nom" type="text" placeholder="Le titre du spectacle">
              </k-field>
              <k-field label="Catégorie*" ref="categorie">
                <select v-model="spectacle.categorie" ref="categorieChooser">
                  <option v-for="(categorie, index) in categories" :key="index" v-bind:value="categorie.value">
                    {{ categorie.name }}
                  </option>
                </select>
              </k-field>
            </hbox>
            <!-- ! infos complémentaires, uniquement si pas CHR -->
            <hbox v-if="this.state.user !== null && this.state.user.type !== 'CHR'" id="infos-complementaires" class="wrap-small">
                <k-field label="Lieu*" ref="lieu">
                  <input v-model="spectacle.lieu" type="text" placeholder="Salle des fêtes, place publique, parc...">
                </k-field>
              <CommuneField v-model="spectacle.communeId" ref="communeId" label="Ville*" placeholder="Ville du spectacle"/>
              <k-field  label="Jauge*" ref="jauge">
                <input v-model="spectacle.jauge" type="text" placeholder="Capacité de la salle" @change="updateMontantAide">
              </k-field>
            </hbox>
          </div>
          <PhotosSpectacles :spectacleId="null"
                            :get-image="() => null"
                            :addPicToDoc="appendPhoto"
                            :removePicFromDoc="deletePhoto"
                            :photoCount="photoCount"
                            :autoSend="false"
                            :anticipe="isAnticipe"/>
          <!-- le . N'est affiché que si on a l'option dans le profil -->
          <vbox id="demandeur-tiers">
            <div>
              <k-field label="Avec demandeur" ref="avecDemandeur" id="avec-demandeur" nolabel>
                <div>
                  <input v-model="spectacle.avecDemandeur" id="demandeurTiers" type="checkbox">
                  <label for="demandeurTiers">Demandeur tiers</label>
                </div>
                <hr>
              </k-field>
            </div>
            <vbox id="detail-demandeur-tiers" :class="{hidden: !spectacle.avecDemandeur}">
            <hbox  class="wrap-small">
              <k-field label="Nom du demandeur" ref="nomDemandeur">
                <input v-model="spectacle.nomDemandeur" type="text" placeholder="Votre nom">
              </k-field>
              <k-field label="Prénom du demandeur" ref="prenomDemandeur">
                <input v-model="spectacle.prenomDemandeur" type="text" placeholder="Votre prénom">
              </k-field>
            </hbox>
            <hbox class="wrap-small">
              <k-field label="Mail du demandeur" ref="mailDemandeur">
                <input v-model="spectacle.mailDemandeur" type="text" placeholder="Votre adresse mail">
              </k-field>
              <k-field label="Téléphone du demandeur" ref="telDemandeur">
                <input v-model="spectacle.telDemandeur" type="text" placeholder="Votre téléphone">
              </k-field>
            </hbox>
            </vbox>
           </vbox>
          <!-- les intervenants du spectacle -->
          <vbox id="intervenants-spectacle" class="fill">
            <h5>Informations sur les salariés</h5>
            <hr>
            <hbox v-for="(intervenant, index) in spectacle.intervenants" :key="index" class="intervenant wrap-small align-expand">
<!--              <CObjectView v-for="(intervenant, index) in spectacle.intervenants" :key="index" class="intervenant-spectacle" cclass="IntervenantSpectacle">-->
<!--                <hbox class="intervenant wrap-small align-expand">-->
                <k-field label="Nom*" ref="nomIntervenant">
                <input v-model="intervenant.nom" type="text" placeholder="Le nom de l'intervenant">
              </k-field>
              <k-field label="Prénom*" ref="prenomIntervenant">
                <input v-model="intervenant.prenom" type="text" placeholder="Le prénom de l'intervenant">
              </k-field>
              <k-field label="Type*" ref="qualiteIntervenant" class="gip-select">
                <select v-model="intervenant.qualite" @change="updateMontantAide()">
                   <option v-for="(qualite, index) in qualitesIntervenant" :key="index" v-bind:value="qualite.value">
                    {{ qualite.name }}
                  </option>
                </select>
              </k-field>
                  <icon-base active iconColor="var(--gip-white-color)" background-color="var(--gip-error-color)" style="background-color: var(--gip-error-color);
    max-width: 50px;
    height: 50px;
    margin-bottom: 8px;"
                             class="k-field delete-intervenant"
                             @click.native="deleteIntervenant(index)">
                    <trash-small></trash-small>
                  </icon-base>
<!--                </hbox>         -->
<!--              </CObjectView>    -->
            </hbox>

          </vbox>
          <!-- bouton ajout intervenant -->
          <a id="ajouter-intervenant" @click="ajouterIntervenant()">
            <add-small id="download-icon"/>Ajouter un nouvel intervenant
          </a>

          <!-- les 2 blocs d'info : infos et montant -->
          <hbox class="wrap-small align-self-expand">
            <info-box id="infos" class="fill fill-same k-field">
              À partir de 2 artistes salariés, le salaire<br/>
              d'un technicien peut être pris en compte sur la même<br/>
              base de calcul.
            </info-box>
            <vbox class="infos fill fill-same k-field" id="montant">
                <p>Montant de l'aide : {{ montantAide }} € **</p>
                <p v-if="aideExceptionnelle">Aide maximale.</p>
            </vbox>
          </hbox>
          <!-- container pour afficher le texte d'aide à droite -->
          <hbox class="fill">
            <p id="infos-spacing" class="fill fill-same k-field"></p>
            <p id="help-montant" class="fill fill-same gip-label-small k-field">** Le montant annoncé est indicatif sous réserve des financements (disponibilité des fonds et plafonds en vigueur sur votre territoire). Le versement de l’aide 
            est soumis au respect des obligations salariales (cotisations réglées et montant minimum brut du cachet par salarié).</p>
          </hbox>

          <!-- le bouton de validation -->
          <BtnAction 
            ref="btn" 
            :once="true" 
            id="update-button"
            class="align-self-center btn-creer-mon-compte"  
            :click="this.verifForm"
            :withLogo="false"
            :titre="loadingService ? 'Chargement...' : 'Envoyer ma demande'"
            :disabled="loadingService">
          </BtnAction>
          <p class="align-self-center gip-label-small">* Champs obligatoires</p>
        </vbox>
        
        </c-object-view>
      </div>
    </div>
  </div>
</template>

<script>
import GipMixin from "../ui/GipMixin";
import BackHeader from "../ui/back/BackHeader";
import InfoBox from "../ui/back/InfoBox";
import kit from "../../kit/kit";
import CObjectView from "../../kit/vue/CObjectView"
import CObject from "../../kit/data/CObject";
import CObjectSet from "../../kit/data/CObjectSet";
import CommuneField from "../ui/CommuneField.vue";
import DateExt from '@/kit/misc/DateExt'

import PhotosSpectacles from "@/components/ui/PhotosSpectacles.vue";

import $ from "jquery";

export default {
  name: "CreationDemande",
  mixins: [GipMixin],
  components: { BackHeader, InfoBox, CObjectView, CommuneField, PhotosSpectacles },
  data() {
    return {
      photoCount:0,
      formData: new FormData(),
      montantAide: "258,40",
      aideExceptionnelle: false,
      photosSpectacle: [],
      selected: null,
      loadingService: false,
      spectacle: {
        nom: null,
        dateRepresentation: null,
        categorie: "MUSIQUE",
        avecDemandeur: false,
        nomDemandeur: null,
        prenomDemandeur: null,
        telDemandeur: null,
        mailDemandeur: null,
        etat: "X",
        communeId: null,
        lieu: null,
        jauge: null,
        intervenants: [
          {
            "__cclass": "IntervenantSpectacle",
            "nom": null,
            "prenom": null,
            "qualite": "A"
          }
        ],
      },
      // la liste des k-field, on ne peut pas iterer sur tous les kfields parce qu'il y a les relations
      // autrement il faudrait identifier le CObjectView englobant
      kfields: ["dateRepresentation", "nom", "categorie", "avecDemandeur", "nomDemandeur", "prenomDemandeur", "mailDemandeur", "telDemandeur", "communeId", "lieu", "jauge"],
      ikfields:["nom", "prenom", "qualite"]
    }
  },
  created() {
    this.newFeature = this.state.newFeature
    // this.$refs["categorieChooser"].setSelected(0)
    this.updateMontantAide()
  },
  computed: {
    selectedString() {
      return this.selected ? this.selected.toDateString() : ''
    },
    categories: function () {
      return kit.model.field("Spectacle.categorie").values()
    },
    defaultCategorie: function () {
      return kit.model.field("Spectacle.categorie").value("MUSIQUE")
    },
    qualitesIntervenant: function () {
      return kit.model.field("IntervenantSpectacle.qualite").values()
    },
    isAnticipe: function () {
        const today = new Date().format('isoDate')
        const dateSpectacle = this.spectacle.dateRepresentation;
        if (!dateSpectacle) return true;
        return dateSpectacle.format('isoDate') > today
    }
  },
  methods: {
    /** ajout intervenant */
    ajouterIntervenant() {
      var itv = {nom:null, prenom:null, qualite:"A"}
      this.spectacle.intervenants.push(itv)
      this.updateMontantAide()
    },
    /** suppression intervenant */
    deleteIntervenant(index) {
      this.spectacle.intervenants.splice(index, 1)
      this.updateMontantAide()
    },

    /** met à jour le montant de l'aide en appelant le service subvention */
    updateMontantAide() {
      var nbArtistes = this.spectacle.intervenants.filter(e => e.qualite === 'A').length;
      var nbTechniciens = this.spectacle.intervenants.filter(e => e.qualite === 'T').length;
      var date = this.spectacle.dateRepresentation ? this.spectacle.dateRepresentation.format("yyyy-mm-dd") : new Date().format("yyyy-mm-dd")
      // console.log("this.employeurId=" + this.state.user.employeurId + " date=" + this.spectacle.dateRepresentation)
      let jauge = parseInt(this.spectacle.jauge)
      let sendJauge = ''
      if (this.state.user.type !== 'CHR' && !isNaN(jauge)) {
        sendJauge = '&jaugeInf300=' + (jauge < 300)
      }
      
      kit.services.service("subvention?nbArtistes=" + nbArtistes + "&nbTechniciens=" + nbTechniciens
          + "&date=" + date + "&employeurId=" + this.state.user.employeurId + sendJauge, null,
          (data, req) => {
            this.montantAide = data.subvention
            this.aideExceptionnelle = data.aideExceptionnelle
          },
          (error) => {
            alert("erreur service subvention")
          })
      },


    appendPhoto(data){
      console.log(data)
      this.photoCount++

      let index = data.ordre;
      this.photosSpectacle[index] = data
      this.formData.append("file_"+index,data?.fichier) // fichier

    },

    deletePhoto(data){
      this.photoCount--
      let index = data.ordre;
      console.log("delete",data)
      delete this.photosSpectacle[index]
      this.formData.delete("file_"+index)
    },

    /** verification de la form et submit si pas d'erreur */
    verifForm() {
      this.loadingService = true
      // on crée un object qui n'a que les valeurs des champs affichés dans la vue
      let object = new CObject("Spectacle")
      object.newValue("employeurId", this.state.user.employeurId)

      // commence par vérifier les intervenants, on n'appelle pas le service de check
      // mais on controle manuellement
      for (var i = 0 ; i < this.spectacle.intervenants.length ; i++) {
        var itv = this.spectacle.intervenants[i]
        for (var k of this.ikfields) {
          var ref = k + "Intervenant"
          this.$refs[ref][i].setError(null)
          if (itv[k] === null) {
            console.log("on a une erreur")
            this.$refs[ref][i].setError("le " + kit.model.field("IntervenantSpectacle."
                + k).name().toLowerCase() + " doit être renseigné")
          }
        }
      }

      // renseigne tous les champs
      for (var kfield of this.kfields) {
        let val = this.spectacle[kfield]
        if (val === undefined)
          val = null
        object.newValue(kfield, kit.model.cclass("Spectacle").field(kfield).convert(val))
//        console.log('set field ' + kfield + " value " + val)
      }
      var intervenants = new CObjectSet("IntervenantSpectacle")

      var photos = new CObjectSet("PhotoSpectacle")
      for (var file of this.photosSpectacle.filter(e => e != undefined)) {
        var photo = new CObject("PhotoSpectacle")
        photo.newValue("fichier", "photo")
        photo.newValue("longitude", file.longitude)
        photo.newValue("ordre", file.ordre)
       console.log(photo.newValue("latitude", file.latitude))

        photos.add(photo)
        console.log(photo)
      }
      for (var itv2 of this.spectacle.intervenants) {
        var intervenant = new CObject("IntervenantSpectacle")
        intervenant.newValue("nom", itv2.nom)
        intervenant.newValue("prenom", itv2.prenom)
        intervenant.newValue("qualite", itv2.qualite)
        intervenants.add(intervenant)
      }
      object.newValue("photos", photos)

      object.newValue("intervenants", intervenants)
      // appelle le service de check. Si ok on appelle la methode en prop 'update', et sinon
      // on met à jour les champs en erreur
      kit.services.check(object, () => {
        console.log("ça passe ?")
            this.errors = null
            this.createDemande(object)
            // this.update(object);
          },
          (error) => {
            this.loadingService = false
            this.$refs.btn.reset();
            this.errors = error
            for (var kfield of this.kfields) {
              if (this.$refs[kfield]) {
                console.log(kfield)
                this.$refs[kfield].setError(null)
                if (error.errorFields().includes(kfield)) {
                  console.log("on a une erreur")
                  let message = error.error(kfield)
                  if (kfield == 'jauge' && this.spectacle.jauge) {  
                    message = 'La jauge doit être un nombre'
                  }
                  this.$refs[kfield].setError(message)
                }
              }
            }
            if (error.errorFields().includes("intervenants")) {
              // erreurIntervenant = error.error("intervenants")
              //alert("erreur=" + error.error("intervenants"))
              for (var i = 0 ; i < this.spectacle.intervenants.length ; i++) {
             //   var itv = this.spectacle.intervenants[i]
                //for (var k of this.ikfields) {
                  var ref = "qualiteIntervenant"
                  this.$refs[ref][i].setError(null)
                  //if (itv[k] === null) {
                    console.log("on a une erreur")
                    this.$refs[ref][i].setError(error.error("intervenants"))
                  //}
                //}
              }
            }
          })
    },

    /** Création de la demande, appelé une fois le check ok */
    createDemande(object) {
      var xml = kit.services.write(object);
      this.formData.set("spectacle",xml)
      
      kit.services.service('creationDemande',this.formData,(data) => {
        this.loadingService = false
        this.$refs.btn.reset();
        window.scrollTo(0, 0)
        this.$router.push({name: "ConfirmationEmployeur"})
        // on push l'objet renvoyé par le service qui a des valeurs en plus et on réordonne
        this.state.user.spectacles.push(data[0])
        this.state.user.spectacles.sort((a, b) => (a.dateRepresentation < b.dateRepresentation) ? 1 : -1)
        
      },(err) => {
        this.loadingService = false      
        this.$refs.btn.reset();

        kit.dialogs.error("" + err)
        console.log(err)
      });
      // kit.services.submit(object, (data) => {
      //   this.$refs.btn.reset();
      //       window.scrollTo(0, 0)
      //       this.$router.push({name: "ConfirmationEmployeur"})
      //       // on push l'objet renvoyé par le service qui a des valeurs en plus
      //       this.state.user.spectacles.push(data[0])
      //     },
      //     (error) => {
      //       this.$refs.btn.reset();
      //
      //       kit.dialogs.error("erreur " + error)
      //       console.log(error)
      //     })
    }
    }
}
</script>

<style>
.dropdown-item:hover {
  background-color: inherit !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: inherit !important;
}
</style>

<style scoped lang="scss">



@import "../../assets/kit.scss";

#spectacle {
  background-color: var(--gip-white-color);
  padding-bottom: 5em;
  padding-right: 2em ;
  padding-left: 2em;
  margin-top: 14px;

}

#spectacle {
  padding-top: 50px;
  padding-bottom: 2em;
  //border-bottom: var(--gip-menu-background) 2px solid;
}

#intervenants-spectacle {
  padding-top: 2em;
  padding-bottom: 2em;
}
.intervenant:first-child .delete-intervenant {
  visibility: hidden;
}
.intervenant {
  align-items: end;
  margin-bottom: 1em;
}

#infos-spacing {
  margin-right: 4em;
}

#infos, .infos {
  background-color: var(--gip-background);
}


#demandeur-tiers {
  //border-bottom: var(--gip-menu-background) 2px solid;
  //padding-top: 1em;
  //padding-bottom: 1em;
}


#detail-demandeur-tiers {
  transition: 0.4s ease-in-out;
  max-height: 400px;
}
#detail-demandeur-tiers.hidden {
  opacity: 0;
  max-height: 0;
}

#help-montant {
  margin-top: 1em;
}

@media (max-width: $size-small) {
  #infos-spacing {
    display: none;
  }
  .intervenant {
    align-items: stretch;
  }

}
#infos, #montant {
  margin-top: 2em;
}
#update-button {
  margin-top: 3em;
  margin-bottom: 1em;
  padding-left: 4em;
  padding-right: 4em;
}

#avec-demandeur .c-field-label {
  display: none;
}

#ajouter-intervenant {
  text-transform: uppercase;
  font-size: 1em;
  color: var(--gip-action-color);
  font-weight: 700;
  font-size: 0.8em;
}

.fix-icon {
  .icon {
    top: 8px;
  }
}

#download-icon {
    width: 1em;
    height: 1em;
    margin-right: 0em;
    top: -0.1em;
    position: relative;
    margin-right: 0.5em;
}

#demandeur-tiers {
  padding-top: 1em;
}

h5, label[for=demandeurTiers] {
  font-size: 14px;
}

hr {
  margin-top: 0;
}

.infos-all {
  margin-bottom: 2em;
}
</style>