<template>
  <!--<div>membre {{ $route.params.id }}</div> -->
  <div class="gip-bg-default">
    <div class="gip-max-width">

  <div class="membre">
    <div>
        <div>
          <div class="gip-breadcrumb"><HomeLogo/> > <router-link :to="{ name:'Actualites' }"><span>Actualités</span></router-link> > {{ data.titre }}</div>
          <div class="infos">

            <hbox class="overlay flex-wrap space-between">
            <vbox class="info-left">
              <h1 class="titre"> {{ data.titre }}</h1>
              <p class="date"><span>{{ data.categorie }}</span> -  {{ formatDate(data.dateCreation, 'DD MMMM YYYY') }}</p>
              <p  class="text" v-html="data.texte"></p>
            
            <div class="network-container">
              <div class="facebook" v-if="data.lienFacebook" @click="openLink(data.lienFacebook)" :title="data.lienFacebook">
                <Facebook/>
                <p>Facebook</p>
              </div>

              <div class="linkedin" v-if="data.lienLinkedin" @click="openLink(data.lienLinkedin)" :title="data.lienLinkedin">
                <Linkedin/>
                <p>Linkedin</p>
              </div>

              <div class="instagram" v-if="data.lienInstagram" @click="openLink(data.lienInstagram)" :title="data.lienInstagram">
                <Instagram/>
                <p>Instagram</p>
              </div>

            </div>
            </vbox>

            <vbox class="image-container fill">
              <img  v-lazy="'/images/actualites/'+ data.image">
            </vbox>
            </hbox>
            <Sphere top="120" right="-70" taille="213"></Sphere>

          </div>

        </div>
    </div>


  </div>

  <PlusSurNous/>
  </div>
  </div>

</template>

<script>
import  PlusSurNous from "../ui/PlusSurNous";
import Sphere from "../ui/Sphere";
import HomeLogo from "../../assets/svg/icons8-accueil.svg"
import Linkedin from "../../assets/svg/LinkedinLogo.svg"
import Facebook from "../../assets/icons/facebook.svg"
import Instagram from "../../assets/icons/instagram.svg"


import kit from "../../kit/kit";
import GipMixin from "../ui/GipMixin";

export default {
  name: "Actualite",
  mixins: [GipMixin],

  components:{
    Sphere,Facebook,Linkedin, PlusSurNous,HomeLogo, Instagram
  },
  data() {
    return {
      data: {
      titre: "Région Bourgogne-Franche- Comté",
      texte: "Les cafés-cultures participent à l’émergence artistique, à la diversité culturelle mais aussi  à l’éveil culturel de nos concitoyens et notamment des plus jeunes d’entre nous. Le fonds d’aide à l’emploi développé via le Gip Cafés Cultures nous permet d’être à leurs côtés. Ce dispositif répond à leurs préoccupations et à celles des artistes. \n \n Mener de front son activité commerciale classique et celle d’organisateur d’événements n’est pas chose facile, tout comme il n’est pas facile de se lancer dans une carrière artistique pérenne. Je remercie donc le Gip d’avoir imaginé, en lien avec les premiers concernés, ce fonds de dotation d’aide à l’emploi artistique direct, solution concrète et simple à même de répondre aux besoins des entrepreneurs et des artistes émergents. Ce mode de financement croisé entre collectivités, État et personnes morales et privées est inventif. Il permet la réactivité et l’implication de tous les acteurs constituant ainsi une véritable voie d’avenir.",
        dateCreation: "20 juillet 2021",
        nom: "",
      id: "",
      quote: "Les cafés cultures accomplissent un travail de terrain et de passion pour faire vivre une culture de proximité et animer la vie dans nos territoires.",
      quoteName: "Marie-Guite Dufay",
      quoteRole: "Présidente de la région Bourgogne-Franche-Comté",
        lienFacebook: "lien facebook",
        lienLinkedin: "lien linkedin"
    }}
  },

   methods:{
     getActualite() {
      console.log(this.$route.params.id)
      kit.call(() => {
        var query = {cclass: 'Actualite', id:this.$route.params.id, json: true};
        kit.services.query(query,
            (set, req) => {
              console.log(set)
              this.data = set

            },
            () => {
              kit.log.error("actu " + this.$route.params.id + " inconnue")
              this.$router.push({name: "404"})

            }
        )
      });
    },
     openLink(url) {
        window.open(url)
     }
  },
  mounted() {
    this.getActualite()
  }
}
</script>

<style lang="scss" scoped>

.gip-breadcrumb{
  padding-top: 100px;
  padding-bottom: 25px;

}

.network-container{

  padding-top: 6em;
  padding-bottom: 4em;

  div{
    display: inline-block;
    margin-right: 15px;
    border-radius: 5px;
    cursor: pointer;
     svg{
      margin:5px 5px;
    }

  }
  p{
    display: inline-block ;
    color: white;
    background: red;
    padding: 8px;
    font-size: 12px;
    margin:0  auto;
    border-radius: 5px;
  font-weight: 600;

    }
  .facebook {
    background: #39579A ;
    p{
      background: #344E8B;

    }
    svg{
      width: 27px;
      height: 27px;
    }

  }
  .twitter {
    background: #00ABEF;
    p{
      background: #009AD7;
    }
  }
  .linkedin{
    background: #117BB5;
    p{
      background: #116EA3;
    }
  }

  .instagram {
    background: #f76b79 ;
    p{
      background: #F05161;

    }
    svg{
      width: 27px;
      height: 27px;
    }

  }
}

.image-container{

  vertical-align: top;
  align-self: flex-start;
  min-width: 400px;

  height: 538px;
  border-radius: 0px 5px 0px 0px;
  overflow: hidden;
  margin: 0 0 0 auto;
}



img{
  display: block;
  width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 0px 5px 0px 0px;

  object-fit: cover;

}

.overlay {
  background: white;
  border-radius: 5px;
  position: relative;
  z-index: 5;
}

.infos {
  display: block;
  margin: 0 auto;
  padding:0px ;
  text-align: left;
  position: relative;
  flex-grow: 1;

  .info-left {
    display: inline-block;
    padding: 4em;
    max-width: 644px;

  }
  .titre {
  }
  .text {
    line-height: 22px;
    white-space: pre-line;
  }
  .date {
    font-weight: 400;
    font-size: 0.8em;
    padding-top: 1em;
    padding-bottom: 4em;
    span {
      color:var(--gip-accent-color);
      font-weight: 700;
    }
  }
}


</style>