<template>
  <EmployeurForm2
    v-if="show" 
    :prop-employeur="employeurIntern" 
    :btn-is-disabled="false" 
    ref="employeurForm" 
    :update="createAccount"  
    btnAction="Créer mon compte"
    @validationError="$emit('endLoading')"
    :creation="true"
    />
</template>

<script>
import EmployeurForm2 from "@/components/employeur/EmployeurForm2.vue";
import kit from "@/kit/kit";

export default {
name: "CreationCompte",
  components: { EmployeurForm2 },
  props: ['employeur'],
  data () {
    return {
      show: false,
      btnIsDisabled:false,
      employeurIntern: null
    }
  },
  watch: {
    employeur: {
      handler(employeur) {
        this.show = false
        this.$nextTick(() => {
          this.employeurIntern = employeur
          this.show = true
        })
        
      },
      immediate: true
    }
  },
    methods:{
      createAccount(object,callback) {
        this.btnIsDisabled = true
        var xml = kit.services.write(object);
        kit.services.service("creer-compte", xml, () => {
          this.$emit('endLoading')    
          this.$router.push({name: "ConfirmationEmployeur"})
          this.btnIsDisabled = false
          callback();
          console.log(object)
            },
            (error) => {
              this.$emit('endLoading')
              alert("pb update")
              this.btnIsDisabled = false
              callback();
              console.log(error)
            })
      },
      submitForm() {
        this.$refs.employeurForm.verifForm()
      } 
    }

}

</script>


<style lang="scss">

.btn-creer-mon-compte a{

  padding: 15px ;
}

</style>
<style lang="scss" scoped>

.gip-container{
  margin: 0 auto;

  position: relative;
}

.btn-container{
  text-align: center;

}

.gip-overlay {
  background: white;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 3px 23px #04437010;
  padding: 5em;
}


.gip-container  {
  h1{
  color: var(--gip-accent-color);
  font-size: 1.5em;
  font-weight: 600;
  }
  p{
    font-weight: 500;
    font-size: 13px;
  }
}

.gip-background{
  padding-top: 76px;
  padding-bottom: 76px;
}


</style>