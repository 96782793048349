
import Vue from 'vue'
import { defineComponent } from "vue";
import { BModal } from 'bootstrap-vue'
//import BModal from 'bootstrap-vue/es/components/modal/modal'

/**
 * Dialogs using vue and bootstrap modals
 *
 * We want to keep the exposed api 'vue agnostic', rely only on a neutral configuration stuff
 *
 * title
 * message
 * buttons
 * close-enabled
 * callback
 *
 * METHODS
 * message(message)
 * info(message)
 * error(message)
 * warning(message)
 * question(message, "
 * value
 * dialog
 *
 */
function VueDialogs() {

    // var : champs privés
    // var _fields = {};
    // var _superClass; // undefined by default

    /**
     * basic message
     * @param message message as string
     */
    this.info = function(message) {
        this.message({title: "Information", message: message});
    };

    /**
     * warning message
     * @param message message as string
     */
    this.warning = function(message) {
        this.message({title: "Alerte", message: message});
    };

    /**
     * errir message
     * @param message message as string
     */
    this.error = function(message) {
        this.message({title: "Erreur", message: message});
    };

    /**
     * message
     * @param message message as string
     */
    this.message = function(options) {
        this.dialog(options)
    };

    /**
     * confirmation
     * options are
     * message : text message
     * title
     * action
     * callback
     */
    this.confirm = function(options) {
        if (options.action)
            options.okTitle = options.action
        var _defaultOptions = {
            okOnly: false,
            title: "Confirmation"
        }
        options = Object.assign(_defaultOptions, options)

        this.dialog(options)
    }

    /**
     * Object.assign() : merge objets, le plus a droite surcharge à gauche !
     * @param options
     */
    this.dialog = function(options) {

        var _defaultOptions = {
            okTitle: "OK",
            cancelTitle: 'Annuler',
            okOnly: true,
            size: "lg" // sm/lg/xl
        }

        options = Object.assign(_defaultOptions, options)

        var ComponentClass = Vue.extend(BModal)
        var instance = new ComponentClass()

        instance.size = options.size
        instance.okOnly = options.okOnly
        instance.title = options.title
        instance.okTitle = options.okTitle
        instance.cancelTitle = options.cancelTitle
        if(options.callback) {
            instance.$on('ok', options.callback);
        }
        instance.$mount() // pass nothing
        document.body.appendChild(instance.$el)

        instance.$slots.default = options.message
        instance.show()
    }

} // end CClass

export default VueDialogs;
