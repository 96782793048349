import CEntity from './CEntity.js'
import Utilities from '../misc/Utilities'

/**
* CField object, extends CEntity
* @class com.omniapp.kit.model.CField
* @extends com.omniapp.kit.model.CEntity
* @param cclass CClass object
* @param cfield jquery field node
* @param kind name of this CField class
*/
function CField(cclass, cfield, kind) {

    CEntity.call(this, cfield);
    this.cclass = cclass;
    this.kind = kind;

    var _defaultValue;
    var _fieldFormatter;
    var _placeHolder;

    /** String rep */
    this.toString = function() {
        return this.kind + "[" + this.id() + ", cclass=" + this.cclass.id() + " type=" + this.node.get(0).tagName + "]";
    };

    /**
     * retrieve default value for this field
     */
    this.defaultValue = function() {
        if (!_defaultValue) {
            _defaultValue = this.node.attr('defaultValue');
        }
        return this.convert(_defaultValue);

    };

    /**
     * retrieve placeholder for this field
     */
    this.placeholder = function() {
        if (_placeHolder === undefined) {
            _placeHolder = this.node.attr('placeHolder');
        }
        return _placeHolder;
    };

    /**
     * retrieve field formatter for this field
     */
    this.formatter = function() {
        if (_fieldFormatter === undefined) {
            var fname = this.node.attr('fieldFormatter');
            if (fname !== undefined) {
                fname = Utilities.basename(fname);
                _fieldFormatter = Utilities.eval(fname);
            }
            else {
                _fieldFormatter = null;
            }
        }
        return _fieldFormatter;
    };

    /**
     * Convert from String to natural object. this method should be overriden.
     * This method must be able to convert from a basic String and a display String.
     * @param val the value to convert
     * @return value converted
     * @throw Error if not implemented or an error occurs
     */
    this.convert = function(val) {
        throw new Error("convert is not implemented by " + this.kind);
    };
    /** convert from natural object to displayable String value */
    this.displayValue = function(val) {
        throw new Error("displayValue is not implemented by " + this.kind);
    };
    /** convert from natural object to String value, as stored id persistence layer */
    this.stringValue = function(val) {
        throw new Error("stringValue is not implemented by " + this.kind);
    };

} // end CField

export default CField
