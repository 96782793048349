<template>
  <div class="gip-background">
  <div class="gip-page">

<hbox class="gip-container">
  <vbox class="gip-overlay fill">
    <LogoValide id="logoValide"/>
    <h1>MERCI</h1>
    <p class="text">
      Nous avons bien reçu votre demande. <br/>
      Notre équipe va étudier votre dossier.<br/>
      Vous recevrez prochainement un e-mail de notre part.
    </p>
  <BtnAction type="action" to="DashboardEmployeur" class="btn-creer-mon-compte"  color="white" titre="RETOURNER SUR LE SITE"></BtnAction>

  </vbox>
  <Sphere top="50"   left="-90" taille="213"/>
</hbox>
  </div>
  </div>
</template>

<script>
import BtnAction from "../ui/BtnAction";
import hbox from "../ui/HBox";
import vbox from "../ui/VBox";
import Sphere from "../ui/Sphere";
import LogoValide from  "../../assets/svg/valid_green.svg"

export default {
name: "Confirmation",
  components: {BtnAction,hbox,vbox,Sphere, LogoValide}
}
</script>


<style lang="scss" scoped>

.btn-creer-mon-compte a{

  padding: 15px 129px !important;

}

.gip-container{
  margin: 0 auto;
  text-align: center;
  position: relative;
}



.gip-overlay{
  padding: 60px 87px ;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 23px #04437010;
}

.btn-creer-mon-compte{
  text-align: center;
}

label{
  font-size: 13px;
  font-weight: 600;
}

.margin-wrap{
  margin:0 50px;
}

.gip-container  {
  h1{
  color: var(--gip-accent-color);
  font-size: 1.5em;
  font-weight: 600;
  }
  p{
    font-weight: 500;
    font-size: 13px;
  }
}

.gip-background{
  padding-top: 76px;
  padding-bottom: 76px;
}

input[type=checkbox] {
  -webkit-appearance: none;
  background-color: #fff;
  vertical-align: top;
  border: 1px solid #EEEEEE;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
}



input[type=checkbox]:active, input[type=checkbox]:checked:active {
  box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

input[type=checkbox]:checked {
  background-color: var(--gip-action-color);
  color:  white;
}

input[type=checkbox]:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: white;
}


input{
  padding: 17px 24px 16px 24px;
  border: 1px solid #EEEEEE;

  border-radius: 5px;
  &::-webkit-input-placeholder{
  font-size: 13px;
    color: #B5B5B5;
    line-height: 22px;
    max-height: 15px;

  }
}

#logoValide {
  margin: auto;
  margin-bottom: 20px;
}

</style>