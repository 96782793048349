<template>
<hbox id="schema-fonctionnement" class="wrap-small">

  <vbox class="box green-box">
    <h1>Les membres financeurs</h1>
    <p>Collectivités territoriales,<br/>Ministère de la Culture</p>
    <router-link to="/membres">Les membres</router-link>
  </vbox>

  <vbox class="box green-box" id="gip-box">
    <h1>Le Gip Cafés Cultures</h1>
    <p>Vérification et validation<br/>des créations de comptes<br/>& versement des aides</p>
  </vbox>

  <vbox id="dashed-box"></vbox>
  <vbox id="right-boxes">
    <vbox class="box blue-box">
      <h1>Le Guso</h1>
      <p>Echange de fichiers avec le GIP&nbsp;Cafés&nbsp;Cultures</p>
    </vbox>
    <vbox class="box blue-box">
      <h1>Les employeurs</h1>
      <p>Création de compte, Paiement du<br/> salaire net aux artistes, Paiement<br/> des cotisations au GUSO</p>
      <router-link to="/employeur">Créer mon compte</router-link>

    </vbox>

  </vbox>
</hbox>
</template>

<script>
import GipMixin from '../ui/GipMixin.js'

export default {
  name: "SchemaFonctionnement",
  mixins: [GipMixin],

}
</script>

<style scoped lang="scss">
@import "../../assets/kit.scss";

#schema-fonctionnement {
  margin-top: 1em;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 5em;
}
.box {
  padding: 1em 2em;
  margin: 2em;
  color: var(--gip-white-color);
  z-index: 1;
}


.green-box {
  background: var(--gip-accent-color);
}
.blue-box {
  background: var(--gip-tint-color);
}

.box h1 {
  font-size: 1.3em;
}
.box p {
  font-size: 1em;
}
#gip-box {
  margin-right: 5em;
}

a {
  color: var(--gip-white-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7em;
}

#dashed-box {
  //background : red;
  border: dotted .4em var(--gip-accent-color);
  height: 14px;
  height: 250px;
  width: 250px;
  margin-left: -10em;
  margin-right: -10em;
  z-index: 0;
}

// fait le nécessaire pour gérer le schéma en vertical sur tel
@media (max-width: $size-small) {

  #right-boxes {
    flex-direction: row;
  }
  #dashed-box {
    margin-top: -7em;
    margin-bottom: -5em;
    margin-left: 0;
    margin-right: 0;
    width: 450px;
    max-width: 100%;
    height: 170px;
  }
  #gip-box {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>