<template>
  <div id="plus-sur-nous">
    <Diviseur titre="En savoir plus sur nous ?"></Diviseur>
    <div class="arrow-container">
      <div v-on:click="prev" class="arrow">
        <FlecheLogo color="#C22292" rotate="180"/>
      </div>
      <div v-on:click="next" class="arrow">

        <FlecheLogo v-on:click="next" rotate="0" color="#C22292"/>

      </div>
    </div>

    <carousel-3d ref="carousel" :count="actualites.length"  :disable3d="true" :space="this.space" :height="this.height" :width="this.width"
                 :loop="true" :clickable="false" :controls-visible="false">
      <slide v-for="(actu,i) in actualites" :key="i" :index="i">
        <CardActualite :data="actu"/>
      </slide>
    </carousel-3d>


    <div class="btn-container">
      <BtnAction class="button-center" to="Actualites" titre="TOUTES LES ACTUALITÉS"></BtnAction>
    </div>
  </div>
</template>

<script>
import Diviseur from "./Diviseur";
import CardActualite from "./CardActualite";
import BtnAction from "./BtnAction";
import FlecheLogo from "../Icons/FlecheLogo";
import { Carousel3d, Slide } from 'vue-carousel-3d';


import {BCarousel} from "bootstrap-vue";
import {BCarouselSlide} from "bootstrap-vue";
import kit from "../../kit/kit";
export default {
  name: "PlusSurNous",
  components: {Diviseur,BtnAction,CardActualite,Carousel3d,Slide,FlecheLogo},
  data(){
    return{
      actualites:[""],
      offset:0,
      limit:15,
      nbSlide:5,
      height:500,
      width:300,
      space:320,
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.goPrev()
    },
    next() {
      this.$refs.carousel.goNext()

    },
    resize(){
      this.height = 530;
          this.width = 330;
      this.space = 340;

    },
    loadActualites(){
      kit.call(() => {
        var query = {cclass: 'Actualite', json: true,limit: this.limit, limitOffset: this.offset};
        kit.services.query(query,
            (set, req) => {
              this.actualites =  set
              this.$refs.carousel.getSlideCount()

            },
            () => {
              kit.log.error("erreur plusSurNous")
            }
        )

      });
      return this.membres
    },

  },
  mounted(){
    this.loadActualites()
    this.resize()
    window.onresize = this.resize
  }
}
</script>

<style lang="scss" >
.actu-carousel .carousel-inner{
  overflow: visible !important;
}
</style>

<style lang="scss" scoped>

.carousel-3d-container {
  .carousel-3d-slide {
    background: none;
    border: none;

    .title { font-size: 22px; }
  }
}

svg{
  vertical-align: sub;

}

.arrow-container{
  text-align: right;
  padding-bottom: 2em;

  div{
    text-align: center;
  }
}

.gip-slide{
  text-align: center;
}


.btn-container{
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.carousel{ // on force la class boostrap
  text-shadow: none !important;
  .gip-card{
    margin-right: 20px;
  }
  :last-child.gip-card{
    margin-right:0px ;
  }
}
.arrow{
  background: white;
  width: 33px;
  margin-right: 20px ;
  cursor: pointer;
  height: 33px;
  border-radius: 16px;
  box-shadow: 0px 3px 23px #0443700F;
  border: 1px solid #D5DCE1;
  display: inline-block;

}
.button-center{
  margin: 0 auto;
}

.actu-carousel{
  position: relative;
  display: block;
  width: 100%;
  height: 500px;


}




#plus-sur-nous {
  background: white;
  margin-top: var(--gip-padding-header);
}

</style>