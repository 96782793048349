/**
 * vérifie qu'un numéro de guso satistait à l'algo de vérification, ie 2 derniers
 * chiffres = modulo 97 des 8 premiers
 * @param guso le numero a check
 * @return boolean
 */
export const isGusoValid = function(guso) {
    //    kit.log.trace('check guso ' + guso);
    if ((guso === null) || (guso.length != 10)) {
	return false;
    }
    var num = parseInt(guso.substring(0, 8));
    var key = parseInt(guso.substring(8, 10));
    return (num % 97) === key;
}