<template>
  <div>
    <canvas id="myChart"
            style="max-height: 177px; display: block; box-sizing: border-box; height: 177px; width: 200px;"
            width="200" height="177"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
export default {
  name: "DonutsChart",

  data: function () {
    return {

      chart: null,
      chartData: {
        labels: this.labels,
        datasets: [{
          label: 'My First Dataset',
          data: this.values,
          backgroundColor: this.colors,
          hoverOffset: 0
        }]
      }
    }
  },
  /**
   * Les props :
   * les labels, les couleurs, l'unité et les valeurs
   */
  props: {
    labels: {type: Array, default: () => []},
    colors: {type: Array, default: () => [
        'rgb(199, 211, 3)',
        'rgb(67, 173, 158)',
        'rgb(213, 220, 225)']},
    unit: {type: String, default: ""},
    values: {type: Array, default: () => [0,0,0,-1]}

  },

  methods: {
    updateValues: function(vals) {
      // alert("update")
      this.chartData.datasets[0].data = vals
      // this.chartData.datasets[0].backgroundColor = [
      //   'rgb(199, 211, 100)',
      //   'rgb(67, 173, 100)',
      //   'rgb(213, 220, 100)'
      // ]
      this.chart.update()
      // this.chart.data = this.chartData
      // this.chart.data = this.chartData
    }
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.chart = new Chart(
        document.getElementById('myChart'),
        {
          type: 'doughnut',
          data: this.chartData,
          options: {

            plugins: {
              legend: {
                display: false
              },
              subtitle: {
                display: false,
                text: 'Custom Chart Subtitle'
              },
              tooltip: {
                afterLabel: "€",
                callbacks: {
                  label: (data) => {
                    console.log(data)
                    let value = data.dataset.data[data.dataIndex]
                    if(value < 1) return '0'  + ' ' + this.unit;
                    return data.label + " : " + data.formattedValue + ' ' + this.unit;
                  }
                }
              }
            },
            elements: {
              arc: {
                borderWidth: 0
              }
            }
          }
        })
  }
}
</script>

<style scoped>

</style>