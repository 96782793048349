<template>
  <div class="gip-bg-default">
    <div class="gip-max-width">
      <div class="head gip-mobile-padding">
      <h1 class="alternance"><span>Les territoires</span> au cœur<br/> du Gip Cafés Cultures</h1>
    <Sphere  couleur="grey" top="20" left="450" taille="213"/>

    <p class="preambule">L’adhésion se traduit par la signature de la convention constitutive et le <br/>
      versement de la contribution annuelle financière. Grâce à ce dispositif,<br/>
      les membres disposent d’un nouvel outil pour soutenir les pratiques <br/>
      culturelles et développer l’attractivité des territoires.</p>

      </div>

      <div class="filtre"><input v-model="filtreMembre" @change="onFilterMembre()" type="text" placeholder="Rechercher un membre par mots clés">
        <LoupeIcon/>
      </div>

    <div>

      <div>
        <div class="membres-container">
        <CardMembre v-for="(membre,index) in membres" :data="membre" :key="index"/>
          <Sphere class="hidden-small" top="-50"  left="-50" taille="213"/>
      </div>


      </div>
   <!-- <router-link :to="{ path: '/membres/user'}" >user</router-link> -->
    <div @click="addMembres" class="charger-container">
      <ChargeIcon/>
      <p class="charger">CHARGER LA SUITE</p></div>
  </div>
  </div>
  </div>
</template>

<script>
import Sphere from "../ui/Sphere";
import CardMembre from "../ui/CardMembre";
import LoupeIcon from "../../assets/svg/eye_icon.svg"
import ChargeIcon from "../../assets/svg/plus_action_logo.svg"
import kit from "../../kit/kit";
import CFilter from "../../kit/data/CFilter"
import CField from "../../kit/data/CField";
export default {
name: "Membres",
  components: {ChargeIcon,Sphere,CardMembre,LoupeIcon},
  data(){
  return{
    membres:[],
    filtreMembre:"",
    offset:0,
    limit:10
  }
  },
  methods:{
    addMembres(){
      kit.call(() => {
        var query = {
          cclass: 'Membre',
          json: true,
          filter: new CFilter("Membre.pageVisible", "O"),
          limit: 10,
          orderField: "enAvant",
          limitOffset: this.offset
        }
        kit.services.query(query,
            (set, req) => {
              this.membres =  this.membres.concat(set)
                this.offset += 10;
            },
            () => {
              kit.log.error("erreur chiffres clés")
            }
        )
      });

    },
    onFilterMembre() {
      kit.call(() => {
        var query = {
          cclass: 'Membre',
          json: true,
          filter: new CFilter("Membre.pageVisible", "O"),
          match: this.filtreMembre
        }
        kit.services.query(query,
            (set, req) => {
              this.membres =  set

            },
            () => {
              kit.log.error("erreur onFilterMembre")
            }
        )
      });
    }
  },
  mounted() {
    this.addMembres();
  }
}
</script>

<style  lang="scss" scoped>
@import "../../assets/kit.scss";

.alternance {
  padding-top: 100px;

}

.preambule{
  padding-top: 34px;
  padding-bottom: 100px;
}

.charger-container {
  text-align: center;
  *{
    cursor: pointer;

  }
  .charger {
    font-size: 12px;
    color: var(--gip-disabled-color);
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

.filtre{
  padding: 13px 35px;
  background: white;
  max-width: 533px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 20px;
  :focus{
    outline: none;
  }
  input {
    border:none;
    flex-grow: 1;
    color: var(--gip-tint-color);
    font-size: 16px;
    font-weight: 600;

    &::placeholder{
      color: var(--gip-tint-color);
    }
  }
}

.head{
  text-align: center;
  overflow-x: hidden;
  position: relative;
  h1{
    position: relative;
    z-index: 5;
  }
}


.gip-card {
  position: relative;
  z-index: 2;
}
.membres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  text-align: center;
  width: 100%;

  :nth-child(2n+2){
    margin-right:0px;
  }
  &> *{
    margin-right:14px;
    margin-bottom: 14px;
  }
}

@media (max-width: $size-medium) {
  .gip-card {
    margin-right: 0 !important;
  }
}
</style>