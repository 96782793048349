<script>
import KField from "@/components/ui/KField.vue";
import CEnumFieldView from "@/kit/vue/CEnumFieldView.vue";

export default {
  components: {CEnumFieldView, KField},
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    type: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<template>
  <div>
    <br>
    <div id="vousetes">Vous êtes :</div>
    <div>
      <k-field nolabel>
        <CEnumFieldView 
          id="type1" 
          v-model="type" 
          field="Employeur.type" 
          name="typeEmployeur" 
          radio value="CHR"
          :excludes="['EXCEP']"
          :display-names="{
            CHR: 'Un café, un hôtel ou un restaurant',
            COLLECTIVITE: 'Une collectivité territoriale',
            AUTRE: 'Un autre employeur occasionnel'
            }"
          />
      </k-field>
    </div>

  </div>
</template>

<style scoped>
.main {
  display: flex;
}

label {
  margin-left: 1em;
}

#vousetes {
  font-weight: bold;
  margin-bottom: 1.5em;
}
</style>