<template>
<div>
  <p class="diviseur" :class="couleur">{{ titre }}</p>
  <DiviseurLogo class="logo"/>
</div>

</template>

<script>
import DiviseurLogo  from "@/components/Icons/DiviseurLogo";

export default {
  name: "Diviseur",
  props: {
    titre:{
      type: String,
    },
    couleur:{
      type:String,
      default: "default",
    }
  },
  components: {DiviseurLogo}

}
</script>

<style lang="scss" scoped>

.diviseur {
  text-transform: uppercase;
  font-size: 1.1em;
}

div {
  text-align: center;
  display: block;
  padding: 20px;
}
p {
  color: var(--gip-tint-color) ;
  font-size: 15px;
  margin: 0 auto;
  font-weight: 700;
}
.logo {
  position: relative;

  top: -10px;
}
.default {
  color: var(--gip-tint-color);
}

.white{
  color: white;
}


</style>