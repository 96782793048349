
/**
Header prend le div en argument et le colle dans le slot
*/
<template>
      <div>
      <div id="header" class="header " :class="{'display-menu-fixed' : isMenuOpen}" style="">


      <div class="gip-max-width ">

      <div  class="navigation gip-page hidden-small">
        <router-link to="/">
        <GipCafesCulturesLogo id="gip-logo" class="logo"/>
        </router-link>

        <div class="hidden-small menu ">
          <slot :clickHandler="slotItemClickHandler" />
        </div>
      </div>

        <div    class="navigation gip-page hidden-big">
          <vbox class="nav-control " :class="{'nav-control-open' : isMenuOpen}">
          <hbox class="nav-header">
            <router-link v-if="!isMenuOpen" to="/">
            <GipCafesCulturesLogo id="gip-logo" class="logo"/>
          </router-link>
            <div class="fill"></div>

            <div class="static-burger" :class="{'burger':!isMenuOpen,'close-mobile' : isMenuOpen}" @click="isMenuOpen = !isMenuOpen">
            <div></div>
            <div></div>
            <div></div>
          </div>
          </hbox>

          <div v-if="isMenuOpen" class="mobile-nav hidden-big">

            <GipCafesCulturesLogo id="gip-logo" class="fix-logo"/>
            <slot class="mobile-nav" :clickHandler="slotItemClickHandler"/>
            <vbox class="espace-container justify-content-end align-items-normal">

              <router-link id="espace-financeur"  @click.native="slotItemClickHandler" to="/membre" v-if="!isConnected() || (isFinanceur() && !isInFinanceur())">Espace financeur</router-link>
              <div class="fill"> </div>
              <router-link to="/employeur" @click.native="slotItemClickHandler" v-if="!isConnected() || (isEmployeur() && !isInEmployeur())"
                           id="espace-employeur">Espace employeur</router-link>

              <div class="seDeconnecter" v-if="isFinanceur() || isEmployeur()" @click="disco">
                <LockIcon/>
                <a id="disconnect">Se déconnecter</a>
              </div>


            </vbox>
          </div>
          </vbox>
          <div   class="hidden-small menu ">
            <slot />
          </div>
        </div>
    </div>
  </div>
        <div class="spacer"></div>
      </div>
</template>

<script>
import GipCafesCulturesLogo from "@/components/Icons/GipCafesCulturesLogo";
import GipMixin from '../ui/GipMixin.js'

// import {disconnect, state} from "@/components/ui/GipMixin";

export default {
  name: "Header",
  mixins: [GipMixin],
  components: {GipCafesCulturesLogo},
  data() {
    return {
      // isLoged: this.loged
      isMenuOpen: false,
    }
  },
  computed : {
  // isMembre(){
  //   return this.state.isMembre
  // }
},
  props:{
    // loged:{
    // type:Boolean,
    // default:false
    // }
  },

  mounted () {
    const stickyElm = document.querySelector('#header')
    const logo = document.querySelector("#gip-logo")

    // resize le header avec le logo quand on descend dans la page
    window.onscroll = () => {

      let sticky = stickyElm.offsetTop;
      let defaultHeight = 80

      if (window.pageYOffset > sticky) {
        stickyElm.classList.add("sticky");
        // let ps = 100 - (window.pageYOffset * 100) / document.body.clientHeight
        let ps = 100 - (window.pageYOffset * 100) / 300
        ps = window.pageYOffset < 100 ? 100 : ps // on ne commence à resize qu'à 100px du haut
        let size = defaultHeight * ps / 100
        logo.style.height = size >= 50 ? `${defaultHeight * ps / 100}px` : `50px`

      } else {
        stickyElm.classList.remove("sticky");
        logo.style.height = `${defaultHeight}px`
      }
    }
  },

  methods: {
    handleScroll (event) {
      // var header = document.getElementById("header")
      // var content = document.getElementById("home")//document.documentElement//.nextElementSibling
      // var gipLogo = document.getElementById("gip-logo")
      // console.log("offsetTop=" + content.offsetTop)
      // if (content.offsetTop > 102) {
      //   gipLogo.style.height = "30px"
      // } else {
      //   gipLogo.style.height = "100px"
      // }
    },
    slotItemClickHandler(){
      // fonction appeller par les  items dans le slot pour que les click soit géré ici
      this.isMenuOpen = false
    },
    disco() {
      this.disconnect()
      this.$router.push({name:"Home"})
    },
    isInFinanceur: function() {
      return this.$route.name.includes('Financeur')
    },
    isInEmployeur: function() {
      console.log(this.$route.name)
      return this.$route.name.includes('Employeur')
    }

  },

}
</script>

<style lang="scss" scoped>

.nav-control{
  flex-grow: 1;
}

.nav-header{
  height: 100px ;
}

.spacer{
 // height: 1px;
 //  margin-bottom: 90px;
}




.nav-control-open{
  background: var(--gip-tint-color);
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;

  width: 100%;
}
.mobile-nav{
  background: var(--gip-tint-color);
  align-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  left:0;
  bottom: 0;
  z-index: 10;

  .seDeconnecter{
    margin: 0 auto;
  }

  #espace-financeur {
    background: var(--gip-action-color);
    font-size: 1.1em;
    line-height: 1.1em;
    padding: 8px 20px;
    text-align: center;
    margin-bottom: 2em;
    margin-top: 2em;

    font-family: "Open Sans" ;
  }

  #espace-employeur {
    text-align: center;
    font-size: 1.1em;
    line-height: 1.1em;
    padding: 8px 20px;
    font-family: 'Open Sans';
  }


  .btn-nav {
    background: var(--gip-action-color);
    padding: 8px 20px ;
    text-align: center;
    width: 200px;
  }
}

.espace {
  background: var(--gip-tint-color);
  height: 55px;
  text-align: right;

  .espace-container {
    //display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }

}



.close-mobile{
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  align-self: center;
  color: white;
  div{
    position: relative;
    top: 22px;
    border: 2.5px solid white;
    width: 50px;
    border-radius: 16px;
    background: white;

    transition: transform 0.5s,margin 0.5s;


  }
  div:nth-child(1){
    margin:0;
    transform: rotate(45deg);

  }

  div:nth-child(2){
    top:17px;
    transform: rotate(-45deg);

  }
  div:nth-child(3){
    margin:0;
    top:13px;
    transform: rotate(-45deg);

  }


}

.static-burger{
  margin-right: 20px;
}

.burger{
  display: block;
  cursor: pointer;
  align-self: center;
  div{
    display: block;
    border: 2.5px solid var(--gip-tint-color);
    width: 50px;
    margin-bottom: 8px;
    border-radius: 16px;
    background: var(--gip-tint-color);
    transition: transform 0.5s,margin 0.5s;

  }
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  //padding: 1em;
  //padding-top: calc(1em );
  .logo{
    transition:  0.3s;
    height: 40px;
  }

  .nav-header{
  height: 50px;
  }

}
a {
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: white;
  }
}
#espace-employeur {
  background: var(--gip-accent-color);
  //margin-right: 1em;
}

.header {

  box-shadow: 0px 3px 23px #0443700F;
  background: white;
  width: 100%;
  z-index: 500;
}



.navigation {
  //margin-top:  11px;
  text-align: left;
  background: white;

  display: flex;
  justify-content: space-between;
}

.logo{
  display: block;
  align-self: center;
  transition:  0.3s;
  height: 80px;

}
.fix-logo{
  height: 80px;
}
.menu {
  align-self: center;

  display: block;
}


</style>

// non scoped pour modifier la bar de navigation
<style lang="scss" >

.display-menu-fixed{
  position: fixed;
}

.mobile-nav #nav {
  display: flex;
  flex-direction: column;
  align-items: center;


  a{
    color: white;
    font-size: 1.2em;
    margin:0px;
    margin-top: 17px;
  }

}

#nav {
  a:last-of-type{
    margin-right:0px;

  }
  a {
    font-weight: 600;
    color: var(--gip-tint-color);
    text-decoration: none;
    display: inline-block;
    padding: 8px 0px;
    font-size: 15px;
    margin-right:36px;

    &:hover {
      color: var(--gip-accent-color);

    }
    &.router-link-exact-active {
      color: var(--gip-accent-color);
      position: relative;
      &:after{
        content:"";
        display: block;
        position:absolute;
        margin: 8px auto;
        left: 0;
        right: 0;
        width: 100%;
        border: 1.5px solid;
        background: var(--gip-accent-color);
      }
    }
  }
  .btn {
    background: var(--gip-accent-color);
    padding: 8px;
    color: white;
    font-size: 12px;
    border-radius: 5px;

    a {
      color: white;
    }

    &.router-link-exact-active {
      color: white;

      &:after {
        background: white;

      }
    }
  }
}

//.header {
//  position: sticky;
//  top: 0;
//  z-index: 86753090; // super elevé pour pinterest !
//}

#disconnect {
  color: var(--gip-white-color);
  margin-left: 0;
  padding-left: 1em;
  padding-right: 0;
  //margin-left:1em;
}

</style>