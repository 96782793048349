<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 152.575 81.81">
  <g id="Groupe_214" data-name="Groupe 214" transform="translate(-610.004 -260.873)">
    <path id="Tracé_5452" data-name="Tracé 5452" d="M610,291.854l19.458,4.4-.847-6.8Z" transform="translate(0 -1.768)" fill="#8f1a6c"/>
    <path id="Tracé_5453" data-name="Tracé 5453" d="M738.814,306.389l4.556-39.395-66.516,2.647h0l-26.921,1.075h0l-25.1.941,6.424,37.924,7.812-.235,10.973-.33,26.9-.811h0l56.21-1.694.032-.121" transform="translate(-0.917 -0.379)" fill="#43ad9e"/>
    <path id="Tracé_5454" data-name="Tracé 5454" d="M644.1,260.873,610,264.625v25.488l34.1-3.753Z" transform="translate(0)" fill="#c22292"/>
    <path id="Tracé_5455" data-name="Tracé 5455" d="M610,345.353,762.579,342.2v-34.03l-42.141-1.193h0L658.6,305.221h0l-29.584-.838L610,304.042Zm110.4-38.26h0" transform="translate(0 -2.67)" fill="#044370"/>
    <g id="Groupe_211" data-name="Groupe 211" transform="translate(641.766 274.352)">
      <path id="Tracé_5456" data-name="Tracé 5456" d="M659.1,293.484a5.727,5.727,0,0,1-1.335,1.077,5.79,5.79,0,0,1-1.5.612,6.222,6.222,0,0,1-1.511.192,5.385,5.385,0,0,1-2.605-.643,6.63,6.63,0,0,1-2.042-1.7,7.842,7.842,0,0,1-1.319-2.46,9.156,9.156,0,0,1-.466-2.911,9.585,9.585,0,0,1,.4-2.718,7.843,7.843,0,0,1,1.191-2.445,6.215,6.215,0,0,1,1.978-1.752,5.5,5.5,0,0,1,2.766-.675,6.613,6.613,0,0,1,1.479.176,6.087,6.087,0,0,1,1.5.562,5.106,5.106,0,0,1,1.335,1.046,5.245,5.245,0,0,1,1,1.624l3.408-2.347a8.505,8.505,0,0,0-3.3-3.6,9.865,9.865,0,0,0-5.291-1.383,10.379,10.379,0,0,0-4.632,1,10.822,10.822,0,0,0-3.425,2.606,11.461,11.461,0,0,0-2.139,3.649,12.008,12.008,0,0,0,.049,8.426,12.247,12.247,0,0,0,2.22,3.763,11.407,11.407,0,0,0,3.408,2.686,9.292,9.292,0,0,0,4.358,1.029,10.936,10.936,0,0,0,2.735-.355,11.634,11.634,0,0,0,2.637-1.028,9.535,9.535,0,0,0,2.235-1.657,6.679,6.679,0,0,0,1.5-2.267l-3.634-2.091A5.279,5.279,0,0,1,659.1,293.484Z" transform="translate(-643.86 -275.296)" fill="#fff"/>
      <path id="Tracé_5457" data-name="Tracé 5457" d="M682.188,294.527v-5.242a6.541,6.541,0,0,0-2.01-5.163,8.219,8.219,0,0,0-5.644-1.784,12.447,12.447,0,0,0-7.011,2.154l1.318,2.735a12.255,12.255,0,0,1,2.637-1.416,7.472,7.472,0,0,1,2.637-.482,3.944,3.944,0,0,1,2.814.9,3.38,3.38,0,0,1,.948,2.573v.965a8.877,8.877,0,0,0-1.913-.548,12.493,12.493,0,0,0-2.2-.192,9.909,9.909,0,0,0-2.894.4,7.359,7.359,0,0,0-2.268,1.11,5.045,5.045,0,0,0-1.479,1.721,4.677,4.677,0,0,0-.531,2.2,5.1,5.1,0,0,0,.434,2.122,5.245,5.245,0,0,0,1.206,1.689,5.476,5.476,0,0,0,1.833,1.125,6.385,6.385,0,0,0,2.284.4,7.859,7.859,0,0,0,3.441-.771,7.475,7.475,0,0,0,2.734-2.252l.1.932a2.307,2.307,0,0,0,.8,1.432,2.994,2.994,0,0,0,1.865.563c.258,0,.563-.015.917-.048a9.96,9.96,0,0,0,1.142-.177v-3.666a1.251,1.251,0,0,1-.9-.321A1.447,1.447,0,0,1,682.188,294.527Zm-4.31-.514a1.546,1.546,0,0,1-.707,1.221,3.756,3.756,0,0,1-1.527,1.029,5.608,5.608,0,0,1-2.075.387,3.038,3.038,0,0,1-2.058-.724,2.3,2.3,0,0,1-.836-1.816,2.267,2.267,0,0,1,1.061-1.914,4.608,4.608,0,0,1,2.735-.756,9.076,9.076,0,0,1,1.737.176,10.76,10.76,0,0,1,1.672.466Z" transform="translate(-645.266 -275.68)" fill="#fff"/>
      <path id="Tracé_5458" data-name="Tracé 5458" d="M690.265,277.074a7.547,7.547,0,0,0-1.528,4.985v2.091H686.55v3.313h2.187v11.578h4.31V287.463h4.117v-3.313h-4.117V281.9q0-3.023,2.252-3.022a4.668,4.668,0,0,1,1.205.176,5.408,5.408,0,0,1,1.142.433l.869-3.247a9.573,9.573,0,0,0-4.149-1A5.063,5.063,0,0,0,690.265,277.074Z" transform="translate(-646.5 -275.241)" fill="#fff"/>
      <path id="Tracé_5459" data-name="Tracé 5459" d="M712.867,275.584h-4.02l-1.736,3.828,2.766.837Z" transform="translate(-647.772 -275.262)" fill="#fff"/>
      <path id="Tracé_5460" data-name="Tracé 5460" d="M714.823,284.943a8.478,8.478,0,0,0-2.767-1.9,9.148,9.148,0,0,0-3.682-.707,9.373,9.373,0,0,0-3.714.707,8.434,8.434,0,0,0-2.814,1.914,8.254,8.254,0,0,0-1.768,2.8,9.366,9.366,0,0,0-.612,3.393,8.9,8.9,0,0,0,.627,3.313,8.268,8.268,0,0,0,4.584,4.647,9.359,9.359,0,0,0,3.666.691,9.223,9.223,0,0,0,4.873-1.238,7.527,7.527,0,0,0,2.975-3.167l-3.7-1.029a3.327,3.327,0,0,1-1.56,1.656,4.97,4.97,0,0,1-2.429.628,4.556,4.556,0,0,1-1.608-.29,4.312,4.312,0,0,1-1.383-.837,4.37,4.37,0,0,1-1-1.318,5.017,5.017,0,0,1-.482-1.769h13.025a3.26,3.26,0,0,0,.081-.643q.016-.386.016-.8a9.21,9.21,0,0,0-.595-3.281A8.063,8.063,0,0,0,714.823,284.943Zm-10.854,4.7a4.626,4.626,0,0,1,.433-1.7,4.42,4.42,0,0,1,.95-1.319,4.232,4.232,0,0,1,1.335-.853,4.506,4.506,0,0,1,4.631.837,4.481,4.481,0,0,1,1.4,3.039Z" transform="translate(-647.299 -275.68)" fill="#fff"/>
      <path id="Tracé_5461" data-name="Tracé 5461" d="M734.306,292.643a3.574,3.574,0,0,0-1.189-1.334,8.625,8.625,0,0,0-2.09-1.029q-1.272-.45-3.071-.9a14.221,14.221,0,0,1-2.686-.869,1.308,1.308,0,0,1-.756-1.254,1.544,1.544,0,0,1,.788-1.448,3.871,3.871,0,0,1,1.946-.449,8.174,8.174,0,0,1,2.509.4,11.748,11.748,0,0,1,2.669,1.3l1.768-2.669a14.428,14.428,0,0,0-3.328-1.527,11.864,11.864,0,0,0-3.586-.563,8.961,8.961,0,0,0-2.686.386,6.594,6.594,0,0,0-2.155,1.11,5.02,5.02,0,0,0-1.946,4.068,4.014,4.014,0,0,0,.322,1.674,3.336,3.336,0,0,0,1.029,1.254,6.857,6.857,0,0,0,1.865.964,25.871,25.871,0,0,0,2.8.8,17.405,17.405,0,0,1,3.072.948,1.412,1.412,0,0,1,.917,1.334,1.54,1.54,0,0,1-.772,1.384,4,4,0,0,1-2.123.482,11.413,11.413,0,0,1-6.207-2.091l-1.737,2.928a9.782,9.782,0,0,0,1.706.933,15.078,15.078,0,0,0,2.026.707,18.165,18.165,0,0,0,2.171.45,14.942,14.942,0,0,0,2.138.16,10.543,10.543,0,0,0,2.878-.37,6.46,6.46,0,0,0,2.2-1.061,4.79,4.79,0,0,0,1.9-3.907A4.1,4.1,0,0,0,734.306,292.643Z" transform="translate(-648.548 -275.678)" fill="#fff"/>
    </g>
    <g id="Groupe_212" data-name="Groupe 212" transform="translate(622.039 309.762)">
      <path id="Tracé_5462" data-name="Tracé 5462" d="M638.077,330.885a5.706,5.706,0,0,1-1.335,1.077,5.791,5.791,0,0,1-1.5.612,6.223,6.223,0,0,1-1.511.192,5.386,5.386,0,0,1-2.605-.643,6.63,6.63,0,0,1-2.042-1.7,7.841,7.841,0,0,1-1.319-2.46,9.159,9.159,0,0,1-.466-2.911,9.587,9.587,0,0,1,.4-2.718,7.826,7.826,0,0,1,1.19-2.444,6.209,6.209,0,0,1,1.978-1.753,5.5,5.5,0,0,1,2.766-.675,6.613,6.613,0,0,1,1.479.176,6.086,6.086,0,0,1,1.5.562,5.127,5.127,0,0,1,1.335,1.046,5.247,5.247,0,0,1,1,1.624l3.408-2.347a8.506,8.506,0,0,0-3.3-3.6,9.865,9.865,0,0,0-5.291-1.383,10.378,10.378,0,0,0-4.632,1,10.821,10.821,0,0,0-3.425,2.606,11.462,11.462,0,0,0-2.139,3.649,12.011,12.011,0,0,0,.049,8.427,12.258,12.258,0,0,0,2.22,3.762,11.409,11.409,0,0,0,3.408,2.686,9.292,9.292,0,0,0,4.358,1.029,10.939,10.939,0,0,0,2.735-.355,11.636,11.636,0,0,0,2.637-1.028,9.514,9.514,0,0,0,2.235-1.657,6.679,6.679,0,0,0,1.5-2.267l-3.634-2.09A5.284,5.284,0,0,1,638.077,330.885Z" transform="translate(-622.832 -313.019)" fill="#fff"/>
      <path id="Tracé_5463" data-name="Tracé 5463" d="M662.279,331.947v-11.9h-4.31V330.5a5.436,5.436,0,0,1-1.914,2.3,4.47,4.47,0,0,1-2.461.756q-2.8,0-2.8-3.891v-9.616h-4.31V330.6a7.754,7.754,0,0,0,1.351,4.953,4.787,4.787,0,0,0,3.956,1.672,8.683,8.683,0,0,0,3.907-.868,7.44,7.44,0,0,0,2.911-2.606l.1,1.383a2.3,2.3,0,0,0,.821,1.463,2.815,2.815,0,0,0,1.816.531c.258,0,.558-.015.9-.048s.739-.091,1.19-.177v-3.666a1.249,1.249,0,0,1-.9-.321A1.447,1.447,0,0,1,662.279,331.947Z" transform="translate(-624.295 -313.422)" fill="#fff"/>
      <path id="Tracé_5464" data-name="Tracé 5464" d="M674.846,332.7a3.165,3.165,0,0,1-.821.129q-1.769,0-1.768-1.962V312.985h-4.31v19.587a4.085,4.085,0,0,0,1.11,3.072,4.317,4.317,0,0,0,3.135,1.077,11.06,11.06,0,0,0,2.091-.225,8.3,8.3,0,0,0,2.025-.644l-.579-3.441C675.451,332.519,675.156,332.615,674.846,332.7Z" transform="translate(-625.622 -312.985)" fill="#fff"/>
      <path id="Tracé_5465" data-name="Tracé 5465" d="M686.556,332.649a3.961,3.961,0,0,1-1.318.225,1.851,1.851,0,0,1-1.158-.37,1.505,1.505,0,0,1-.482-1.271V323h3.569v-3.312H683.6V314.22h-4.31v5.468H677.1V323h2.187v9.682a4.657,4.657,0,0,0,.353,1.913,3.528,3.528,0,0,0,.949,1.286,3.682,3.682,0,0,0,1.383.723,6.123,6.123,0,0,0,1.689.225,9.058,9.058,0,0,0,2.653-.386,19.014,19.014,0,0,0,2.139-.771l-.869-3.41Q687.2,332.424,686.556,332.649Z" transform="translate(-626.189 -313.061)" fill="#fff"/>
      <path id="Tracé_5466" data-name="Tracé 5466" d="M707.119,331.947v-11.9h-4.31V330.5a5.437,5.437,0,0,1-1.914,2.3,4.471,4.471,0,0,1-2.461.756q-2.8,0-2.8-3.891v-9.616h-4.31V330.6a7.753,7.753,0,0,0,1.351,4.953,4.787,4.787,0,0,0,3.956,1.672,8.683,8.683,0,0,0,3.907-.868,7.441,7.441,0,0,0,2.911-2.606l.1,1.383a2.3,2.3,0,0,0,.821,1.463,2.815,2.815,0,0,0,1.816.531c.258,0,.558-.015.9-.048s.739-.091,1.19-.177v-3.666a1.249,1.249,0,0,1-.9-.321A1.447,1.447,0,0,1,707.119,331.947Z" transform="translate(-627.069 -313.422)" fill="#fff"/>
      <path id="Tracé_5467" data-name="Tracé 5467" d="M721.989,319.773a4.8,4.8,0,0,0-2.943,1.062,7.83,7.83,0,0,0-2.267,2.8v-3.6h-3.956v16.852h4.309V326.688a4.426,4.426,0,0,1,2.22-2.171,8.494,8.494,0,0,1,3.506-.756v-3.924q-.162-.031-.338-.048C722.4,319.779,722.224,319.773,721.989,319.773Z" transform="translate(-628.398 -313.405)" fill="#fff"/>
      <path id="Tracé_5468" data-name="Tracé 5468" d="M740.349,322.344a8.477,8.477,0,0,0-2.767-1.9,9.148,9.148,0,0,0-3.682-.707,9.373,9.373,0,0,0-3.714.707,8.434,8.434,0,0,0-2.814,1.914,8.255,8.255,0,0,0-1.768,2.8,9.372,9.372,0,0,0-.612,3.393,8.9,8.9,0,0,0,.627,3.313,8.268,8.268,0,0,0,4.584,4.647,9.358,9.358,0,0,0,3.666.691,9.223,9.223,0,0,0,4.873-1.238,7.528,7.528,0,0,0,2.975-3.167l-3.7-1.029a3.327,3.327,0,0,1-1.56,1.656,4.97,4.97,0,0,1-2.429.628,4.557,4.557,0,0,1-1.608-.29,4.341,4.341,0,0,1-2.38-2.155,5.016,5.016,0,0,1-.482-1.769h13.025a3.278,3.278,0,0,0,.081-.643q.015-.386.016-.8a9.233,9.233,0,0,0-.595-3.281A8.08,8.08,0,0,0,740.349,322.344Zm-10.854,4.7a4.619,4.619,0,0,1,.433-1.7,4.417,4.417,0,0,1,.949-1.319,4.232,4.232,0,0,1,1.335-.853,4.506,4.506,0,0,1,4.631.837,4.481,4.481,0,0,1,1.4,3.039Z" transform="translate(-629.151 -313.403)" fill="#fff"/>
      <path id="Tracé_5469" data-name="Tracé 5469" d="M759.833,330.044a3.574,3.574,0,0,0-1.19-1.334,8.623,8.623,0,0,0-2.09-1.029q-1.272-.45-3.072-.9a14.233,14.233,0,0,1-2.685-.869,1.309,1.309,0,0,1-.756-1.254,1.544,1.544,0,0,1,.788-1.448,3.871,3.871,0,0,1,1.946-.449,8.164,8.164,0,0,1,2.509.4,11.733,11.733,0,0,1,2.67,1.3l1.768-2.669a14.426,14.426,0,0,0-3.329-1.527,11.863,11.863,0,0,0-3.586-.563,8.962,8.962,0,0,0-2.686.386,6.593,6.593,0,0,0-2.155,1.11,5.02,5.02,0,0,0-1.946,4.068,4.013,4.013,0,0,0,.322,1.674,3.334,3.334,0,0,0,1.029,1.254,6.83,6.83,0,0,0,1.865.963,25.451,25.451,0,0,0,2.8.8,17.47,17.47,0,0,1,3.072.949,1.412,1.412,0,0,1,.917,1.334,1.54,1.54,0,0,1-.772,1.384,4,4,0,0,1-2.123.482,11.413,11.413,0,0,1-6.207-2.091l-1.737,2.928a9.784,9.784,0,0,0,1.706.933,15.033,15.033,0,0,0,2.026.707,18.163,18.163,0,0,0,2.171.45,14.933,14.933,0,0,0,2.138.16,10.541,10.541,0,0,0,2.878-.37,6.444,6.444,0,0,0,2.2-1.062,4.837,4.837,0,0,0,1.4-1.689,4.886,4.886,0,0,0,.5-2.218A4.1,4.1,0,0,0,759.833,330.044Z" transform="translate(-630.4 -313.401)" fill="#fff"/>
    </g>
    <g id="Groupe_213" data-name="Groupe 213" transform="translate(615.284 269.314)">
      <path id="Tracé_5470" data-name="Tracé 5470" d="M621.486,276.787l2.273.01,0,1.144a3.79,3.79,0,0,1-2.966,1.59,3,3,0,0,1-1.357-.318,3.529,3.529,0,0,1-1.087-.851,3.9,3.9,0,0,1-.72-1.253,4.49,4.49,0,0,1-.253-1.542,4.744,4.744,0,0,1,.242-1.5,4,4,0,0,1,.676-1.255,3.385,3.385,0,0,1,1.049-.866,2.836,2.836,0,0,1,1.359-.323,3.25,3.25,0,0,1,1.668.449,3.094,3.094,0,0,1,1.193,1.332l1.315-.941a4.207,4.207,0,0,0-1.565-1.722,5.185,5.185,0,0,0-4.591-.233,5.035,5.035,0,0,0-1.615,1.176,5.736,5.736,0,0,0-1.091,5.893,5.9,5.9,0,0,0,1.061,1.774,5.29,5.29,0,0,0,1.6,1.242,4.325,4.325,0,0,0,1.975.473,4.116,4.116,0,0,0,3.1-1.406l-.006,1.342,1.435.006.022-5.478-3.708-.014Z" transform="translate(-615.632 -269.884)" fill="#fff"/>
      <rect id="Rectangle_70" data-name="Rectangle 70" width="1.846" height="1.678" transform="translate(11.473 1.846) rotate(-89.775)" fill="#fff"/>
      <rect id="Rectangle_71" data-name="Rectangle 71" width="7.98" height="1.678" transform="translate(11.436 11.138) rotate(-89.775)" fill="#fff"/>
      <path id="Tracé_5471" data-name="Tracé 5471" d="M639.681,275.686a4.155,4.155,0,0,0-.781-1.315,3.852,3.852,0,0,0-1.187-.905,3.37,3.37,0,0,0-1.494-.342,3.212,3.212,0,0,0-1.649.421,3.454,3.454,0,0,0-1.194,1.14l.006-1.434-1.48-.006-.044,11.229,1.678.007.019-4.745a3.564,3.564,0,0,0,1.162,1.194,3.3,3.3,0,0,0,3.112.143,3.565,3.565,0,0,0,1.125-.881,4,4,0,0,0,.737-1.309,4.967,4.967,0,0,0,.266-1.624A4.408,4.408,0,0,0,639.681,275.686Zm-1.612,2.572a3.022,3.022,0,0,1-.484.868,2.294,2.294,0,0,1-.75.607,2.077,2.077,0,0,1-.962.225,1.946,1.946,0,0,1-.717-.141,2.769,2.769,0,0,1-1.2-.92,3.042,3.042,0,0,1-.394-.688l.008-2.09a1.678,1.678,0,0,1,.323-.616,2.5,2.5,0,0,1,.544-.494,2.811,2.811,0,0,1,.665-.333,2.052,2.052,0,0,1,.672-.119,2.294,2.294,0,0,1,.976.218,2.511,2.511,0,0,1,.791.583,2.721,2.721,0,0,1,.523.857,2.823,2.823,0,0,1,.187,1.038A2.973,2.973,0,0,1,638.069,278.258Z" transform="translate(-616.636 -270.072)" fill="#fff"/>
    </g>
  </g>
</svg>



</template>

<script>
export default {
  name: "GipCafesCulturesLogo",

}
</script>

