/**
 * a simple xml writer
 * @class com.omniapp.util.XmlWriter
 */
var XMLWriter = function () {

    var xml = '';
    var stack = [];
    var indent = 4;

    /**
     * open an element
     * @param elem element name
     * @param attributes array of attributes, json
     * @param withSpaces if true add indent properly
     * @param close if true close the element
     * @param cr if true ends with a cr. default is true.
     */
    this.open = function ( elem, attributes, withSpaces, close, cr ) {

        if (cr === undefined)
          cr = true;
        var spaces = ( withSpaces ? new Array(stack.length * indent + 1).join(' ') : '' );

        if ( !close )
            stack.push(elem);

        xml += spaces + '<' + elem + ' ';
        for ( var i in attributes )
            xml += i + '="' + attributes[i] + '" ';

        if (close)
            xml += '/>';
        else
           xml += '>';

        if (cr)
            xml += '\n';

        return this;

    };

    /**
     * Close current element Throw an exception if the current element does not match
     */
    this.close = function ( elem, withSpaces ) {

        var top = stack.pop();
        if ( elem !== top )
            throw "XmlWriter: close tag does not match (closing '" + elem + "', waiting for '" + top + "')";

        var spaces =  ( !withSpaces ? new Array(stack.length * indent + 1).join(' ') : '' );

        xml += spaces + '</' + elem + '>';

        return this;

    };

    /**
     * add a self closing element
     * @param elem element name
     * @param attributes array of attributes, json
     */
    this.add = function ( elem, attributes, value ) {

        var spaces = new Array(stack.length * indent + 1).join(' ');

        xml += spaces + '<' + elem + ' ';
        for ( var i in attributes )
            xml += i + '="' + attributes[i] + '" ';
        if (value)
            xml += '>' + value + '</' + elem + '>';
        else
          xml += '/>';
        xml += '\n';
        return this;

    };

    /**
     * write cdata
     * @param text data to write
     */
    this.write = function(text) {
        xml += text;
    }

    /**
     * Retrieve string of the formatted xml
     */
    this.toString = function () {

        return xml;

    };

};

export default XMLWriter
