<template>
  <div>
    <k-field ref="naf" class="margin-wrap gip-select" label="Votre code NAF*">
      <b-autocomplete ref="nafChooser"
                      :custom-formatter="formatNaf"
                      :data="nafs"
                      placeholder="code naf"
                      @select="selectNaf"
                      @typing="getNafs">
        <template slot-scope="props">
          {{ props.option.nafId }} ({{ props.option.nom }})
        </template>
        <template #empty>Aucun résultat</template>
      </b-autocomplete>
    </k-field>
  </div>
</template>

<script>

import KField from "@/components/ui/KField.vue";
import {default as CFilter, CFilterCondition, CFilterType} from '@/kit/data/CFilter.js'

import kit from "@/kit/kit";
import debounce from 'lodash/debounce'

export default {
  components: {KField},
  props: ['value'],
  data (){
    return {
      nafs : []
    }
  },
  methods: {
    selectNaf(option) {
      this.naf = option
    },
    formatNaf(d) {
      return d.nafId + " - " + d.nom
    },
    getNafs: debounce(function (name) {
      if (!name.length) {
        this.nafs = []
        return
      }
      let filter = new CFilter(CFilterType.OR)
      filter.add("NAF.nafId", CFilterCondition.CONTAINS, name)
      filter.add("NAF.nom", CFilterCondition.CONTAINS, name)
      kit.services.query({
            cclass: "NAF",
            filter: filter,
            limit: 100
          },
          (data) => {
            console.log(data)
            this.nafs = []
            data.forEach((item) => this.nafs.push(item))
            console.log("nafs size=" + this.nafs.length)
          },
          (error) => {
            this.nafs = []
            alert("error")
          })

    }, 500),
  },

  computed: {
    naf: {
      get() {
        return null;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style>


</style>