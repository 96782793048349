  <template>
    <div>
      <h5 class="photo-titre">Photos de l'événement : <span v-if="!anticipe" :class="{ 'error-photo-count': photoCount == 0 }">{{ photoCount }}/5</span></h5>
      <hr>
      <hbox class="flex-wrap space-between">
        <vbox class="photosInfos">
          <div class="phototext alertp" v-if="photoCount === 0 && !anticipe">
            <hbox>
               <b-icon icon="alert"
                       class="icons"
                       type="is-warning">
                </b-icon>   
                <span>Vous n'avez aucune photo, merci d'ajouter 1 à 5 photos</span>
            </hbox>
          </div>
          <div class="phototext info">
            <hbox>
              <InfoSmallIcon class="icons"/>
              <div>
                  <p v-if="!anticipe"> 
                    Merci d’ajouter des photographies du spectacle (au moins une photo d’ensemble et une photo de la représentation) aux fins de vérification de votre éligibilité au financement demandé et veiller à avoir toutes les autorisations nécessaires à cet égard.
                  </p>
                  <p v-else>
                    Des photos du spectacle (au moins une photo d'ensemble et une photo de la représentation) sont à ajouter après la date du spectacle.<br>Les demandes faites de manière anticipée seront préfinancées (sous réserve de la disponibilité des fonds). Elles seront ensuite financées après paiement des cotisations sociales et ajout des photos.
                  </p>
              </div>
            </hbox>
          </div>
        </vbox>
        <vbox v-if="!anticipe">
          <hbox class="flex-wrap demande-photos">
            <div v-for="index in 5" :key="index">
              <PhotoInput
                :doc="getImage(spectacleId, index)"
                @picAdd="(doc) => addPicToDoc(doc, spectacleId)"
                @picRm="(doc) => removePicFromDoc(doc, spectacleId)"
                :spectacleId="spectacleId"
                :autoSend="autoSend"
                :index="index">
              </PhotoInput>
            </div>
          </hbox>
      </vbox>
      </hbox>
    </div>
  </template>
  <script>
  import PhotoInput from "@/components/ui/PhotoInput.vue";

  import GipMixin from "@/components/ui/GipMixin";

export default {
  name: "PhotosSpectacles",
  components: { PhotoInput },
  mixins: [GipMixin],

  data() {
    return {
    photosIndex1: [1, 2, 3],
    photosIndex2: [4, 5]
    }
  },
  props:{
    spectacleId: null,
    getImage: Function,
    autoSend: Boolean,
    addPicToDoc: Function,
    removePicFromDoc: Function,
    photoCount: Number,
    anticipe: Boolean
  }
    }

  </script>

  <style scoped>
  .icons {
      margin-right: 15px;
      flex: none;
    }
  .photo-titre {
    font-size: 14px;
  }

  .photosInfos {
    max-width: 500px;
  }
  
  .phototext {
    padding: 16px 42px 16px 24px;
    margin-bottom: 15px;
    font-weight: 600;
    background: #f9fafb;
    width: 100%;

    &.info {
      padding: 16px;
    }

    &.alertp {
      padding-left: 16px;
    }
  }

  .demande-photos {
    max-width: 480px;
  }

  hr { 
    margin-top: 0;
  }
  .error-photo-count {
    color: var(--gip-error-color)
  }
  </style>