
import XMLWriter from '../misc/XMLWriter'
import kit from '../kit.js'

/**
 * a CFilter is a set of elements/sub filters.
 * For now only serialize to xml, but should implement match() for in memory matching
 * @class com.omniapp.kit.model.filter.CFilter
 * @param filterTypeOrField CFilterType.AND or CFilterType.OR if 1 arg, else a field
 * @param value optionnal, if set filter is init with an equal element using filterTypeOrField and value
 *
 * prog rep : 'nom=toto and miseEnVente < '2010-01-01' and (truc = 'x' or truc = 'y')
 * json rep :
 * filter
 * {
 *  joinField: 'ref',
 *  joinClass: 'anotherClass',
 *  type: 'AND'
 *     Bien.nom: 'toto'
 *     Bien.miseEnVente: {condition: '<', value:'2010-01-01'}
 * }
 *
 *                 // filter : on voudrait du json
 // filter = {
                //   type: 'AND',
                //   joinConditions: {
                //      ref: Procedure
                //   },
                //   elements: {
                //      Bien.dispoVente: 'O',
                //      Procedure.etat: {condition:'isNot', value: ' 999'}
                //   }
                // }
 //

 */
export default function CFilter(filterTypeOrField, value) {
    
    /** filter type 'and' or 'or' */
    this.type = CFilterType.AND;
    
    /** base CClass this filter is build for */
   this.cclass = null;

    /** is this filter inverted */
    this.inverted = false;

    /** list of elements. mix of CFilter/CFilterElement */
   this.elements = [];

    /** list of join conditions */
    this.joinConditions = null;

    /** retrieve number of elements in this filter */
    this.size = function() {
        return this.elements.length;
    };

   /**
     * Add a condition to this filter
     * called with 1 arg, expect a CFilterElement
     * called with 2 args, add a simple CFilterElement(field, EQUALS, value)
     * @param elementOrField element to add or field with an EQUALS value
     * @param fieldValue see above
     * @return this for fluent usage
     */
   this.add = function(elementOrField, conditionOrValue, fieldValue) {
       // 3 args : field, condition and value
       if (typeof fieldValue !== 'undefined')
           this.elements.push(new CFilterElement(elementOrField, conditionOrValue, fieldValue));
       // 2 args : field and value
       else if (typeof conditionOrValue !== 'undefined')
           this.elements.push(new CFilterElement(elementOrField, conditionOrValue));
       // 1 arg : a CFilterElement
       else
           this.elements.push(elementOrField);
       return this;
   };
   
   /** convert this filter to a xml representation */
  this.toXml = function() {

      if (this.elements.length === 0)
          return '';
      var xml = new XMLWriter();
      this.writeXml(xml);
      return xml.toString();
  };
  
   /**
    * writes this filter to a xml writer
    */
  this.writeXml = function(xml) {
      
      if (this.elements.length === 0)
        return;
      
      var atts = {};
      atts['type'] = this.type;
      if (this.cclass != null)
          atts['cclass'] = this.cclass.id();
      if (this.inverted)
          atts['inverted'] = "true";
          
      xml.open('filter', atts);

      if (this.joinConditions !== null) {
          for (var c = 0 ; c < this.joinConditions.length ; c++) {
              var jc = this.joinConditions[c];
              xml.open('join-condition', {field:jc.field, classes:jc.classes}, true, true, true);

          }
      }
      for (var i = 0 ; i < this.elements.length ; i++) {
          this.elements[i].writeXml(xml);
      }
      xml.close('filter');
  };

    /**
     * Add a join condition to this filter
     * @param field the field join is porting on
     * @param classes list of classes the join is on
     */
    this.addJoinCondition = function(field, classes) {
        if (this.joinConditions === null)
            this.joinConditions = [];
        this.joinConditions.push(new CJoinCondition(field, classes));
    };

    // constructor
    if ((filterTypeOrField !== undefined) && (value !== undefined))
        this.add(filterTypeOrField, value);
    else if (filterTypeOrField)
        this.type = filterTypeOrField;


} // end class CFilter


/**
 * CJoinCondition
 * @class com.omniapp.kit.model.filter.CJoinCondition
 */
export function CJoinCondition(field, classes) {
    this.field = field;
    this.classes = classes;
} // end class CJoinCondition


/**
 * CFilterType
 * @class com.omniapp.kit.model.filter.CFilterType
 */
export const CFilterType = {
    AND : 'AND',
    OR : 'OR'
};
/**
 * Defines different types of conditions usable into a CFilterElement
 * @class com.omniapp.kit.model.filter.CFilterCondition
 */
export const CFilterCondition = {
    EQUALS : 'equals',
    IS_NULL : "undefined",
    DIFFERENT : "isNot",
    COMPRISE_IN : "between",
    CONTAINS : "contains",
    NOT_CONTAINS : "notContains",
    STARTS_WITH : "startsWith",
    ENDS_WITH : "endsWith",
    GREATER : "greater",
    LESSER : "lesser",
    GREATER_OR_EQUAL : "greaterOrEquals",
    LESSER_OR_EQUAL : "lesserOrEquals",
    AFTER : "after",
    BEFORE : "before",
    AFTER_OR_EQUAL : "afterOrEquals",
    BEFORE_OR_EQUAL : "beforeOrEquals"
};

/**
 * A CFilterElement is a CField, a condition and a set of values
 * @class com.omniapp.kit.model.filter.CFilterElement
 * @param aField field that must match a condition
 * @param condOrValue if there are 3 args, take the 2nd arg as a condition, else
 *        use it as value and use EQUALS condition
 * @param theValues either a single value or an array of values
 */
export function CFilterElement(aField, condOrValue, theValues) {
    
    /** the field */
    this.field = null;
    
    /** the condition. One of */
    this.condition = null;
    this.values = [];
    this.inverted = false;
    
    if (aField) {
        if (typeof aField === 'string')
            this.field = kit.model.field(aField);
        else
            this.field = aField;
    }

    if (typeof theValues === 'undefined') {
        this.condition = CFilterCondition.EQUALS;
        this.values.push(condOrValue);
    }
    else {
        this.condition = condOrValue;
        if (theValues instanceof Array)
            this.values = theValues;
        else
            this.values.push(theValues); 
    }
    
    /** write this CFilterElement as xml. Called from CFilter */
    this.writeXml = function(xml) {
        
        var atts = {};
        atts['cclass'] = this.field.cclass.id();
        atts['cfield'] = this.field.id();
        atts['condition'] = this.condition;
        if (this.inverted)
            atts['inverted'] = 'true';
        for (var i = 0 ; i < this.values.length ; i++)
            atts['value' + (i+1)] = this.values[i];
        xml.open('filter-element', atts, true, true, true);
    };
    
} // end class CFilterElement

/**
 * a CQuery encapsulate a CFilter and add more informations to it. It can be used
 * instead of a CFilter when calling services.query().
 * params are :
 * - limit : max size of the request
 * - limitOffset : offset for the query
 * - sortField : sort the query
 * - reverse : order of the sort if orderField is set
 * - depth : depth for the query, -1 for no depth limit
 *
 * @class com.omniapp.kit.model.CQuery
 * @param cclass the CClass we are querying. either a String or a CClass
 * @param filter the filter this query is encapsulating
 * @constructor
 */
// CQuery = function(aCClass, aCFilter) {
//     this.cclass = aCClass;
//     this.filter = aCFilter;
//     this.limit = -1;
//     this.limitOffset = 0;
//     this.sortField = null;
//     this.reverse = false;
//     this.depth = -1;
//
//     if (!(this.cclass instanceof CClass))
//         this.cclass = kit.model.cclass(this.cclass);
//
// }; // end class CQuery
