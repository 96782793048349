
import CClass from '../CClass.js'
import CField from '../CField.js'
import Log from '../../misc/Log.js'
import Utilities from '../../misc/Utilities'
import $ from 'jquery'
import kit from '../../kit.js'

/**
 * CEnumValue : id, value, [icon], [color]
 * @class com.omniapp.kit.model.field.CEnumValue
 */
function CEnumValue(id, value, name, icon, color) {
    this.id = id;
    this.value = value ? value : id;
    this.name = name;
    this.icon = icon;
    this.color = color;

    this.toString = function() {
        return '[CEnumValue id=' + this.id + ', value=' + this.value + ', name=' + this.name + ']';
    };
    this.getValue = function() {
        return value ? value : id;
    };
} // end class CEnumValue

/**
 * CEnumField
 * @class com.omniapp.kit.model.field.CEnumField
 * @extends com.omniapp.kit.model.CField
 */
function CEnumField(cclass, cfield) {

    CField.call(this, cclass, cfield, 'CEnumField');

    // store values as map AND as array
    var _valuesMap = null;
    var _valuesArray = null;
    var _type = null;

    /** return type, one of LIST, YES_NO, COMPACT, MULTIPLE */
    this.type = function() {
        if (this._type == null)
            this._type = this.node.attr('type');
        if (this._type === undefined)
            this._type = 'LIST';
        return this._type;
    };

    /** retrieve all possible values of this enum */
    this._loadValues = function() {
        if (_valuesMap == null) {
            _valuesMap = {};
            _valuesArray = [];
            // var self = this;
            this.node.find("enum-value").each(function() {
                // Log.log("found value " + $);
                var value = new CEnumValue($(this).attr('id'), $(this).attr('value'),
                    $(this).attr('name'), $(this).attr('icon'), $(this).attr('color'));
                _valuesMap[value.value] = value;
                _valuesArray.push(value);
            });
        }
    };

    /** retrieve all values */
    this.values = function() {
        this._loadValues();
        return _valuesArray;
    };

    /** retrieve a CEnumValue given its id */
    this.value = function(id) {
        this._loadValues();
        return _valuesMap[id];
    };

    /** retrieve a CEnumValue given its index */
    this.valueAt = function(index) {
        this._loadValues();
        return _valuesArray[index];
    };

    /** retrieve value count */
    this.valueCount = function() {
        this._loadValues();
        return _valuesArray.length;
    };

    /** String -> CEnumValue */
    this.convert = function(val) {
        // if (!val || (val === null))
        if (val == null)
            return null;
        this._loadValues();
        if (val instanceof CEnumValue) {
            return val;
        }
        else if (typeof val == "boolean") {
            if (this.type() === "YES_NO") {
                return this.valueAt(val ? 0 : 1)
            }
        }
        else {
            if (this.type() === "MULTIPLE") {
                var ids = val.split(/[\s,]+/); // split on , and space
                for (var i = 0 ; i < ids.length ; i++) {
                    ids[i] = _valuesMap[ids[i]];
                }
                return ids;
            }
            else {
                return _valuesMap[val];
            }
        }
    };

    /** CEnumValue -> display String */
    this.displayValue = function(val) {
        if (val === null)
            return '';
        else if (!val) {
            kit.log.log('undef');
            return '';
        }
        else
            return val.name;
    };

    /** CEnumValue ->  String */
    this.stringValue = function(val) {
        if (val === null)
            return null;
        else if (Utilities.isString(val))
            return val;
        else
            return val.getValue();
    };

    /** contains this value ? val is either CEnumValue or String */
    this.containsValue = function(val) {
        if (!val)
            return false;
        this._loadValues();
        if (Utilities.isString(val))
            return _valuesMap[val];
        else
            return _valuesMap[val.value];
    };

} // end class CEnumField

export default CEnumField
