import CEntity from './CEntity.js'
import Log from '../misc/Log.js'
import $ from 'jquery'
/**
 * CClass object, extends CEntity
 * @class com.omniapp.kit.model.CClass
 * @extends com.omniapp.kit.model.CEntity
 */
function CClass(cclass) {

    CEntity.call(this, cclass);

    var _fields = {};
    var _superClass; // undefined by default

    /** retrieve all fields ids */
    this.fields = function() {
        var names = this.superClass() != null ? this.superClass().fields() : [];
        $(this.node).find("fields").children().each(function() {
            names.push($(this).attr('id'));
        });
        return names;

    };

    /** retrieve superclass */
    this.superClass = function() {
        if (!this._superClass) {
            var sc = this.node.attr('superClass');
            if (sc)
                this._superClass = this._model.cclass(sc);
            else
                this._superClass = null;
        }
        return this._superClass;
    };

    this.baseClass = function() {
        return this.superClass() === null ? this : this.superClass()
    }

    /** retrieve a field of this cclass given its id */
    this.field = function(fid) {

        var cached = _fields[fid];
        if (cached) {
            // Log.log("return cached field");
            return cached;
        }

        // if (!fid)
        // Log.error('fid is null..');

        // manage paths. Only 1 depth for now
        if (fid.contains('.')) {
            // probleme
            // soit on est dans mandat et on veut procedure.mission
            // soit on est dans PieceDeclarationCreance et on veut pieces.nom
            // PieceDeclarationCreance est une composition multiple, on devrait avoir nom, et
            // dans une forme englobante la CClass
            Log.warning('cclass ' + this.id() + ' looking for field ' + fid);
            //alert('looking for ' + fid.substring(0, fid.indexOf('.')) + " field=" + fid.substring(fid.indexOf('.') + 1, fid.length));
            // var rfid = fid.substring(fid.indexOf('.') + 1, fid.length);
            var rfid = fid.substring(0, fid.indexOf('.'));
            // var rfield = this.field(fid.substring(0, fid.indexOf('.')));
            var rfield = this.field(rfid);
            var rclass = rfield.targetClass();
            return rclass.field(fid.substring(fid.indexOf('.') + 1, fid.length));
        }

        var field = this.node.find("fields").children("[id='" + fid + "']");
        if (!field.get(0)) {
            if (this.superClass() != null)
                return this.superClass().field(fid);
            else {
                Log.warning("field " + fid + " not found in CClass " + this.id() + " (clear cache ?)");
                return null;
            }
        }
        var fieldName = field.get(0).tagName;
        //  Log.log("fieldName=" + fieldName + " constructor=" + fieldMap[fieldName]);
        var fieldConstructor = this._model.fieldMap[fieldName];
        if (!fieldConstructor)
            throw new Error("no declared field for '" + fieldName + "'")
        //return new CField(this, field);
        var ff = new fieldConstructor(this, field);
        ff._model = this._model;
        _fields[fid] = ff;
        return ff;
    };

    /** retrieve list of fields with a given tag */
    this.fieldsWithTag = function(tag) {
        var list = [];
        var fields = this.fields();
        for (var i = 0 ; i < fields.length ; i++) {
            var field = this.field(fields[i]);
            if (field.hasTag(tag))
                list.push(field);
        }
        return list;
    };

    /** name of an object */
    this.objectName = function(obj) {
        var flist = this.fieldsWithTag('NAME');
        if (flist.length === 0)
            return obj.id;
        else {
            var name = obj.displayValue(flist[0].id());
            return name;
        }
        // return obj.id;
    };

    /** retrieve list of actions ids */
    this.actions = function() {
        //var ids = [];
        return this.node.find('actions')[0];
        //> action').each(function() {
        //    ids.push($(this).attr('id'));
        //});
        //return ids;
    };

    /** String rep */
    this.toString = function() {
        return "CClass[" + this.id() + "]";
    };
} // end CClass

export default CClass;


