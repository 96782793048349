
import $ from 'jquery'

/**
 * log methods, managing levels and other outputs than console.
 * Levels are, in this order : error, warning, user, log, trace, debug
 * When level is set everything over this level is skipped.
 * default level is log.
 * Log.install() install an output div on the body, printing logs
 * @class com.omniapp.util.Log
 */
export default {
    
    LEVELS : ['error', 'warning', 'user', 'log', 'trace', 'debug'],
   
    // current log level
    _level : 'log',
    
    // callback
    _callback : null,
    
    /** display a user message */
    user : function(msg) {
        this.trace(msg, 'user');
    },

    /** display a log message */
    log : function(msg) {
       this.trace(msg, 'log');
    },
    
    /** display a debug message */
    debug : function(msg) {
        this.trace(msg, 'log');
    },

    /** display a warning message */
   warning : function(msg) {
        this.trace(msg, 'warning');
    },
    
    /** display an error message */
   error : function(msg) {
        this.trace(msg, 'error');
    },

   /** display a trace message */
    trace : function(msg, level) {
        if (level === undefined)
            level = 'trace';
        if (this.LEVELS.indexOf(level) > this.LEVELS.indexOf(this._level)) {
           // // console.log("skip " + level);
            return;
        }
        // console.log('level=' + Log.LEVELS.indexOf(level) + ' current level=' + Log.LEVELS.indexOf(Log._level));
        // if (!$.browser && !$.browser.msie)
     if ((level === 'log') || (level === 'trace') || (level === 'user') || (level === 'debug'))
       console.log('[' + level + '] ' + msg);
     else if (level === 'warning')
       console.warn('[' + level + '] ' + msg);
     else if (level === 'error')
       console.error('[' + level + '] ' + msg);

     if (this._callback !== null) {
            this._callback(msg, level);
        }
    },

    /** set/get level */
    level : function(val) {
        if (val !== undefined)
           this._level = val;
        return this._level;
    },
    
    /** set/get callback */
   callback : function(cb) {
        if (cb !== undefined)
            this._callback = cb;
        return this._callback;
   },
   
   /** 
    * instal a fixed view. pending, not here
    * create log-container/log-content/log-message elements 
    */
   // install : function() {
   //      var container =
   //          $('<div id="log-container" style="margin-bottom:5px;margin-left:5px;margin-right:5px;padding:5px'
   //        + ';height:60px;background:#aaa;'
   //        + '-webkit-border-radius:10px;-moz-border-radius: 10px;border-radius: 8px;'
   //        + 'opacity:0.85;flex-shrink:0;-webkit-flex-shrink:0;">'
   //        + '<div id="log-content" style="overflow-y:auto;overflow-x:hidden;height:100%;margin-left:10px;margin-right:10px"></div>'
   //        + '</div>');
   //
   //
   //          var fadeOut = function() {};
   //
   //          // callback called when a log message is received : append to log container
   //          var callback = function(msg, level) {
   //
   //              msg = msg.replace("<", "&lt;").replace(">", "&gt;").replace("&", "&amp;");
   //              color = 'inherit';
   //              if (level === 'error')
   //              color = '#bb0c21';
   //              else if (level === 'warning')
   //                  color = '#916506';
   //              else if (level === 'user')
   //                  color = '#145704';
   //              else if ((level === 'log') || (level === 'trace'))
   //                  color = '#000';
   //
   //              // $("#log").append('<p style="font-weight:bold;color:' + color + '">' + msg + '</p>');
   //              $("#log-content").append('<div class="log-message" style="font-size:0.6em;color:' + color + '">' + msg + '</div>');
   //              $("#log-content").scrollTop($("#log-content")[0].scrollHeight);
   //
   //               container.stop(true);
   //              container.css('opacity', '1');
   //              container.delay(4000).animate({opacity:0}, 1000);
   //          };
   //
   //         // display on mouse over
   //            container.bind("mouseenter", function() {
   //                  Log.debug("enter");
   //                 container.stop(true);
   //                  container.animate({opacity:1});
   //              });
   //         // hide when mouse exit
   //              container.bind("mouseleave", function() {
   //                  Log.debug("out");
   //                  container.stop();
   //                  container.animate({opacity:0});
   //              });
   //
   //          $("body").append(container);
   //          Log.callback(callback);
   //          return container;
   //
   // }
   }
