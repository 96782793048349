/**
La vue qui permet d'update le password, utilisé aussi bien en membre qu'en employeur
UpdatePassword utilise cette vue lors du reset de password, mais fournit la méthode
onUpdatePassword qui remplace le comportement par défaut
*/
<template>
  <vbox class="password-updater">
    <back-header title="Mot de passe" v-if="!notitle"/>
    <b-form class="password-form vbox">
      <k-field label="Mot de passe*" ref="password" id="login-field" class="fill">
        <PasswordField v-model="password" placeholder="Mot de passe"/>
      </k-field>
      <k-field label="Confirmez votre mot de passe*" id="password-field" ref="password2" class="fill">
        <PasswordField v-model="password2" placeholder="Confirmation"/>
      </k-field>
      <btn-action id="update-button" titre="Mettre à jour le mot de passe" :withLogo="false"
                  :click="this.updatePassword"/>
    </b-form>
  </vbox>
</template>

<script>

import GipMixin from "../GipMixin";
import BackHeader from "./BackHeader";
import BtnAction from "../BtnAction";
import CObject from "../../../kit/data/CObject";
import kit from "../../../kit/kit";
import PasswordField from "../PasswordField.vue";

export default {name: "PasswordUpdater",
  mixins: [GipMixin],
  components: {BackHeader, BtnAction, PasswordField},
  data: function() {
    return {
      password: null,
      password2: null,
      kfields: ["password", "password2"]
    }
  },
   props: {

    /**
     * Supprime le titre si défini
     */
    notitle: {
      type: Boolean,
      default: false,
    },
     /**
      * Le type, Employeur ou Membre
      */
     type: {
      type: String,
       default: null
     },
    /**
      * la methode onUpdatePassword est appelée avec password et password2
      * en argument si défini, sinon on a un comportement par défaut de
      * check/maj du mot de passe
      * PENDING attention pour l'instant uniquement Employeur !
      */
    onUpdatePassword: {
      type: Function,
      default: null
    }
  },
  methods: {
  //  la on considère qu'on update le mot de passe depuis le profil Employeur/Membre, ce
    // qui n'est pas la même chose que si on est en reset de password demandé :
    // on a pas le user mais uniquement le token
    updatePassword: function() {
      this.setError(null)
      if (this.onUpdatePassword !== null) {
        this.onUpdatePassword(this.password, this.password2);
      }
      else {
        let object = new CObject(this.type, this.type === "Employeur" ? this.state.user.employeurId : this.state.user.membreId)
        object.newValue("password", this.password)
        object.newValue("password2", this.password2)

        kit.services.check(object, () => {
              kit.services.submit(object, () => {
                    kit.dialogs.info("Votre mot de passe a été mis à jour")
                  },
                  (error) => {
                    kit.dialogs.error("Erreur lors de la mise à jour du mot de passe")
                    console.log(error)
                  })
            },
            (error) => {
               this.setError(error)
              })
      }
    },
    setError(error) {
      for (var kfield of this.kfields) {
        this.$refs[kfield].setError(null)
        if ((error !== null) && error.errorFields().includes(kfield)) {
          console.log("on a une erreur")
          this.$refs[kfield].setError(error.error(kfield))
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.password-form {
  margin-top: 2em;
}


.password-form .c-field-label {
  font-size: 4em !important;
}
.password-form label {
  color: red !important;
}

form .form-group {
  color: var(--gip-tint-color);
  font-size: 1em;
  font-weight:600;
}

.password-updater {
  //flex-shrink: 0;
}

#update-button, #password-field {
  margin-top: 1em;
}

</style>