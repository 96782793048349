// le header à fond bleu utilisés dans les différents back
// prend title en props et un composant en slot
<template>
<hbox class="back-header flex-wrap">
  <h1>{{ title }}</h1>
  <slot/>
</hbox>
</template>

<script>
import GipMixin from '../../ui/GipMixin.js'
export default {
  name: "BackHeader",
  mixins: [GipMixin],
  props: {
    title: String
  }
}
</script>

<style scoped>

.back-header {
  background-color: var(--gip-tint-color);
  color: var(--gip-text-constrast-color);
  border-radius: 5px;
  padding: 0.7em;
  padding-left:1.5em;
  align-items: center;
}

.back-header h1 {
  font-weight: normal;
  font-size: 1.3em;
  margin-right: 1em;
  margin-top:0;
  margin-bottom:0;
}
</style>