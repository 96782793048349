<template>
  <login ref="login" titre="Espace employeur" :texte="description" login-label="Nº GUSO"
         login-placeholder="Entrez votre numéro Guso" targetPage="DashboardEmployeur"
         type="Employeur" @connect="connection"/>

</template>

<script>
import Login from "../ui/Login"
import GipMixin from '../ui/GipMixin.js'

export default {
  name: "LoginEmployeur",
  mixins: [GipMixin],
  components: {Login},

  data: function () {
    return {
      description: "Bienvenue dans l'espace employeur. Pour vous connecter, <br/>"
          + "indiquez votre numéro de Guso ainsi que votre mot de passe"
    }
  },
  methods: {
    connection(user) {
      this.connectEmployeur(user)
      this.$router.push({name: "DashboardEmployeur"})
    },
  }
}
</script>

<style scoped lang="scss">

</style>