import $ from 'jquery'
/**
 * @class com.omniapp.kit.model.CError
 * @constructor
 */
export default function CError ( cErrorData ) {
	
	this.data = cErrorData;

	this.errorFields = function() {
        var fields = []
        for (var error of this.data)
            fields.push(error.cfield)
        return fields
    }

    /**
     * Retrieve error message for a field. Returns null if no message
     * @param field
     * @returns {*}
     */
    this.error = function(field) {
        for (var error of this.data) {
            if (error.cfield === field)
                return error.message
        }
        return null
    }

    this.toString = function() {
        return this.data[0].message;
    }
}

CError.parse = function(content) {
    if (content.toLowerCase().startsWith("<?xml")) {
        return CError.fromXML(content)
    }
    else {
        var json = JSON.parse(content)
        return new CError(json.errors)
    }
};

CError.fromXML = function ( xmlNode ) {
	
	var errors = [];
	$(xmlNode).find('error').each(function () {
		
		errors.push({'cfield': $(this).attr('cfield'), 'message': this.textContent});
		
	});
	
	return new CError(errors);
};
