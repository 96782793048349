"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var SiteGip_vue_1 = require("./SiteGip.vue");
var router_1 = require("./router");
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
var vue_lazyload_1 = require("vue-lazyload");
var buefy_1 = require("buefy");
// import 'buefy/dist/buefy.css'
var bootstrap_vue_1 = require("bootstrap-vue");
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
vue_1.default.use(bootstrap_vue_1.BootstrapVue);
vue_1.default.use(vue_lazyload_1.default);
vue_1.default.use(buefy_1.default);
vue_1.default.component('b-modal', bootstrap_vue_1.BModal);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
// createApp(SiteGip)
//     .use(router)
//     .mount('#app')
new vue_1.default({
    el: '#app',
    router: router_1.default,
    components: { SiteGip: SiteGip_vue_1.default },
    // mixins: [GensyndMixin],
    template: '<SiteGip/>',
    created: function () {
        // map sur http://localhost:8080/gensynd, le proxy est fait dans config/index.js
        // (seulement en dev, en prod c'est apache qui proxy)
        // /resources/model.xml ou /static/resources/model.xml
        // kit.init("/services", "/static/resources/model.xml",
        //     // post framework load callback
        //     () => {
        //         this.$emit('model-loaded');
        //     });
    }
});
