<template>
    <b-input-group>
        <b-form-input
            :id="id"
            v-model="password"
            :placeholder="placeholder"
            required
            :type="show ? 'text' : 'password'"
        ></b-form-input>
        <b-input-group-append is-text @click="show = !show" class="eye-icon">
            <EyeIcon :slash="show"/>
        </b-input-group-append>
    </b-input-group>
</template>

<script>
import EyeIcon from '@/assets/icons/EyeIcon.vue';

export default {
    components: { EyeIcon },
    props: ['id', 'placeholder', 'value'],
    data() {
        return {
            show: false
        }
    },
    computed: {
        password: {
        get() {
            return this.value;
        },
        set(val) {
            this.$emit('input', val);
        }
        }
    }
}
</script>

<style scoped>
.eye-icon:hover  {
  cursor: pointer;
}
</style>