<template>
  <div v-if="employeur !== null" class="gip-bg-default ">
    <div class="gip-page">
      <div class="back-title">
        <h1>Mon profil</h1>
      </div>
      <hbox class="infos-container wrap-medium">
        <vbox class="informations-container">

          <BackHeader v-if="employeur.valide == 'F'" title="Votre profil n’est plus actif car le compte est fermé" style="background-color: var(--gip-action-color)"/> 
          <BackHeader v-if="employeur.valide == 'N' || employeur.valide == 'M'" title="Votre profil est en attente de validation par nos équipes" style="background-color: var(--gip-action-color)"/>

          <BackHeader title="Informations"/>
          <div class="gip-container">
            <EmployeurForm2 ref="employeurForm" :propEmployeur="this.employeur" :update="update" :withPassword="false" btnAction="Mettre à jour mes informations"/>
          </div>
        </vbox>
        <hbox class="wrap-small">
          <PasswordUpdater type="Employeur"></PasswordUpdater>
        </hbox>
      </hbox>
  <DocumentsModal :ownerId="employeur.employeurId" :financeur="false" :callback="onFileUploaded"/>
      <BackHeader title="Mes Documents">
        <b-form-select id="typeDocChooser" @change.native="onTypeDocSelected()" v-model="typeDoc"
                       :options="typesDocs"></b-form-select>
        <div class="flex-grow-1"></div>
        <a  class="docModalBtn" @click="showModallocal()">Ajouter un document</a>

      </BackHeader>
      <div class="table-demandes">
        <composite-table :is-header="true">
          <hbox class="fill hbox align-center">
            <p class="fill" style="width:10em;flex-basis:auto;">Date de dépôt</p>
            <p class="fill" style="flex-basis:70%">Document</p>
            <p class ="" style="width:10em;">Type</p>
            <p class="fill" style="width:15em;flex-basis:auto;">Statut</p>
            <icon-base class="hidden" @click="props.expand()">
              <eye-small></eye-small>
            </icon-base>
            <icon-base class="hidden">
              <download-small></download-small>
            </icon-base>
            <icon-base class="hidden">
              <trash-small></trash-small>
            </icon-base>
          </hbox>
        </composite-table>
        <composite-table ref="demandeCell" v-for="(document, index) in documents" :key="index">
<!--          <template v-slot:default="props">-->
  <vbox class="fill">
  <hbox class="align-items-center">
            <p class="" style="width:10em;flex-basis:auto;"> {{ display('Document.dateDepot', document.dateDepot) }}</p>
            <p class="fill" style="flex-basis:70%">{{ document.fichier }}</p>
            <p style="width:10em;">{{ document.typeDocumentId ? document.typeDocumentId.type : ''}}</p>
            <p class="" :style="statusColor(document.etat)">
              {{ display('Document.etat', document.etat) }}</p>
            <!-- l'oeil n'est affiché que sur les documents refusés -->
            <icon-base @click.native="toggleDetail(index)" :class="{ hidden: document.etat != 'R' }">
              <eye-small :ref="'eye-' + index"></eye-small>
              <eye-stroke-small :ref="'eye-stroke-' + index" class="hidden"></eye-stroke-small>
            </icon-base>
            <icon-base title="Télécharger ce document" @click.native="downloadDocument(document)">
              <download-small></download-small>
            </icon-base>
            <icon-base active title="Supprimer ce document" iconColor="var(--gip-white-color)" background-color="var(--gip-error-color)"
                       :class="{ hidden: !['R', 'N'].includes(document.etat) }"
                        @click.native="deleteDocument(document)">
              <trash-small></trash-small>
            </icon-base>
<!--          </template>-->

  </hbox>
<!--          <template v-slot:expanded>-->
            <hbox class="document-detail document-detail-hidden" :ref="'document-detail-' + index">
              <vbox class="fill">
                <p class="text-refus"><span>Votre document a été refusé : {{ document.texteRefus }}</span><br/>Veuillez prendre contact avec
                  l’équipe du Gip Cafés Cultures.</p>

                <a id="releve-annuel" @click="deposerDocument()" class="match">
                  <download-small id="download-icon"/>Déposer à nouveau le document</a>
<!--                <p class="deposer-document-text">-->
<!--                  <hbox><DownloadSmall/>-->
<!--                    <span>Déposer à nouveau le document</span>-->
<!--                  </hbox>-->
<!--                </p>-->
              </vbox>
            </hbox>
</vbox>

<!--          </template>-->
        </composite-table>
      </div>
    </div>
  </div>
</template>

<script>
import BackHeader from "../ui/back/BackHeader";
import EmployeurForm2 from "./EmployeurForm2";
import PasswordUpdater from "../ui/back/PasswordUpdater";
import GipMixin from "../ui/GipMixin";
import kit from "../../kit/kit";
import DocumentsModal from "../ui/DocumentsModal";
import CompositeTable from "./CompositeTable"
import CObject from "../../kit/data/CObject"

export default {
  name: "ProfilEmployeur",
  components: {BackHeader, EmployeurForm2, PasswordUpdater, CompositeTable,DocumentsModal},
  mixins: [GipMixin],
  data() {
    return {
      showUploadDocModal: false,
      status: "rouge",
      typeDeDocument: "fzefe",
      dateDeDepot: "feferff",
      typeDoc: "T",
      typesDocs: [
        {value: 'T', text: 'Tous'},
        {value: 'N', text: 'En traitement'},
        {value: 'V', text: 'Validés'},
        {value: 'R', text: 'Refusés'},
      ],
      // ça c'est ce qu'on voudrait
      tableConfig: {
        columns: ["Date de dépôt", "Document", "Type", "Statut", ""],
        widths: ["8em", "fill", "fill", "6em", "match"],
        aligns: ["left", "left", "left", "left", "center"]
      },
      documents: null,
      // http://localhost:8081/services/query?cclass=Employeur&id=1165&depth=3
      employeur: null /**  {
        "__cclass": "Employeur",
        "__id": "1165",
        "employeurId": "1165",
        "nom": "Casa Datcha",
        "formeJuridiqueId": {
          "__cclass": "FormeJuridique",
          "__id": "2",
          "formeJuridiqueId": "2",
          "numero": "57",
          "nom": "SAS"
        },
        "raisonSociale": "SAS CASA DATCHA",
        "nomGerant": "delabeye",
        "prenomGerant": "romain",
        "tel": "+590690992845",
        "mail": "casa.datcha@gmail.com",
        "guso": "5342675124",
        "password": "$2a$10$teSYrcQW7gyaDjYXmCAzzuNqxubsSMXTLuHYeeKtGLxyp9pY.kBSO",
        "siret": "80950655300027",
        "naf": {
          "__cclass": "NAF",
          "__id": "5610C",
          "nafId": "5610C",
          "nom": "Restauration de type rapide",
          "eligibilite": "NORMALE"
        },
        "iban": "FR7616159053410002043610140",
        "bic": "CMCIFR2A",
        "licence": "N",
        "numeroLicence": "non",
        "etablissementEnCentreCommercial": "N",
        "licenceRestauration": "N",
        "valide": "N",
        "adresse1": "100 bis impasse gisors",
        "communeId": {
          "__cclass": "Commune",
          "__id": "36830",
          "communeId": "36830",
          "insee": "97113",
          "codePostal": "97190",
          "nom": "GOSIER",
          "latitude": "16.216300",
          "longitude": "-61.483500",
          "population": "26666"
        },
        "objectId": "1165",
        "tags": [],
        "notes": "<br/><br/>",
        "notifMail": "O",
        "avisCommissionSecurite": "O",
        "autoriserDemandeurTiers": "N",
        "dateCreation": "2018-07-25",
        "lastUpdate": "2020-08-18",
        "archive": "O",
        "documents": [
          {
            "__cclass": "Document",
            "__id": "136",
            "documentId": "136",
            "employeurId": "1165",
            "fichier": "Casadatcha-Lenovo-PC.jpg",
            "typeDocumentId": {
              "__cclass": "TypeDocument",
              "__id": "2",
              "typeDocumentId": "2",
              "type": "Bail"
            },
            "dateDepot": "2021-11-17",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "137",
            "documentId": "137",
            "employeurId": "1165",
            "fichier": "Casadatcha.jpg",
            "typeDocumentId": {
              "__cclass": "TypeDocument",
              "__id": "4",
              "typeDocumentId": "4",
              "type": "ERP"
            },
            "dateDepot": "2021-12-16",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "138",
            "documentId": "138",
            "employeurId": "1165",
            "fichier": "Casadatcha1-Lenovo-PC.jpg",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "139",
            "documentId": "139",
            "employeurId": "1165",
            "fichier": "Casadatcha1.jpg",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "140",
            "documentId": "140",
            "employeurId": "1165",
            "fichier": "Casadatcha2-Lenovo-PC.jpg",
            "typeDocumentId": {
              "__cclass": "TypeDocument",
              "__id": "5",
              "typeDocumentId": "5",
              "type": "Licence"
            },
            "dateDepot": "2021-11-01",
            "etat": "R"
          },
          {
            "__cclass": "Document",
            "__id": "141",
            "documentId": "141",
            "employeurId": "1165",
            "fichier": "Casadatcha2.jpg",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "142",
            "documentId": "142",
            "employeurId": "1165",
            "fichier": "Casadatcha3-Lenovo-PC.jpg",
            "typeDocumentId": {
              "__cclass": "TypeDocument",
              "__id": "5",
              "typeDocumentId": "5",
              "type": "Licence"
            },
            "dateDepot": "2021-12-17",
            "etat": "V"
          },
          {
            "__cclass": "Document",
            "__id": "143",
            "documentId": "143",
            "employeurId": "1165",
            "fichier": "Casadatcha3.jpg",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "144",
            "documentId": "144",
            "employeurId": "1165",
            "fichier": "Casadatcha4-Lenovo-PC.jpg",
            "etat": "N"
          },
          {
            "__cclass": "Document",
            "__id": "145",
            "documentId": "145",
            "employeurId": "1165",
            "fichier": "Casadatcha4.jpg",
            "etat": "N"
          }
        ],
        "Spectacles": [
          {
            "__cclass": "Spectacle",
            "__id": "13032",
            "spectacleId": "13032",
            "nom": "PATRICK GUEPPOID",
            "categorie": "MUSIQUE",
            "dateRepresentation": "2018-07-20",
            "dateDemande": "2018-07-25",
            "objectId": "13032",
            "tags": [
              {
                "__cclass": "ObjectTag",
                "__id": "35195",
                "objectTagId": "35195",
                "objectCClass": "Spectacle",
                "objectId": "13032",
                "tagId": "11"
              }
            ],
            "intervenants": [
              {
                "__cclass": "IntervenantSpectacle",
                "__id": "34070",
                "intervenantSpectacleId": "34070",
                "spectacleId": "13032",
                "nom": "GUEPPOID",
                "prenom": "PATRICK",
                "qualite": "A"
              }
            ],
            "notes": "à jour",
            "anticipe": "NORMAL",
            "etat": "R",
            "etatGuso": "R",
            "dateEnvoiGuso": "2018-12-10",
            "dateRetourGuso": "2018-12-11",
            "montant": "41.24",
            "financements": [],
            "archive": "O",
            "nbCachet": null,
            "nbArtiste": "1",
            "nbTechnicien": "0"
          }
        ],
        "Historique": [
          {
            "__cclass": "Historique",
            "__id": "479",
            "historiqueId": "479",
            "employeurId": "1165",
            "date": "2020-06-03 13:53:00",
            "type": "S",
            "texte": "25/08/18 : kbis reçu, ils vont nous envoyer une copie de leur registre de sécurité + mail pr leur demander s&#39;ils ont 1 licence de débit de boissons ou de restauration<br/>21/08/18 : mail pr demander kbis et attestation N5"
          }
        ]
      } **/

    }
  },
  mounted() {
    this.employeur = this.state.user
  //  this.$refs.employeurForm.setEmployeur(this.employeur)
    this.documents = this.employeur.documents.filter(doc => doc.origine !== 'INVISIBLE')
  },
  methods: {
    update(object) {
      console.log('update dans profil employeur' )

      kit.services.submit(object, () => {
            this.$buefy.notification.open({
              message: "Votre profil a été mis à jour",
              position: "is-bottom"
            })
            },
          (error) => {
            alert("pb update")
            console.log(error)
          })
    },

    toggleDetail(detail) {
      var comp = this.$refs["document-detail-" + detail][0]
      console.log("el=" + comp.$el)
      comp.$el.classList.toggle("document-detail-hidden")
      this.$refs["eye-" + detail][0].classList.toggle("hidden")
      this.$refs["eye-stroke-" + detail][0].classList.toggle("hidden")
    },
    statusColor(etat) {
      var enumEtat = kit.model.cclass("Document").field("etat").value(etat)
      // console.log("bim statut=" + etat + " color=" + enumEtat.color)
      return "color:rgb(" + enumEtat.color + ");" + "width:15em;flex-basis:auto;"
    },
    onTypeDocSelected() {
      this.documents = this.employeur.documents.filter(d => this.typeDoc === 'T' || d.etat === this.typeDoc)
      console.log("doc selected")
    },

    deleteDocument(doc) {
      kit.dialogs.confirm({
        action: "Supprimer",
        message: "Voulez-vous supprimer ce document ?",
        callback: (e) => {
          var rdoc = new CObject("Document", doc.documentId)
          kit.services.delete(rdoc, () => {
            //alert("suppression ok")
            var index = this.documents.indexOf(doc)
            this.documents.splice(index, 1)
          }, (error) => {
            kit.dialogs.error("erreur de suppression")
            kit.log.error("erreur suppression : " + error)
          })
        }
      })
      // alert("delete")
    },
    /**
     * télécharge un document. Attention devrait être protégé par le routeur !!
     * @param doc
     */
    downloadDocument(doc) {
      window.open(kit.servicesUrl + "/document?type=doc&id=" + doc.fichier, "_blank")
        // window.open("/documents/etablissements/" + doc.employeurId + "/" + doc.fichier)
      },
    showModallocal(){
      this.$bvModal.show("my-modal")
    },
    onFileUploaded(data) {
      console.log("upload, fichier=0" + data)
      console.log(this.documents);
      this.documents.push(data);
      kit.dialogs.info("Le document a bien été déposé")
    }
  },

}
</script>

<style lang="scss" scoped>

#typeDocChooser {
  flex-basis: fit-content;
}

.back-header {
  margin-bottom: 1em;
}

//h1 {
//  padding-top: 1em;
//}
p{
  margin: 0;
}

.informations-container {
  margin-right: 1em;
}

.infos-container {
  //margin-top: 3.125em;
}

.vert {
  color: #42b983;
}

.rouge {
  color: darkred;
}

.gip-container {
  background: white;
  border-radius: 0.3125em;
  padding: 1.5625em;
  box-shadow: 0px 3px 23px #04437010;
  margin-bottom: 3em;

}

.text-refus {
  span {
    color: #E64D4D;
  }
}

.text-deposer-document {
  color: var(--gip-action-color);
}

#download-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.8em;
}

.document-detail {
  overflow: hidden;
  transition: 0.4s ease-in-out;
  //height: 100px;
  max-height: 200px;
}
.document-detail-hidden {
  //height: 0px;
  opacity: 0;
  max-height: 0;
}
.docModalBtn{
  color:white;
  text-decoration: none;
  align-items: self-end;

}
//.document-detail-shown {
//  height: auto;
//}
.gip-page {
  padding-bottom: 8em;
}

</style>