<template>
  <div>
    <quillEditor :class="{uneditable: !editable}" ref="editor" v-model="inputVal" :editorToolbar="null"
                :options="editorOptions" :placeholder="placeHolder" :disabled="!editable"
                @change="onEditorChange"/>
    <div>{{ chars() }}</div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

export default {
  name: "StyledTextEditor",
  components: {quillEditor},
  data() {
    return {
      // text: '',
      editorOptions: {
        "modules": {
          "toolbar": [['bold', 'italic', 'underline', 'strike']]
        },
        theme: 'snow', // bubble pour la toolbar dans un balloon
        placeholder: '',
        fullText: null
      }
    }
  },
  props: {
    // pour v-model
    value: {
      type: String,
      default: null,
      required: true
    },
    // max chars
    maxChars: Number,
    // placeholder
    placeHolder: String,
    // editable
    editable: {
      type: Boolean,
      default : true
    }
  },
  mounted() {
    this.$refs.editor.quill.toolbar = null
    this.fullText = this.$refs.editor.quill.getText()
    this.$forceUpdate(); // sinon les nb de chars ne s'update pas au 1er affichage
  },
  methods: {
    onEditorChange(html, text) {
      this.fullText = html.text

      // console.log("editor change!, len=" +  html.text.length - 1)

      // this.content = html
    },
    chars() {
      console.log("chars !")
      if (this.maxChars != null)
        return (this.fullText != null ? (this.fullText.length - 1) : 0) + "/" + this.maxChars
      else
        return null
    },
  },
  // watch: {
  //   value() {
  //     this.$emit('input', this.value);
  //   }
  // },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        let ed = this.$refs.editor
        console.log("set val" + ed.quill.getText())
        this.fullText = ed.quill.getText()
        this.$emit('input', val);
      }
    },
  }

}
</script>

<style lang="scss" >

.uneditable .ql-toolbar {
  display: none;
}
.uneditable .ql-editor {
  padding: 0;
  min-height: inherit;
}

.ql-editor {
  min-height: 7em;
}
.uneditable .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #EEEEEE;
  border-radius: 5px;
}
.ql-toolbar.ql-snow {
  border: 1px solid #EEEEEE;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #EEEEEE;
  padding: 16px 24px 16px 24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

</style>