/**
* zone infos avec une icone. Attend un texte en slot
*/
<template>
  <hbox class="align-center info-box infos">
    <InfoSmallIcon class="info-icon"/>
    <p class="gip-label">
    <slot/>
    </p>
  </hbox>
</template>

<script>
import GipMixin from "../GipMixin";
export default {
  name: "InfoBox",
  mixins: [GipMixin],
  // props: {
  //   infos: String
  // }
}
</script>

<style scoped lang="scss">

.infos {
  background: var(--gip-white-color);
}
.info-icon {
  flex-shrink: 0;
}
</style>