<template>
  <vbox class="gip-page">
    <div class="gip-container">

      <vbox class="back-title justify-left">
        <h1>Mes documents</h1>
      </vbox>

      <hbox class="wrap-small justify-start" id="documents">
        <vbox class="documents-container fill">
          <back-header title="Mes éditions et rapports">
          </back-header>
          <div class="cells">
            <document-cell libelle="Rapport de financement" icon="EditSmall" :callback="configRapportFinancement"/>
            <document-cell libelle="Rapport par établissements" icon="EditSmall" :callback="configRapportEtablissements"/>
            <document-cell libelle="Rapport annuel régional" icon="EditSmall" :callback="configRapportRegional"/>
            <document-cell v-if="hasPlaquettes" libelle="Plaquette personnalisée" icon="EditSmall" :callback="configPlaquette"/>
          </div>
        </vbox>
        <vbox class="documents-container fill">
          <back-header title="Documents Gip Cafés Cultures" class="fill">
          </back-header>
          <div class="cells">
<!--            <document-cell libelle="Convention constitutive" icon="DownloadIcon" :callback="download"/>-->
<!--            <document-cell libelle="Annexes de la Convention constitutive" icon="DownloadIcon" :callback="download"/>-->
<!--            <document-cell libelle="Coordonnées des membres adhérents" icon="DownloadIcon" :callback="download"/>-->
<!--            <document-cell libelle="Chemin de fer pour page membre" icon="DownloadIcon" :callback="download"/>-->
<!--            <document-cell libelle="Rapport d'activité AG" icon="DownloadIcon" :callback="download"/>-->

            <document-cell v-for="(doc, lienId) in documentsGip" :key="lienId"
            :libelle="doc.nom" icon="DownloadSmall" :context="doc" :callback="download"/>

          </div>
        </vbox>
      </hbox>
      <DocumentsModal :ownerId="financeurId" :financeur="true" :callback="onFileUploaded"/>
      <vbox class="documents-container fill">
          <back-header title="Mes documents" class="fill">
            <div class="flex-grow-1"></div>
            <a  class="docModalBtn" @click="showModallocal()">Ajouter un document</a>
          </back-header>
          <div class="cells">
            <document-cell 
              v-for="doc in documents" :key="doc.documentId"
              :libelle="doc.fichier" 
              icon="DownloadSmall" 
              :context="doc" 
              :callback="downloadDocument"
              @delete="deleteDocument(doc)"
              />
          </div>
      </vbox>
      <ConfigMaquette v-if="hasPlaquettes"/>

      <!-- génération de la plaquette, pour l'instant uniquement pour bloquer parce que l'édition est longue -->
      <b-modal id="modal-plaquettepdf" ref="plaquetteRef" class="report-modal" size="lg" centered
              title="Plaquette personnalisée" 
              ok-title="Générer ma plaquette"
              @ok="editerPlaquette" ok-only justify-content-center static>
        <p class="text">
          Edite votre plaquette configurable au format pdf à des fins d'impression.
        </p>
          <hbox class="align-center" v-if="plaquettes.length > 1">
              <span>Type </span>
              <b-form-select style="width: auto; margin-left: 8px;" v-model="selectedPlaquette" :options="plaquetteTypes"></b-form-select>
          </hbox>
        <hbox class="justify-center">
          <b-spinner v-show="rapportPlaquetteLoading" label="Spinning"></b-spinner>
        </hbox>
      </b-modal>

      <!-- config du rapport de financement -->
      <b-modal id="modal-rapportFinancementRef" title="Générer mon rapport de financement" ref="rapportFinancementRef" class="report-modal" size="lg" centered
               ok-title="Générer mon rapport"
               :ok-disabled="rapportFinancementLoading"
               @ok="rapportFinancement" ok-only justify-content-center static>
        <!--        <div class="gs-label gs-dialog-text">{{reportDescription}}</div>-->
        <!--<div>Sélectionnez les options du rapport</div>-->
        <!--         <b-field>-->
        <!--          <b-checkbox>Basic</b-checkbox>-->
        <!--        </b-field>-->
        <LoadingOverlay :show="rapportFinancementLoading"/>
        <div class="block">
        <b-field label="Période du" horizontal>
          <b-datepicker
              ref="datepicker" v-model="dateDebut"
              expanded editable
              placeholder="Date de début">
          </b-datepicker>
          <b-field label="au" horizontal>
            <b-datepicker
                ref="datepicker" v-model="dateFin"
                expanded editable
                placeholder="Date de fin">
            </b-datepicker>
          </b-field>
          <!--          <b-button-->
          <!--              @click="$refs.datepicker.toggle()"-->
          <!--              icon-left="calendar-today"-->
          <!--              type="is-primary" />-->
        </b-field>

        <b-field horizontal label="Utiliser la" >
          <b-select placeholder="Select a topic" v-model="dateCalcul" expanded>
            <option value="DATE_SPECTACLE">Date de spectacle</option>
            <option value="DATE_REGLEMENT">Date de règlement</option>
          </b-select>
        </b-field>

        <b-field horizontal label="Versements">
          <b-select placeholder="Select a topic" v-model="versements" expanded>
            <option value="TOUS_VERSEMENTS">Tous</option>
            <option value="ANNUELS">Annuels</option>
            <option value="COMPACT">Compact</option>
          </b-select>
        </b-field>
        </div>
        <b-field grouped label="Inclure le détail des spectacles" expanded horizontal>
          <b-radio v-model="details"
                   name="name"
                   native-value="O">
            Oui
          </b-radio>
          <b-radio v-model="details"
                   name="name"
                   native-value="N">
            Non
          </b-radio>
        </b-field>

        <b-field horizontal label="Financement" v-if="isFinanceurMultiple">
          <b-select placeholder="Sélectionner un financement" v-model="financement" expanded>
            <option value="CHR">CHR</option>
            <option value="OCCASIONNEL">Occasionnel</option>
          </b-select>
        </b-field>
       </b-modal>

      <!-- config du rapport par établissements -->
      <b-modal id="modal-rapportEtablissementRef" ref="rapportEtablissementsRef" class="report-modal" size="lg" centered
               title="Générer mon rapport par établissements" ok-title="Générer mon rapport"
               @ok="rapportEtablissements" ok-only justify-content-center static
               :ok-disabled="rapportEtablissementsLoading">
        <LoadingOverlay :show="rapportEtablissementsLoading"/>
        <div class="block">
          <b-field label="Période du" horizontal>
            <b-datepicker
                ref="datepicker" v-model="dateDebut"
                expanded editable
                placeholder="Date de début">
            </b-datepicker>
            <b-field label="au" horizontal>
              <b-datepicker
                  ref="datepicker" v-model="dateFin"
                  expanded editable
                  placeholder="Date de fin">
              </b-datepicker>
            </b-field>
            <!--          <b-button-->
            <!--              @click="$refs.datepicker.toggle()"-->
            <!--              icon-left="calendar-today"-->
            <!--              type="is-primary" />-->
          </b-field>

          <b-field horizontal label="Utiliser la" >
            <b-select placeholder="Select a topic" v-model="dateCalcul" expanded>
              <option value="DATE_SPECTACLE">Date de spectacle</option>
              <option value="DATE_REGLEMENT">Date de règlement</option>
            </b-select>
          </b-field>

          <b-field horizontal label="Financement" v-if="isFinanceurMultiple">
            <b-select placeholder="Sélectionner un financement" v-model="financement" expanded>
              <option value="CHR">CHR</option>
              <option value="OCCASIONNEL">Occasionnel</option>
            </b-select>
          </b-field>
        </div>
      </b-modal>

      <!-- config du rapport par region -->
      <b-modal id="modal-rapportRegionsRef" ref="rapportRegionsRef" class="report-modal" size="lg" centered
               title="Générer mon rapport par région" ok-title="Générer mon rapport"
               @ok="rapportRegions" ok-only justify-content-center static
               :ok-disabled="rapportRegionsLoading">
        <LoadingOverlay :show="rapportRegionsLoading"/>
        <div class="block">
          <b-field horizontal label="Année" >
            <b-select placeholder="Choisissez une année" v-model="anneeRegion">
              <option v-for="i of annees" :value="i.value" :key="i.text">{{i.text}}</option>
            </b-select>
          </b-field>

          <b-field horizontal label="Financement" v-if="isFinanceurMultiple">
            <b-select placeholder="Sélectionner un financement" v-model="financement" expanded>
              <option value="CHR">CHR</option>
              <option value="OCCASIONNEL">Occasionnel</option>
            </b-select>
          </b-field>
        </div>
      </b-modal>
    </div>
  </vbox>
</template>

<script>
import GipMixin from '../ui/GipMixin.js'
import BackHeader from '../ui/back/BackHeader'
import {BModal} from "bootstrap-vue";
import DocumentCell from "../ui/back/DocumentCell";
import kit from '../../kit/kit';
import ConfigMaquette from './ConfigMaquette';
import CObject from '../../kit/data/CObject'
import { default as CFilter, CFilterCondition, CFilterType } from '../../kit/data/CFilter.js'
import DocumentsModal from "@/components/ui/DocumentsModal.vue"
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue'

export default {
  name: "DocumentsFinanceur",
  mixins: [GipMixin],
  components: {BackHeader, BModal, DocumentCell, ConfigMaquette, DocumentsModal, LoadingOverlay},

  data() {
    return {

      documentsGip: null,

      dateDebut: new Date(new Date().getFullYear(), 0, 1),
      dateFin: new Date(new Date().getFullYear(), 11, 31),
      dateCalcul: "DATE_SPECTACLE",
      versements: "ANNUELS",
      details: "O",
      hasPlaquettes: false,
      plaquettes: [],
      plaquetteTypes: [],
      selectedPlaquette: 0,

      anneeRegion: null,
      annees: [
        { value: '2015', text: '2015' },
        { value: '2016', text: '2016' },
        { value: '2017', text: '2017' },
        { value: '2018', text: '2018' },
        { value: '2019', text: '2019' },
        { value: '2020', text: '2020' },
        { value: '2021', text: '2021' },
        { value: '2022', text: '2022' },
        { value: '2023', text: '2023' },
        { value: '2024', text: '2024' },
      ],
      documents: null,
      financeurId: '',
      isFinanceurMultiple: false,
      financement: 'CHR',
      isFinanceurChr:false,
      rapportFinancementLoading: false,
      rapportEtablissementsLoading: false,
      rapportRegionsLoading: false,
      rapportPlaquetteLoading: false,

    }
  },

  mounted() {
    this.financeurId = this.state.user.financeurId.__id
    this.isFinanceurMultiple = this.state.user.financeurId.montantFinance > 0 && this.state.user.financeurId.montantFinanceOccas > 0
    this.financement = this.state.user.financeurId.montantFinance > 0 || this.isFinanceurMultiple ? "CHR" : "OCCAS"
    this.hasPlaquettes = this.state.user.plaquette.length > 0
    this.plaquettes = this.state.user.plaquette
    console.log(this.plaquettes)
    const types = { CHR: 'CHR', OCCAS: 'Occasionnels', COMMUNE: 'Commune'}
    
    for (let index = 0; index < this.plaquettes.length; index ++) {
      this.plaquetteTypes.push({ value: index, text: types[this.plaquettes[index].type] })
    }
    this.selectedPlaquette = 0

    var filter = new CFilter(CFilterType.OR)
    filter.add("Lien.type", 'DOC')
    if (this.isFinanceurMultiple)
      filter.add("Lien.type", 'DOC_COMMUN')
    else
      filter.add("Lien.type", 'DOC_CHR')


    kit.call(() => {
          kit.services.query({cclass: "Lien", filter: filter, json: true},
              (data) => {
              this.documentsGip = data
              },
              (error) => {
                kit.log.error("pb chargements docs liens")
              });
        }
    );
    var date = new Date(this.state.user.financeurId.dateAdhesion)
   this.documents = this.state.user.financeurId.documents.filter(doc => doc.origine !== 'INVISIBLE')
   
    // var docFilter = new CFilter(CFilter.AND)
    // docFilter.add("Membre.membreId", this.state.user.membreId)
    // kit.call(() => {
    //       kit.services.query({cclass: "Membre", filter: docFilter, json: true, depth: 2},
    //           (data) => {
    //           console.log(data)
    //           },
    //           (error) => {
    //             kit.log.error("pb member")
    //           });
    //     }
    // );
  },
  created() {
    // dateAdhesion ne marche pas parce que ca devrait etre la date
    // la plus ancienne de tous les territoires de la region !
    // let ans = []
    // var dateAdhesion = new Date(this.state.user.financeurId.dateAdhesion)
    const current = new Date().getFullYear() - 1
    // for (let i = dateAdhesion.getFullYear() ; i <= current; i ++) {
    //   ans.push({ value: String(i), text: String(i) })
    // }
    // this.annees = ans
    this.anneeRegion = String(current)

  },
  methods: {
    configRapportFinancement: function () {
      var cclass = kit.model.cclass('RapportFinanceur')
      this.reportDescription = cclass.help()
      this.$refs.rapportFinancementRef.show()
    },
    configRapportEtablissements: function () {
      var cclass = kit.model.cclass('RapportEtablissements')
      this.reportDescription = cclass.help()
      this.$refs.rapportEtablissementsRef.show()
    },
    configRapportRegional: function () {
      var cclass = kit.model.cclass('RapportRegions')
      this.reportDescription = cclass.help()
      this.$refs.rapportRegionsRef.show()
    },
    configPlaquette: function () {
      this.$refs.plaquetteRef.okDisabled = false
      this.$refs.plaquetteRef.show()
    },

    editerPlaquette: function (event) {

      event.preventDefault()
      this.$refs.plaquetteRef.okDisabled = true
      this.rapportPlaquetteLoading = true

      // pour l'instant pas de config
      var rapport = new CObject("RapportPlaquette")
      rapport.set("type", this.plaquettes[this.selectedPlaquette].type)
      // var xml = kit.services.write(rapport);

      this.check(rapport)
      // kit.dialogs.info("Cette fonctionnalité n'est pas encore implémentée")
      // kit.services.service2("report?reportId=Plaquette&cclass=Membre&reportType=pdf&id=" + this.state.user.membreId,
      //     {
      //       data: xml,
      //       onSuccess: (report) => {
      //         this.$refs.plaquetteRef.hide()
      //         this.editing = false
      //         window.open(window.location.origin + "/" + report.file, "_blank")
      //       },
      //       onError: (error) => {
      //         this.$refs.plaquetteRef.hide()
      //         this.editing = false
      //         kit.dialogs.error("Erreur lors de l'édition de la plaquette : " + error)
      //       }
      //     });
    },

    rapportFinancement: function (event) {
      // alert("kit services=" + kit.servicesUrl)
      event.preventDefault()
      this.rapportFinancementLoading = true
      var rapport = new CObject("RapportFinanceur")
      rapport.set("dateDebut", this.dateDebut)
      rapport.set("dateFin", this.dateFin)
      rapport.set("typeRapport", "COLLECTIVITE")
      rapport.set("versements", this.versements)
      rapport.set("details", this.details)
      rapport.set("dateCalcul", this.dateCalcul)
      rapport.set("typeFinancement", this.financement)
      
      //  on positionne un financeur bidon mais il est surchargé par la servlet, parce qu'on ne l'a pas !
      rapport.set("financeurId", "6")
      this.check(rapport)
    },

    rapportEtablissements: function (event) {
      event.preventDefault()
      this.rapportEtablissementsLoading = true
      var rapport = new CObject("RapportEtablissements")
      rapport.set("dateDebut", this.dateDebut)
      rapport.set("dateFin", this.dateFin)
      // rapport.set("typeRapport", "COLLECTIVITE")
      // rapport.set("versements", this.versements)
      // rapport.set("details", this.details)
      rapport.set("dateCalcul", this.dateCalcul)
      //  on positionne un territoire bidon, la servlet repositionnera le territoire du financeur
      rapport.set("territoires", "1")
      rapport.set("typeFinanceurs", this.financement)
      this.check(rapport)
    },
    rapportRegions: function (event) {
      event.preventDefault()
      this.rapportRegionsLoading = true
      var rapport = new CObject("RapportRegions")
      rapport.set("annee", this.anneeRegion)
      this.check(rapport)
    },
    editReport: function(rapport) {
       var xml = kit.services.write(rapport);
       kit.services.service2("report?reportId=" + rapport.cclass + "&cclass=Membre&reportType=pdf&id=" + this.state.user.membreId,
           {
         data: xml,
         onSuccess: (report) => {

           // this.$refs.plaquetteRef.hide()
           // this.editing = false
           // window.open(window.location.origin + "/" + report.file, "_blank")

           window.open(window.location.origin + "/" + report.file, "_blank")
           if (this.rapportFinancementLoading) {
             this.rapportFinancementLoading = false
             this.$refs.rapportFinancementRef.hide()
           } else if (this.rapportEtablissementsLoading) {
             this.rapportEtablissementsLoading = false
             this.$refs.rapportEtablissementsRef.hide()
           } else if (this.rapportRegionsLoading) {
             this.rapportRegionsLoading = false
             this.$refs.rapportRegionsRef.hide()
           } else if (this.rapportPlaquetteLoading) {
             this.rapportPlaquetteLoading = false
             this.$refs.plaquetteRef.hide()
           }
         },
         onError: (error) => {
           kit.dialogs.error("Erreur lors de l'édition du rapport : " + error)
           this.rapportFinancementLoading = false
           this.rapportEtablissementsLoading = false
           this.rapportRegionsLoading = false
           this.rapportPlaquetteLoading = false
         }
       });
    // window.open(kit.servicesUrl + "/report?reportId=" + "RapportFinanceur"
    //       + "&reportType=pdf&id=" + this.state.user.membreId + "&data=" + encodeURIComponent(xml), "_blank");
    },
    /** call check service with the object */
    check: function (rapport, callback) {
      kit.services.check(rapport, () => {
        this.editReport(rapport)
        // this.errors = null
        // callback(self.value)
      }, (error) => {
        // var errorFields = error.errorFields()
        // self.errors = error
        kit.dialogs.error("Erreur lors de la vérification : " + error)
        // alert("check failed")
        console.log("errors=" + error)
        this.rapportFinancementLoading = false
        this.rapportEtablissementsLoading = false
        this.rapportRegionsLoading = false
        this.rapportPlaquetteLoading = false
      })
    },
    download: function (doc) {
      // alert("download " + doc.nom + " url=" + doc.url)
      window.open("/download/" + doc.url)
    },
    downloadDocument(doc) {
      window.open(kit.servicesUrl + "/document?type=doc&ownerType=financeur&id=" + doc.fichier, "_blank")
    },
    showModallocal(){
      this.$bvModal.show("my-modal")
    },
    onFileUploaded(data) {
      this.documents.push(data);
      kit.dialogs.info("Le document a bien été déposé")
    },
    deleteDocument(doc) {
      kit.dialogs.confirm({
        action: "Supprimer",
        message: "Voulez-vous supprimer ce document ?",
        callback: (e) => {
          var rdoc = new CObject("Document", doc.documentId)
          kit.services.delete(rdoc, () => {
            //alert("suppression ok")
            var index = this.documents.indexOf(doc)
            this.documents.splice(index, 1)
          }, (error) => {
            kit.dialogs.error("erreur de suppression")
            kit.log.error("erreur suppression : " + error)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/*
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #8c67ef;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
        (
            "white": (
                $white,
                $black,
            ),
            "black": (
                $black,
                $white,
            ),
            "light": (
                $light,
                $light-invert,
            ),
            "dark": (
                $dark,
                $dark-invert,
            ),
            "primary": (
                $primary,
                $primary-invert,
                $primary-light,
                $primary-dark,
            ),
            "link": (
                $link,
                $link-invert,
                $link-light,
                $link-dark,
            ),
            "info": (
                $info,
                $info-invert,
                $info-light,
                $info-dark,
            ),
            "success": (
                $success,
                $success-invert,
                $success-light,
                $success-dark,
            ),
            "warning": (
                $warning,
                $warning-invert,
                $warning-light,
                $warning-dark,
            ),
            "danger": (
                $danger,
                $danger-invert,
                $danger-light,
                $danger-dark,
            ),
        ),
        $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
*/

.gip-container {
  padding-bottom: 5em;
}

.documents-container {
  align-content: flex-start;
  margin-bottom: 2em;
}

.cells {
  background: white;
  margin-top: 0.5em;
  border-radius: 0.3em;
}

#documents .documents-container:first-child {
  margin-right: 3em;
}

#documents .document-cell:not(:last-child) {
  border-bottom: solid 2px #04437010;
}

#documents {
  align-items: flex-start;
}
//@media (max-width: $size-medium) {
@media (max-width: 960px) {
  #documents .documents-container:first-child {
    margin-right: 0;
  }
  #documents {
    align-items: stretch;
  }
}
.docModalBtn{
  color:white;
  text-decoration: none;
  align-items: self-end;

}
//@import "~bulma";
//@import "~buefy/src/scss/buefy";
</style>