
<template>
  <div class="gip-bg-default">
  <div class="gip-max-width">
    <div class="head gip-mobile-padding">
      <h1 class="alternance"><span>Tout ce qu’il faut savoir</span><br/> sur le Gip Cafés Cultures</h1>
      <Sphere  couleur="grey"  top="20" left="450" taille="213"/>
    </div>
    <div class="filtre">

      <c-enum-field-view class="sss" field="Actualite.categorie"
                         @input="onFilterActualites" defaultLabel="Rechercher par catégorie" v-model="filtreActualite" type="list"/>

<div></div>
<!--      <input @change="onFilterActualites()" v-model="filtreActualite" type="text" placeholder="Rechercher par catégories">-->
      <ArrowDownIcon/>
    </div>

    <div class="actu-container">

      <CardActualite v-for="(actualite, index) in actualites" :data="actualite" :key="index"/>
    </div>
  </div>
    <div  @click="addActualites" class="charger-container">
      <ChargeIcon/>
      <p class="charger">CHARGER LA SUITE</p>
  </div>
  </div>
</template>

<script>
import Sphere from "../ui/Sphere"
import CardActualite from "../ui/CardActualite";
import ArrowDownIcon from "../../assets/svg/download_arrow_logo.svg"
import ChargeIcon from "../../assets/svg/plus_action_logo.svg"
import CFilter from '../../kit/data/CFilter.js'
import CEnumFieldView from "../../kit/vue/CEnumFieldView";
import kit from "../../kit/kit";
export default {
name: "Actualites",
  components: { ArrowDownIcon,Sphere,CardActualite,ChargeIcon,CEnumFieldView},
  data(){
    return{
      actualites:[],
      offset:0,
      categorie:null,
      categories:["vvv","vv","vv"],
      filtreActualite:null,
      limit:6
    }
  },
  methods:{
    addActualites(){
      kit.call(() => {
        var filter = new CFilter("Actualite.categorie", this.filtreActualite);
        var query = {cclass: 'Actualite', orderField:"dateCreation",reverse:true,json: true,limit:this.limit,limitOffset:this.offset ,filter:filter};
        kit.services.query(query,
            (set, req) => {
              this.actualites =  this.actualites.concat(set)
              this.offset += this.limit;
            },
            () => {
              kit.log.error("erreur actualite")
            }
        )
      });

    },
    onFilterActualites(value){
      this.offset = 0;
      console.log("vv")
      kit.call(() => {
        this.filtreActualite = value
        var filter = new CFilter("Actualite.categorie", value.toUpperCase());
        var query = {cclass: 'Actualite',orderField:"dateCreation",reverse:true,limit:this.limit,limitOffset:this.offset, json: true,filter:filter};
        kit.services.query(query,
            (set, req) => {
              this.actualites =  set
              this.offset += this.limit;
            },
            () => {
              kit.log.error("erreur onfilterActualites")
            }
        )
      });
    }
  },
  mounted() {
    this.addActualites();
  }
}
</script>

<style lang="scss" >

.head{
  text-align: center;
  position: relative;
  overflow-x: hidden;

  h1{
    position: relative;
    z-index: 5;
  }
}

.alternance{

  padding-top: 100px;
  padding-bottom: 100px;
}

.charger-container {
  text-align: center;
  cursor: pointer;

  .charger {
    font-size: 12px;
    color: var(--gip-disabled-color);
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

.filtre{
  padding: 13px 35px;
  background: white;
  max-width: 533px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  :focus{
    outline: none;
  }
  input {
    border:none;
    flex-grow: 1;
    color: var(--gip-tint-color);
    font-size: 16px;
    font-weight: 600;
    &::placeholder{
      color: var(--gip-tint-color);
    }
  }
}

.actu-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  text-align: center;
  width: 100%;
  div{
    margin-bottom: 20px ;
  }



}

 .sss {
   flex-grow:1;
   select  {
     border: none !important;
     font-size: 16px;
     color: var(--gip-tint-color);
     font-weight: 600;
     width: 100%;
   }
 }



</style>