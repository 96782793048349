<template>
  <login  titre="Espace financeur" :texte="description" login-label="Identifiant financeur"
         login-placeholder="Entrez votre identifiant de financeur" targetPage="DashboardFinanceur"
        type="Membre" @connect="connection"/>
</template>

<script>
import Login from "../ui/Login"
import GipMixin from '../ui/GipMixin.js'

export default {
  name: "LoginFinanceur",
  components: {Login},
  mixins: [GipMixin],

  data: function() {
    return {
      description: "Bienvenue dans l'espace financeur. Pour vous connecter, <br/>"
      + "indiquez votre identifiant ainsi que votre mot de passe"
    }
  },
  methods: {
    connection(user) {
      this.connectFinanceur(user)
      this.$router.push({name: "DashboardFinanceur"})
    },
  }
}
</script>

<style scoped lang="scss">

</style>