<script src="../../router/index.ts"></script>
<template>
  <div>
  <div class="photoInput" @dragover.prevent="onDragOver" @drop="onDrop">

    <div class="photo">
      <div  v-if="image !== null">
      <button @click="onClickRemove()">x</button>
    <img :src="image" style="cursor: pointer;" @click="isImageModalActive = true">

      </div>
      <div  v-if="loading === true" class="spinner">
        <div class="circle-1"></div>
      </div>
    <div v-if="image === null && loading === false">

    <input class="inputfile" :id="'file'+index+spectacleId" @change="onFileChange" accept="image/*" type="file"  />
      <label style="cursor: pointer;" :for="'file'+index+spectacleId"> <img class="img" src="@/assets/image/photoicon.png" alt=""></label>
       <div class="dropzone" @dragover.prevent @drop="onDrop">

            <p>Drag & Drop your files here or click to upload</p>

          </div>
    </div>
    </div>

  </div>
    <b-modal content-class="photoModal" v-model="isImageModalActive">
      <template v-slot:modal-header>
        <div></div>
      </template>
      <div class=" ">
        <img :src="image">
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import kit from "@/kit/kit";
import gipMixin from "@/components/ui/GipMixin";
import CObject from "@/kit/data/CObject";
export default { // TODO : renvoyer au component parent les nouvelles data dans this.spectacles (que ça update la row et donc le photo count aussi )
  name: "photoInput",
  mixins: [gipMixin],
  props:{
    index: 0,
    autoSend:true,
    doc: null,
    spectacleId: null
  },

  components: {},
  data() {
    return {
      triggered:false,
      image: null,
      longitude:  null,
      latitude:  null,
      isImageModalActive:false,
      loading : false,
      document:null,
    }
  },
  mounted() {
    let img = new Image();
    this.document = this.doc
    if(this.document) {
      this.loading = true
      img.onload = () => {
        this.image = img.src
        this.loading = false
      }

      img.src = "/services/document?type=image&ownerType=spectacle&id=" + this.document?.fichier
        + "&spectacleId=" + this.document.spectacleId
    }




  },
  methods:{
    onClickRemove(){
      if(this.autoSend){
        var rdoc = new CObject("PhotoSpectacle", this.document.photoId);
        kit.services.delete(
          rdoc,
          () => {
         this.removePic()
          },
          (error) => {
            kit.dialogs.error("erreur de suppression");
            kit.log.error("erreur suppression : " + error);
          }
      );
      }else{
        this.removePic()
      }

    },
    removePic(){
      this.image = null
      this.$emit('picRm',this.document)
    },
     sendFile(file,callback,errCallback) {

      var formData = new FormData();
      if (this.longitude && this.latitude) {
        formData.append("longitude", this.longitude);
        formData.append("latitude", this.latitude);
      }


      formData.append("file", file, file.name); // TODO : trouver comment recup le vrai nom du fichier
      formData.append("upload_file", true);
      formData.append("origine", "SITE")
      formData.append("typeDocumentId", 10)
      formData.append("spectacleId", this.spectacleId)
      formData.append("ordre", this.index)
      formData.append("ownerType","spectacle")

      formData.append("ownerId", this.state.user.employeurId)

      kit.services.service('upload',formData,callback,errCallback);

    },
     onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.processFiles(files);
    },
    processFiles(files) { 
   
      const fileSize = files[0].size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb > 10) {
        kit.dialogs.error('Fichier trop volumineux. La taille maximale autorisée est de 10 Mo.')
        return
      }
      
      this.loading = true
      navigator.geolocation.getCurrentPosition((pos) => {
        this.longitude = pos.coords.longitude
        this.latitude = pos.coords.latitude
        this.createImage(files[0]);
      }, () => {
        //alert('votre position est nécessaire pour valider la prise de photo')
        //this.loading = false
        this.createImage(files[0]);
      });

    },
    beforeUnloadListener(){
      return alert("dont pleas")
    },
     onDrop(e) {
            e.preventDefault(); 
      var files = e.dataTransfer.files;
      if (files.length) {
        this.processFiles(files);
      }
    },
    onDragOver(e) {
      e.preventDefault(); 
    },
    createImage(file) {

      var image = new Image();
      var reader = new FileReader();
      this.loading = true
      reader.onload = (e) => {
        window.addEventListener("beforeunload", this.beforeUnloadListener, { capture: true });
        if((file.size / 1024) / 1024 > 10  ){ // file.size retourne la taille du fichier en byte  on ne veux pas dépasser 10 MiB donc on divise pas 1024 x2
          this.image = null
          this.loading = false
          alert("Votre fichier ne doit pas dépasser 10MiB");
          return;
        }
        if(this.autoSend) {
          this.sendFile(file, (data) => {
            window.removeEventListener("beforeunload", this.beforeUnloadListener, {
              capture: true,
            });

            this.document = data
            console.log(this.document)
            this.$emit('picAdd', this.document)
            this.loading = false
            this.image = e.target.result;


          }, (err) => {
            alert(err)
            this.loading = false
            window.removeEventListener("beforeunload", this.beforeUnloadListener, {
              capture: true,
            });
          })
        }else{
          this.document = {
            photoId:'',
            fichier: file,
            latidude: this.latitude,
            longitude: this.longitude,
            ordre:this.index,
            date: new Date(),
          }
          this.$emit('picAdd', this.document)
          this.loading = false
          this.image = e.target.result;
        }



      };
      reader.readAsDataURL(file);
    },
  }
}
</script>

<style lang="scss" scoped>
.photoInput {
  position: relative;
  border-radius: 5px;
}
button {
  background: #e64d4d;
  position: absolute;
  right: -10px;
  top: -10px;
  color: white;
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  vertical-align: center;
  border-radius: 16px ;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #e64d4d;
}

.photo{
  overflow: hidden;
  border-radius: 5px;
  background: #f5f7fa;
  width: 130px;
  height: 110px;
  margin: 0px 0px 20px 20px;
}
img {
  display: block;
}

.spinner {
  width: 60px;
  height: 60px;
  margin: 20px auto;
  border-radius: 100%;
  position: relative;
  .circle-1 {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    border: calc(60px / 5) solid transparent;
    border-top-color:var(--gip-accent-color);
    border-bottom-color:var(--gip-tint-color);
    animation: half-circle-spinner-animation 0.9s infinite;
  }
  @keyframes half-circle-spinner-animation {

    100%{
      transform: rotate(360deg);
    }
  }

}
.photoModal{
  display: none;
}

</style>

<style >
td{
  color: var(--gip-tint-color);
  background: white;
}

</style>