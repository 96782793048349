// le header à fond bleu utilisés dans les différents back
// prend title en props et un composant en slot
<template>
<hbox class="back-header2">
  <slot/>
</hbox>
</template>

<script>
import GipMixin from '../../ui/GipMixin.js'
export default {
  name: "BackHeader2",
  mixins: [GipMixin],
}
</script>

<style>

.back-header2 {
  background-color: var(--gip-tint-color);
  color: var(--gip-text-constrast-color);
  border-radius: 5px;
  padding: 0.7em 1.5em;
  align-items: center;
}

.back-header2 h1 {
  font-weight: normal;
  font-size: 1.3em;
  margin-right: 1em;
  margin-top:0;
  margin-bottom:0;
  display: inline;
}

.back-header2 > :not(:first-child) {
  margin-left: 2em;
}
</style>