
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CDateField. Javascript type is Date.
 * @class com.omniapp.kit.model.field.CDateField
 * @extends com.omniapp.kit.model.CField
 */
function CDateField(cclass, cfield) {

    CField.call(this, cclass, cfield, 'CDateField');

    var _type = null;

    /** return type, one of DATE, DATE_TIME, TIME */
    this.type = function() {
        if (this._type == null)
            this._type = this.node.attr('type');
        if (this._type === undefined)
            this._type = 'DATE';
        return this._type;
    };

    /** retrieve date display format */
    this.displayFormat = function() {
        var t = this.type();
        if (t === 'DATE')
            return 'dd/mm/yyyy';
        else if (t === 'DATE_TIME')
            return 'dd/mm/yyyy HH:MM';
        else if (t === 'TIME')
            return 'HH:MM';
        else throw new Error('bad type in CDateField');
    };

    /** retrieve date format */
    this.format = function() {
        var t = this.type();
        if (t === 'DATE')
            return 'yyyy-mm-dd';
        else if (t === 'DATE_TIME')
            return 'yyyy-mm-dd HH:MM:ss';
        else if (t === 'TIME')
            return 'HH:MM';
        else throw new Error('bad type in CDateField');
    };


    /** String -> Date. beware, only manage sql notation (yyyy-mm-dd [hh:mm]) */
    this.convert = function(val) {
        if ((val === undefined) || (val === null) || (val === ''))
            return null;
        else if (val instanceof Date)
            return val;
        else {
            // use DateExt.parseDate
           //  console.log(val) // ex 2021-11-13

            //! on vire la ligne car pb de formalisme de date
          //  var d = Date.parseDate(val, val.contains("/") ? this.displayFormat() : this.format());

             val =   val.split(' ')
            let time = val[1];
            val =   val[0].split('-')

            var d = new Date(0);
            d.setFullYear(val[0])
            d.setMonth(val[1]-1)
            d.setDate(val[2])
           // console.log((d))
           // console.log(d.toLocaleDateString('fr-FR'))

            return d;
        }
    };

    /**
     * Date -> display String. we take an optionnal second parameter that gives type, to
     * force conversion. if not set use intrinsec type
     */
    this.displayValue = function(val, f) {
        if (val == null)
            return '';
        return val.format(f ? f : this.displayFormat());
    };
    /** Date -> String */
    this.stringValue = function(val) {
        if (val == null)
            return null;
        console.log(val)
        return new Intl.DateTimeFormat('fr-FR').format(val);
    };

} // end class CDateField

export default CDateField


