import Vbox from '../ui/VBox.vue'
import Hbox from '../ui/HBox.vue'
import IconBase from './IconBase'
import KField from "./KField";
import DialogIcon from '../../assets/icons/dialog-icon'
import EuroIcon from '../../assets/icons/euro-icon'
import PersonneIcon from '../../assets/icons/personne-icon'
import HandIcon from '../../assets/icons/hand-icon.svg'
import HomeIcon from '../../assets/icons/home-icon.svg'
import InfoSmallIcon from '../../assets/icons/info-small-icon.svg'
import InfoSmallPinkIcon from '../../assets/icons/info-small-pink-icon.svg'

import LockIcon from '../../assets/icons/lock-icon.svg'
import WarningIcon from '../../assets/icons/warning-icon.svg'
import CloseIcon from '../../assets/icons/close-icon.svg'

import EditIcon from '../../assets/icons/edit-icon.svg'
import DownloadIcon from '../../assets/icons/download-icon.svg'

import AddSmall from '../../assets/icons/add-small.svg'
import DownloadSmall from '../../assets/icons/download-small.svg'
import EyeSmall from '../../assets/icons/eye-small.svg'
import EyeStrokeSmall from '../../assets/icons/eye-stroke-small.svg'
import EditSmall from '../../assets/icons/edit-small.svg'
import TrashSmall from '../../assets/icons/trash-small.svg'

import Sphere from "@/components/ui/Sphere";
import BtnAction from "@/components/ui/BtnAction";

// import Utilities from '../../kit/misc/Utilities.js'
import moment from 'moment'
import kit from '../../kit/kit'

import CObject from '../../kit/data/CObject'
import CObjectView from '../../kit/vue/CObjectView'
import CServicesProvider from "@/kit/data/CServicesProvider";

import Vue from 'vue'

const setCookie = function (cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = exdays != 0 ? ";expires=" + d.toUTCString() : "";
    document.cookie = cname + "=" + cvalue + expires + ";path=/"; //+ ";" + expires
}


export const getCookie = function (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export const state = Vue.observable({
    user: null,
    site: null,
    newFeature: false,
    isFinanceur: getCookie("financeur") != '',
    isEmployeur: getCookie("employeur") != ''
    // isFinanceur: false,//getCookie("financeur") != '',
    // isEmployeur: false//getCookie("employeur") != ''
})

export const connectEmployeur = (user) => {
    setCookie("employeur", "true", 0)

    state.user = user
    state.isEmployeur = true
    state.isMembre = false;

}
export const connectFinanceur = (user) => {
    setCookie("financeur", "true", 0)
    state.user = user
    state.isEmployeur = false
    state.isFinanceur = true
}


export const disconnect = () => {

    // on supprime les cookies
    setCookie("employeur", "", -1)
    setCookie("financeur", "", -1)


    state.user = null
    state.isFinanceur = false
    state.isEmployeur = false
    kit.services.service("auth?action=logout", null, () => {
        // self.$cookie.remove('sid')
        state.user = null

    }, () => {
        alert("pb deco")
    });
}





export default {
    components: {

        // composants de base
        Hbox, Vbox, IconBase, KField,

        // les vues d'OmniKit
        CObjectView,
        // les icones
        DialogIcon, EuroIcon, PersonneIcon, HandIcon, HomeIcon,
        WarningIcon, CloseIcon, LockIcon,
        EditIcon, DownloadIcon, InfoSmallIcon, InfoSmallPinkIcon,

        AddSmall, DownloadSmall, EditSmall, EyeStrokeSmall, EyeSmall, TrashSmall,

        // composants du projet
        Sphere, BtnAction
    },

    data() {
        return {
            state,
        }
    },
    props: {
        // utilisateur connecté, peut etre un Employeur ou un Membre
        // state
    },

    mounted() {
    // v
    },
    methods: {
        connectEmployeur,
        connectFinanceur,
        disconnect,

        postLoad: function (callback) {
           // this.site = this.state.site
            this.newFeature = this.state.newFeature
            // n'appelle le service que si besoin, une fois qu'il est dans le contexte c'est ok
            if (this.state.site == null) {
                kit.call(() => {
                    kit.services.query({cclass: 'SiteGip', json: true, depth: 3},
                        (data) => {
                            this.state.site = data[0]
                            this.state.newFeature = this.state.site.dev == "O";
                            // alert("montantMinimumBrut=" + this.site.montantMinimumBrut)
                            // Vue.prototype.$options = data[0]
                            // console.log("BIM LE SITE " + this.site.montantMinimumBrut)
                            if (callback !== null)
                                callback()
                        },
                        (error) => {
                            alert("erreur chargement donnés du site")
                        }
                    )
                })
            }
        },

        isConnected: function () {
            // return this.state.user != null
            return getCookie("financeur") != '' || getCookie("employeur") != ''
        },
        // connect: function() {
        //     this.isMembre = true
        // },
        // disconnect: function() {
        //     this.isMembre = false
        // },
        // isMembre: function(){
        //     return true
        // },

        isEmployeur: function () {
            // return this.state.user != null && this.state.isEmployeur
            return getCookie("employeur") != ''
        },
        isFinanceur: function () {
            // return this.state.user != null && this.state.isFinanceur
            return getCookie("financeur") != ''
        },
        // formate un double avec 2 décimales
        format2: function (value) {
            if (!value)
                return '';
            value = Number.parseFloat(value)
            if (Number.isInteger(value))
                return value.format('', ' ', 0)
            else {
                return value.format(',', ' ', 2);
            }
        },
        // formate un prix avec €, 'Prix non defini' si pas défini
        formatPrice(price) {
            if (!price || price.length == 0)
                return "Non défini"
            else
                return price + "  €";
        },
        // formate une date dd/MM/yyyy
        formatDate: function (str, format) {
            if (str) {
                moment.locale('fr')
                var date = moment(str)
                return date.format(format ? format : 'DD/MM/YYYY')
            } else {
                return ''
            }
            // console.log("format date : " + str + " Date.parse=" + Date.parseDate(str, "yyyy-MM-dd")
            //     + "isDate=" + (Date.parseDate(str, "yyyy-MM-dd") instanceof Date)
            // + " formatted=" + Date.parseDate(str, "yyyy-MM-dd").format("dd/MM/yyyy"))
            // // return "xx"
            // if (str) {
            //     var date = null;
            //     // if (str.length <= 10)
            //         date = Date.parseDate(str,"yyyy-MM-dd");
            //     // else
            //     //     date = Date.parse("yyyy-MM-dd hh:mm:ss");
            //     return date.parseDate(str, "dd/MM/yyyy")
            //     // return new Intl.DateTimeFormat('fr').format(date)
            // }
            // else
            //     return ''
        },

        /**
         * Convert
         */
        convert: function (field, value) {
            return kit.model.field(field).convert(value)
        },

        /**
         *  formate un champ du modèle
         *  @param field de la forme 'NomClasse.nomField'
         *  en java : CClass.get("NomClasse").getField("nomField")
         *  en js : kit.model.field("NomClasse.nomField")
         */
        display: function (field, value) {
            // init time


            if (!kit.model)
                return ''
            var tfield = kit.model.field(field)//('Procedure').field('regime')
            if (!tfield)
                alert("field " + field + " inconnu")
            // console.log(field)
            //
            // console.log(tfield)

            return tfield.displayValue(tfield.convert(value))
        },

        /** liste tous les KField contenus dans les refs de la vue passée en paramètre */
        getFields: function (vue) {
            var kfields = []
            for (var r in this.$refs) {
                if (vue.$refs[r].$options.name === "KField")
                    kfields.push(r)
            }
            return kfields
        }
    },
}