*
/**
Header prend le div en argument et le colle dans le slot
*/
<template>

      <div id="espaceHeader" class="header" >
        <hbox v-if="this.state.site.avecBandeauInfo==='O'" id="infos" class="flex-grow-1"
        v-html="texteBandeau()">

        </hbox>

        <div class="espace hidden-small">
          <div class="gip-max-width">
            <hbox class="espace-container justify-content-end align-items-center">
              <vbox class="custom-flex-grow"></vbox>
              <vbox class="flex-grow-1">
              <hbox class="flex-grow-1 space-between">
                <hbox>
              <router-link  class="btn-nav" to="/financeur" v-if="!isConnected() || (isFinanceur() && !isInFinanceur())">Espace financeur</router-link>
              <router-link :to="{name: 'DashboardEmployeur' }" v-if="!isConnected() || (isEmployeur() && !isInEmployeur())"
                           class="btn-nav" id="espace-employeur">Espace employeur</router-link>
                </hbox>
                <hbox>
              <div v-if="isFinanceur() || isEmployeur()" @click="disco">
                <LockIcon/>
                <a id="disconnect">Se déconnecter</a>
              </div>
                </hbox>
              </hbox>
              </vbox>

            </hbox>



          </div>

        </div>


  </div>
</template>

<script>
import GipCafesCulturesLogo from "@/components/Icons/GipCafesCulturesLogo";
import GipMixin from '../ui/GipMixin.js'

// import {disconnect, state} from "@/components/ui/GipMixin";

export default {
  name: "Header",
  mixins: [GipMixin],
  data() {
    return {
       isMenuOpen: false,
      site:null,
    }
  },
  created() {
  this.site = this.state.site
  },
  computed : {
},
  props:{
  },


  methods: {

    texteBandeau() {
      return this.state.site.bandeauInfo.replaceAll('\\n', '<br>')
    },
    disco() {
      this.disconnect()
      this.$router.push({name:"Home"})
    },
    isInFinanceur: function() {
      return this.$route.fullPath.includes('financeur')
    },
    isInEmployeur: function() {
      // alert("isInEmployeur = " + this.$route.fullPath)
      return this.$route.fullPath.includes('employeur')
    }

  },

}
</script>

<style lang="scss" scoped>


#infos {
  justify-content: center;
//  background: var(--gip-action-color);
  background: var(--gip-accent-color);
  color: white;
  padding: 0.2em;
  font-weight: 600;
  text-align: center;
}


.espace {
  background: var(--gip-tint-color);
  height: 55px;
  text-align: right;

  .espace-container {
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .custom-flex-grow {
    flex-grow: 25;
  }


}





a {
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: white;
  }
}


#espace-employeur {
  background: var(--gip-accent-color);
  //margin-right: 1em;
}

.header {
  box-shadow: 0px 3px 23px #0443700F;
  background: white;
}




</style>

// non scoped pour modifier la bar de navigation
<style lang="scss" >

  .btn {
    background: var(--gip-accent-color);
    padding: 8px;
    color: white;
    font-size: 12px;
    border-radius: 5px;

    a {
      color: white;
    }

    &.router-link-exact-active {
      color: white;

      &:after {
        background: white;

      }
    }
  }




#disconnect {
  color: var(--gip-white-color);
  margin-left: 0;
  padding-left: 1em;
  padding-right: 1em;
  //margin-left:1em;
}

</style>