<template>
  <vbox class="gip-background">

    <vbox class="gip-page max-100">
      <vbox class="gip-container max-100">
        <div class="login-container">
          <Sphere  bottom="60" right="-60"  taille="213"/>
          <Sphere  top="40" left="-50"  couleur="grey" taille="213"/>
          <div class="sphere-container">
            <h1>
              Mot de passe oublié
            </h1>
            <p>{{ infoText }}</p>
            <b-form class="vbox">
              <k-field :label="fieldLabel" ref="identifiant" class="fill">
<!--              <b-form-group id="login-group" label="Nº Guso ou mail" label-for="login" class="gip-label">-->
                <b-form-input
                    id="login" ref="login"
                    v-model="login"
                    :placeholder="loginPlaceholder"
                    required
                ></b-form-input>
              </k-field>


              <BtnAction :click="resetPassword" :withLogo="false" class="fill connect"
                         titre="Envoyer un mail de récupération" id="send-action"></BtnAction>
            </b-form>

          </div>
        </div>
      </vbox>
    </vbox>
  </vbox>
</template>

<script>

import GipMixin from "../ui/GipMixin";
import Sphere from "../ui/Sphere";
import BtnAction from "../ui/BtnAction";
import kit from "../../kit/kit";

export default {
  mixins: [GipMixin],
  components: {Sphere, BtnAction},
  name: "ResetPassword",
  data() {
    return {
      // password: null,
      login: null,
      // error: null
    }
  },
  props: {
    // Employeur ou Membre
    type: null
  },
  methods: {
    resetPassword(e) {
      if (!this.login || this.login.length < 3) {
        this.$refs.identifiant.setError("Veuillez renseigner votre identifiant")
        return
      }

      kit.services.service2('auth?action=resetPassword', {
        params: {login: this.login, type: this.type},
        onSuccess: (data) => {
            kit.dialogs.info("Le mail a été envoyé, vérifiez votre boîte de réception")
            this.$router.push({name:"Home"})
        },
        onError: (err) => {
          let message = "erreur de réinitialisation"
          if (err.data && err.data[0] && err.data[0].message)
            message = err.data[0].message
          kit.dialogs.error(message)
        }
      });
    },
  },
  computed: {
    fieldLabel() {
      return this.type === "Employeur" ? "Nº GUSO" : "Mail"
    },
    loginPlaceholder() {
      return this.type === "Employeur" ? "Votre numéro GUSO" : "Votre adresse mail"
    },
    infoText() {
      let id = this.type === "Employeur" ? "numéro GUSO" : "adresse mail"
      return "Renseignez votre " + id  + ", nous vous enverrons un courrier vous permettant de réinitialiser votre mot de passe."
    }
  }
}
</script>

<style lang="scss" scoped>

.login-container {
  margin-top: 3em;
  margin-bottom: 5em;
  //padding: 5em;
  //background: var(--gip-white-color);
  max-width: 100%;
  width: 600px;
  align-self: center;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.login-container h1 {
  color: var(--gip-accent-color);
  font-size: 1.5em;
}

.max-100{
  max-width: 100%;
}

.sphere-container {
  z-index: 1;
  position: relative;
  padding: 4em;
  background: var(--gip-white-color);
}

.password-action a {
  padding-right: 0 !important;
}
.connect {
  text-align: center;
}

.pas-inscrit {
  align-items: center;
  padding-top: 1em;
}

.pas-inscrit-action  a {
  padding: 0.5em 0 0 0.5em !important;
  margin: 0 !important;
}

#send-action {
  margin-top: 1em;
}
</style>