<template>
<h1 class="" id="label-404">La page que vous demandez est introuvable.</h1>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped lang="scss">

#label-404 {
  text-align: center;
  margin: 5em;
  background: inherit;
}
</style>