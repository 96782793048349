<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.857" height="9.988" viewBox="0 0 11.857 9.988">
    <g fill="#AB7C94" id="noun_Arrow_1256499" transform="translate(30.825 33.809) rotate(180)">
      <path id="Tracé_40" :transform="'rotate('+rotate+',24.90,28.80)'"  data-name="Tracé 40"  d="M30.825,28.825a.765.765,0,0,0-.765-.765H21.675l3.108-2.9a.765.765,0,1,0-1.044-1.116l-4.528,4.218a.765.765,0,0,0,0,1.118L23.74,33.6a.765.765,0,0,0,1.044-1.118l-3.108-2.9h8.385A.765.765,0,0,0,30.825,28.825Z"  :fill="color"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: "FlecheLogo",
  props:{
    color:{
      type:String,
      default: "#FFFFFF"
    },rotate:{
      type:String,
      default: "0"
    }
  }
}
</script>

