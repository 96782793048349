<template>
  <svg
      enable-background="new 0 0 128 128"
      height="128px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 128 128"
      width="128px"
      xml:space="preserve"
      sodipodi:docname="1164349_circle_instagram_logo_media_network_icon.svg"
      inkscape:version="1.3.2 (091e20e, 2023-11-25)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"><defs
     id="defs6"><inkscape:path-effect
       effect="powerclip"
       id="path-effect9"
       is_visible="true"
       lpeversion="1"
       inverse="true"
       flatten="false"
       hide_clip="false"
       message="Utilise la règle de remplissage de la boîte de dialogue &lt;b&gt;Fond et contour&lt;/b&gt; en l'absence de résultat de mise à plat après une conversion en chemin." /><inkscape:path-effect
      effect="powerclip"
      id="path-effect7"
      is_visible="true"
      lpeversion="1"
      inverse="true"
      flatten="false"
      hide_clip="false"
      message="Utilise la règle de remplissage de la boîte de dialogue &lt;b&gt;Fond et contour&lt;/b&gt; en l'absence de résultat de mise à plat après une conversion en chemin." /><inkscape:path-effect
      effect="powerclip"
      id="path-effect1"
      is_visible="true"
      lpeversion="1"
      inverse="true"
      flatten="false"
      hide_clip="false"
      message="Utilise la règle de remplissagee la boîte de dialogue &lt;b&gt;Fond et contour&lt;/b&gt; en l'absence de résultat de mise à plat après une conversion en chemin." /><clipPath
      clipPathUnits="userSpaceOnUse"
      id="clipPath1"><g
         id="g1"
         style="display:none"><path
           d="M 82.333,104 H 45.667 C 33.72,104 24,94.281 24,82.333 V 45.667 C 24,33.719 33.72,24 45.667,24 H 82.333 C 94.281,24 104,33.719 104,45.667 V 82.334 C 104,94.281 94.281,104 82.333,104 Z M 45.667,30.667 c -8.271,0 -15,6.729 -15,15 v 36.667 c 0,8.271 6.729,15 15,15 h 36.666 c 8.271,0 15,-6.729 15,-15 V 45.667 c 0,-8.271 -6.729,-15 -15,-15 z"
           fill="#ffffff"
           id="path1" /></g><path
      id="lpe_path-effect1"
      class="powerclip"
      d="M -5,-5 H 133 V 133 H -5 Z M 82.333,104 C 94.281,104 104,94.281 104,82.334 V 45.667 C 104,33.719 94.281,24 82.333,24 H 45.667 C 33.72,24 24,33.719 24,45.667 V 82.333 C 24,94.281 33.72,104 45.667,104 Z M 45.667,30.667 c -8.271,0 -15,6.729 -15,15 v 36.667 c 0,8.271 6.729,15 15,15 h 36.666 c 8.271,0 15,-6.729 15,-15 V 45.667 c 0,-8.271 -6.729,-15 -15,-15 z" /></clipPath><clipPath
      clipPathUnits="userSpaceOnUse"
      id="clipPath6"><g
         id="g7"
         style="display:none"><circle
           cx="85.25"
           cy="42.75"
           fill="#ffffff"
           r="4.5830002"
           id="circle6" /></g><path
      id="lpe_path-effect7"
      class="powerclip"
      d="M -5,-5 H 133 V 133 H -5 Z m 94.833,47.75 a 4.5830002,4.5830002 0 0 0 -4.583,-4.583 4.5830002,4.5830002 0 0 0 -4.583,4.583 4.5830002,4.5830002 0 0 0 4.583,4.583 4.5830002,4.5830002 0 0 0 4.583,-4.583 z" /></clipPath><clipPath
      clipPathUnits="userSpaceOnUse"
      id="clipPath8"><g
         id="g9"
         style="display:none"><path
           d="M 64,84 C 52.972,84 44,75.027 44,64 44,52.971 52.972,44 64,44 c 11.028,0 20,8.971 20,20 0,11.027 -8.972,20 -20,20 z m 0,-33.333 c -7.352,0 -13.333,5.981 -13.333,13.333 0,7.353 5.981,13.333 13.333,13.333 7.352,0 13.333,-5.98 13.333,-13.333 0,-7.352 -5.98,-13.333 -13.333,-13.333 z"
           fill="#ffffff"
           id="path8" /></g><path
      id="lpe_path-effect9"
      class="powerclip"
      d="M -5,-5 H 133 V 133 H -5 Z M 64,84 C 75.028,84 84,75.027 84,64 84,52.971 75.028,44 64,44 52.972,44 44,52.971 44,64 c 0,11.027 8.972,20 20,20 z m 0,-33.333 c -7.352,0 -13.333,5.981 -13.333,13.333 0,7.353 5.981,13.333 13.333,13.333 7.352,0 13.333,-5.98 13.333,-13.333 0,-7.352 -5.98,-13.333 -13.333,-13.333 z" /></clipPath></defs><sodipodi:namedview
      id="namedview6"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      inkscape:showpageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="true"
      inkscape:deskcolor="#d1d1d1"
      showgrid="false"
      inkscape:zoom="6.875"
      inkscape:cx="64"
      inkscape:cy="52.363636"
      inkscape:window-width="1472"
      inkscape:window-height="1051"
      inkscape:window-x="2241"
      inkscape:window-y="188"
      inkscape:window-maximized="0"
      inkscape:current-layer="g8" /><g
      id="g10"><g
       id="g8"
       clip-path="url(#clipPath8)"
       inkscape:path-effect="#path-effect9"><g
         id="g6"
         clip-path="url(#clipPath6)"
         inkscape:path-effect="#path-effect7"><g
           id="g2"
           clip-path="url(#clipPath1)"
           inkscape:path-effect="#path-effect1"><linearGradient
             gradientTransform="matrix(1 0 0 -1 594 633)"
             gradientUnits="userSpaceOnUse"
             id="SVGID_1_"
             x1="-566.7114"
             x2="-493.2875"
             y1="516.5693"
             y2="621.4296"><stop
               offset="0"
               style="stop-color:#ffffff;stop-opacity:1;"
               id="stop1" /><stop
      offset="1"
      style="stop-color:#ffffff;stop-opacity:1;"
      id="stop2" /></linearGradient><circle
      cx="64"
      cy="64"
      fill="url(#SVGID_1_)"
      r="64"
      id="circle2"
      d="M 128,64 A 64,64 0 0 1 64,128 64,64 0 0 1 0,64 64,64 0 0 1 64,0 64,64 0 0 1 128,64 Z" /></g></g></g></g></svg>




</template>

<script>
export default {
  name: "LinkedinLogo",
}
</script>

