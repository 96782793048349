<template>
    <div class="c-enum-field-view">
        <!-- type = DATE -->
        <b-datepicker v-if="type === 'DATE'" v-model="date"></b-datepicker>
        <!-- type = TIME -->
            <b-timepicker v-if="type === 'TIME'" v-model="time"></b-timepicker>
        <!-- type = DATE_TIME -->
        <b-datepicker v-if="type === 'DATE_TIME'" v-model="datetime"></b-datepicker>
    </div>
</template>

<script>
    import kit from '../kit'
    export default {
        name: "CDateFieldView",
        data() {
            return {
                 date: null,
                time: null,
                datetime: null
                // multipleModel: [],
                // singleModel: ''
            }
        },
        props: {
            field: {
                type: String,
                default: function() {
                  return null
                }
            }
        },
        methods: {
            isDefault: function(id) {
                var fieldDefault = this.defaultValue
                kit.log.log('default=' + fieldDefault + ' id=' + id)
                return fieldDefault && fieldDefault.id === id ? 'selected' : ''
            },
            value: function() {
                if (this.type === 'DATE')
                    return kit.model.field(this.field).value(this.date)
                else if (this.type === 'TIME')
                    return kit.model.field(this.field).value(this.time)
                else if (this.type === 'DATE_TIME') {
                    return kit.model.field(this.field).value(this.datetime)
                }
            }
        },
        computed: {
            name: function() {
                // return this.field
                return kit.model.field(this.field).name()
            },
            type: function() {
                return kit.model.field(this.field).type()
            },
            values: function() {
                return kit.model.field(this.field).values()
            },
            defaultValue: function() {
                return kit.model.field(this.field).defaultValue()
            }
        },
        mounted() {
            // kit.log.log('value(0)=' + kit.model.field(this.field).valueAt(0))
            // this.switchModel = this.defaultValue ===  kit.model.field(this.field).valueAt(0)
            // this.singleModel = this.defaultValue ? this.defaultValue.id : null
        }
    }
</script>

<style scoped>

</style>

