<template>
  <b-modal id="wizardModal" :hide-footer="true" v-model="show" :size="size">
    <LoadingOverlay :show="showLoadingOverlay"/>
    <b-container fluid>
      <div class="wizard-container">
        <h1 class="wizardTitle">{{ titleLabel }}</h1>
        <p class="wizardDescription">
          {{ description }}
        </p>
        <slot></slot>
        <hbox class="wizardButtons justify-center">
            <BtnAction :class="{ invisible: panelStack.length < 2 }" class="wizardButton wizardPreviousButton" titre="PRÉCÉDENT" :click="previous" :withLogo="false" type="outline"/>
            <BtnAction class="wizardButton wizardNextButton" :titre="nextButtonTitle" :click="next" :withLogo="false" :disabled="nextButtonDisabled"/>
        </hbox>
      </div>
    </b-container>
    <template #modal-footer>
      
     </template>
    
  </b-modal>  
</template>

<script>
import GipMixin from '../GipMixin.js'
import BtnAction from '../BtnAction.vue'
import { computed } from 'vue'
import LoadingOverlay from '../LoadingOverlay.vue'

export default {
  name: "Wizard",
  components: { BtnAction, LoadingOverlay },
  mixins: [ GipMixin ],
  props: ['value', 'firstPanel', 'title', 'showLoadingOverlay'],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    currentPanel() {
      return this.panels[this.currentPanelIndex]
    },
    titleLabel() {
      return this.currentPanel?.title ?? this.title
    },
    description() {
      return this.currentPanel?.description ?? ""
    },
    size() {
      return this.currentPanel?.size ?? 'md'
    },
    nextButtonDisabled() {
      if(this.currentPanel) {
        return !this.currentPanel.isValid
      }
      return false
    },
    nextButtonTitle() {
      if(this.currentPanel && this.currentPanel.nextButton) {
        return this.currentPanel.nextButton
      }
      return "SUIVANT"
    }

  },
  data() {
    return {
      initialized: false,
      panelStack: [],
      currentPanelIndex: "",
      panels: {}

    }
  },
  methods: {
    addPanel(p) {
      this.panels[p.nom] = p
      this.reset()
    },
    cancel() {
      this.show = false
    },
    next() {
      this.$emit('next', this)
    },
    previous() {
      if (this.panelStack.length > 1) {
        this.panelStack.splice(-1)
        this.setPanel(this.panelStack[this.panelStack.length -1])
      }
    },
    pushPanel(nom) {
    this.panelStack.push(nom)
    this.setPanel(nom)
    },
    setPanel(nom) {
      for (const index in this.panels) {
        const panel = this.panels[index]
        //panel.$el.style.display = panel.nom == nom ? "block" : "none"
      }
      this.currentPanelIndex = nom
    },
    reset() {
      this.panelStack = []
      this.pushPanel(this.firstPanel)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.$emit('reset')
      this.panels = {}
      // this.$nextTick(() => {
      //   this.panels = {}
      //   for (const slot of this.$slots.default) {
      //     const component = slot.componentInstance
      //     if (component && component.$options.name === 'WizardPanel') {
      //       this.addPanel(component)
      //     }
      //   }
      //   this.reset()
      // }) 
    })
  },
  provide() {
    return {
      currentPanel: computed(() => this.currentPanelIndex),
      addPanel: this.addPanel
    }
  },

}

</script>

<style lang="scss" scoped>
.modal-header {
  padding-bottom: 0
}
.modal-footer {
  display: none
}
.wizard-container {
  margin: 0 40px 40px 40px;
}
.wizardTitle {
  color: var(--gip-accent-color);
  font-size: 1.5em;
}
.wizardDescription {
  font-size: 0.9em;
}
.wizardButtons {
  margin-top: 30px;
}
.wizardButton {
  flex-grow: 1;
  max-width: 230px;
}
.wizardNextButton {
  margin-left: 1em;
}
.wizardPreviousButton {
  margin-right: 1em;
}
.invisible {
    visibility: hidden;
}
</style>

<!-- 
Utilisation du Wizard
exemple:
<Wizard v-model="showRegisterWizard" first-panel="essai1" title="Créer mon compte" @next="next" @reset="reset">
    <WizardPanel nom="essai1" description="Afin de créer votre compte nous avons besoin de quelques infos vous concernant" :isValid="type != null">  
        <Essai1 v-model="type"/>
    </WizardPanel>
    <WizardPanel nom="essai2" description="blalalal" :isValid="this.autreDatas.ca === true && this.autreDatas.salaries === true">
        <Essai2 v-model="autreDatas" />
    </WizardPanel>
    <WizardPanel nom="essai3" description="ghsghsgh jkjl k bji bhb " nextButton="TERMINER" :isValid="this.communeDatas.habitants === true && this.communeDatas.guso !== ''">
        <Essai3 v-model="communeDatas"/>
    </WizardPanel>
</Wizard>

Wizard:
v-model du wizard est un booléen qui affiche une modale contenant le wizard
title titre affiché en haut des vues
first-panel designe la première page à afficher
reset est une fonction  appelée à chaque fois qu'on affihce le wizard
next est une fonction qui est appelé quand on clique sur "suivant", elle a en paramètre le composant wizard.
On peut récupérer le nom de la page courant avec currentPanelIndex et, aller à une nouvelle page avec pushPanel(nom)
exemple:
next(wizard) {
    switch (wizard.currentPanelIndex) {
    case "essai1":
        wizard.pushPanel("essai2")
        break;
    case "essai2":
        wizard.pushPanel("essai3")
        break;
......


WizardPanel:
A l'intérieur, chaque page doit être représentée par un composant encapsulé dans un WizardPanel dont les propriétés sont
- nom(désignation), 
- title(titre affiché en haut, remplace le titre du wizard), 
- description (description en dessous du titre)
- nextButton(si on veut que le label du bouton soit différent de "suivant", typiquement pour la dernière page)
- isValid (booléen qui indique si on peut passer à l'étape suivante)


Le plus pratique pour partager des données est d'utiliser v-model dans le composant, pour ce faire on utilise la méthode suivante:
props: ['value'],
  computed: {
    variable: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  }
variable est la variable utilsée à l'intérieur du composant

-->