<template>
  <input v-model="data"   :style="style" type="range" id="salaire" name="montantRange"  :min="min" :max="max" class="slider">

</template>

<script>
export default {
  name: "Range",
  props:
      {
        value:{
        type: Number,
          default: 0
        },

        min: {
          type: Number,
          default: 0,
        },
        max: {
          type: Number,
          default: 200,
        }
      },

  computed:{
    style(){
      var val = ((this.data) * 100) / (this.max-this.min)
        val = Math.ceil(val)
      //this.$emit('update',Number(this.data))
        let range = document.getElementById("#salaire")

      return{
        "background-image": '-webkit-gradient(linear,  0% 100%, 100% 100%,  color-stop('+ (val)+'%, rgb(194, 34, 146)), color-stop('+val +'%, #11436D24))'
      }

    },
    data : {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('update',Number(newValue))

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  padding-right: 0px;
  padding-left: 0px;
  box-shadow: none;
  margin-right: 20px;
  -webkit-appearance: none;
  width: 345px;
  -moz-apperance: none;
  border-radius: 7px;
  border: 0px solid black;
  margin-top: -1px;
  height: 11px;
  color: var(--gip-action-color);
  background-image: -webkit-gradient(
          linear,
          0% 100%,
          100% 0%,
          color-stop(0.50, var(--gip-action-color)),
          color-stop(0.50, #11436D24)
  );
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    border: 0px solid black;
    box-shadow: 1px 1px 2px black;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2px;
  }
}


</style>