<template>
  <div class="c-enum-field-view">
    <!-- type = YES_NO as switch-->
    <b-switch v-if="type === 'YES_NO' && !checkbox" v-model="switchModel">{{ name }}</b-switch>
    <!-- type = YES_NO as checkbox-->
    <!--      <b-checkbox v-if="type === 'YES_NO' && checkbox" v-model="switchModel" >{{name}}</b-checkbox>-->
    <div v-if="radio">
      <div v-for="ev in values" :key="ev.id" >
        <input :id="ev.id" v-model="vroom"  :name="name" :value="ev.value"
               type="radio">
        <label :for="ev.id">{{ displayName(ev.id, ev.name) }}</label>
      </div>
    </div>
    <div v-if="type === 'YES_NO' && checkbox">
      <input :id="field" v-model="computedSwitchModel" type="checkbox" @input="handleInput">
      <label :for="field">{{ name }}</label>
    </div>
    <!-- type = LIST -->
    <select v-if="type === 'LIST'" v-model="singleModel" @change="handleInputList">
      <option v-if="defaultLabel" value="null">{{ defaultLabel }}</option>
      <option v-for="ev in values" :key="ev.id" :selected="isDefault(ev.id)" :value="ev.value">{{ displayName(ev.id, ev.name) }}</option>
    </select>

    <!-- type = COMPACT -->
    <b-field v-if="type === 'COMPACT' && !radio">
      <b-radio-button v-for="ev in values" :key="ev.id" v-model="singleModel" :class="{ 'is-small': isSmall}"
                      :native-value="ev.id">
        {{ displayName(ev.id, ev.name) }}
      </b-radio-button>
    </b-field>
    <!-- type = MULTIPLE -->
    <hbox v-if="type === 'MULTIPLE'">
      <b-checkbox v-for="ev in values" :key="ev.id" v-model="multipleModel"
                  :native-value="ev.name">
        {{ ev.name }}
      </b-checkbox>
    </hbox>

  </div>
</template>

<script>
import kit from '../kit'

export default {
  name: "CEnumFieldView",
  data() {
    return {
      switchModel: true,
      multipleModel: [],
      singleModel: ''
    }
  },
  // custom v-model en test
  model: {
    prop: 'theModel',
    event: 'theModelEvent',
  },
  props: {
    theModel: {
      // set: function(newValue) {
      //   this.switchModel = newValue
      // }
    },
    field: {
      type: String,
      default: function () {
        return null
      }
    },
    defaultLabel: {
      type: String,

    },
    /**
     * checkbox is used when type is YES_NO, if checkbox is false we use an iphone toggle, and
     * a checkbox if true
     */
    checkbox: {
      type: Boolean,
      default: false
    },

    radio: {
      type: Boolean,
      default: false
    },
    /**
     * Label override field name if set
     */
    label: {
      type: String,
      default: null
    },
    displayNames: {
      type: Object,
      default: function () {
        return {}
      }
    },
    excludes: {
        type: Array,
        default: function () {
        return []
      }
    }
  },
  // watch: {
  //   theModel: function(newVal, oldVal) { // watch it
  //     alert('theModel: ', newVal, ' | was: ', oldVal)
  //   }
  // },
  methods: {
    isDefault: function (id) {
      var fieldDefault = this.defaultValue
      kit.log.log('default=' + fieldDefault + ' id=' + id)
      return fieldDefault && fieldDefault.id === id ? 'selected' : ''
    },
    value: function () {
      if (this.type === 'YES_NO')
        return kit.model.field(this.field).valueAt(this.switchModel ? 0 : 1);
      else if (this.type === 'LIST')
        return kit.model.field(this.field).value(this.singleModel)
      else if (this.type === 'COMPACT')
        return kit.model.field(this.field).value(this.singleModel)
      else if (this.type === 'MULTIPLE') {
        return null;
      }
    },
    handleInput(value) {
      // alert('emit change value=' + value.target.checked)
      var checked = value.target.checked
      console.log(value)
      console.log(kit.model.field(this.field).valueAt(checked ? 0 : 1)) // TODO : pb return un Cenum a interpretter dans le v-model
      this.$emit('theModelEvent', kit.model.field(this.field).valueAt(checked ? 0 : 1))
    },


    handleInputList(value) {

      this.$emit('theModelEvent', this.singleModel)
      this.$emit('input', this.singleModel)

    },
    displayName: function(id, name) {
      return (this.displayNames[id]) ? this.displayNames[id] : name
    }
  },
  computed: {
    name: function () {
      // return this.field
      return this.label !== null ? this.label : kit.model.field(this.field).name()
    },
    type: function () {
      return kit.model.field(this.field).type()
    },
    values: function () {
      return kit.model.field(this.field).values().filter(f => !this.excludes.includes(f.value))
    },
    defaultValue: function () {
      return kit.model.field(this.field).defaultValue()
    },
      vroom:{
        get: function (){
            return this.singleModel
        },
          set : function (newValue){
            this.singleModel = newValue;
            this.$emit('theModelEvent',newValue)
          }

      },
    computedSwitchModel: {

      get: function () {
        return this.theModel === kit.model.field(this.field).valueAt(0).value
      },
      set: function (newValue) {
        this.switchModel = newValue
      }
    }
  },
  mounted() {
    this.switchModel = this.defaultValue === kit.model.field(this.field).valueAt(0)
    this.singleModel = this.defaultValue ? this.defaultValue.id : null
    if (this.singleModel !== null) {
      this.$emit('theModelEvent', this.singleModel)
      this.$emit('input', this.singleModel)
    }

  }
}
</script>

<style scoped>

</style>
