<template>
  <vbox class="fill k-field">
    <label ref="label" :class="nolabel ? 'hidden' : ''" class="gip-label c-field-label">{{ label }}</label>
    <div v-if="tooltip"><p class="k-field-tooltip">{{ tooltip }}</p></div>
    <slot>
      <!-- when we have a slot, default to the child component if no slot provided -->
      <component :is="viewClass(field)" :ref="field" :field="field"/>
    </slot>
    <label class="gip label error-label"> {{ error }}</label>
  </vbox>
</template>

<script>

import {GipMixin} from "../ui/GipMixin";
import Vbox from "@/components/ui/VBox";

import kit from "@/kit/kit";
import CTextFieldView from "@/kit/vue/CTextFieldView";
import CDateFieldView from "@/kit/vue/CDateFieldView";
import CEnumFieldView from "@/kit/vue/CEnumFieldView";

export default {
  name: "KField",
  components: {Vbox, CTextFieldView, CDateFieldView, CEnumFieldView},
  // mixins: [GipMixin],

  data() {
    return {
      error: null
    }
  },
  props: {
    // attention pour le moment field est optionnel, si renseigné on construit la vue depuis ce champ
    field: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    nolabel: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    // error: {
    //   type: String,
    //   default: null
    // }
  },
  // watch: {
  //   field: function(newVal, oldVal) {
  //     if (newVal) {
  //       // this.$refs.label.$el.textContent = "coucou"
  //     }
  //   }
  // },
  mounted() {
    // console.log("mounted KField")
    if (this.field !== null) {
      this.$refs.label.textContent = kit.model.field(this.field).name()
          + (kit.model.field(this.field).hasTag("NOT_NULL") ? "*" : "")
    }
  },
  methods: {
    // fieldName: function (field) {
    //   return kit.model.cclass(this.cclass).field(field).name()
    // },
    viewClass: function (field) {
      console.log("--view class de " + field + " : " + kit.model.field(field)
          + ", view=" + kit.model.field(field).constructor.name)
      var f = kit.model.field(field)
      //alert('view class, f=' + f + ' name=' + f.kind)
      return f.kind + 'View'
    },

    setError: function (str) {
      this.error = str
      this.$el.classList.remove("kit-error")
      if (this.error !== null) {
        this.$el.classList.add("kit-error")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/kit.scss";


.c-field-label.hidden {
  display: none;
}

.k-field-tooltip {
  color: var(--gip-tint-color)
}

.error-label {
  visibility: hidden;
  color: var(--gip-error-color);
  //white-space: pre-wrap;
}


</style>