<template>
  <div class="gip-icon" :style="'background-color:' + backgroundColor + ';'"
       :class="[active ? 'active' : null]">
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         viewBox="0 0 20 20"
         :aria-labelledby="iconName"
         role="presentation"
    >
      <!--<title :id="iconName" lang="en">{{iconName}} icon</title>-->
      <g :fill="iconColor">
        <slot/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconBase",
  components: {},
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 20
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    backgroundColor: {
      type: String,
      default: 'var(--gip-sphere-grey)'
    },
    active: {
      type: Boolean,
      default: true
    }
}
}
</script>

<style lang="scss" scoped>

.gip-icon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--gip-sphere-grey);
  justify-content: center;
  border-radius: 5px;
  //cursor: pointer;
  transition: 0.6s ease;
  flex-shrink: 0;
}
.gip-icon.active {
  cursor: pointer;
}

.gip-icon:hover {
  background-color: var(--gip-action-color) !important;
}

</style>
