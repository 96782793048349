import kit from '../kit.js'

/**
 * bunch of utilities methods
 * @class com.omniapp.util.Utilities
 */
var Utilities = {

    debounce: function (fn, delay) {
        var timeoutID = null
        return function () {
            var that;
            that = this
            clearTimeout(timeoutID)
            var args = arguments
             timeoutID = setTimeout(function () {
                fn.apply(that, args)
            }, delay)
        }
    },

    /**
     * random int value
     */
    random: function (max) {
        return Math.floor(Math.random() * Math.floor(max));
    },

    /**
     * For a string with format xx.yy.abc or xx.yy$abc returns abc. This is
     * used to convert a java class pathname to its simple name
     */
    basename: function (str) {
        if (str.contains('$'))
            return str.substring(str.lastIndexOf('$') + 1);
        else if (str.contains('.'))
            return str.substring(str.lastIndexOf('.') + 1);
        else
            return str;
    },

    /**
     * retrieve filename extension
     */
    fileExtension: function(filename) {
        return filename.split('.').pop();
    },

    /**
     *
     */
    eval: function (fname) {
        try {
            return eval('new ' + fname + '()');
        }
        catch (err) {
            kit.log.warning('cannot instanciate "' + fname + '", returns null');
            return null;
        }
    },

    /**
     * return children of a dom element, because children field doesnt exists under safari
     */
    children: function (element) {
        if (element.children === undefined) {
            var children = [];
            var l = element.childNodes.length;
            for (var i = 0; i < l; i++) {
                if (element.childNodes[i].nodeType === 1)
                    children.push(element.childNodes[i]);
            }
            return children;
        }
        return element.children;
    },
    /**
     * Parse a date in the format dd/mm/yy[yy]
     * date can be in the following formats :
     * dd/mm/yyyy, dd/mm/yy, dd.mm.yy, dd.mm.yyyy, dd-mm-yy, dd-mm-yyyy
     */
    parseSimpleDate: function (date) {
        var parts = date.split(/(?:\/| |-|\.)+/)
        if (parts.length != 3)
            return null;
        var d = parseInt(parts[0]);
        var m = parseInt(parts[1]);
        var y = parseInt(parts[2]);
        if (y < 50)
            y = 2000 + y;
        else if (y < 100)
            y = 1900 + y;
        kit.log.debug("d=" + d + "m=" + m + " y=" + y);
        return new Date(y, m - 1, d);
    },

    /**
     * print an array
     *
     * @param array array to print
     * @param level depth to print. defaults to 1
     * @return string rep of the array
     */
    printArray: function (obj, level) {

        level = level ? level : 1;
        if (level > 30)
            return "Too Deep !";

        var str = '';
        for (var i in obj) try {
            if (typeof obj[i] === 'object')
                str += "\n" + i + "\n=> " + Utilities.printArray(obj[i], level + 1).replace(/\n/g, "\n\t") + "\n";

            else str += "\n" + i + "\n=> " + obj[i];

        } catch (e) {
            kit.log.debug('exception')
        }



        return str;
    },

    /**
     * Format a Number
     * @param val Number to format
     * @param decimalSep decimal separator
     * @param thousandSep thousands separator
     * @param numDecimals num of decimals
     * @return a String with the number formatted using params
     */
    format: function (val, decimalSep, thousandSep, numDecimals) {
        val = val.toFixed(numDecimals);
        var str = val.toString();
        //str.replace(".", decimalSep);

        var x = str.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? decimalSep + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + thousandSep + '$2');
        }
        return x1 + x2;
    },

    /**
     * Convenient method to guess if something is a string, fuck js..
      * @param s
     * @returns {boolean}
     */
    isString: function(s) {
        return typeof(s) === 'string' || s instanceof String;
    }


};

/** guest if s is a String, either a literal or an object */
function isString(s) {
    return typeof(s) === 'string' || s instanceof String;
}

/** add format function to Number */
Number.prototype.format = function (decimalSep, thousandSep, numDecimals) {
    return Utilities.format(this, decimalSep, thousandSep, numDecimals);
}

if (!Array.indexOf) {
    Array.prototype.indexOf = function (obj) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] == obj) {
                return i;
            }
        }
        return -1;
    }
}

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        return this[this.length - 1];
    };
}


export default Utilities
