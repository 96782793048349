<script>

import KField from "@/components/ui/KField.vue";
import CTextFieldView from "@/kit/vue/CTextFieldView.vue";
import GipMixin from "@/components/ui/GipMixin";
import { isGusoValid } from "@/utils/utils";

export default {
  components: {CTextFieldView, KField},
  mixins: [GipMixin],
  props: ['value'],
  computed: {
    datas: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    gusoValide: {
      get() {
        return isGusoValid(this.datas.guso)
      }
    }
  }
}
</script>

<template>
  <div>
    <k-field  label="Votre numéro de SIRET* :">
      <CTextFieldView v-model="datas.siret" field="Employeur.siret" />
    </k-field>
    <br>
    <k-field v-model="datas.guso" label="Votre numéro GUSO* :">
      <CTextFieldView v-model="datas.guso" field="Employeur.guso" />
    </k-field>
    <div id="gusoError" v-if="!gusoValide">
      Numéro GUSO incorrect.<br>
      Si votre numéro GUSO comporte 9 chiffres, vous devez le faire précéder d'un "0" (zéro).
    </div>
    <div v-if="state.newFeature">
      guso valid 3333333359<br>
      siret valid 85333307800014<br>
      siret naf invalid 81987475100020
    </div>
  </div>
</template>

<style scoped>
#gusoError {
  font-size: 90%;
  color: var(--gip-error-color);
}

</style>