<template>
  <div class="simulateur gip-bg-default" id="Simulateur">
    <div class="gip-page">
    <Diviseur titre="COMBIEN ?"/>
    <hbox class="justify-center wrap-medium">
    <div class="entree" @change="getAide" >

      <h2 class="alternance">Simulez rapidement votre <br/>
        <span>aide financière</span></h2>

      <p>
        Evaluez le montant de l’aide à laquelle<br/>
        vous pourriez prétendre.
      </p>

      <MainPanel v-if="isAideCHR === undefined" v-model="type"/>
      <hbox class="wrap-small align-center align-normal-small">
        <label id="nb-salaries" class="gip-label">- Nombre de salariés :</label>
        <hbox class="align-self-normal-small">
        <input class="simulateur-input grow-small" v-model="nbSalaries"  type="number" name="nbSalaries" min="1">
        </hbox>
      </hbox>
      <div v-if="this.type !== 'CHR'">
        <p>
          <span class="gip-label">- Jauge estimée :</span> 
        </p>
        
        <hbox class="radioList justify-center-small">
          <div class="radioInline">
            <input v-model="jaugeInf300" type="radio" id="moins300" :value="true">
            <label for="moins300">&lt; 300 personnes</label>
          </div>
          <div class="radioInline">
            <input  v-model="jaugeInf300" type="radio" id="plus300" :value="false">
            <label for="plus300">&gt;= 300 personnes</label>
          </div>
        </hbox>
      </div>
      <p>
        <span class="gip-label">- Montant total des salaires </span> (pour un jour travaillé) :
      </p>
      
      <hbox class="radioList justify-center-small">
        <div class="radioInline">
          <input v-model="baseCalcul" type="radio" name="baseCalcul" id="Net" value="Net">
          <label for="Net">Net</label>
        </div>
        <div class="radioInline">
          <input  v-model="baseCalcul" type="radio" name="baseCalcul" id="Brut" value="Brut">
          <label for="Brut">Brut</label>
        </div>
        <div class="radioInline">
          <input v-model="baseCalcul" type="radio" name="baseCalcul" id="CoutTotal" value="CoutTotal" checked="checked">
          <label for="CoutTotal">Coût Total</label>
        </div>
      </hbox>
      <hbox class="align-center">
        <Range class="hidden-small" :value="Number(montant)" :max="Number(3000)" @update="updateMontant"/>
        <input type="text" name="montantAff"  @change="updateMontant(inputTextMontant)" @click="inputTextMontant=''"
                id="montantSalaire"  v-model="inputTextMontant" class="simulateur-input grow-small"/>
        <span class="euro">€</span>
      </hbox>
    <!-- <div id="cout-total-info" class="row row-m-t" style="display: none;">
      <div class="col-xs-4 vcenter col-xs-offset-8">
        <p>*Soit Coût Total de <span id="cout-total">930 €</span></p>
      </div> -->
    </div>
    <div class="result simulator-right">
      <div class="triangle"></div>

      <Sphere top="25" left="-60" taille="213"/>
      <Sphere right="-40" top="210" couleur="grey" taille="213"/>
      <div class="result-container">
        <div class="simulateur-header">
          <p>
            <span>Avec ce fonds d'aide du Gip Cafés Cultures,</span><br/>
            vous pourriez prétendre à une aide à hauteur de :
          </p>
          <h3 id="totalAide">{{ aide.gip + aide.guso }} €*</h3>
          <div id="no-aide" v-if="aide.gip + aide.guso === 0">
            <b>Le salaire saisi doit respecter la rémunération minimum <br/>en vigueur ({{this.site.montantMinimumBrut}}€ brut)</b>
          </div>
          <h2 class="horizontal-rule">
            <span>Répartition</span>
          </h2>
        </div>

      <hbox class="infos">
        <vbox class="infos-text-container fill align-left space-between unwrap-small flex-wrap">
          <vbox>
          <h3 class="total-gip" >{{ aide.gip }} €</h3>
          <label>d'aide du GIP</label>
          </vbox>
          <vbox>
          <h3  class="total-a-charge" >{{ aide.resteACharge }} €</h3>
          <label>reste à charge employeur</label>
            </vbox>
        </vbox>
        <div class="simulateur-chart hidden-small">
           <DonutsChart :labels="['Gip','Guso','Reste à charge']" ref="donut"  unit="€" />
        </div>
      </hbox>
      <hbox class="btn-container justify-center-small">
        <BtnAction to="LoginEmployeur" titre="DEMANDER LA SUBVENTION"></BtnAction>
      </hbox>
      <p class="postscriptum">* Cette estimation pourra être ajustée en fonction de l’application des taux de
        cotisations sociales.</p>
    </div>
    </div>
    </hbox>
  </div>
  </div>
</template>

<script>
import Diviseur from "./Diviseur";
import BtnAction from "./BtnAction";
import Range from './Range'
import Sphere from "./Sphere";
import DonutsChart from "./DonutsChart";
import hbox from "./HBox";
import kit from "../../kit/kit";
import GipMixin from "./GipMixin";
import MainPanel from "../employeur/wizard/MainPanel.vue";

export default {
  name: "Simulateur",
  mixins:[GipMixin],
  components: {BtnAction,hbox, Diviseur,Sphere,Range,DonutsChart, MainPanel},
  props:['isAideCHR'],

  data: function () {
    return {
      montant: 1500,
      nbSalaries: 6,
      inputTextMontant: 0,
      baseCalcul: "Brut",
      plafondGusoAtteint: "Oui",
      type: 'CHR',
      jaugeInf300: true,
      // ce que retourne le service :
      aide: {
        coutTotal: 3,
        guso: 4,
        gip: 6,
        resteACharge: 8
      },
      site: null
    }
  },
  methods: {
    updateMontant(data) {
      this.montant = data ;
      this.inputTextMontant= data
    },
    getAide() {
      kit.call(() => {
        const chr = this.type == 'CHR'
        kit.services.service('/simulation?chr=' + chr +'&nbSalaries=' + this.nbSalaries
            + '&baseCalcul=' + this.baseCalcul + '&montant=' + this.montant 
            + '&jaugeInf300=' + this.jaugeInf300,
            {},
            (set, req) => {
              this.aide = set
              this.$refs.donut.updateValues([this.aide.gip,this.aide.guso,this.aide.resteACharge])
            },
            (error) => {
              alert("erreur service simulation " + error)
            }
        )
      });
    },
  },
  watch: {
    // force le refresh lorsque l'on change le type d'aide
    isAideCHR: {
      immediate: true,
      handler: function (newVal, oldVal) { // watch it
      this.type = newVal == true ? 'CHR' : 'AUTRE'
      this.$forceUpdate()
      this.getAide()
      }
    }
  },
  mounted () {
    this.updateMontant(this.montant)
    this.getAide()
  },
  created() {
    this.site = this.state.site
  }
}
</script>

<style lang="scss" scoped>

  .simulateur{
    text-align: center;
    margin-bottom: 100px;
    padding-top: var(--gip-padding-header);
  }
  .entree{
    vertical-align: top;
    display: inline-block;
    padding: 0px 36px 20px 36px;
    text-align: left;
    .label{
    font-weight: Bold;
     &.espace{
       margin-right: 16px;
     }
    }
    input[type="text"],input[type="number"]{
      //height: 46px;
      font-size: 20px ;
      font-weight: 600;
      border: 1px solid #EEEEEE;
      text-align: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4px;
      font-family: "Raleway", sans-serif;
      color: var(--gip-tint-color);
    }

    .radioInline{
      display: inline-block;
      margin-right: 35px ;
      label{
        display: inline-block;
      }
    }
  }



  .result{
    display: inline-block;
    position: relative;

    .result-container{
      vertical-align: top;
      padding: 36px 43px 36px 55px;
      position: relative;
      color: white;
      background: var(--gip-tint-color);
      box-shadow: 0px 3px 15px #00000029;
      border-radius: 10px;
      position: relative;
      z-index: 2;
    }
    .infos{
      text-align: left;
      font-weight: 500;
      max-width:100%;
      .simulateur-chart{

        width: 177px;
      }
      h3 {
        font-size: 35px;
        margin: 7px 0px 7px 0px;
        font-weight: 500;
      }
      label{
        display: block;
        font-size: 14px;
        margin: 0px 0px 18px 0px;
        line-height: 16px;

      }
    }
    .simulateur-header{
      p{
        font-size: 15px;
        span{
          font-weight: 600;
        }
      }
      h3{
      font-size: 60px;
        margin:40px 0px;
        font-weight: 500;
      }
      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 2px solid #386889;
        line-height: 0.1em;
        margin: 10px 0 20px;
        span {
          font-size: 16px;
          font-weight: 500;
          background: var(--gip-tint-color);
          padding: 0 10px;
        }
      }
    }
    .total-a-charge{
      color: var(--gip-menu-background);
    }
    .total-gip{
      color: var(--Rio-Grande-color-c7d303) ;
    }
    .total-guso{
      color: var(--gip-accent-color);
    }

    .btn-container{
      text-align: right;
      margin-bottom: 20px;
    }


    .postscriptum{
      font-size: 11px;
      line-height: 16px;
      text-align: left;
      position: relative;
      font-weight: 500;
      display: block;
      display: block;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border: 33px solid transparent;
    border-bottom: 1px;
    border-radius:8px;
    border-top: 50px solid white;
  }


  input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #d5dbe1;
    background: white;
    border-radius: 50%;
    background-clip: content-box;
    + {
      label {
        position: relative;
        top: -5px;
        left: 8px;
        font-size: 15px;
        color: var(--gip-tint-color);
        font-weight: 600;
      }
    }
    &:checked {
      border: 5px solid var(--gip-action-color);
    }
    &:focus {
      outline-color: transparent;
      outline-style: none;
    }
  }

.gip-label {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
#nb-salaries {
  margin-right: 1em;
}

.simulateur-input {
  //margin: 0.5em;
  padding: 0.3em;
  width: 4em;
}

.euro {
  font-size: 20px ;
  font-weight: 600;
  margin-left: 5px;
}
#methode-calcul-label {
  margin-bottom: 0;
}

#no-aide {
  padding-bottom: 1em;
  //padding-top: -2em;
  margin-top: -1em;
}
  @media screen and (max-width: 640px) {

    .result .result-container {
      border-radius: 0px;
    }
  }
</style>