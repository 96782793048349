
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CTextField. Javascript type is String
 * @class com.omniapp.kit.model.field.CTextField
 * @extends com.omniapp.kit.model.CField
 */
function CTextField(cclass, cfield) {

    CField.call(this, cclass, cfield, 'CTextField');

    var _rows = null;
    var _columns = null;

    /** Number of rows */
    this.rows = function() {
        _rows = this.node.attr('rows') ? parseInt(this.node.attr('rows')) : 1
        console.log("rows=" + _rows)
      return _rows
    };
    /** String -> Number */
    this.convert = function(val) {
        
         return val;

    };
    /** Number -> String */
    this.displayValue = function(val) {
        if (this.formatter() !== null) {
            var f = this.formatter();
            return f.format(this, val);
        }
        return val == null ? '' : val;
    };
    /** Number -> display String */
    this.stringValue = function(val) {
        return val;
    };

} // end class CTextField

export default CTextField
