<template>
  <Wizard v-model="showRegisterWizard" first-panel="main" title="Créer mon compte" @next="next" @reset="reset" :show-loading-overlay="showLoadingOverlay">
    <WizardPanel :isValid="type != null"
                 description="Afin de créer votre compte nous avons besoin de quelques infos vous concernant"
                 nom="main">
      <MainPanel v-model="type"/>
    </WizardPanel>
    
    <WizardPanel :isValid="this.communeDatas.communeId !== null 
                    && ((this.communeDatas.type == 'commune' && this.communeDatas.moins3500) 
                    || (this.communeDatas.type == 'epci' && this.communeDatas.moins7000))"
                 description="Encore une dernière précision :"
                 nom="commune">
      <CommunePanel v-model="communeDatas"/>
    </WizardPanel>

    <WizardPanel :isValid="this.autreDatas.ca && this.autreDatas.salaries && this.autreDatas.auMoins1an" description="Une dernière précision :"
                 nom="autre">
      <AutrePanel v-model="autreDatas"/>
    </WizardPanel>
    
    <WizardPanel :isValid="isGusoValid(siretDatas.guso) && siretDatas.siret !=''" description="Encore quelques précisions :"
                 nom="siret">
      <SiretPanel v-model="siretDatas"/>
    </WizardPanel>
    
    <WizardPanel :isValid="true" description="" next-button="TERMINER" nom="nonEligible" title="">
      <NonEligiblePanel/>
    </WizardPanel>
    
    <WizardPanel :isValid="naf !== null"
                 description="⚠️ Désolé, nous n'avons pas trouvé votre numéro de SIRET dans notre base d'information. Veuillez saisir votre code NAF."
                 nom="naf">
      <NafPanel v-model="naf"/>
    </WizardPanel>

    <WizardPanel 
      :isValid="true" 
      :description="`Bienvenue dans l'espace employeur, vous pouvez créer un compte pour votre ${ displayTypeEmployeur } en remplissant les champs indiqués ci-dessous.`" 
      next-button="CRÉER MON COMPTE" 
      nom="final" 
      size="xl">
      <FinalPanel 
        ref="finalForm" 
        :employeur="employeur" 
        @endLoading="showLoadingOverlay = false"
        description = ""
        />
    </WizardPanel>
  </Wizard>
</template>

<script>
import GipMixin from '@/components/ui/GipMixin';
import Wizard from '@/components/ui/wizard/Wizard.vue';
import WizardPanel from '@/components/ui/wizard/WizardPanel.vue';
import MainPanel from '@/components/employeur/wizard/MainPanel.vue'
import FinalPanel from '@/components/employeur/wizard/FinalPanel.vue'
import SiretPanel from '@/components/employeur/wizard/SiretPanel.vue'
import NonEligiblePanel from '@/components/employeur/wizard/NonEligiblePanel.vue'
import AutrePanel from '@/components/employeur/wizard/AutrePanel.vue'
import CommunePanel from '@/components/employeur/wizard/CommunePanel.vue'
import NafPanel from '@/components/employeur/wizard/NafPanel.vue'

import kit from "@/kit/kit";
import {default as CFilter, CFilterCondition, CFilterType} from '@/kit/data/CFilter.js'
import { isGusoValid } from '@/utils/utils.js'

export default {
  name: "CreationCompte",
  mixins: [GipMixin],
  components: {
    Wizard,
    WizardPanel,
    MainPanel,
    CommunePanel,
    AutrePanel,
    FinalPanel,
    SiretPanel,
    NonEligiblePanel,
    NafPanel
  },
  async mounted() {
    const nafs = await this.getGoodNafs()
    this.$options.goodNafs = nafs.map(n => n.nafId)
    this.$options.chrNafs = nafs.filter(n => n.eligibilite === 'NORMALE').map(n => n.nafId)
    this.$options.otherNafs = nafs.filter(n => n.eligibilite === 'EXCEPTIONNELLE').map(n => n.nafId)
    this.$options.formeJuridiques = await this.getFormeJuridiques()
  },
  computed: {
      displayTypeEmployeur: function() {
        switch (this.type) {
          case 'CHR': return 'établissement';
          case 'COLLECTIVITE': return this.communeDatas.type == 'commune' ? 'commune' : 'EPCI'
          case 'AUTRE': return 'structure'
          default: return ''
        }
      }
  },
  data() {
    return {
      showRegisterWizard: false,
      showLoadingOverlay: false,
      type: null,
      autreDatas: {
        ca: false,
        salaries: false,
        auMoins1an: false
      },
      communeDatas: {
        communeId: null,
        type: 'commune',
        moins3500: false,
        moins7000: false
      },
      siretDatas: {
        siret: '',
        guso: ''
      },
      naf: null,
      employeur: null
    }
  },
  goodNafs: null,
  chrNafs: null,
  otherNafs: null,
  formeJuridiques: null,
  nom: null,
  raisonSociale: null,
  formeJuridique: null,
  adresse: null,
  nomDirigeant: null,
  prenomDirigeant: null,
  commune: null,
  siret: null,
  methods: {
    show() {
      this.showRegisterWizard = true
    },
    /* eslint-disable no-case-declarations*/
    async next(wizard) {
      switch (wizard.currentPanelIndex) {
        case "main":
          wizard.pushPanel(this.type === 'COLLECTIVITE' ? 'commune' : 'siret')
          break;
        case 'siret':
          this.showLoadingOverlay = true
          const result = await this.checkSiret(this.siretDatas.siret)
          this.showLoadingOverlay = false
          
          switch (result) {
              case 'nonEligible':  
                wizard.pushPanel('nonEligible');
                break
              case 'ok':
                this.updateEmployeur()
                wizard.pushPanel(this.type == 'CHR' ? 'final' : 'autre');
                break
              case 'nonTrouve':
                wizard.pushPanel('naf');
                break
              default:
                window.alert(result)
            }
            break
        case 'naf':
          this.showLoadingOverlay = true
          const nafOk = this.checkNaf(this.naf.nafId)
          this.showLoadingOverlay = false
          this.checkTypeWithNaf(this.naf.nafId)
          if(nafOk && this.type == 'CHR' ) this.updateEmployeur()
          wizard.pushPanel(nafOk ? (this.type == 'CHR' ? 'final' : 'autre') : 'nonEligible')
          break
        case "commune": 
        this.showLoadingOverlay = true
        if (this.communeDatas.communeId.siren) {
          await this.checkSiret(this.communeDatas.communeId.siren, true)
        }
        this.$options.commune = this.communeDatas.communeId
        this.$options.nom = this.communeDatas.type == 'commune' ? this.communeDatas.communeId.nom : this.communeDatas.communeId.codeEpci.nom ?? ''
        this.showLoadingOverlay = false
        this.updateEmployeur()
        wizard.pushPanel("final")
          break
        case "autre":
          this.$options.auMoins1an = this.communeDatas.auMoins1an
          this.$options.moins2M = this.autreDatas.ca
          this.$options.moins10Salaries = this.autreDatas.salaries
          this.updateEmployeur()
          wizard.pushPanel("final")
          break;
        case "nonEligible":
          this.showRegisterWizard = false
          break;
        case "final":
          this.showLoadingOverlay = true
          this.$refs.finalForm.submitForm()
          break;
        default:
          break;
      }
    },
    /* eslint-enable no-case-declarations*/
    reset() {
      this.type = null
      this.autreDatas = {
        ca: false,
        salaries: false,
        auMoins1an: false
      }
      this.communeDatas = {
        communeId: null,
        type: 'commune',
        moins3500: false,
        moins7000: false,
      }
      this.siretDatas = {
        siret: '',
        guso: ''
      }
      this.naf = null
      this.$options.nom = null
      this.$options.raisonSociale = null
      this.$options.formeJuridique = null
      this.$options.adresse = null
      this.$options.nomDirigeant = null
      this.$options.prenomDirigeant = null
      this.$options.commune = null
      
    },
    isGusoValid(guso) {
      return isGusoValid(guso)
    },
    
    updateEmployeur() {
    
      let formeJuridique = null
      const numeroJur = this.$options.formeJuridique?.substr(0,2);
      if(numeroJur && this.$options.formeJuridiques) {
        formeJuridique = this.$options.formeJuridiques.find(f => f.numero == numeroJur)
      }
      
      this.employeur = {
        "type": this.type,
        "nom": this.$options.nom,
        "formeJuridiqueId": formeJuridique,
        "raisonSociale": this.$options.raisonSociale,
        "nomGerant": this.$options.nomDirigeant,
        "prenomGerant": this.$options.prenomDirigeant,
        "tel": null,//'0611162425',
        "tel2": null,
        "mail": null,//'fred@omniapp.fr',
        "guso": this.siretDatas.guso,
        "password": null,//'iopiop',
        "password2": null,//'iopiop',
        "siret": this.$options.siret,
        "naf": this.naf,
        "etablissementBancaire": null,
        "iban": null,//'NL81RABO8234998285',
        "bic": null,//'jkljkl',
        "licence": 'N',
        "numeroLicence": null,
        "etablissementEnCentreCommercial": 'N',
        "categorieErp": null,
        "typeErp": null,
        "licenceRestauration": "N",
        "adresse1": this.$options.adresse,
        "communeId": this.$options.commune,
        "notifMail": "O",
        "autoriserDemandeurTiers": 'N',
        "avisCommissionSecurite": "N",
        "auMoins1an": this.type == 'AUTRE' ? this.autreDatas.auMoins1an : false,
        "moins2M": this.type == 'AUTRE' ? this.autreDatas.ca : false,
        "moins10Employes": this.type == 'AUTRE' ? this.autreDatas.salaries : false,
        "isEpci": this.type == 'COLLECTIVITE' ? this.communeDatas.type == 'epci' : false, 
        "moins3500habitans": this.type == 'COLLECTIVITE' ? (this.communeDatas.type == 'commune' ? this.communeDatas.moins3500 : false) : false, 
        "moins7000habitants": this.type == 'COLLECTIVITE' ? (this.communeDatas.type == 'epci' ? this.communeDatas.moins7000 : false) : false,
      }
    },
    
    // renvoi ok, nonEligible, nonTrouve
    async checkSiret(siret, isCommune) {
      const url = `infos-employeur?siret=${ siret }`
      try {
        const json = await kit.services.serviceGet(url)
        if (isCommune) {
          this.$options.nom = null
          this.$options.raisonSociale = null
        } else {
          this.$options.nom = json.enseigne ?? json.denomination ?? null
          this.$options.raisonSociale = json.nomEntreprise ?? null
        }
        this.$options.formeJuridique = json.formeJuridique ?? null
        this.$options.adresse = json.adresse1 ?? json.adresse2 ?? null
        this.$options.nomDirigeant = json.nomDirigeant ?? null
        this.$options.prenomDirigeant = json.prenomDirigeant ?? null
        this.$options.siret = json.siret
        
        if (json.codeApe) {
          const ape = json.codeApe.replace('.', '')
          if(!isCommune && !this.checkNaf(ape)) {
            return 'nonEligible'
          }
         
          const naf = await this.getNaf(ape)
          this.naf = naf

          if(!isCommune) {
            this.checkTypeWithNaf(ape)
          }
        }

        if (isCommune) {
          this.$options.commune = this.communeDatas.communeId
        } else { 
          if (json.codePostal) {
          const commune = await this.getCommune(json.codePostal, json.commune)
          this.$options.commune = commune
          }
        }
        
        return 'ok'
      } catch(error) {
        const jsonError = await error.response.json()
        if(jsonError && jsonError.errors && jsonError.errors.length > 0 && jsonError.errors[0].message == "siret non trouvé")
            return 'nonTrouve'
        else {
          console.error(error)
          return 'Une erreur est survenue'
        }
      }
    },
    getNaf(naf) {
      let filter = new CFilter(CFilterType.AND)
      filter.add("NAF.nafId", CFilterCondition.EQUALS, naf)
      return new Promise((resolve) => {
        kit.services.query({
              cclass: "NAF",
              filter: filter,
            },
            (data) => {
              if (data.length !== 0) {
                resolve(data[0])
              }
              else resolve(null)
            },
            (error) => {
              console.error(error)
              resolve(null)
            })
        })
    },
    getFormeJuridiques() {
      return new Promise((resolve) => {
        kit.services.query({
              cclass: "FormeJuridique"
            },
            (data) => {
              if (data.length !== 0) {
                resolve(data)
              }
              else resolve(null)
            },
            (error) => {
              console.error(error)
              resolve(null)
            })
        })
    },
    getGoodNafs() {
      let filter = new CFilter(CFilterType.OR)
      filter.add("NAF.eligibilite", CFilterCondition.EQUALS, "NORMALE")
      filter.add("NAF.eligibilite", CFilterCondition.EQUALS, "EXCEPTIONNELLE")
      return new Promise((resolve,reject) => {
        kit.services.query({
              cclass: "NAF",
              filter: filter,

            },
            (data) => {
              // const aideNormaleNafList = []
              // data.forEach((item) => aideNormaleNafList.push(item.nafId))
              resolve(data)
            },
            (error) => {
              console.error(error)
              reject("Erreur")
            })
        })
    },
    getCommune(cp, commune) {
      let filter = new CFilter(CFilterType.AND)
      filter.add("Commune.codePostal", CFilterCondition.EQUALS, cp)
      return new Promise((resolve) => {
        kit.services.query({
              cclass: "Commune",
              filter: filter,
            },
            (data) => {
              if (data.length === 1) { // si une seule ville pour le cp retourne la ville
                resolve(data[0])
              }
              else if (data.length > 1 && commune) { // si plusieurs ville, on essaie de trouver par le nom de la commune
                for (const ville of data) {
                  if (commune.unaccent().toLowerCase() === ville.nom.unaccent().toLowerCase()) {
                    resolve(ville)
                    return
                  }
                }
                resolve(null)
              }
              else resolve(null)
            },
            (error) => {
              console.error(error)
              resolve(null)
            }
          )
        })
    },

    checkNaf(nafId) {  
      if (this.$options.goodNafs) {
        return this.$options.goodNafs.includes(nafId)
      } else {
        return true
      }
    },
    checkTypeWithNaf(nafId) {
        if(this.$options.chrNafs.includes(nafId) && this.type == 'AUTRE') {
          this.type = 'CHR'
          return
        }
        if(this.$options.otherNafs.includes(nafId) && this.type == 'CHR') {
          this.type = 'AUTRE'
        }
    },
    delay(milliseconds){
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}
  }
}
</script>