
/**
PENDING
c'était DemandeCell puis TableCell mais on veut un composant qui implémente une table, comme dans le framework
- props pour définir la config
- fournir un modèle
- pouvoir custom le rendu
*/
<template>
  <div class="cell" :class="{ header: isHeader, bg: isExpanded }">
    <hbox class="align-baseline fill table-row">
      <slot></slot>
    </hbox>
    <vbox class="">
      <Transition name="cell-expanded">
        <slot v-if="expanded" name="expanded"></slot>
      </Transition>

    </vbox>
  </div>
</template>


<script>
import GipMixin from "../ui/GipMixin";
export default {
  name: "CompositeTable",
  mixins: [GipMixin],
  components: {},
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },
    // expanded: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    toggleExpanded() {
      // console.log(data)
      // if(!this.isHeader) {
      this.expanded = !this.expanded
      // }
    },
    isExpanded() {
      return this.expanded
    }

  }
}
</script>

<style lang="scss">

.cell {
  background: white;
  padding: 1em ;
  font-weight: 600;

  font-size: 15px;
  border-bottom: 2px solid #EEEEEE ;
  cursor: pointer;
  //padding-left: 2em;
  //padding-right: 2em;
  padding: 0.7em 2em;
  border-bottom: solid 2px var(--gip-sphere-grey);
}

.cell:last-child {
  border-bottom: none;
}

.cell.header {
  //padding: 0.3em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-bottom: none;
}

.cell-expanded-enter-active, .cell-expanded-leave-active {
  transition: 0.4s ease-in-out;
  max-height: 200px;
}

.cell-expanded-enter, .cell-expanded-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  max-height: 0;
}


.header {
  color: var(--gip-tint-color);
  font-weight: 500;
  border:none;
  border-radius: 5px 5px 0px 0px;
  cursor: inherit;

  background: var(--gip-menu-background);
}

.cell .gip-icon {
  margin-right: 1em;
}

.cell p {
  margin-top: 0;
  margin-bottom: 0;
}

</style>