

<template>
<k-field ref="commune" class="margin-wrap gip-select" :label="villeLabel">
    <b-autocomplete 
        ref="communeChooser"
        :custom-formatter="formatCommune"
        :data="communes"
        :placeholder="placeHolder"
        @select="selectCommune"
        @typing="getCommunes"
    >
    <template slot-scope="props">
        {{ props.option.nom }} ({{ props.option.codePostal }})
    </template>
    <template #empty>Aucun résultat</template>
    </b-autocomplete>
</k-field>
  
     
  </template>
  
  <style>
  
  
  </style>
  
  <script>
  
  import KField from "@/components/ui/KField.vue";
  import kit from "@/kit/kit";
  import {default as CFilter, CFilterCondition, CFilterType} from '@/kit/data/CFilter.js'
  import debounce from 'lodash/debounce'
  
  export default {
    components: { KField},
    props: ['value', 'avecEpci', 'label', 'placeholder'],
    computed: {
      commune: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      villeLabel: function() {
         return this.label ? this.label : 'Ville*'
      },
      placeHolder: function() {
         return this.placeholder ? this.placeholder : 'code postal, ville...'
      } 
    },
    data() {
      return {
        communes: [],
      }
    } ,
    methods: {
      formatCommune(d) {
        return d.nom + " (" + d.codePostal + ")"
      },
      selectCommune(option) {
        this.commune = option
      },
    // debounce permet de faire du lazy load avec timeout (ne pas refaire la requete et attendre un chouille)
    getCommunes: debounce(function (name) {
      this.commune = null
      if (!name.length) {
          this.communes = []
          return
      }
      let filter = new CFilter(CFilterType.OR)
      filter.add("Commune.nom", CFilterCondition.CONTAINS, name)
      filter.add("Commune.codePostal", CFilterCondition.CONTAINS, name)
      const depth = this.avecEpci ? 2 : 1
      kit.services.query({
            cclass: "Commune",
            filter: filter,
            limit: 100,
            depth: depth
          },
          (data) => {
            this.communes = []
            data.forEach((item) => this.communes.push(item))
          },
          (error) => {
            this.communes = []
            alert("error")
          })
      }, 500),
      setError(error) {
        this.$refs.commune.setError(error)
      }
    }
  }
  </script>