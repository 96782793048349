<template>
<div class="pourQui-container gip-mobile-padding">
  <Diviseur titre="POUR QUI ?"></Diviseur>
  <h2 class="alternance"><span>2 fonds d’aide</span><br/> pour soutenir l’emploi</h2>
  <hbox class="pourQui  flex-wrap-reverse space-around">
    <div class="overlay">
    <Sphere top="-60" left="-60" taille="213"/>
  <div class="pourqui-box">


    <h3 class="alternance" v-html="state.site.titreFondsChr">
<!--    FONDS D’AIDE <span> SPÉCIFIQUE</span>-->
  </h3>
  <p v-html="state.site.texteCourtFondsChr">
<!--    Ce fonds d’aide s’adresse spécifiquement aux cafés, bars-->
<!--    et restaurants, obligatoirement employeurs des artistes et-->
<!--    techniciens qui se produisent dans leur établissement.-->
<!--    Tous les salariés doivent être déclarés auprès du GUSO-->
<!--    (le guichet unique du spectacle occasionnel mis en-->
<!--    œuvre par Pôle Emploi – <a target="_blank" href="https://www.guso.fr">www.guso.fr</a>). <br/>-->
  </p>
    <div class="btn-container">
    <btn-action type="action"  to="FondsAideSpe" titre="DÉCOUVRIR CE FONDS"></btn-action>
      </div>
  </div>
    </div>

    <div class="overlay">
      <Sphere top="60" right="-60"  couleur="grey"  taille="213"/>

      <div class="pourqui-box">

      <h3 class="alternance" v-html="state.site.titreFondsOccasionnel">
      </h3>
      <p v-html="state.site.texteCourtFondsOccasionnel">
<!--        Ce fonds d’aide, abondé par le Ministère de la Culture,-->
<!--        s’adresse aux collectivités territoriales de moins de 3500-->
<!--        habitants, ainsi qu’aux structures de droit privé entrant-->
<!--        dans le champ d’application du GUSO, à l’exception des-->
<!--        particuliers employeurs.-->
<!--        Tous les salariés doivent être déclarés auprès du GUSO-->
<!--        (le guichet unique du spectacle occasionnel mis en-->
<!--        œuvre par Pôle Emploi – <a target="_blank" href="https://www.guso.fr">www.guso.fr</a>).<br/>-->
      </p>
        <div class="btn-container">
      <btn-action type="action"  to="FondsAide" titre="DÉCOUVRIR CE FONDS"></btn-action>
        </div>
    </div>
      </div>
  </hbox>


  </div>
</template>

<script>
import BtnAction from "./BtnAction";
import Diviseur from "./Diviseur";
import Sphere from "./Sphere";
import GipMixin from "./GipMixin";

export default {
  name: "PourQui",
  mixins: [GipMixin],
  components: {Diviseur, BtnAction,Sphere}
}
</script>

<style lang="scss" scoped>
@import "../../assets/kit.scss";

.btn-container {
  text-align: right;
  position: absolute;
  bottom: 20px;
  right: 32px;
}

.overlay {
  vertical-align:top ;
  padding: 22px 0px;
  z-index: 5;
  width: 45%;

  position: relative;

}
.pourQui-container{
  text-align: center;
}

.pourqui-box {
  vertical-align:top ;
  text-align: left;
  padding: 55px 50px ;
  text-align: left;
  min-height: 372px;
  background: white;
  box-shadow: 0px 3px 23px #04437010;
  border-radius: 5px;
  position: relative;
  background: white;

  h3 {
    margin-bottom: 34px ;
    font-size: 16px;
  }

}



a {
  color: var(--gip-tint-color);
  text-decoration: underline;
}
@media (max-width: $size-small) {
  .overlay {
    width: 100%;
  }
  .pourqui-box {
    padding: 50px 25px;
  }
}

</style>