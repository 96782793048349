  /**
   *
   * CObjectView se construit de plusieurs façon :
  *  - soit automatiquement depuis les champs de la CClass
     - soit parce qu'on lui passe la liste des champs à afficher, et cela reste de l'automatique
     - soit on passe un slot qui contient les champs rajoutés manuellements (ce qui permet alors de faire
       la mise en page que l'on souhaite

   Dans les 2 premiers cas les champs sont simplement alignés verticalement.

  Le principe est le même que CObjectView en java, sinon que en java on utilise les tags/group/tab pour
  effectuer le layout des champs.
  lorsque l'on utilise un slot c'est une sorte de méthode intermédiaire entre le layout automatique
  et l'utilisation d'un template.

  Dans le cas de la construction automatique on construit des b-field (PENDING k-field aussi, mais passer
  en c-field-view et passer les CXXXFieldView en CXXXFieldEditor. La façon de récupérer les editeurs
  est que l'on positionne leur ref à la construction.
  Dans le cas d'un slot, celui-ci contient des k-field, qui eux meme on des refs, avec des composants
  sur lesquels on a positionné un v-model qui est dans data : rien à voir !!
  on a besoin d'avoir le meme system field (label) + editor, quelque soit le composant
  */
  <template>
    <div class="c-object-view">
      <slot>
<!--        si Kfield alors pas de field donc impossible de recup les value dans les reference -->

      </slot>

     </div>
</template>

<script>
    // import { GensyndMixin } from '../ui/GensyndMixin.js'
    import kit from '../kit'
    import CObject from '../data/CObject'
    import CEnumFieldView from './CEnumFieldView'
    import CTextFieldView from './CTextFieldView'
    import CDateFieldView from './CDateFieldView'
    import CRelationFieldView from './CRelationFieldView'

    export default {
        name: "CObjectView",
        // mixins: [GensyndMixin],

        props: {
            cclass: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                // cclass: null,
                customFields: null,
                object: null,
                errors: null
            }
        },
        methods: {
            fieldName: function(field) {
                return kit.model.cclass(this.cclass).field(field).name()
            },
            viewClass: function(field) {
                console.log("--view class de " + field + " : " + kit.model.cclass(this.cclass).field(field)
                    + ", view=" + kit.model.cclass(this.cclass).field(field).constructor.name)
                var f = kit.model.cclass(this.cclass).field(field)
                //alert('view class, f=' + f + ' name=' + f.kind)
                return f.kind + 'View'
            },
            errorMessage: function(field) {
                return this.errors === null ? null : this.errors.error(field)
            },
            errorType: function(field) {
                return this.errorMessage(field) === null ? null : 'is-danger'
            },
            isHidden: function(field) {
                var f = kit.model.cclass(this.cclass).field(field)
                console.log("isHidden field=" + field + " : " + f.hasTag('NO_LABEL'))
                return f.hasTag('NO_LABEL') ? 'hidden' : ''
            },
            /** call check service with the object */
            check: function(callback) {
                var object = this.value;
                kit.services.check(object, () => {
                    this.errors = null
                    callback(this.value)
                }, (error) => {
                     var errorFields = error.errorFields()
                    this.errors = error
                })
            },

        },
        computed: {
          slotPassed () {
            return !!this.$slots.header
          },
            fields: function() {
                kit.log.log("fields=" + kit.model.cclass(this.cclass).fields())
                if (this.cclass()) {
                  // either use custom fields
                    if (this.customFields) {
                        return this.customFields
                    }
                    // or there is content, iterate over kfield into slot
                        else if (this.slotPassed()) {
                          var array = []
                          for (var r in this.$parent.$refs) {
                            if (this.$parent.$refs[r].$options.name === "KField") {
                              console.log("bim kfield " + r)
                              array.push(r)
                            }
                          }
                          return array
                    }
                    else {
                        var cclass = kit.model.cclass(this.cclass)
                        var fields = cclass.fields()
                        fields = fields.filter(f => !cclass.field(f).hasTag('HIDDEN'))
                         return fields;
                    }
                }
                else {
                    return []
                }
            },
            value: {
                get: function() {
                    var object = this.object == null ? new CObject(this.cclass) : this.object
                    for (var f of this.fields) {
                        var view = this.$refs[f][0] // [0] parce que les refs positionnées dans un v-for sont des array
                        console.log('read value for field ' + f + ', value=' + view.value())
                        object.set(f, view.value())
                    }
                    return object
                },
                set: function(newValue) {
                    this.object = newValue
                    // positionne les valeurs, mais on a pas forcement de vue
                    for (var f of this.fields) {
                        if (this.$refs[f]) {
                            var view = this.$refs[f][0] // [0] parce que les refs positionnées dans un v-for sont des array
                            console.log('set value for field ' + f + ', value=' + this.object.value(f))
                            view.value = this.object.value(f)
                            // object.set(f, view.value())
                        }
                    }
                    // reset errors
                    this.errors = null
                }
            }
        }
    }
</script>

<style scoped>

</style>
