<template>
    <div class="vbox">
      <slot/>
    </div>
</template>

<script>
    export default {
        name: "Vbox"
    }
</script>

<style lang="scss" scoped>
@import "../../assets/kit.scss";

</style>
