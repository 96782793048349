<template>
  <div  class="gip-bg-default">
    <div class="fondsAide gip-max-width ">
      <div class="gip-mobile-padding">
        <h1 class="alternance title"> <span>2 fonds d’aide</span> pour soutenir l’emploi<br/> artistique et technique du spectacle<br/> vivant</h1>
        <p class="subtitle gip-label" id="choix-fonds">Choisir mon fonds d’aide :</p>
        <hbox class="aide-container align-baseline space-around">
          <p :class="{active: isAideNormale}" v-on:click="showSpecifique()" >{{site.titreSimpleFondsChr}}</p>
          <p :class="{active: !isAideNormale}" v-on:click="showExceptionnelle()">{{site.titreSimpleFondsOccasionnel}}</p>
        </hbox>
      </div>
      <div v-if="isAideNormale" class="fonds-box show ">
        <div class=" overlay ">
          <div class="gip-mobile-padding">
            <p v-html="site.titreFondsChr" class="titre-interne alternance"></p>
            <div>
              <hbox class="info-box flex-wrap alert">
                <info-small-pink-icon class="info-icon"/>
                <span v-html="site.resumeFondsChr">
                  </span>
              </hbox>
            </div>
            <p class="text" v-html="site.texteLongFondsChr">
            </p>
            <div class="btn-container">
              <BtnAction type="action"  go-to="Comprendre" titre="COMPRENDRE LE CALCUL DE L’AIDE"/>
              <BtnAction go-to="Simulateur" titre="FAIRE UNE SIMULATION"/>
            </div>
          </div>
        </div>
        <Sphere  top="160" right="-80"  class="hidden-small" couleur="grey" taille="213"/>
      </div>
      <div v-else class="fonds-box show">
        <div class="overlay">
          <div class="gip-mobile-padding">
            <p v-html="site.titreFondsOccasionnel" class="titre-interne alternance"></p>
            <div>
              <hbox class="info-box flex-wrap alert">
                <info-small-pink-icon class="info-icon"/>
                <span v-html="site.resumeFondsOccasionnel"></span>
              </hbox>
            </div>
            <p class="text" v-html="site.texteLongFondsOccasionnel">
            </p>
            <div class="btn-container">
              <BtnAction go-to="Comprendre" type="action" titre="COMPRENDRE LE CALCUL DE L’AIDE"/> <BtnAction go-to="Simulateur" titre="FAIRE UNE SIMULATION"/>
            </div>
          </div>
        </div>
        <Sphere  top="160" right="-80" couleur="grey"  taille="213"/>
      </div>

      <Simulateur ref="simulateur" :isAideCHR="isAideNormale"/>

      <Diviseur titre="CALCUL DE L’AIDE"/>

      <div class="calcul-aide">
        <div class="cacul-aide-preambule">
          <h2 class="alternance"> <span>  Règles de calcul </span>de l’aide<br/> spécifique</h2>

          <p id="Comprendre" class="subtitle gip-label">Applicable sur la masse salariale</p>

          <p class="text"> L’aide à l’emploi artistique correspond à la prise en charge de 26% à 65% de la masse salariale, <br/>
            selon le nombre d’artistes salariés, sur la base du cachet minimum brut indiqué par la Convention Collective
            Nationale du Spectacle Vivant Privé.<br/>
            À partir de 2 artistes salariés, le salariat d’un technicien peut être pris en compte, sur la même base de
            calcul que pour les artistes.</p>
          <!--      <div class="error">-->
          <!--        <warning-icon/>-->
          <!--        <p>barème valable jusqu’au 30 septembre 2022</p>-->
          <!--      </div>-->
          <br><br>
        </div>
        <table>
          <thead>
          <tr class="head">
            <th>Nombre de salaires</th>
            <th>Prise en charge</th>
            <th>{{ isAideNormale ? 'Soit en euros' : 'jauge &lt;300 pers.'}}</th>
            <th v-if="!isAideNormale">jauge &gt;=300 pers.</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="n in 10" :key="n">
            <td>{{ n }}</td>
            <td>{{ site['taux' + n] }}%</td>
            <td>{{ site['aide' + n] }}€</td>
            <td v-if="!isAideNormale">{{ site['aideOccas' + n] }}€</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div id="fonctionnement">
        <Diviseur titre="FONCTIONNEMENT"/>

        <h2 class="alternance titre-guso gip-mobile-padding">Une interopérabilité <br/> avec le <span>GUSO</span></h2>
        <div class="gip-sphere-container">
          <div class="gip-overlay">

            <hbox class="avecGuso flex-wrap-reverse" >
              <hbox class=" flex-grow-1 justify-center align-center">
                <div class="avecguso-box">
                  <vbox class="align-center gip-mobile-padding">
                    <p class="text">
                      Le Gip Cafés Cultures gère un fonds d’aide destiné à favoriser l’emploi<br/>
                      artistique et technique du spectacle vivant. Ce fonds est financé par le<br/>
                      Ministère de la Culture et par les collectivités territoriales qui souhaitent<br/>
                      déployer ce dispositif sur leur territoire.<br/>
                      <br/>
                    </p>
                    <p class="subtext">
                      Par le déploiement d’un dispositif technique spécifique avec le GUSO,<br/>
                      le Gip Cafés Cultures est informé du bon règlement des cotisations sociales<br/>
                      qui conditionne le versement de l’aide à l’emploi artistique et technique aux employeurs. Cette interopérabilité garantit ainsi l’objectif de pérenniser<br/>
                      les professions du spectacle vivant.
                    </p>
                  </vbox>
                </div>
              </hbox>
              <img class="img" src="@/assets/image/franche-compte.jpg" alt="">
            </hbox>

          </div>
          <Sphere top="-80" right="-80" taille="218" class="hidden-small"></Sphere>
        </div>

        <schema-fonctionnement/>
      </div>


    </div>
  </div>
</template>

<script>
import BtnAction from "../ui/BtnAction";
import Simulateur from "../ui/Simulateur";
import Sphere from "../ui/Sphere";
import Diviseur from "../ui/Diviseur";
import InfoLogo from "../../assets/svg/info_logo.svg"
import GipMixin from "../ui/GipMixin";
import SchemaFonctionnement from "./SchemaFonctionnement";

export default {
  name: "FondsAide",
  mixins: [GipMixin],
  components:{BtnAction, Diviseur, Simulateur, Sphere, SchemaFonctionnement},

  props: {
    aideNormale: {
      default: true
    }
  },
  data() {
    return {
      isAideNormale: this.aideNormale,
      site: null,
    }
  },
  methods: {
    showSpecifique() {
      this.isAideNormale = true

    },
    showExceptionnelle() {
      this.isAideNormale = false;
    }
  },
  created() {
    // alert ("brut=" + this.site.montantMinimumBrut)
    console.log("on a this.site oouuuuuuu")
    console.log(this.state.site)
    this.site = this.state.site
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/gip.scss";
@import "../../assets/kit.scss";

.title{
  padding-top:100px ;
}

.subtitle{
  //padding-top: 97px ;
  //padding-bottom: 34px;
}

#choix-fonds {
  padding-top: 5em;
  padding-bottom: 1em;
}

.img {
  display: block;
  max-width: 40%;
  height: auto
}
.titre-guso {
  padding-bottom: 60px;
}

.overlay {
  position: relative;
  z-index: 2;
  padding: 92px 0;
  background: white;
}
.fonds-box.show {
  text-align: left;
  background: white;
  position: relative;
  box-shadow: 0px 3px 23px #04437010;
  border-radius: 5px;
  max-width: 1008px;
  margin:0 auto;
  margin-top: 60px ;

  .btn-container {
    padding-top: 50px;
    text-align: right;
  }

  .alert {
    background: var(--gip-grey-color);
    padding: 2em;
    font-weight: 600;
  }
  .text {
    margin-top: 24px ;
    font-weight: 500;
    font-size: 15px;
  }
}

.avecGuso {
  text-align: center;
  margin: 0 auto;
  background: white;
  min-height: 400px;
  border-radius: 5px;
  padding-bottom: 80px ;
  box-shadow: 0px 3px 23px #04437010;
  .img{
    border-radius: 0px 5px 5px 0px;

  }
  .avecguso-box {
    p{
      text-align: left;
      font-size: 15px;
      max-width: 536px;
      padding: 0px 5px;
    }

    vertical-align: top;

    // padding: 0px 34px 0px 34px;
    display: inline-block;
    background: white;

  }
  .text{


    font-weight: 600;
  }
  .subtext{
    font-weight: 500;
    margin-top: 20px;
  }
  .image-container{
    vertical-align: top;
    width: 352px;
    flex-grow: 1;
    border-radius: 0px 5px 5px 0px;

    height: 400px;
    background: url("../../assets/image/franche-compte.jpg");
    display: inline-block;
    position: relative;
  }
}
img{
  width: 100%;

}
.calcul-aide {
  margin: 0 auto;
  max-width:720px;
  .cacul-aide-preambule{
    padding:  0 34px 0 34px;

  }

  .subtitle{
    font-weight: 700;
    text-align: left;
    margin-top: 61px;
  }
  .text {
    margin-top: 24px ;
    font-weight: 500;
    text-align: left;
  }


  .error {
    margin-top: 24px;
    padding-bottom: 2em;
    color: var(--gip-error-color);
    text-align: left;
    p{
      display: inline-block;
      padding-left: 12px;
    }
    margin-bottom: 24px;
  }

  table {
    font-size: 18px;
    border-collapse: collapse;

    thead {

      th {
        width: 260px;
        padding: .8em 1em;
        color: var(--gip-action-color);
      }

    }
    tr:nth-child(odd){
      background: var(--gip-grey-color);
    }
    tr:nth-child(even){
      background: var(--gip-white-color);
    }
    tr.head {
      background: var(--gip-menu-background);
    }
    td {
      padding: .6em .5em;
      font-weight: 600;
    }
  }
}

.aide-container{


  p{
    display: inline-block;
    position: relative;
    font-weight: 600;
    cursor:pointer;
    font-size: 20px ;
    color: var(--gip-disabled-color);
    margin-bottom: 14px;
    &.active {
      color: var(--gip-action-color);
      padding-bottom: .5em;
      border-bottom: solid 3px var(--gip-action-color);
    }
  }
}

.fondsAide {
  text-align: center;
}

#fonctionnement {
  padding-top: var(--gip-padding-header);
}

.titre-interne {
  padding-bottom: 1em;
}
@media all and (max-width: 960px) {
  .img{

    max-width: 100%;
  }
}


</style>