<template>
  <div id="derniers-membres">

    <Diviseur titre="NOS DERNIERS MEMBRES"></Diviseur>
    <div class="arrow-container">
      <div v-on:click="prev" class="arrow first">
        <FlecheLogo    color="#C22292" rotate="180"/>
      </div>
      <div v-on:click="next" class="arrow">

        <FlecheLogo  rotate="0" color="#C22292"/>
      </div></div>

<!--    <b-carousel-list ref="carousel" :data="membres" :items-to-show="2.5">-->
<!--      <template #item="membre">-->
<!--        <CardMembre :data="membre"/>-->
<!--      </template>-->
<!--    </b-carousel-list>-->


    <carousel-3d ref="carousel" :loop="true" :perspective="0" :border="0" :count="membres.length"   :width="this.width" :disable3d="true" :space="this.space"  :height="this.height" :clickable="false" :controls-visible="false">
      <template v-slot:default>
      <slide class="gip-slide" v-for="(membre,i) in membres" :key="i" :index="i">
          <div class="carousel-card"><CardMembre   :data="membre" /></div>

      </slide>
      </template>
    </carousel-3d>



     <div class="btn-container"> <BtnAction class="button-center" to="Membres" titre="TOUS LES MEMBRES"></BtnAction></div>

    <div class="spacing">
    </div>


  </div>
</template>

<script>
import Diviseur from "./Diviseur";
import BtnAction from "./BtnAction";
import CardMembre from "./CardMembre"
import FlecheLogo from "../Icons/FlecheLogo";
import {BCarousel} from "bootstrap-vue";
import {BCarouselSlide} from "bootstrap-vue";
import { Carousel3d, Slide } from 'vue-carousel-3d';

import kit from "../../kit/kit";
import CFilter from "../../kit/data/CFilter";
export default {
  name: "DernierMembre",
  components: {FlecheLogo,Diviseur,BtnAction,CardMembre,Carousel3d,Slide},
  data() {
    return{
      membres:[""],
      sliderIndex: null,
      offset: 0,
      limit:10,
      nbSlide: 5,
      space:320,
      width:300,
      height:500
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.goPrev()
    },
    next() {
      this.$refs.carousel.goNext()
    },
    resize(){
      if(window.innerWidth > 960) {
        this.height = 300;
        this.width = 520;
        this.space = 550;
      }else{
        this.height = 500;
        this.width = 300;
        this.space = 320;
      }

    },
    loadMembres(){
      kit.call(() => {
        var query = {cclass: 'Membre', json: true, filter: new CFilter("Membre.pageVisible", "O"),
          limit:this.limit, limitOffset: this.offset, orderField:"membreId", reverse:true}
        kit.services.query(query,
            (set, req) => {
              this.membres =  set

             // console.log(this.offset)
            },
            () => {
              kit.log.error("erreur DernierMembre")
            }
        )

      });
    return this.membres
    }
  },

  mounted() {
   let v =  this.loadMembres();
    this.resize()
    window.onresize = this.resize
  }
}
</script>

<style lang="scss" >
.membre-carousel .carousel-inner{
  overflow: visible !important;
}
</style>

<style lang="scss" scoped>

.arrow-container{
  text-align: right;
  //padding: 25px 0px 25px 0px;

  div{
    text-align: center;
  }
}
.gip-slide{
  text-align: center;
}
.gip-card {
  //display: inline-block;
  margin-right: 10px;
}

.carousel-3d-container {
  .carousel-3d-slide {
    background: none;
    border: none;

    .title { font-size: 22px; }
  }
}

.btn-container{
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.carousel{ // on force la class boostrap
  text-shadow: none !important;
}
.arrow.first {
  margin-right: 20px ;
}

.arrow{
  background: white;
  width: 33px;
  cursor: pointer;
  height: 33px;
  border-radius: 16px;
  box-shadow: 0px 3px 23px #0443700F;
  border: 1px solid #D5DCE1;
  display: inline-block;

}

svg{
  vertical-align: sub;

}
.button-center{
  margin: 0 auto;
}

.spacing{
  padding-bottom: 100px;

}
.carousel-card {
  display: inline-block;
  vertical-align: middle;
}

#derniers-membres {
  margin-top: var(--gip-padding-header);
}

</style>