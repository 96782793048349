<template>
  <div class="Footer">
    <div v-if="!isSmall" class="TitreFooter">
      <div class="image-container">
        <div class="image"></div>
      </div>
      <h2 class="titre">Depuis sa création en 2015 <br>le Gip Cafés Cultures a soutenu</h2>
      <p class="nombre" v-html="formatValeur(chiffrePrincipal)"></p>
      <p class="spectacles">{{chiffrePrincipal.legende}}</p>
      <div>
        <div>
          <vbox class="chiffre-cle">
            <diviseur class="titre_autres-chiffres" titre="AUTRES CHIFFRES CLÉS"></diviseur>
            <hbox class="justify-center wrap-medium">
              <hbox class="fill wrap-small">
              <div class="fill">
                <p class="chiffre" v-html="formatValeur(chiffresCles[0])"></p>
                <p class="legende"> {{ chiffresCles[0].legende }} </p>
                <p class="date"> {{ chiffresCles[0].legende2 }} </p>
              </div>
              <div class="fill">
                <p class="chiffre Rio" v-html="formatValeur(chiffresCles[1])"></p>
                <p class="legende"> {{ chiffresCles[1].legende }} </p>
                <p class="date"> {{ chiffresCles[1].legende2 }} </p>
              </div>
              </hbox>
              <hbox class="fill wrap-small">
              <div class="fill">
                <p class="chiffre Accent" v-html="formatValeur(chiffresCles[2])"></p>
                <p class="legende"> {{ chiffresCles[2].legende }} </p>
                <p class="date"> {{ chiffresCles[2].legende2 }} </p>
              </div>
              <div class="fill">
                <p class="chiffre" v-html="formatValeur(chiffresCles[3])"></p>
                <p class="legende"> {{ chiffresCles[3].legende }} </p>
                <p class="date"> {{ chiffresCles[3].legende2 }} </p>
              </div>
              </hbox>
          </hbox>
          </vbox>
        </div>
      </div>
    </div>

    <div class="logo-gip">
      <GipCafesCulturesLogo class="logo-gip"/>
    </div>
    <div class="logo-gafa">
      <a href="https://www.linkedin.com/company/gip-cafes-cultures/" target="_blank" title="Le LinkedIn du Gip Cafés Cultures">
        <LinkedinLogo class="facebook-logo"/>
      </a>
      <a href="https://fr-fr.facebook.com/gipcafescultures/" target="_blank" title="Le Facebook du Gip Cafés Cultures">
        <FacebookLogo  class="facebook-logo"/>
      </a>
      <a href="https://www.instagram.com/gipcafescultures/" target="_blank" title="Le Instagram du Gip Cafés Cultures">
        <InstagramLogo class="facebook-logo"/>
      </a>
    </div>
    <hbox class="form-container wrap-small space-around gip-page ">
      <hbox class="footer-info fill">
      <div class="GipAdresse">
        <h3>GIP CAFÉS CULTURES</h3>
        <p>
          <span>2 impasse Girardon</span>
          <span>75018 Paris</span>
          <span>01 42 58 98 90</span>
          <span>contact@gipcafescultures.fr</span></p>
      </div>
      </hbox>
        <hbox id="lien-container" class="footer-info fill">
      <div class="LienUtiles">

        <h3>LIENS UTILES</h3>
        <p>

          <!--        <div v-for="(nom,lienId) in liens" :key="lienId">-->
          <!--        <a><FlecheLogo class="icon"/>test</a>-->
          <!--        <a><FlecheLogo class="icon"/>test</a>-->
          <!--</div>-->
          <a v-for="(lien, lienId) in liens" :key="lienId" @click="onLienSelected(lien)" :title="lien.url">
            <FlecheLogo class="iconFleche"/>
            {{ lien.nom }}</a>

        </p>

      </div></hbox>

      <form id="Contact" class="Form fill" action="">
        <hbox class="wrap-small">
          <k-field class="fill custom-margin" nolabel ref="nom">
            <input type="text" name="nom" placeholder="Nom" class="fill" v-model="demandeInfo.nom">
          </k-field>
          <k-field class="fill" nolabel ref="prenom">
            <input type="text" name="prenom" placeholder="Prénom" class="fill" v-model="demandeInfo.prenom">
          </k-field>
        </hbox>
        <hbox class="wrap-small">
          <k-field class="fill custom-margin" nolabel ref="telephone">
            <input type="text" class="fill" name="telephone" placeholder="Téléphone" v-model="demandeInfo.telephone" :disabled="disableInput">
          </k-field>
          <k-field class="fill" label="pouf" nolabel ref="mail">
            <input type="text" class="fill" name="email" placeholder="E-mail" v-model="demandeInfo.mail" :disabled="disableInput">
          </k-field>
        </hbox>
        <hbox>
          <k-field class="fill" nolabel ref="message">
            <textarea class="textarea fill" v-model="demandeInfo.message" name="message" placeholder="Taper votre Message"></textarea>
          </k-field>
        </hbox>
        <BtnAction class="btn-right" titre="ENVOYER" :click="this.envoyerDemande"/>
      </form>
    </hbox>

    <div class="Mention">
      <a href="/mentionsLegales">Mentions légales</a>
    </div>

  </div>

</template>

<script>
import GipMixin from '../ui/GipMixin.js'
import GipCafesCulturesLogo from "@/components/Icons/GipCafesCulturesLogo";
import LinkedinLogo from "@/components/Icons/LinkedinLogo";
import InstagramLogo from "@/components/Icons/InstagramLogo";
import FacebookLogo from "@/assets/icons/facebook.svg";

import BtnAction from "@/components/ui/BtnAction.vue"
import Diviseur from "@/components/ui/Diviseur";
import FlecheLogo from "@/components/Icons/FlecheLogo";
import kit from '../../kit/kit'
import CObject from "@/kit/data/CObject";
import { default as CFilter, CFilterCondition, CFilterType } from '../../kit/data/CFilter.js'


export default {
  name: "Footer",
  mixins: [GipMixin],
  components: {FlecheLogo, Diviseur, BtnAction, LinkedinLogo, FacebookLogo, GipCafesCulturesLogo,InstagramLogo},
  props: {
    isSmall: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    'state.user' (newVal, oldVal){
      console.log("FOOOT", newVal)
        if(newVal !== null){
          this.disableInput = true
          this.demandeInfo.nom = newVal.nomGerant;
          this.demandeInfo.prenom = newVal.prenomGerant;
          this.demandeInfo.mail = newVal.mail;
          this.demandeInfo.telephone = newVal.tel;
        }else{
          this.demandeInfo.nom = "";
          this.demandeInfo.prenom = "";
          this.demandeInfo.mail = "";
          this.demandeInfo.telephone = "";
        }
    },

},

  data() {
    return {
      liens: [],
      disableInput : false,
      demandeInfo: {
        nom: null,
        prenom: null,
        mail: null,
        telephone: null,
        message: null,
      },
      chiffrePrincipal: {
        "__cclass": "ChiffreCle",
        "__id": "1",
        "chiffreCleId": "1",
        "position": "1",
        "id": "nbAdherents",
        "type": "A",
        "legende": "collectivités adhérentes",
        "legende2": "depuis juin 2015",
        "valeur": "71"
      },
      chiffresCles: [
        {
          "__cclass": "ChiffreCle",
          "__id": "1",
          "chiffreCleId": "1",
          "position": "1",
          "id": "nbAdherents",
          "type": "A",
          "legende": "collectivités adhérentes",
          "legende2": "depuis juin 2015",
          "valeur": "71"
        },
        {
          "__cclass": "ChiffreCle",
          "__id": "2",
          "chiffreCleId": "2",
          "position": "2",
          "id": "nbBeneficiaires",
          "type": "E",
          "legende": "établissements demandeurs",
          "legende2": "depuis juin 2015",
          "valeur": "2668"
        },
        {
          "__cclass": "ChiffreCle",
          "__id": "3",
          "chiffreCleId": "3",
          "position": "3",
          "id": "nbCachets",
          "type": "C",
          "legende": "salaires aidés",
          "legende2": "depuis juin 2015",
          "valeur": "67085"
        },
        {
          "__cclass": "ChiffreCle",
          "__id": "4",
          "chiffreCleId": "4",
          "position": "4",
          "id": "montantAide",
          "type": "M",
          "legende": "d’aides attribuées",
          "legende2": "depuis juin 2015",
          "valeur": "4788",
          "unite": "k€"
        }
      ],

      kfields: null,
    }
  },
  mounted() {

    let employeur = this.state.user
    console.log("footer state user",employeur)

    if(employeur !== null){
      this.disableInput = true
      this.demandeInfo.nom = employeur.nomGerant;
      this.demandeInfo.prenom = employeur.prenomGerant;
      this.demandeInfo.mail = employeur.mail;
      this.demandeInfo.telephone = employeur.tel;

    }
    kit.call(() => {

      // charge les liens
      var filter = new CFilter(CFilterType.OR)
      filter.add("Lien.type", 'DOC')
      filter.add("Lien.type", 'URL')

      kit.services.query({cclass: 'Lien', filter: filter, json: true},
          (set, req) => {
            this.liens = set
          },
          () => {
            kit.log.error("erreur liens")
          }
      )
      // charge les chiffres clés
      kit.services.query({cclass: "ChiffreCle", orderField: "position", json: true},
          (set, req) => {
             this.chiffrePrincipal = set[0]
             set.splice(0, 1)
             this.chiffresCles = set
           },
          () => {
            kit.log.error("erreur chiffres clés")
          }
      )
    });
    this.kfields = this.getFields(this)
  },

  methods: {
    // selection d'un lien
    onLienSelected: function(lien) {
      if (lien.type === "URL") {
        window.open(lien.url, "_self")
      }
      else {
        window.open("/download/" + lien.url)
        // alert("doc pdf " + lien.url)
      }
    },
    // formatte la valeur d'un chiffre clé avec éventuellement son unité
    formatValeur: function(chiffreCle) {
      return this.format2(chiffreCle.valeur) + (chiffreCle.unite ? chiffreCle.unite : "")
    },

    /** clear les messages d'erreur */
    clearFields: function() {
      for (var kfield of this.kfields) {
        this.$refs[kfield].setError(null)
      }
    },
    /** verification et envoi de la demande d'informations */
    envoyerDemande: function() {

      let object = new CObject("DemandeInfos")

      for (var kfield of this.kfields) {
        let val = this.demandeInfo[kfield]
        if (val === undefined)
          val = null
        object.newValue(kfield, kit.model.cclass("DemandeInfos").field(kfield).convert(val))
      }
      // rajoute l'employeur si defini
      if (this.state.isEmployeur && this.state.user != null)
          object.newValue("employeurId", this.state.user.employeurId)

      // appelle le service de check. Si ok on appelle la methode en prop 'update', et sinon
      // on met à jour les champs en erreur
      
      
      kit.services.check(object, () => {
            this.clearFields()
            kit.services.submit(object, () => {
                  kit.dialogs.info("Votre demande a bien été envoyée et sera traitée dans les meilleurs délais")
                  this.addHistorique(this.demandeInfo["message"]);
                  if(this.state.user != null){
                    this.demandeInfo.message= null
                  } else {
                    this.demandeInfo = { nom: null, prenom: null, mail: null, telephone: null,  message: null}
                  }
                  this.disableInput = false;
                },
                (error) => {
                  kit.dialogs.error("problème lors de l'envoi de la demande")
                  console.log(error)
                })
          },
          (error) => {
            this.clearFields()
            for (var kfield of this.kfields) {
              if (error.errorFields().includes(kfield)) {
                this.$refs[kfield].setError(error.error(kfield))
              }
            }
          })
    },
    addHistorique: function(message) {
      if(this.state.user?.employeurId) {
            let historiqueObject = new CObject("Historique")
            historiqueObject.newValue("employeurId", this.state.user.employeurId)
            historiqueObject.newValue("texte", "demande infos: " + message)
            kit.services.submit(historiqueObject, () => {
                  console.debug("historique ok")
              },
              (error) => {
                  console.error(error)
              }
          )
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.Footer {
  background: var(--gip-tint-color);
  color: white;
  text-align: center;
}

.facebook-logo{
  width: 27px;
  height: 27px;
}

.titre_autres-chiffres{
  padding-top: 70px;
}

.custom-margin{
  margin-right: 3px !important;
}

.form-container{
    padding: 0px 35px;
  & input:disabled{
    background: lightgrey;
    cursor:not-allowed;
    color: #696969;
  }
  background: var(--gip-tint-color);

}

.k-field{
  flex-basis:0;
}
.logo-gafa {
  margin-bottom: 70px;

  a {
    margin-right: 5px;
  }

}

.logo-gip {

  margin-top: 85px;
  margin-bottom: 20px;
}


.btn-right{
  float:right;
}

.chiffre-cle {

  background: white;
  margin: 0 auto;
  max-width: 84%;
  border-radius: 5px;
  color: var(--gip-tint-color);
  font-size: 14px;
  font-weight: 700;

  position: relative;
  margin-top: 80px;


  .Data div {
    width: 260px;

    margin-bottom: 80px;
  }


  .chiffre {
    padding: 1em 0;
    font-size: 55px;
    line-height:55px;
    flex-grow: 1;
    margin: 0 auto;
    color: var(--gip-action-color);
    font-weight: 400;

    &.Rio {
      color: var(--gip-yellow-color);
    }

    &.Accent {
      color: var(--gip-accent-color);
    }
  }

  .date {
    font-weight: 400;
    font-size: 15px;

  }
}

.TitreFooter {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 500px;
    opacity: 0.45;
    z-index: -1;
    left: 0px;
    background: url("../../assets/image/footer_image.jpg");

  }

  margin: 0 auto;

  .titre {
    font-size: 35px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 120px;

    line-height: 45px;
    font-weight: 500;
  }

  .nombre {
    color: var(--Rio-Grande-color-c7d303);
    font-weight: 600;
    font-size: 4.5em;
    margin-bottom: 0.4em;
  }

  .spectacles {
    font-weight: 700;
    font-size: 15px;
  }
}

a {
  color: white;
}



.GipAdresse {
  text-align: left;
  font-size: 14px;
  display: block;
  margin-bottom: 60px;


  h3 {
    font-weight: 700;
    font-size: 14px;

  }

  p {
    font-weight: 500;

    span {
      display: block;
    }
  }
}
.logo-gip {
  width: 130px;
  padding: 25px 0 25px 0;
  margin: 0 auto;
}


.LienUtiles {
  text-align: left;
  display: block;

  .iconFleche {
    margin-right: 6px;
  }

  h3 {
    font-weight: 700;
    font-size: 14px;

    color: var(--Rio-Grande-color-c7d303)
  }

  p {
    font-weight: 600;

    a {
      display: block;
    }
  }
}

.Form {
  align: left;
  // on sup les 10px de margin sur le dernier input de la div
  //div:last-child input{
  //  margin: 0px 0px 20px 0px;
  //}

  input, textarea {
    background: white;
    border-radius: 5px;
    //margin: 0px 16px 20px 0px;
    padding: 18px;
    border: none;
    color: var(--gip-tint-color);

    &::placeholder, ::-webkit-input-placeholder {
      color: var(--gip-tint-color);
      font-family: "Raleway", sans-serif;
      font-size: 13px;
      font-weight: 500;
    }

    &.textarea {
      display: block;
      width: 100%;
      height: 231px;
      resize: none;
    }
  }



}

@media screen and (max-width: 960px){

  .footer-info{
    flex-direction: row;
    //justify-content: space-between;
  }
  #lien-container{
    order:3;
  }
  .chiffre-cle{
    max-width: 100%;
    border-radius: 0px;
  }
  .Form{

    input{
      margin: 20px 0 20px 0;

    }
    textarea{
      margin: 20px 0 20px 0;
    }
  }

}

.Mention {
  font-size: 11px;
  font-weight: 500;
  padding: 20px;
}

.legende {
  text-transform: uppercase;
  word-spacing: 100vw; // force le retour à la ligne tous les mots
}

.k-field:not(:last-child) {
  margin-right: 1em;
}
</style>