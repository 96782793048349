import CField from './CField.js';
import CObjectSet from './CObjectSet.js';
import Utilities from '../misc/Utilities.js'
import kit from '../kit.js'

import $ from 'jquery'

/**
 * a CObject is the base object in the framework. It store object cclass and field values
 * 
 * Take care : cclass and getter/setter use String args
 * @class com.omniapp.kit.model.CObject
 */
function CObject (cclass, id, data ) {

    this.cclass = cclass;
    this.id = id ? id : null;
    this.data = data ? data : {};
    this.newValues = null;
    this.fullText = null;

    // on veut clean data : virer les champs internes et convertir
    if (data) {
        if (data.__id)
            this.id = data.__id
        // delete data["__cclass"]
        // delete data["__id"]
    }

    // on convertit les relations qui sont des CObject en CObject
    for (var field in this.data) {
        if (this.data[field]) {
            if (Object.getPrototypeOf(this.data[field]) === Object.prototype) {
                console.log("literal pour " + field)
                this.data[field] = new CObject(this.data[field].__class, this.data[field].__id, this.data[field])
            }
            else if (Array.isArray(this.data[field])) {
                var children = this.data[field]
                console.log("array")
                var targetClass = kit.model.cclass(this.cclass).field(field).targetClass()
                var set = new CObjectSet(targetClass)
                for (const child of children)
                    set.add(new CObject(targetClass, null, child))
                this.data[field] = set
            }
        }
    }

    /** set/get new value */
    this.newValue = function(field, value) {
        if (value !== undefined) {
            if (this.newValues === null)
               this.newValues = {};
            this.newValues[field] = value;
        }
        return this.newValues[field];
    };

    /** set a value */
    this.set = function(field, value) {
        if (!value)
           delete this.data[field];
        else
           this.data[field] = value;
    };

    /**
     * retrieve a value with its natural type
     * @param {String} field the field we want the value for
     * @return natural value of the field
     */
    this.value = function(field) {
        var cfield = kit.model.cclass(this.cclass).field(field);
        if (cfield == null)
            throw 'field ' + field + ' not found on cclass ' + this.cclass
        return cfield.convert(this.data[field]);
    };

    /** retrieve display value. supports pseudo-path for field */
    this.displayValue = function(field) {
        if (field.contains('.')) {
            var i = field.indexOf('.');
            var ff = field.substring(0, i);
            var rel = this.value(ff);
            if (rel === null) {
                alert('null, field=' + field + ' objectid=' + this.id);
                return '';
            }
            return rel.displayValue(field.substring(i+1));
        }
       var cfield = kit.model.cclass(this.cclass).field(field);
       //if (cfield === null)
       //		kit.log.error('field ' + field + ' not found on cclass ' + this.cclass);
       return cfield.displayValue(this.value(field));
    };

    /** retrieve string value */
    this.stringValue = function(field) {
       return this.data[field];    
    };

    /** retrieve full string value */
    this.fullString = function() {
        if (!this.fullText)
            this.fullText = self.name().unaccent().toLowerCase();
        return this.fullText;

    };

    /** retrieve object name, ie fields with TAG_NAME */
    this.name = function() {
        var name = kit.model.cclass(this.cclass).objectName(this);
        // alert('name is ' + name + ' for cclass=' + this.cclass);
        return name;
    };

    //this.name = this.objectName;

    /** retrieve values count */
    this.size = function() {
        var i = 0;
        for (var v in this.data)
           i++;
        return i;
    };

    /** format a string */
    this.format = function(format, helper) {
        if ((format === undefined) || (format === null))
            return format;
        var len = format.length;
        var out = '';
        var field = false;
        var inside = false;
        for (var i = 0 ; i < len ; i++) {
            var c = format.charAt(i);
            if (inside) {
                if ((c === '}') && field) {
                    out += this.displayValue(id);
                    inside = false;
                }
                else if ((c === ']') && !field) {
                    out += id;
                    inside = false;
                }
                else {
                    id += c;
                }
            }
            else if ((c === '[') || (c === '{')) {
                inside = true;
                id = '';
                field = c === '{';
            }
            else {
                out += c;
            }
        }
        return out;
    };


    this.toXML = function ( prolog ) {

        var xml = '<cobject cclass="' + data.cclass + '">';

        // iterate over each cfield value
        for ( var i in data.data ) {

            xml += "\n" + '<value cfield="' + i + '">';

            if ( typeof data.data[i] !== 'object' )
                xml += data.data[i];
            else
                xml += data.data[i].toXML();

            xml += "\n" + '</value>';

        }

        xml += '</cobject>';

        if ( prolog )
            xml = '<?xml version="1.0" encoding="utf-8"?>' + "\n" + xml;

        return xml;

    };

    this.toString = function() {
        return "[CObject cclass=" + this.cclass + " id=" + this.id + " name=" + this.name() + "]";
    };

}

CObject.fromXML = function(xmlNode) {

    // alert((new XMLSerializer()).serializeToString(xmlNode));
    // alert(xmlNode.nodeName);
    var cclass = $(xmlNode).attr('cclass');
    if (cclass === undefined) {
       alert('afah, val=' + $(xmlNode).html());
    }
    else {
        // if (cclass === 'UserSearch')
        // alert('c good, val=' + $(xmlNode).html());
    }
    var id = $(xmlNode).attr('id');
    var data = {};

    // Log.log('fromxlm, cclass=' + cclass);
    // var cObjectData = {
        // 'cclass': ,
        // 'id' : ,
        // 'data': {}
    // };

    var children = Utilities.children(xmlNode);
    var len = children.length;

    for (var i = 0 ; i < len ; i++) {
        var node = children[i];
        var field = node.attributes["cfield"].value;
        var nchildren = Utilities.children(node);
        // simple value
        if (nchildren.length === 0) {
            data[field] = node.textContent;
        }
        // CObject or CObjectSet
        else {
            var sub = nchildren[0];
            if (sub.nodeName === "cobjectset") {
                var set = CObjectSet.fromXml(sub);
                data[field] = set;
            }
            else {
                var obj = CObject.fromXML(sub);
                data[field] = obj;
            }
        }
    }


    return new CObject(cclass, id, data);

};

// CObject.fromHTML = function ( htmlNode ) {
//
//
//     var parseRelation = function (relation) {
//
//         var field = $(relation).attr('field');
//         var targetClass = $(relation).attr('targetClass');
//         var multiple = $(relation).hasClass('multiple');
//         var cobjects = [];
//
//         $(relation)
//             .children('.cobject:not(*[field="' + field + '"] .composition .cobject):not(.template)')
//             .each(function () {
//
//                 var data = {};
//                 var relationData = $(this).find('*:not(*[field="' + field + '"] .composition *)').serializeArray();
//                 for ( var i = 0; i < relationData.length; i++ ) {
//
//                     var f = relationData[i].name.split('.');
//                     data[f[f.length - 1]] = relationData[i].value;
//
//                 }
//
//                 $(this).find('.composition:not(*[field="' + field + '"] .composition .composition)').each(function () {
//
//                     var relation = GensyndService.serializeRelation(this);
//                     data[relation.field] = relation;
//
//                 });
//
//                 cobjects.push(data);
//
//             });
//
//         return {
//             'type': 'composition',
//             'multiple': multiple,
//             'field': field,
//             'targetClass': targetClass,
//             'cobjects': cobjects
//         };
//
//     };
//
//     var data = {};
//     var formData = $(htmlNode).find('*:not(.composition *)').filter('input, select, textarea').serializeArray();
//     for ( var i = 0; i < formData.length; i++ )
//         data[formData[i].name] = formData[i].value;
//
//     $(htmlNode).find('.composition:not(.composition .composition)').each(function () {
//
//         var relation = parseRelation(this);
//         data[relation.field] = relation;
//
//     });
//
//     return data;
//
// };

export default CObject
