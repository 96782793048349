/**
 * A simple message notification center. 
 * 
 * For now only allow to send/receive messages in a single session, but in future will
 * allow to use web sockets to connect to messages from the framework running as services.
 *
 * Usage :
 * listen('topic', callback);
 * send('topic', args)
 *
 * for the framework we would use it to track changes on objects/set
 * listen(cclass)
 * send(cclass, {type:delete, object:object});
 * @class com.omniapp.util.MessageManager
 */
var MessageManager = function() {

    // a map of topic/ [listeners]
    var _listeners = {};
        
    /** send a message to registred listeners on the topic */
    this.send = function(topic, args) {
        var receivers = _listeners[topic];
        if ((receivers === undefined) || (receivers.length === 0))
            console.log('no receiver for topic ' + topic);
        else {
            for (var i = 0 ; i < receivers.length ; i++)
                receivers[i](topic, args);
        }
    };
    
    /** listen on a given topic */
    this.listen = function(topic, callback) {
        if (_listeners[topic] === undefined)
            _listeners[topic] = [callback];
        else
            _listeners[topic].push(callback);
    };
    
    /** unlisten on a given topic */
    this.unlisten = function(topic, callback) {
        if (_listeners[topic] !== undefined) {
            var receivers = _listeners[topic];
            var index = receivers.indexOf(callback);
            if (index !== -1)
                receivers.splice(index, 1);
            if (receivers.length === 0)
                delete _listeners[topic];
        }
    };

    /** create a message */
    //this.message = function(topic, properties) {};
};

export default MessageManager
