<template>
  <div>
    <BackHeader2 class="justify-between">
      <hbox class="align-center">
        <h1>Configuration de la plaquette</h1>
        <b-form-select v-if="plaquettes.length > 1" @change="onUpdatePlaquetteType()" v-model="selectedPlaquette" :options="plaquetteTypes" ></b-form-select>
      </hbox>
      <a @click="show = !show; degreeFleche = (parseInt(degreeFleche) + 180).toString()">
        <FlecheLogo :rotate="degreeFleche" color="#FFFFFF" class="icon"/>
      </a>
    </BackHeader2>
    <transition name="fade">
      <form autocomplete="off">
      <div v-if="show">
        <!-- infos générales, les logos -->
        <div class="box">
          <h3>Informations <span>Générales</span></h3>
          <div class="ligne"></div>
          <div id="logos-help">Vous devez renseigner entre 1 et 3 logos (format png, fond transparent)</div>
          <hbox class="space-evenly">
            <k-field label="Logo*" ref="logo" nolabel>
              <ImageUpload v-model="logo" :path="path" titre="Logo n°1"/>
            </k-field>
            <k-field label="Logo*" ref="logoSup1" nolabel>
              <ImageUpload v-model="logoSup1" :path="path" titre="Logo n°2"/>
            </k-field>
            <k-field label="Logo*" ref="logoSup2" nolabel>
              <ImageUpload v-model="logoSup2" :path="path" titre="Logo n°3"/>
            </k-field>
          </hbox>
        </div>
        <!-- recto -->
        <div class="box">
          <h3>Informations sur le <span>Recto</span></h3>
          <div class="ligne"></div>
          <k-field label="Titre*" tooltip="Titre de la première page, environ 100 caractères">
            <input v-model="plaquette.titreRecto" type="text" placeholder="">
          </k-field>
          <k-field label="Image" ref="imageRectoContent">
            <ImageUpload :width="500" ref="imageRecto" v-model="imageRecto" :path="path" titre="Image recto"/>
          </k-field>
          <k-field label="Texte" ref="texteRecto">
            <StyledTextEditor :maxChars="450" v-model="plaquette.texteRecto"></StyledTextEditor>
          </k-field>
        </div>
        <!-- verso -->
        <div class="box">
          <h3>Informations sur le <span>Verso</span></h3>
          <div class="ligne"></div>
          <k-field label="Contacts" ref="imageVerso" nolabel>
            <ImageUpload :width="500" v-model="imageVerso" :path="path" titre="Image n°2"/>
          </k-field>
          <k-field label="Contacts" ref="contacts">
            <StyledTextEditor v-model="plaquette.contacts"></StyledTextEditor>
          </k-field>
          <k-field label="Texte" ref="texteVerso">
            <StyledTextEditor :maxChars="1000" v-model="plaquette.texteVerso"></StyledTextEditor>
          </k-field>

          <BtnAction id="saveconfig" :withLogo="false" titre="Enregistrer la configuration"
                     :click="this.saveConfig"/>

        </div>
      </div>
      </form>
    </transition>
  </div>
</template>

<script>
import ImageUpload from "../ui/ImageUpload";
import BackHeader from "../ui/back/BackHeader";
import BackHeader2 from "../ui/back/BackHeader2";
import GipMixin from "../ui/GipMixin";
import StyledTextEditor from "../ui/StyledTextEditor"
import FlecheLogo from "@/components/Icons/FlecheLogo"

import kit from "../../kit/kit"
import CObject from "../../kit/data/CObject"

export default {
  name: "ConfigMaquette",
  mixins: [GipMixin],
  components:{ImageUpload,BackHeader2,StyledTextEditor,FlecheLogo},
  data() {
    return {
      show: false,
      degreeFleche: "90",
      kfields: ["titreRecto", "texteRecto", "texteVerso", "contacts"],
      plaquettes: [],
      plaquetteTypes: [],
      selectedPlaquette: 0,
      plaquette : {
        logo: null,
        logoSup1: null,
        logoSup2: null,
        titreRecto: "titre recto",
        texteRecto: 'texte recto',
        imageRecto: null,
        imageRectoContent: null,
        texteVerso: "texte verso",
        contacts: "texte contacts",
        imageVerso: null,
      },
      // pour passer un seul objet à ImageUpload
      // image n'est renseigné que si on upload un fichier sinon fichier suffit
      imageRecto: {image: null, fichier: null, changed: false},
      imageVerso: {image: null, fichier: null, changed: false},
      logo: {image: null, fichier: null, changed: false},
      logoSup1: {image: null, fichier: null, changed: false},
      logoSup2: {image: null, fichier: null, changed: false}
    }
  },
  mounted() {
    // plaquette est une relation multiple avec actuellement 1 seul elt
    this.plaquettes = this.state.user.plaquette
    const types = { CHR: 'CHR', OCCAS: 'Occasionnels', COMMUNE: 'Commune'}

    for (let index = 0; index < this.plaquettes.length; index ++) {
      this.plaquetteTypes.push({ value: index, text: types[this.plaquettes[index].type] })
    }
    this.selectedPlaquette = 0
    this.selectPlaquette(0)
  },
  computed: {
    // chemin des images, positionné sur chaque ImageUpload
    path() {
      return "/images/membres/" + this.state.user.membreId + "/"
    },

  },
  methods: {
    
    selectPlaquette(index) {
      this.plaquette = this.plaquettes[index]
      this.imageRecto.fichier = this.plaquette.imageRecto
      this.imageVerso.fichier = this.plaquette.imageVerso
      this.logo.fichier = this.plaquette.logo
      this.logoSup1.fichier = this.plaquette.logoSup1
      this.logoSup2.fichier = this.plaquette.logoSup2

      // pour que les images se mettent à jour
      if(this.show) {
        this.show = false;
          this.$nextTick(() => {
          this.show = true;
        })
       
      }
    },
    onUpdatePlaquetteType() {
      this.selectPlaquette(this.selectedPlaquette)
    },
    
    /**
     * Vérification et enregistrement de la config de la maquette
     */
    saveConfig() {

      // on va d'abord essayer avec query normale, et sinon passer par un service particulier
      // on crée un object qui n'a que les valeurs des champs affichés dans la vue

      // on crée l'object
      let object = new CObject("Plaquette", this.plaquette.__id)

      // for (var ff of kfields)
      //   console.log("--->" + ff.id)
      for (var kfield of this.kfields) {
        // console.log("----> vue=" + kfield.__vue__)
        let val = this.plaquette[kfield]
        if (val === undefined)
          val = null
        object.newValue(kfield, kit.model.cclass("Plaquette").field(kfield).convert(val))
        console.log('set field ' + kfield + " value " + val)
      }

      // A L'ARRACHE
      if (this.imageRecto.changed) {
        object.newValue("imageRecto", this.imageRecto.fichier)
        object.newValue("imageRectoContent", this.imageRecto.image)
        console.log(this.imageRecto.fichier)
      }
      if (this.imageVerso.changed) {
        object.newValue("imageVerso", this.imageVerso.fichier)
        object.newValue("imageVersoContent", this.imageVerso.image)
      }
      if (this.logo.changed) {
        object.newValue("logo", this.logo.fichier)
        object.newValue("logoContent", this.logo.image)
      }
      if (this.logoSup1.changed) {
        object.newValue("logoSup1", this.logoSup1.fichier)
        object.newValue("logoSup1Content", this.logoSup1.image)
      }
      if (this.logoSup2.changed) {
        object.newValue("logoSup2", this.logoSup2.fichier)
        object.newValue("logoSup2Content", this.logoSup2.image)
      }
      // appelle le service de check. Si ok on appelle la methode en prop 'update', et sinon
      // on met à jour les champs en erreur
      kit.services.check(object, () => {
            this.errors = null
            kit.services.submit(object, () => {
                  this.$buefy.notification.open({
                    message: "La maquette a été mise à jour",
                    position: "is-bottom"
                  })
                  this.reloadPlaquette()
                },
                (error) => {
                  alert("pb update")
                  console.log(error)
                })
          },
          (error) => {
            this.errors = error
            for (var kfield of this.kfields) {
              this.$refs[kfield].setError(null)
              if (error.errorFields().includes(kfield)) {
                console.log("on a une erreur")
                this.$refs[kfield].setError(error.error(kfield))
              }
            }
          })
    },
    reloadPlaquette() {
      kit.services.service2("auth?action=info", {
          onSuccess: (data) => {
            if (data && data.plaquette) {
              this.state.user.plaquette = data.plaquette
              this.plaquettes = data.plaquette
            }
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

.bottom-margin{
  margin-bottom: 15px;
}

h3{
  font-size: 23px;
  color: var(--gip-tint-color);
  span{
    color: var(--gip-action-color);
    font-weight: bold;
  }
}

#saveconfig {
  margin: 0 auto;
  display: block;
  max-width: 250px;
  margin-top: 15px;
}

.ligne {
  border: solid 0.5px #04437010;
  margin: 15px 0px;
}

#logos-help {
  color : var(--gip-tint-color);
  margin-bottom: 1em;
}


</style>