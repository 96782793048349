<template>
  <div>

    <div v-if="!value.fichier">
      <div v-on:click="$refs.fileInput.click()" class="empty-box">
        <div class="place">
          <div class="camera-logo">
            <camera/>
          </div>
          <p class="placeholder">{{ titre }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div :style="style" class="image-hold">
        <img class="image-box" :src="pic"/>
        <div class="croix" v-on:click="removeImage">
          <redbin/>
        </div>
      </div>
    </div>
    <input style="display: none;" ref="fileInput" type="file" @change="onFileChange">

  </div>
</template>

<script>
import camera from '../../assets/icons/photo-camera.svg';
import redbin from "../../assets/icons/delete-icon.svg";

export default {
  name: "ImageUpload",
  components: {camera, redbin},
  props: {
    // value : {image, fichier, changed}
    value: {
      type: Object,
      default: () => ({
        image: null,
        fichier: null,
        changed: false
      }),
    },
    // fichier: {
    //   type: String,
    //   default : null
    // },
    titre: {
      default: null
    },
    path: {
      type: String,
      default: ""
    },
    width: {
      default: 200,
      type: Number
    }
  },
  data() {
    return {
      pic: this.path + this.value.fichier
    };
  },
  mounted() {
    // alert(this.value.fichier)
    // this.pic = "/images/Cantinallegra5.JPG"
  },
  computed: {

    style() {
      return 'width: ' + this.width + "px;";
    },
    //    realValue: {
    //     get() {
    //       return this.value;
    //     },
    //     set(val) {
    //       // let ed = this.$refs.editor
    //       this.$emit('input', {...payload, firstName: value});
    //       console.log("set val" + ed.quill.getText())
    //       this.fullText = ed.quill.getText()
    //       this.$emit('input', val);
    //     }
    // }
  },
  methods: {

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      this.pic = new Image();
      let reader = new FileReader();

      reader.onload = (e) => {
        // this.value.fichier = file.name
        // this.value.image = e.target.result
        // this.value.changed = true
        this.pic = e.target.result
        this.$emit('input', {fichier: file.name, image: e.target.result, changed:true})
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.pic = null
      // this.value.image = null
      this.$refs.fileInput.value = null;
      this.$emit('input', {fichier:null, image:null, changed:true})
    }
  }
}

</script>

<style lang="scss" scoped>
.image-hold {
  position: relative;
  background: var(--gip-sphere-grey);
  border-radius: 5px;
  display: table;
  cursor: pointer;
  height: auto;
  box-shadow: 0px 3px 23px #04437010;


}

.image-box {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  border-radius: 4px;
  padding: 25px;

}

img {
  display: block;
}

.empty-box {
  width: 200px;
  cursor: pointer;
  height: 200px;
  border: solid 0.5px grey;
  border-style: dashed;
  display: table;
  border-radius: 4px;

}

.placeholder {
  text-align: center;

}

.croix {
  color: red;
  padding: 2px;
  background: white;
  width: 33px;
  height: 33px;
  line-height: 0.7;
  border-radius: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  position: absolute;
  box-shadow: 2px 5px 55px #05437010;
  top: -11px;
  right: -15px;
}

.camera-logo {
  text-align: center;
  max-width: 100px;
  margin: 0 auto;
}

.place {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}


</style>