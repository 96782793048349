<template>
  <div>
    <div class="iconContainer">
      <InfoIcon class="infoIcon"/>
    </div>

  <h3>
    Désolé,<br>
    vous ne remplissez pas les critères d'éligibilité pour bénéficier des aides.
  </h3>
  </div>
</template>

<script>
  import InfoIcon from '@/components/ui/InfoIcon.vue';
  export default {
    components: {InfoIcon}
  }
</script>
<style scoped>
h3 {
  font-size: 22px;
  text-align: center;
  margin: 35px 0 50px 0;
}

.infoIcon {
  margin: auto;
}
</style>