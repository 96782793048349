
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CVirtualField. Javascript type is String
 * @class com.omniapp.kit.model.field.CVirtualField
 * @extends com.omniapp.kit.model.CField
 */
function CVirtualField(cclass, cfield) {

    CField.call(this, cclass, cfield, 'CVirtualField');

    var _rows = null;
    var _columns = null;

    /** String -> Number */
    this.convert = function(val) {
        return val;
    };
    /** Number -> String */
    this.displayValue = function(val) {
        if (this.formatter() !== null) {
            var f = this.formatter();
            return f.format(this, val);
        }
        return val == null ? '' : val;
    };
    /** Number -> display String */
    this.stringValue = function(val) {
        return val;
    };

} // end class CVirtualField

export default CVirtualField
