<template>
  <div>
    <EspaceHeader/>
  <Header v-slot:default="props">
    <div id="nav">
      <router-link @click.native="props.clickHandler" to="/financeur">Synthèse</router-link>
      <router-link @click.native="props.clickHandler" to="/financeur/documents">Documents</router-link>
      <router-link @click.native="props.clickHandler" to="/financeur/profil">Profil</router-link>

    </div>
  </Header>
    <transition name="gip-transition" mode="out-in">
      <div class="gip-bg-default">
          <router-view v-if="state.user"/>
      </div>
    </transition>
  <Footer :is-small="true"/>
  </div>
</template>

<script>
import Header from '../../components/site/Header.vue'
import Footer from '../../components/site/Footer.vue'
import EspaceHeader from '../../components/site/EspaceHeader.vue'
import GipMixin from "../ui/GipMixin"


export default {
  name: 'Financeur',
  mixins: [GipMixin],
  components: {Header, Footer,EspaceHeader}
}
</script>

<style scoped lang="scss">
@import "../../assets/gip.scss";

</style>
