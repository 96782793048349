<template>
  <div class="gip-bg-default" id="home">
    <div class="gip-max-width">
      <Carrousel/>
      <simulateur />
    </div>
    <Carte/>
    <div class="gip-max-width">
      <PourQui/>
      <PlusSurNous/>
      <DernierMembre/>
    </div>
  </div>
</template>

<script>


import Simulateur from "../ui/Simulateur.vue";
import Carrousel from "../ui/Carrousel";
import PourQui from "../ui/PourQui";
import Carte from "../ui/Carte";
import PlusSurNous from "../ui/PlusSurNous";
import DernierMembre from "../ui/DernierMembre"
export default {
  name: "Home",
  components: {DernierMembre,Carte,PlusSurNous,PourQui,Simulateur,Carrousel}
}
</script>


