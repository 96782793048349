
import CClass from '../CClass.js'
import CField from '../CField.js'

/**
 * CLinkField
 * @class com.omniapp.kit.model.field.CLinkField
 * @extends com.omniapp.kit.model.CField
 */
function CLinkField(cclass, cfield) {
    CField.call(this, cclass, cfield, 'CLinkField');

    // var _targetClass = null;
    // var _relationType = null;
    // var _compositionType = null;
    // var _multiple = null;
    /** String -> CEnumValue */
    this.convert = function(val) {
        return val;
    };
    this.displayValue = function(val) {
        return val;
    };
} // end class CLinkField

export default CLinkField
