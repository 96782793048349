<template>
  <vbox class="gip-page">
    <div class="gip-container">

      <vbox class="back-title fill">
        <hbox>
        <h1 class="first-title">Mon profil</h1>
          <div class="fill"/>
          <info-box>
            Vos informations ne sont pas modifiables pour le moment<br/>
            Cette fonctionnalité sera bientôt disponible.
          </info-box>
        </hbox>
      </vbox>

      <hbox class="wrap-small justify-start" id="documents">
        <vbox id="profil" class="fill">
          <back-header title="Informations en ligne" class="fill">
          </back-header>

          <form autocomplete="off" id="profil-content">
          <k-field label="Nom" ref="nom">
            <input v-model="membre.nom" type="text" placeholder="Le nom de votre structure" disabled>
          </k-field>
            <k-field label="Présentation" ref="presentation">
<!--              <textarea v-model="membre.textePersonnalite" type="text" placeholder="Description de votre entité"-->
<!--                rows="10" max-rows="50" disabled/>-->
                <StyledTextEditor v-model="membre.textePersonnalite" placeHolder="Le texte principal"
                                  :maxChars="200" :editable="false"/>
            </k-field>
            <k-field label="Lien" ref="lien">
              <input v-model="membre.lien" type="text" placeholder="Lien vers votre site" disabled>
            </k-field>
            <k-field label="Logo" ref="logo">
              <img id="logo-img" :src="logo"/>
            </k-field>
            <k-field label="Image" ref="image">
              <img id="image-img" :src="image"/>
            </k-field>
            <k-field label="Présentation" ref="presentation">
              <StyledTextEditor v-model="membre.accrochePersonnalite" placeHolder="Le texte de la personnalité"
                                :editable="false"></StyledTextEditor>
            </k-field>
            <k-field label="Nom personnalité" ref="nomPersonnalite">
              <input v-model="membre.nomPersonnalite" type="text" placeholder="Le nom de la personnalité"
                     rows="5" max-rows="50" disabled/>
            </k-field>
            <k-field label="Titre personnalité" ref="titrePersonnalite">
              <input v-model="membre.titrePersonnalite" type="text" placeholder="Le titre de la personnalité" disabled>
            </k-field>
            <k-field label="Photo personnalité" ref="photoPersonnalite">
              <img id="photo-img" :src="photoPersonnalite"/>
            </k-field>
         </form>
<!--          <b-form id="profil-content">-->
<!--            <b-form-group id="input-group-2" label="Nom" label-for="input-2">-->
<!--              <b-form-input-->
<!--                  id="input-2"-->
<!--                  class="disabled-input"-->
<!--                  disabled-->
<!--                  v-model="membre.nom"-->
<!--                  placeholder="Votre nom"-->
<!--                  required-->
<!--              ></b-form-input>-->
<!--            </b-form-group>-->
<!--            <b-form-group id="input-group-2" label="Présentation" label-for="input-2">-->
<!--              <b-form-textarea-->
<!--                  id="input-2"-->
<!--                  class="disabled-input"-->
<!--                  v-model="textePersonnalite"-->
<!--                  placeholder="Présentation"-->
<!--                  rows="4"-->
<!--                  max-rows="50"-->
<!--                  required-->
<!--                  disabled-->
<!--              ></b-form-textarea>-->
<!--            </b-form-group>-->
<!--            <b-form-group id="logo-group" label="Logo" label-for="logo">-->
<!--              <img id="logo-img" :src="logo"/>-->
<!--            </b-form-group>-->
<!--            <b-form-group id="logo-group" label="Image" label-for="image">-->
<!--              <img id="image-img" :src="image"/>-->
<!--            </b-form-group>-->

<!--            <b-form-group id="input-group-2" label="Présentation" label-for="input-2">-->
<!--              <b-form-textarea-->
<!--                  id="input-2"-->
<!--                  class="disabled-input"-->
<!--                  v-model="accrochePersonnalite"-->
<!--                  placeholder="Accroche"-->
<!--                  rows="4"-->
<!--                  max-rows="50"-->
<!--                  required-->
<!--                  disabled-->
<!--              ></b-form-textarea>-->
<!--            </b-form-group>-->

<!--            <hbox class="wrap-small">-->
<!--                <b-form-group id="input-group-2" label="Nom personnalité" label-for="input-2">-->
<!--                  <b-form-input-->
<!--                      id="input-2"-->
<!--                      class="disabled-input"-->
<!--                      v-model="membre.nomPersonnalite"-->
<!--                      placeholder="Votre nom"-->
<!--                      required-->
<!--                      disabled-->
<!--                  ></b-form-input>-->
<!--                </b-form-group>-->
<!--                <b-form-group id="input-group-2" label="Titre personnalité" label-for="input-2">-->
<!--                  <b-form-input-->
<!--                      class="disabled-input"-->
<!--                      id="input-2"-->
<!--                      v-model="membre.nom"-->
<!--                        placeholder="Votre nom"-->
<!--                        required-->
<!--                        disabled-->
<!--                    ></b-form-input>-->
<!--                  </b-form-group>-->
<!--                  </hbox>-->
<!--             </b-form>-->
        </vbox>
        <vbox id="identifiants" class="match">
          <password-updater type="Membre"/>
        </vbox>
      </hbox>
    </div>
  </vbox>

</template>

<script>
import GipMixin from '../ui/GipMixin.js'
import BackHeader from '../ui/back/BackHeader'
import {BModal} from "bootstrap-vue";
import DocumentCell from "../ui/back/DocumentCell";
import PasswordUpdater from "../ui/back/PasswordUpdater";
import InfoBox from "../ui/back/InfoBox";
// import { VueEditor } from 'vue2-quill-editor'
import StyledTextEditor from "../ui/StyledTextEditor"
export default {
name: "ProfilFinanceur",
  mixins: [GipMixin],
  components: {BackHeader, PasswordUpdater, InfoBox, StyledTextEditor},

  data() {
    return {
      membre: {
        "__cclass": "Membre",
        "__id": "2",
        "membreId": "2",
        "financeurId": "6",
        "ordre": "10",
        "nom": "Régidon Pays de la Loire",
        "adhesion": "2015-04-01",
        "lien": "http://www.paysdelaloire.fr/",
        "logo": "RVB-PDL_Institutionnel.jpg",
        "image": "PaysdeLoire2.jpg",
        "fondateur": "O",
        "enAvant": "N",
        "pageDetaillee": "O",
        "chiffresCles": [],
        "nomPersonnalite" : "nom personnalité",
        "titrePersonnalite" : "titre personnalité",
        "accrochePersonnalite": "L’engagement des fondateurs de ce groupement est de développer des bassins d’emplois artistiques et techniques,<br/><b>pour soutenir l’activité de spectacle dans les lieux de proximité que sont les cafés cultures.</b>",
        "textePersonnalite": "La Région des Pays de la Loire est attachée à l’emploi culturel et à la pérennité des lieux culturels de proximité qui sont un maillon important pour l’émergence de jeunes artistes. Elle a ainsi mené une expérimentation, de 2012 à 2014, dont l’objectif était de soutenir la diffusion d’artistes professionnels sur les petites scènes, dans un cadre juridique sécurisé par le financement des charges sociales.<br/>Ayant accompagné plus d’un millier de cachets chaque année, cette expérimentation ligérienne a permis d’évaluer le besoin des cafés et des artistes, de rendre crédible l’idée d’un tel dispositif à l’échelle nationale et d’en tester la mise en place. <br/>Afin de généraliser et de pérenniser cette expérimentation au plan national, la Région des Pays de la Loire a été membre fondateur, aux côtés d’autres institutions, du Ministère de la Culture, de collectivités et d’organismes professionnels, d’un groupement d’intérêt public (GIP) afin de créer et d&#39;assurer la gestion d&#39;un fonds d’aide à l’emploi artistique et technique. <br/>L’engagement des fondateurs de ce groupement est de développer des bassins d’emplois artistiques et techniques, pour soutenir l’activité de spectacle dans les lieux de proximité que sont les cafés cultures, et favoriser ainsi les circuits courts et l’accès du plus grand nombre à la culture.",
        "photoPersonnalite": "PaysdeLoire2.jpg"
      },
      foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },
  mounted() {
    this.membre = this.state.user
    // this.$refs.employeurForm.setEmployeur(this.employeur)
    // this.documents = this.employeur.documents
  },
  computed: {
  textePersonnalite: function() {
    return this.cleanup(this.membre.textePersonnalite)
  },
  accrochePersonnalite: function() {
      return this.cleanup(this.membre.accrochePersonnalite)
    },
    logo: function() {
      return "/images/membres/" + this.membre.logo
    },
    image: function() {
      return "/images/membres/" + this.membre.image
    },
    photoPersonnalite: function() {
      return "/images/membres/personnalites/" + this.membre.photoPersonnalite
    },
  },
  methods: {
    cleanup: function(text) {
      return this.decodeHtml(text)
          .replace("<br/>", "\n")
          .replace(/(<([^>]+)>)/gi, "")
    },
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },

  }
}
</script>

<style scoped lang="scss">

#logo-img {
  width:200px;
  background: var(--gip-sphere-grey);
  padding: 1.5em;
}
#image-img {
  width:500px;
  //background: red;
  padding: 0;
}
#photo-img {
  width:500px;
}
#profil-content {
  margin-top: .4em;
  padding-top: 2em;
  padding-left: 3em;
  background: var(--gip-white-color);
  padding-right: 3em;
  border-radius: 5px;
}

#identifiants {
  margin-left: 1em;
}


form .form-group {
  color: var(--gip-tint-color);
  font-size: 1em;
  font-weight:600;
}

.first-title {
  align-self: center;
}

.back-title {
  padding-bottom: 2em;
}

.gip-container {
  padding-bottom: 5em;
}

.disabled-input:disabled {
  background-color: var(--gip-white-color);
}
</style>